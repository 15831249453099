import React from "react";
import { View } from "react-native";
import rstyled from "styled-components";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { LinearGradient } from "expo-linear-gradient";
import dayjs from "dayjs";
import { useSchool } from "../commons/Stores";
import { ages, durtnText, wwidth, localLang } from "../commons/utils";
import {
  Text14 as UIText14,
  Text16 as Text,
  RowBetween,
  Row,
  Press,
  RowCentered,
  DGRAY,
  CardImage,
  GREEN,
  GRAY,
  BACKGRAY,
  LITEBORDER,
  MarkerComp,
  Medium18,
  Medium28,
  BACKGREEN,
} from "../commons/UI";
import { DurComp, TimeComp } from "./TimeComp";
import CoachCard from "./CoachCard";
import { translates } from "../translates";

export default observer(({ event: ev, program: prg, myid, ...r }) => {
  const { id, from, to, age } = ev,
    lang = r.lang || localLang(),
    rus = lang == "ru",
    book = ev.clientsIds?.includes(myid),
    isnow = Date.now(),
    ongoing = from < isnow && to > isnow,
    { name, photo } = r.coach,
    ageName = ages[lang].find((a) => a.id >= age)[
      wwidth > 400 ? "full" : "name"
    ],
    title = !ev.privat
      ? r.handleLevel(ev.level) + ` ${ageName}`
      : TTL1[lang](book);

  let onPress = () => r.navigate("Event", { id });

  const Wrap = book
    ? BookedWrap
    : ongoing
    ? OngoingWrap
    : r.gray
    ? GrayEventWrap
    : EventWrap;

  return (
    <Wrap {...{ onPress }}>
      <Row>
        <Image style={{ backgroundImage: `url(${prg?.image})` }}>
          <Gradient>
            <GroupIMGText>{prg?.name || ev.progID}</GroupIMGText>
          </Gradient>
        </Image>

        <Body>
          <Text numberOfLines={1}>{title}</Text>

          <RowCentered style={{ marginTop: -4 }}>
            <Time>{dayjs(from).format(r.hhfrmt).toLowerCase()}</Time>
            <DurComp {...{ from, to }} />
          </RowCentered>

          <RowBetween style={{ alignItems: "flex-end" }}>
            <CoachCard mini {...{ name, photo }} style={{ flex: 1 }} />
            {book ? (
              <MarkerComp
                small
                color={GREEN}
                text={rus ? "бронь" : "booked"}
                style={{ marginRight: -22, zIndex: 11 }}
              />
            ) : (
              <Text style={{ marginBottom: -2 }}>{ev.price}$</Text>
            )}
          </RowBetween>
        </Body>
      </Row>
    </Wrap>
  );
});

export const CartCard = observer(
  ({
    full,
    event: ev,
    program: prg,
    discont = 0,
    isTrial,
    passID,
    onPress,
    ...r
  }) => {
    let { handleLevel } = useSchool(),
      lang = r.lang || localLang(),
      rus = lang == "ru",
      { from, error, client, custom, progID } = ev,
      { quant: qt } = full ? r : client,
      ageName = ages[lang].find((a) => ev.age <= a.id)?.name || "17-25",
      dur = (ev.to - from) / 60000,
      durtext = durtnText(dur, full || dur < 60 || !(dur % 60), rus),
      sum = full ? qt * ev.price : client.sum - discont,
      type = CLSTYPE[lang](ev.privat, custom && !full, isTrial),
      level = ev.group && !isTrial && handleLevel(ev.level).toLowerCase();

    const ErrorComp = error && (
      <CartErrorCover>
        <MarkerComp text={error} style={{ marginLeft: -8 }} />
      </CartErrorCover>
    );

    const ImageComp = (
      <Press onPress={() => r.navigate("AddInfo", { progID })}>
        <CartImage style={{ backgroundImage: `url(${prg.image})` }}>
          <Gradient cart>
            <CartIMGText>{prg.name}</CartIMGText>
          </Gradient>
        </CartImage>
      </Press>
    );

    if (!full)
      return (
        <CartWrap {...{ onPress }} style={{ height: cartHeight }}>
          {ErrorComp}
          <RemovePress onPress={r.remove}>
            <Text14>remove</Text14>
          </RemovePress>
          {ImageComp}

          <CartBody style={{ height: 180 }}>
            <ItemRow>
              <TimeComp med color="black" {...{ from }} />
              <Text>{durtext}</Text>
            </ItemRow>
            <ItemRow>
              <Text>{type + (level ? `, ${level}` : "")}</Text>
              <Text>{ageName}</Text>
            </ItemRow>
            <ItemRow style={{ borderBottomWidth: 0 }}>
              <Text>
                {qt + (rus ? " чел." : " person" + (qt === 1 ? "" : "s"))}
              </Text>
              {!r.pass && (
                <Text>
                  {discont > 0 && <CrossedPrice>{client.sum}$</CrossedPrice>}
                  {" " + sum}$
                </Text>
              )}
            </ItemRow>
          </CartBody>
        </CartWrap>
      );

    return (
      <CartWrap {...{ onPress }}>
        {ErrorComp}
        {ImageComp}
        <CartBody>
          <ItemRow>
            <Type>{rus ? "Начало" : "Start"}</Type>
            <TimeComp med color="black" {...{ from }} />
          </ItemRow>
          <ItemRow>
            <Type>{rus ? "Длительность" : "Duration"}</Type>
            <Text>{durtext}</Text>
          </ItemRow>
          <ItemRow>
            <Type>{rus ? "Тип" : "Type"}</Type>
            <Text>{type}</Text>
          </ItemRow>
          {level && (
            <ItemRow>
              <Type>{rus ? "Уровень" : "Level"}</Type>
              <Text>{level}</Text>
            </ItemRow>
          )}
          <ItemRow>
            <Type>{rus ? "Возраст" : "Age"}</Type>
            <Text>{ageName}</Text>
          </ItemRow>
          <ItemRow>
            <Type>{rus ? "Человек" : "Persons"}</Type>
            <Text>{qt}</Text>
          </ItemRow>
          <ItemRow style={{ borderBottomWidth: 0 }}>
            <Type>{rus ? "Стоимость" : "Price"}</Type>
            <Text>
              {discont > 0 && <CrossedPrice>{client.sum}$</CrossedPrice>}
              {sum}$
            </Text>
          </ItemRow>
        </CartBody>
      </CartWrap>
    );
  }
);

let imgWidth = 136,
  imgHeight = 106,
  cartWidth = wwidth - 24 * 2,
  cartImgHeight = cartWidth / 2;

export const cartHeight = cartImgHeight + 142 + 12;

let EventWrap = styled(Press)`
    background: white;
    width: ${cartWidth}px;
    border-radius: 18px;
    height: 116px;
    padding: 5px;
  `,
  GrayEventWrap = styled(EventWrap)`
    background: ${BACKGRAY};
  `,
  BookedWrap = styled(EventWrap)`
    background: ${BACKGREEN};
  `,
  OngoingWrap = styled(EventWrap)`
    border: 2px solid ${GREEN};
    padding: 3px;
  `,
  CartWrap = styled(EventWrap)`
    background: ${BACKGRAY};
    height: undefined;
    padding: 0 20px;
  `,
  CartBody = styled.View`
    padding: 6px 0;
  `,
  GroupIMGText = styled(Medium18)`
    color: white;
    z-index: 10;
    letter-spacing: 0.2px;
    margin: 11px 13px;
  `,
  CartIMGText = styled(Medium28)`
    color: white;
    z-index: 1;
    margin: 20px;
  `,
  Body = styled.View`
    flex: 1;
    flex-shrink: 1;
    justify-content: space-between;
    padding: 11px 11px 13px 0;
  `,
  Time = styled(Text)`
    color: ${GREEN};
  `,
  Text14 = styled(UIText14)`
    color: ${DGRAY};
  `,
  Image = rstyled(CardImage)`
    width: ${imgWidth}px;
    height: ${imgHeight}px;
    margin-right: 20px;
  `,
  CartImage = rstyled(CardImage)`
    width: ${cartWidth}px;
    height: ${cartImgHeight}px;
    border-radius: 18px;
    margin-left: -20px;
  `,
  ItemRow = styled(RowBetween)`
    border-bottom-width: 1px;
    border-bottom-color: ${LITEBORDER};
    padding: 12px 0;
  `,
  Type = styled(Text)`
    color: ${DGRAY};
  `,
  CartErrorCover = styled.View`
    position: absolute;
    left: 0;
    z-index: 1;
    height: ${cartHeight}px;
    width: ${cartWidth}px;
    background: rgba(255, 255, 255, 0.65);
    padding-top: 20px;
  `,
  CrossedPrice = styled(Text)`
    color: ${GRAY};
    text-decoration-line: line-through;
    text-decoration-style: solid;
  `,
  RemovePress = styled(Press)`
    position: absolute;
    top: 16px;
    right: 16px;
    background: white;
    z-index: 2;
    border-radius: 8px;
    padding: 8px;
  `;

let gradStyle = {
  position: "absolute",
  justifyContent: "flex-end",
  bottom: 0,
  width: "100%",
  height: "90%",
  borderRadius: 15,
};

//style={{ width: 136, height: 106 }}
// style={{  width: wwidth - 24 * 2,  height: cartImgHeight,  borderRadius: 18,}}
let Gradient = ({ cart, ...pr }) => {
  if (cart)
    (gradStyle.width = cartWidth),
      (gradStyle.height = cartImgHeight),
      (gradStyle.borderRadius = 18);
  else (gradStyle.width = imgWidth), (gradStyle.height = imgHeight);

  return (
    <LinearGradient
      colors={["rgba(0,0,0,0)", "rgba(0,0,0,0.5)"]}
      start={{ x: 0, y: 0 }}
      end={{ x: 0, y: 1.0 }}
      style={gradStyle}
    >
      {pr.children}
    </LinearGradient>
  );
};

let { CLSTYPE } = translates,
  { TTL1 } = translates.EventCard;
