import React, { useState, useEffect, useCallback } from "react";
import { View, FlatList } from "react-native";
import { observer } from "mobx-react-lite";
import styled from "styled-components/native";
import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import { useAuth, useCart, useClient, useSchool } from "../commons/Stores";
import {
  genrtItemLayout,
  getDay,
  durations,
  durtnText,
  wwidth,
  vibroToast,
  groupBy,
  getFreeSlots,
  formhh,
  callAlert,
  checkSlotsCrossed,
  localRus,
  localLang,
} from "../commons/utils";
import {
  Press,
  DGRAY,
  GREEN,
  Text18,
  QuantButton,
  BACKGREEN,
  RowBetween,
  LINEGRAY,
  BACKRED,
  RED,
  TmznComp,
  BlankView,
  BlankText,
  Text16,
} from "../commons/UI";
import EventBuilder, { dttext } from "./EventBuilder";
import Rescheduler from "./Rescheduler";
import { BookMark, DateParent, datesListProps } from "./Dates";
import { TimeFormatComp } from "../screens/Home";
import { translates } from "../translates";

let durtext = (it, rus = localRus()) => durtnText(it.maxDur || it, "full", rus),
  toast = (tx, dur = 3000, offset = 120) => vibroToast(tx, dur, offset);

let toastUnactual = (t, i = 0, lang = localLang(), hhfrmt) => {
  let timetx = dttext(t, 0, lang, hhfrmt),
    tx = UNACTLST1[lang](timetx);
  return setTimeout(() => toast(tx, 3000, 120 + i * 60), i * 1000);
};

export default observer(
  ({ initEvt: e0, cartStarts, reschedule: rescdl, hideDur, ...r }) => {
    const { navigate } = useNavigation(),
      { time24, hhfrmt, lang, rus } = useAuth(),
      {
        coaches: { [r.coachID]: c },
      } = useSchool(),
      {
        books: { [rescdl || "00"]: book },
        activeBooks,
        passes: { [r.passID || "00"]: pass },
      } = useClient(),
      { remove: cartRemove } = useCart(),
      mybooks = activeBooks.filter(
        (e) => e.coachID === r.coachID && (rescdl ? e.id !== rescdl : true)
      );

    const starts = slots2starts(
      c,
      rescdl ? [] : cartStarts,
      mybooks,
      book,
      pass
    );

    const startDayGroups = groupBy(starts, "day"),
      cartStartsGroups = cartStarts?.[0] && groupBy(cartStarts, "day"),
      days = Object.keys(startDayGroups).sort(),
      bookedDays = new Set(mybooks.map((e) => getDay(e.from)));

    const { from: from0, to: to0 } = e0 || {},
      [initStart] = useState(
        from0 && startDayGroups[e0.day]?.find((s) => s.from === from0)
      ),
      [picked, setPicked] = useState(initStart ? [initStart] : []),
      quant = picked.length,
      passdur = pass ? pass.durLeft ?? pass.duration : undefined,
      multiPick = !e0 && (passdur ? passdur > 45 : true),
      maxDur = quant
        ? Math.min(...picked.map((s) => s.maxDur), passdur ?? 360)
        : Math.min(360, passdur ?? 360),
      durs = durations(maxDur),
      [initDur] = useState(
        initStart && durs.find((d) => d === (to0 - from0) / 60000)
      ),
      [dur, setDur] = useState(
        initStart
          ? initDur || 60
          : r.canTrial
          ? 45
          : !pass
          ? 60
          : passdur == 75
          ? 75
          : passdur >= 60
          ? 60
          : passdur < 30
          ? 30
          : 45
      ),
      msdur = dur * 60000,
      filtered = picked.filter((s) => s.maxDur >= dur),
      sortedPicked = filtered[1] ? orderBy(filtered, "from") : filtered,
      [first] = sortedPicked,
      { from, day } = first || {};

    const unactualStart = e0
      ? from === from0 || startDayGroups[day]?.find((s) => s.from === from)
        ? false
        : true
      : filtered.find(
          (f) => !startDayGroups[f.day]?.some((s) => s.from === f.from)
        )?.from;

    // if background updated slots don't have the picked start, remove it
    useEffect(() => {
      if (unactualStart) {
        if (filtered.length) {
          let allUnactuals = filtered
            .filter(
              (f) => !startDayGroups[f.day]?.some((s) => s.from === f.from)
            )
            .map((f) => f.from);
          setPicked((pr) =>
            pr.filter(({ from: t }) => !allUnactuals.includes(t))
          );
          allUnactuals.forEach((t, i) => toastUnactual(t, i, lang, hhfrmt));
        } else {
          toastUnactual(picked[0], 0, lang, hhfrmt);
          setPicked([]);
        }
      }
    }, [!unactualStart]);

    const pickStart = useCallback(
      (p) => {
        if (!multiPick) return p.from === first?.from ? null : setPicked([p]);
        setPicked((pr) => {
          if (pr.some((s) => s.from === p.from))
            return pr.filter((s) => s.from !== p.from);
          return [...pr, p];
        });
      },
      [!multiPick && first?.from]
    );

    const removeStart = useCallback(
      (value) => {
        if (value === "all") return setPicked([]);
        setPicked((pr) => [...pr.filter((s) => s.from !== value)]);
      },
      [picked.length]
    );

    const checkCartCross = useCallback(
      (check) => cartStarts?.some((e) => checkSlotsCrossed(e, check)),
      [cartStarts?.length]
    );

    if (!starts[0])
      return (
        <BlankView>
          <BlankText>{NOSLOTS[lang](c.name)}</BlankText>
        </BlankView>
      );

    const initDayIndex = initStart && days.indexOf(initStart.day);

    const renderDays = ({ item: day, index: i }) => {
      let dayStarts = startDayGroups[day];
      let props = {
        ...r,
        navigate,
        starts: dayStarts,
        incarts: cartStartsGroups?.[day],
        first: !i,
        last: i === days.length - 1,
        dur,
        pickStart,
        bookedDays,
        time24,
        hhfrmt,
        cartRemove,
        lang,
      };

      if (!multiPick) return <DayTimes {...props} {...{ picked }} />;

      let blocked = dayStarts.reduce((res, s) => {
        let blockedBy = picked.find(
          (p) =>
            (p.from > s.from && p.from < s.from + msdur) ||
            (p.from < s.from && p.from + msdur > s.from)
        );
        if (blockedBy) res.push({ ...s, blockedBy: blockedBy.from });
        return res;
      }, []);

      return (
        <DayTimes
          {...props}
          {...{ blocked }}
          multiPick
          picked={picked.filter((s) => s.day === day)}
        />
      );
    };

    const ondurMinus = dur > 30 ? () => setDur((pr) => pr - 15) : null;

    const ondurPlus =
      dur === 360
        ? null
        : () =>
            durs.includes(dur + 15)
              ? setDur((pr) => pr + 15)
              : passdur < dur + 15
              ? toast(durtext(passdur, rus) + PASMAXDUR[lang])
              : denyMaxdur(
                  picked.find((s) => s.maxDur === maxDur),
                  lang,
                  hhfrmt
                );

    return (
      <>
        {r.TRIALCOMP}

        <RowBetween>
          <TmznComp br />
          <TimeFormatComp border />
        </RowBetween>

        {multiPick && <Caption>{PICKTL[lang]}</Caption>}

        <View style={tableStyle(hideDur)}>
          <FlatList
            {...datesListProps(time24 ? null : "wide")}
            data={days}
            renderItem={renderDays}
            initialScrollIndex={initDayIndex > 2 ? initDayIndex - 2 : 0}
          />
          <Gradient />
          <Gradient type={2} />
        </View>

        {!hideDur && (
          <QuantButton
            text={durtext(dur, rus)} // + (r.canTrial && dur <= 45 ? ' (free)' : '')}
            plus={ondurPlus}
            minus={ondurMinus}
            style={{ marginTop: 32 }}
          />
        )}

        {rescdl ? (
          <Rescheduler {...r} {...{ dur }} id={rescdl} picked={first} />
        ) : (
          <EventBuilder
            {...r}
            {...{
              e0,
              dur,
              unactualStart,
              checkCartCross,
              removeStart,
              multiPick,
            }}
            picked={sortedPicked}
            nopicked={!picked[0]}
            initStart={initStart?.from}
          />
        )}
      </>
    );
  }
);

const DayTimes = ({
  starts,
  incarts,
  dur,
  picked,
  pickStart: pick,
  time24,
  coachID,
  passID,
  lang,
  hhfrmt,
  ...r
}) => {
  // const startsAndIncarts = incarts?.length ? orderBy(starts.concat(incarts), 'from') : starts;
  const [{ day }] = starts,
    hasMark = r.bookedDays.has(day),
    initIndex =
      picked[0] && starts.findIndex((st) => st.from === picked[0].from),
    rus = lang === "ru";

  let dttext2 = (item) => dttext(item, 0, lang, hhfrmt);

  let renderTimes = ({ item: st }) => {
    let { booked, blockedByCart: blocked } = st,
      incart = incarts?.some((ev) => ev.from === st.from),
      ispicked = picked[0]?.from === st.from,
      pickbl = !blocked && st.maxDur >= dur,
      time = dayjs(st.from).format(hhfrmt),
      blockedText;

    if (blocked) blockedText = BLCK1[lang](dttext2(blocked));

    let handleBook = () => {
      callAlert(...BLCK2[lang](dttext2(st)), [
        {
          text: rus ? "Открыть занятие" : "Open class",
          onPress: () => r.navigate("Event", { id: st.id }),
        },
      ]);
    };

    let handleIncart = () => {
      let incart = incarts.find((ev) => ev.from === st.from);
      callAlert(...BLCK3[lang](dttext2(incart)), [
        {
          text: rus ? "Открыть корзину" : "Open cart",
          onPress: () => r.navigate("Cart", { coachID, passID }),
        },
        {
          text: rus ? "Удалить из корзины" : "Remove it",
          onPress: () => r.cartRemove({ coachID, passID, id: incart?.id }),
        },
      ]);
    };

    let onPress = ispicked
      ? null
      : booked
      ? handleBook
      : incart
      ? handleIncart
      : () =>
          blocked
            ? toast(blockedText, 5000)
            : pickbl
            ? pick(st)
            : denyMaxdur(st.maxDur, lang, hhfrmt);

    if (!r.multiPick)
      return (
        <TimeComp
          {...{ time, time24, booked, ispicked, incart, pickbl, onPress }}
        />
      );

    ispicked = picked.some((pck) => pck.from === st.from);
    blocked = blocked || r.blocked?.find((b) => b.from === st.from);
    pickbl = !blocked && pickbl;

    if (blocked && !st.blockedByCart) {
      let timetx = dttext2(blocked.blockedBy),
        durtx = durtext(dur, rus);

      blockedText = BLCK4[lang](timetx, durtx);
    }

    onPress = ispicked ? () => pick(st) : onPress;

    return (
      <TimeComp
        {...{ time, time24, booked, ispicked, incart, pickbl, onPress }}
      />
    );
  };

  return (
    <DateParent {...r} {...{ day }} width={time24 ? null : 90} mark={hasMark}>
      <FlatList
        {...timesListProps}
        data={starts}
        renderItem={renderTimes}
        initialScrollIndex={initIndex > 2 ? initIndex - 2 : 0}
      />
    </DateParent>
  );
};

let TimeComp = ({ ispicked: pikd, pickbl, booked, time24, ...r }) => {
  let InnerTimeComp = booked
    ? InnerTimeView
    : pikd
    ? PickedTimeView
    : r.incart
    ? CartTimeView
    : InnerTimeView;

  let color =
    r.incart || (pikd && pickbl) || booked
      ? GREEN
      : pikd && !pickbl
      ? RED
      : !pickbl
      ? LINEGRAY
      : DGRAY;

  let TimeTextComp = time24 ? TimeText : MiniTime;

  return (
    <Press {...r}>
      <TimeView>
        <InnerTimeComp
          style={[
            pikd && !pickbl && { backgroundColor: BACKRED },
            !time24 && { width: 82 },
          ]}
        >
          <TimeTextComp style={{ color }}>{r.time.toLowerCase()}</TimeTextComp>
          {booked && <BookMark style={{ top: 12, right: 0 }} />}
        </InnerTimeComp>
      </TimeView>
    </Press>
  );
};

let timeKeys = (it) => it.from,
  getTimeLayout = (_, i) => genrtItemLayout(44, i);

let timesListProps = {
  keyExtractor: timeKeys,
  nestedScrollEnabled: true,
  initialNumToRender: 5,
  windowSize: 7,
  contentContainerStyle: { paddingVertical: 12 },
  getItemLayout: getTimeLayout,
  style: { marginTop: 8 },
};

let denyMaxdur = (it, lang = localLang(), hhfrmt) => {
  let rus = lang === "ru",
    classTime = it.from && dttext(it.from, 1, lang, hhfrmt),
    text = DENYDUR[lang](classTime, durtext(it, rus));
  return toast(text, 5000);
};

let MIN30 = 30 * 60000,
  MIN15 = 15 * 60000;

let slots2starts = (
  { slots, busy: busy0 },
  cartEvents = [],
  booksArr,
  initEvt,
  pass
) => {
  let now = Date.now();
  let slotsArr = orderBy(Object.values(slots || {}), "from").filter(
    (s) =>
      s?.to > now + 60 * 60000 && // remove slots ending in less than 60 mins
      (pass ? s.from <= pass.to : true)
  );
  if (!slotsArr[0]) return [];

  let { [initEvt?.id]: _, ...busy } = busy0,
    freeSlots = getFreeSlots(slotsArr, Object.values(busy || {}), pass?.to);
  if (!freeSlots[0]) return [];

  let starts =
    booksArr
      ?.filter((e) => (pass ? e.from <= pass.to : true))
      .map(({ id, from, to }) => ({
        id,
        from,
        to,
        day: getDay(from),
        booked: true,
      })) || [];

  let parseSlot = ({ id, from: SLFROM, to: SLTO }) => {
    let MAXFROM = SLTO - MIN30; // MAXFROM possible start time as min. duration is 30 min
    if (pass) MAXFROM = Math.min(pass.to, MAXFROM);

    //  slots can have busy parts already blocked by curent cart
    let slotCartEvs = cartEvents.filter((e) => e.to > SLFROM && e.from < SLTO);

    // starts array length is MAXFROM-from divided by 15 mins + the last one, f.e. 60 min slot = 5 start times
    let startsArrSize = parseInt((MAXFROM - SLFROM) / 60000 / 15) + 1;

    let handleStart = (_, i) => {
      let from = SLFROM + MIN15 * i;
      //// skip if start in less than 15 mins
      if (from <= now + MIN30) return;
      // skip if starts array already has the same start time
      if (starts?.some((e) => e.from === from)) return;

      let nearestCartStart = slotCartEvs.find((e) => e.from > from)?.from,
        maxto = Math.min(SLTO, nearestCartStart || SLTO),
        maxDur = (maxto - from) / 60000 > 360 ? 360 : (maxto - from) / 60000, // max duration = difference with the end of the free slot or nearest future class in cart, but <= 6 hours
        obj = { slotID: id, from, maxDur, day: getDay(from) };

      // mark with 'blockedByCart' if the time is blocked by come class from cart (from 15 mins before the start till the class end time, but not included the end time)
      let blockedByCart = slotCartEvs.find(
        (e) => from >= e.from - MIN15 && from < e.to
      );
      if (blockedByCart) obj.blockedByCart = blockedByCart;

      return obj;
    };

    let slotStartsArr = new Array(startsArrSize)
      .fill(1)
      .map(handleStart)
      .filter(Boolean);

    starts.push(...slotStartsArr);
  };

  freeSlots.forEach(parseSlot);
  return orderBy(starts, "from");
};

let tableStyle = (hideDur) => ({
  height: 52 + 8 + 12 + 44 * (hideDur ? 7.6 : 4.6),
  marginTop: 20,
});

let Caption = styled(Text16)`
    color: ${DGRAY};
    margin: 16px 0 -8px;
  `,
  TimeView = styled.View`
    align-items: center;
    width: 82px;
    height: 44px;
  `,
  InnerTimeView = styled.View`
    flex: 1;
    justify-content: center;
    width: 66px;
  `,
  PickedTimeView = styled(InnerTimeView)`
    background: ${BACKGREEN};
  `,
  CartTimeView = styled(InnerTimeView)`
    border: 1px solid ${GREEN};
    margin: 0 1px;
  `,
  TimeText = styled(Text18)`
    text-align: center;
    margin-bottom: -1px;
  `,
  MiniTime = styled(Text16)`
    text-align: center;
    margin-bottom: -1px;
  `;

let Gradient = ({ type: t }) => (
  <LinearGradient
    colors={["white", "rgba(255,255,255,0)"]}
    start={{ x: 0, y: t == 2 ? 1.0 : 0 }}
    end={{ x: 0, y: t == 2 ? 0 : 1.0 }}
    style={[
      { position: "absolute", width: wwidth, height: 18 },
      t == 2 ? { bottom: -4 } : { top: 52 + 8 },
    ]}
  />
);

let {
  UNACTLST1,
  NOSLOTS,
  PASMAXDUR,
  PICKTL,
  BLCK1,
  BLCK2,
  BLCK3,
  BLCK4,
  DENYDUR,
} = translates.TimesBuilder;
