import { makeAutoObservable, runInAction } from "mobx";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  OAuthProvider,
  signOut,
  getAdditionalUserInfo,
} from "firebase/auth";
import {
  getDoc,
  getDocs,
  where,
  query,
  setDoc,
  updateDoc,
  limit,
} from "firebase/firestore";
import {
  set as setRdb,
  ref as rdbref,
  orderByChild,
  equalTo,
  query as rdbquery,
} from "firebase/database";
import {
  rdb,
  dbUsers,
  rdbDeposits,
  tmzn,
  colors,
  localStor,
  timezoneName,
  parseBalance,
  device,
  offline,
  offlineToast,
  handleError,
  callAlert,
} from "./utils";
import { auth } from "../../config";
import { translates } from "../translates";

let gProvider = new GoogleAuthProvider(),
  aProvider = new OAuthProvider("apple.com");
aProvider.addScope("email");
aProvider.addScope("name");
gProvider.addScope("profile");

let localUser = localStor.getItem("user"),
  parsedUser = localUser ? JSON.parse(localUser) : {};

export default class Auth {
  load = false;
  profile = parsedUser;
  afterLoginRoute = null;
  depoApplies = {}; // requests for balance deposit
  timeFormat =
    (localUser ? parsedUser.timeFormat : localStor.getItem("timeFormat")) ||
    "24";

  constructor(school) {
    makeAutoObservable(this);
    this.school = school;
  }

  get lang() {
    return this.school.lang;
  }
  get rus() {
    return this.lang === "ru";
  }

  get myid() {
    return this.profile.uid;
  }

  get name() {
    return this.profile.name;
  }
  get age() {
    return this.profile.age;
  }
  get photo() {
    return this.profile.photo;
  }

  get dbref() {
    return this.myid ? dbUsers(this.myid) : null;
  }

  get isactive() {
    return this.myid && !!this.age;
  }

  get balance() {
    return parseBalance(this.profile.balance);
  }
  get canTrial() {
    return this.myid && [false, true].includes(this.profile.canTrial)
      ? this.profile.canTrial
      : null;
  }

  get time24() {
    return this.timeFormat == "24";
  }
  get hhfrmt() {
    return this.time24 ? "HH:mm" : "h:mm A";
  }

  get depoAppliesRef() {
    return rdbquery(rdbDeposits(), orderByChild("uid"), equalTo(this.myid));
  }

  setLoad = (bool) => (this.load = bool || null);

  setLang = (ll) => {
    console.log("AUTH setLang", ll);
    this.school.setLang(ll);
    if (this.myid && this.profile.lang !== ll) this.updateFields({ lang: ll });
  };

  toggleTimeFormat = () => {
    let val = this.time24 ? "12" : "24";
    this.timeFormat = val;
    localStor.setItem("timeFormat", val);
    if (this.myid) updateDoc(this.dbref, { timeFormat: val });
  };

  setProfile = (ob) => {
    this.profile = ob;
    if (ob.timeFormat) {
      this.timeFormat = ob.timeFormat;
      localStor.setItem("timeFormat", ob.timeFormat);
    }
    if (ob.lang) this.school.setLang(ob.lang);

    this.setLoad(false);
    if (Object.keys(ob)[0])
      setTimeout(() => localStor.setItem("user", JSON.stringify(ob)), 1000); // save locally after delay, so that last cart change check after a login was run
  };

  setAfterLogin = (screen, params) =>
    (this.afterLoginRoute = screen ? { screen, params } : null);

  addDepoApply = (obj) => (this.depoApplies = { ...this.depoApplies, ...obj });
  setDepoApplies = (obj) => (this.depoApplies = obj);

  addDBUser = async (ob) => {
    let { uid } = ob;
    console.log("addDBUser", uid);
    if (!uid) return;
    let user = ob.age
      ? ob
      : {
          ...ob,
          device,
          created: Date.now(),
          timezone: tmzn,
          timezoneName,
          color: colors[Math.round(Math.random() * 27)],
          canTrial: true,
          level: "0",
        };
    this.setProfile(user);
    return setDoc(dbUsers(uid), user); //  if no ob.age, means just signed up, so need to fill data at "EditProfile" first. Otherwise, data already filled from  "EditProfile"
  };

  getDBUser = async (ob) => {
    let { uid } = ob;
    // console.log("getDBUser", uid);
    if (!uid) return;
    let data;

    let d = await getDoc(dbUsers(uid));
    if (d?.exists()) data = d.data();
    else {
      let q1 = await getDocs(
        query(dbUsers(), where("newUid", "==", uid), limit(1))
      );
      if (!q1.empty) data = q1.docs[0].data();
      else {
        let q2 = await getDocs(
          query(
            dbUsers(),
            where("email", "==", ob.email),
            where("provider", "==", ob.provider),
            limit(1)
          )
        );
        if (!q2.empty) {
          data = q2.docs[0].data();
          let newUid = uid,
            errorData = {
              uid: data.uid,
              newUid,
              device,
              provider: ob.provider || data.provider,
            };
          updateDoc(dbUsers(uid), { newUid });
          setRdb(rdbref(rdb, "UID-ERRORS/" + newUid), errorData);
        }
      }
    }
    if (!data) return this.addDBUser(ob);
    this.setProfile(data);
    return data;
  };

  checkDBUser = async () => this.getDBUser(this.profile);

  googleLogin = async () => {
    if (offline()) return offlineToast();
    this.setLoad(true);
    let { lang } = this;
    // await signInWithRedirect(auth, gProvider).catch((err) => console.warn(JSON.stringify(err))).then((res) => console.log("signInWithRedirect", res));
    // await getRedirectResult(auth).then((res) => { console.log("getRedirectResult", res);
    signInWithPopup(auth, gProvider) // then will be handled by onAuthStateChanged listener in APP.js
      .then((res) => {
        let add = getAdditionalUserInfo(res);
        if (add.isNewUser) this.addDBUser(handleGoogleAuthUser(res.user)); // will be handled by onAuthStateChanged listener in APP.js
      })
      .catch((err) => {
        runInAction(() => this.setLoad(false));
        if (err.code.includes("cancelled") || err.code.includes("closed"))
          return;
        callAlert(...INTRNTERR[lang](err, "Google"));
      });
  };

  appleLogin = async () => {
    if (offline()) return offlineToast();
    this.setLoad(true);
    let { lang } = this;
    await signInWithPopup(auth, aProvider)
      .then((res) => {
        let add = getAdditionalUserInfo(res);
        if (add.isNewUser) this.addDBUser(handleGoogleAuthUser(res.user)); // will be handled by onAuthStateChanged listener in APP.js
      })
      .catch((err) => {
        this.setLoad(false);
        if (err.code.includes("cancelled") || err.code.includes("closed"))
          return;
        callAlert(...INTRNTERR[lang](err, "Apple"));
      });
  };

  logout = async () => {
    if (offline()) return offlineToast();
    localStor.removeItem("user");
    await signOut(auth).then(() => this.setProfile({}));
  };

  localUpdateFields = (obj) => this.setProfile({ ...this.profile, ...obj });

  updateFields = async (obj, next) => {
    if (offline()) return offlineToast();
    let { lang } = this,
      error;

    await updateDoc(this.dbref, obj).catch((er) => (error = er));
    if (error) return handleError(ERTYPE1[lang], error);
    if (next) next();
    this.localUpdateFields(obj);
  };
}

export let handleGoogleAuthUser = ({
  uid,
  email,
  displayName: name,
  photoURL,
  provider,
  providerData: prov,
}) => ({
  uid,
  email,
  name,
  photo: photoURL?.replace("=s96-c", "=s550") || null,
  provider:
    provider ||
    (!prov[0]
      ? null
      : prov[0].providerId.includes("google")
      ? "google"
      : "apple"),
});

// https://app.bloss.am/__/auth/handler?apiKey=AIzaSyCvv4YF7T8gCbd97ZrbZ-7LPNQI0k4HCHA&appName=%5BDEFAULT%5D&authType=signInViaRedirect&redirectUrl=https%3A%2F%2Fapp.bloss.am%2Flogin&v=10.8.0&providerId=google.com&scopes=profile
//https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=339329916104-76jkkt8qgkogugcnhq4nvomgo8fccpi0.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fapp.bloss.am%2F__%2Fauth%2Fhandler&state=AMbdmDlSKhKZIB-Mtug4XkoLL-IAikNTiOTIagrozplLxGjQmd0KQbDP0uA3rOyos9vQyaerFvUnIOAc2PLAPByQcFwEHG_IDCsNx5GjZx2TSjX9gfhNUK0Vr2a5z-Y4HjkJ0bbijRlYJw_L8P4dcRIl5zKAFLPbg4zB9nTH3k3_T-YvS4zFZB28_3rHRa51Jip-IXlnc6Nn8E_SpKBaDtC0Ym7VR4bcwSAhMR1lO04wXfRDnZgUlH5R_40C_ZGa57Z3fmbZt-DxMpO9BHfubzKd-8jDcbvz4SCTL1nj3s8NPRUCu9b5&scope=openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20profile&context_uri=https%3A%2F%2Fapp.bloss.am

let { INTRNTERR, ERTYPE1 } = translates.AuthStore;
