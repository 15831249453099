// common shared with back server for stripe payments
import {
  query,
  where,
  orderBy as order,
  documentId,
  getDocs,
  arrayUnion,
  getDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { get as getRdb } from "firebase/database";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import {
  db,
  dbCoaches,
  dbEvents,
  dbUsers,
  dbPasses,
  dbOrders,
  rdbPackages,
  getFreeSlots,
  isbookblEvent,
  durtnText,
  formhh,
  issame,
  isvalidPackage,
  parseBalance,
  parsePackage,
  resetStackRoute,
  device,
  localRus,
  localLang,
  rootNavEvent,
} from "./utils";
import { translates } from "../translates";

export const checkEventSlot = (event, coach, slotsArr, canTrial) => {
  let { id, from, to, isTrial, client: cl } = event;
  if (!isbookblEvent({ from, custom: true }))
    return { error: "too late to book" };

  if (isTrial && canTrial === false) return { error: "no free trial" };

  if (cl?.sum > 0) {
    let dur = (to - from) / 60000 / 60,
      priceRate = cl.quant > 1 ? coach.grPrice : coach.price,
      correctSum = dur * priceRate * cl.quant;
    if (cl.sum < correctSum) return { error: "prices expired" };
  }

  let slot = slotsArr.find((s) => s.from <= from && s.to >= to);
  if (!slot) return { error: "slots changed" };

  let { busy } = coach;
  if (busy) delete busy[id]; // remove this event (if provided) from busy parts of the slot, so to show its time as editable

  let freeSlots = getFreeSlots([slot], Object.values(busy || {})),
    freePart = freeSlots.find((s) => s.from <= from && s.to >= to);

  if (!freePart) return { error: "slots changed" };
  return { slotID: slot.id };
};

// check order events before Cart ordering or paying order by deposit balance
export const orderChecks = async (
  events,
  updateSlot,
  updateGroup,
  getCoach
) => {
  let eventsArr = orderBy(Object.values(events), "from"),
    [{ coachID }] = eventsArr,
    { uid } = eventsArr.find((e) => !!e.client)?.client || {},
    [changed, customs, groupIds] = [[], [], []],
    refund = 0;

  let addChange = (id, change) => (
    changed.push({ id, change }), (refund += events[id].client?.sum || 0)
  );

  eventsArr.forEach((e) =>
    !isbookblEvent(e)
      ? addChange(e.id, "too late to book")
      : e.custom
      ? customs.push(e)
      : groupIds.push(e.id)
  );

  if (changed.length == eventsArr.length) return { changed, refund };

  // 1. check custom events for available slots
  const checkCustoms = async () => {
    let coach = await getCoach(coachID);
    let slotsArr = orderBy(Object.values(coach?.slots || {}), "from").filter(
      (s) => s.to > Date.now() + 60 * 60000
    );

    if (!coach || coach.status !== "approved")
      return customs.forEach(({ id }) => addChange(id, "expired"));
    if (!slotsArr[0])
      return customs.forEach(({ id }) => addChange(id, "slots changed"));

    let checkCanTrial;

    if (customs.some((e) => e.isTrial)) {
      let user = (await getDoc(dbUsers(uid))).data();
      checkCanTrial = user?.canTrial;
    }

    // find free slot, if not, can't be booked, so push to changed
    customs.forEach((e) => {
      let { slotID, error } = checkEventSlot(e, coach, slotsArr, checkCanTrial);
      if (error) return addChange(e.id, error);
      if (slotID !== e.slotID && updateSlot)
        updateSlot(e.id, slotID),
          console.log("updated slot id", e.slotID, slotID);
    });
  };

  let handleGroup = (doc) => {
    let d = doc.data(),
      e = events[d.id],
      { id, active } = d,
      booked = d.clientsIds?.includes(uid),
      noChange = issame(d.edited, e.edited),
      tooLate = d.from < Date.now() - 10 * 60000;

    if (active && noChange && !booked && !tooLate) return true;
    if (updateGroup && (booked || !noChange)) updateGroup(d);
    let change = !active
      ? "cancelled or passed"
      : booked
      ? "already booked"
      : !noChange
      ? "changed"
      : "already started";
    return addChange(id, change);
  };

  // 2. check group events for changes and impossible bookings
  const checkGroups = async () => {
    let getEvents = async (ids) => {
      let q = await getDocs(query(dbEvents(), where(documentId(), "in", ids)));
      // console.log("checkGroups", q.size);
      // if something in event was changed or it was deleted, can't be booked, so push to changed
      // check if some events were deleted
      if (!q || q.empty)
        return ids.forEach((id) => addChange(id, "cancelled or passed"));
      if (q.size < ids.length) {
        let qids = q.docs.map((d) => d.id),
          missedIds = ids.filter((id) => !qids.includes(id));
        missedIds[0] &&
          missedIds.forEach((id) => addChange(id, "cancelled or passed"));
      }

      q.forEach(handleGroup);
    };

    if (!groupIds[10]) return getEvents(groupIds);
    else {
      // split  groupIds array by 1-10 subarrays, because firestore 'in' query is limited to 10
      let idsby10 = groupIds.reduce(
        (res, cur) => {
          let lastInd = res.length - 1;
          res[lastInd].length === 10 ? res.push([cur]) : res[lastInd].push(cur);
          return res;
        },
        [[]]
      );
      await Promise.all(idsby10.map(async (ids) => await getEvents(ids)));
    }
  };

  // 3. run checks
  await Promise.all([
    groupIds[0] && checkGroups(),
    customs[0] && checkCustoms(),
  ]);

  return { changed, refund };
};

export const dbbatchOrderEvents = (events, batch, changed) => {
  let changedIds =
    typeof changed?.[0] == "object" ? changed.map((c) => c.id) : changed;

  let eventsArr = Object.values(events),
    { orderID, uid } = eventsArr.find((e) => !!e.client)?.client || {},
    orderRef = dbOrders(orderID),
    time = Date.now();

  let handleEvent = (e0) => {
    let { id } = e0;
    // if was changed, set events[id].active = false &  return
    if (changedIds?.includes(id))
      return batch.update(orderRef, {
        [`events.${id}.active`]: false,
      });

    let evref = dbEvents(id),
      { from, to, client, custom, added, day, ...e } = e0;

    client.time = time;

    if (custom) {
      e.clients = { [uid]: client };
      e.clientsIds = [uid];

      batch.set(evref, Object.assign({}, e, { from, to, clientCreated: uid }));
      batch.update(dbCoaches(e.coachID), {
        [`slots.${e.slotID}.busy.${id}`]: { id, from, to },
        [`busy.${id}`]: { id, from, to },
      });
    } else
      batch.update(evref, {
        [`clients.${uid}`]: client,
        clientsIds: arrayUnion(uid),
      });
  };

  eventsArr.forEach(handleEvent);

  return batch;
};

export const checkPackageChanged = (prev, curr, uid) => {
  let lang = localLang(),
    rus = lang === "ru";
  if (!isvalidPackage(curr)) return PKGER1[lang];

  let { price, duration: dur, term } = prev,
    { price: newPrice, duration: newDur, term: newTerm } = curr;

  if (!issame(newPrice, price)) return PKGER2[lang] + newPrice;
  if (!issame(newDur, dur)) return PKGER3[lang] + durtnText(newDur, "f", rus);
  if (!issame(newTerm, term))
    return PKGER4[lang] + newTerm + (rus ? " дней" : " days");

  let { allowed } = parsePackage(curr, uid);
  if (!allowed) return PKGER5[lang];
};

export const prebuyPackageChecks = async (
  data,
  uid,
  setPackage,
  getCoach,
  checkDBUser
) => {
  let id = data.packID || data.id, // if package pass provided, get its packID
    lang = localLang(),
    rus = localRus();

  let [v, coach] = await Promise.all([
    getRdb(rdbPackages(id)),
    getCoach(data.coachID),
  ]);

  let pack = v.exists() && v.val();
  if (setPackage) setPackage(pack || id);

  // 1. check if coach is active
  if (coach?.status !== "approved") return { error: CCHER[lang] };

  // 2. check if package is valid & hasn't changed
  let change = checkPackageChanged(data, pack, uid);
  if (change) return { error: change };

  // 3. check if user has current active package membership
  // (durLeft > 15 min) OR has an active booking for this package
  let packPasses = await getDocs(
    query(
      dbPasses(),
      where("uid", "==", uid),
      where("packID", "==", id),
      where("active", "==", true),
      order("to", "desc")
    )
  );

  if (!packPasses.empty) {
    let arr = packPasses.docs.map((d) => d.data()),
      [going, finished] = [[], []],
      batch = writeBatch(db),
      time = Date.now();

    arr.forEach((p) => (p.to > time ? going : finished).push(p));

    let finishPass = (p, type) => {
      let active = type == "active",
        dbref = dbPasses(p.id),
        newUse = { time, finished: true, duration: -p.durLeft || 0 };

      if (active) newUse.desc = PASER1[lang];

      let updData = {
        active: false,
        durLeft: 0,
        status: p.durLeft ? "finished" : "completed",
        [`uses.${time}`]: newUse,
      };

      if (active) updateDoc(dbref, updData);
      else batch.update(dbref, updData);
    };

    if (finished.length) {
      finished.forEach(finishPass);
      batch.commit();
    }

    let [pass] = going;

    if (pass) {
      let { id: passID } = pass,
        error;

      let dateFormtd = (dt) => dayjs(dt).format("D MMM " + formhh());

      let alertButtons = [
        {
          text: rus ? "Открыть абонемент" : "Open package",
          onPress: () => resetStackRoute("Package", { passID }),
        },
      ];

      // either has durLeft > 15 min OR has an active booking
      if (pass.durLeft >= 30) {
        error = PASER2[lang] + dateFormtd(pass.to);
        return { error, alertButtons };
      }

      let booksQ = await getDocs(
        query(
          dbEvents(),
          where(`clients.${uid}.passID`, "==", passID),
          where("active", "==", true)
        )
      );

      if (booksQ.empty) finishPass(pass, "active");
      else {
        let book = booksQ.docs[0].data();
        error = PASER3[lang] + dateFormtd(book.from);
        alertButtons.push({
          text: rus ? "Открыть занятие" : "Open class",
          onPress: () => rootNavEvent({ id: book.id }),
        });

        return { error, alertButtons };
      }
    }
  }

  let response = { pack };

  if (checkDBUser) {
    let user = await checkDBUser(),
      balance = parseBalance(user?.balance);

    response.balance = balance;
    response.paybyBalance = balance >= pack.price;
  }

  return response;
};

export const paidPassUpdates = (id, dur, term) => {
  let time = Date.now();
  return {
    id,
    active: true,
    status: "paid",
    method: "balance",
    durLeft: dur,
    time,
    from: time,
    to: time + term * 24 * 60 * 60000,
    device,
  };
};

let { CCHER, PASER1, PASER2, PASER3, PKGER1, PKGER2, PKGER3, PKGER4, PKGER5 } =
  translates.orderChecks;
