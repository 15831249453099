import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { getDoc } from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import { useAuth, useClient, useSchool } from "../commons/Stores";
import {
  dbEvents,
  callAlert,
  openRefundPlcy,
  vibroToast,
  issame,
  isactivePass,
  offline,
  offlineToast,
  rootNavEvent,
  refundsAlertButton,
} from "../commons/utils";
import { Button, RefundsComp, SumRow } from "../commons/UI";
import { translates } from "../translates";

let toast = (tx, dur = 3000, offset = 120) => vibroToast(tx, dur, offset);

export default observer(({ id, dur, setLoad, passID, ...r }) => {
  //  const initEvt = editCartEvt || rescdlEvt,
  const { navigate, setParams } = useNavigation(),
    goBack = () => navigate("Event", { id }),
    { myid, lang, rus, hhfrmt } = useAuth(),
    {
      books: { [id]: e },
      allBooksArr,
      deleteEvent,
      setEvent,
      deleteBook,
      rescdleEvent,
      cancelBook,
      createOrder,
      getPass,
    } = useClient(),
    {
      coaches: { [r.coachID || e?.coachID]: c },
    } = useSchool(),
    { price: prvPrice, grPrice } = c || {};

  const {
    from: from0,
    clients: { [myid]: client },
    clients: {
      [myid]: { quant, sum: paidsum },
    },
  } = e || {};

  const { from, slotID } = r.picked || {},
    to = from + dur * 60000,
    noChanges = from === from0 && to === e.to,
    isTrial = e?.isTrial || (e.privat && !passID && !e.price);
  // noDurChange = isTrial || passID;

  const price = isTrial ? 0 : (quant > 1 ? grPrice : prvPrice) * (dur / 60),
    newsum = isTrial || passID ? 0 : price * quant;

  let checkFreeChange = (data) =>
    (data || e).isTrial || (data || e).from > Date.now() + 8 * 60 * 60000;

  const freeChange = checkFreeChange(),
    sumDiff = isTrial || passID ? 0 : newsum - paidsum,
    sumTopay = freeChange && !sumDiff ? 0 : freeChange ? sumDiff : newsum;

  const dochecks = async () => {
    if (!from) return toast(TIMER[lang]);
    if (!slotID) return toast(DURER[lang]);

    if (freeChange && !checkFreeChange()) {
      // need to rerender so that 'freeChange' is updated
      setLoad(true);
      setTimeout(() => setLoad(false), 100);

      return callAlert(...ALRTPAID[lang](passID, newsum), [
        { label: rus ? "Продолжить" : "Continue", onClick: save },
        refundsAlertButton,
      ]);
    }

    if (isTrial) {
      let earlierBook = allBooksArr.find((e) => e.id !== id && e.from <= from);
      if (earlierBook) return denyTrialRescdle(earlierBook, hhfrmt, lang);
    }

    setLoad(true);

    let dc = await getDoc(dbEvents(id)),
      d = dc.exists() && dc.data();

    if (!d) {
      navigate("ProfileStack", { screen: "Profile" });
      deleteEvent(id);
      return toast(EVTER1[lang]());
    }

    let refuseNUpdate = (text) => (
      goBack(), setEvent(d), toast(text, 4000, 200)
    );

    if (!d.active) return refuseNUpdate(EVTER1[lang](d));

    if (!d.clientsIds.includes(myid))
      return goBack(), deleteBook(id), toast(EVTER2[lang]);

    let canRescdl = !d.clientsIds[1] && (d.privat || d.clientCreated === myid);
    if (!canRescdl) return refuseNUpdate(EVTER3[lang](d.clientsIds[1]));

    let wasEdited = !issame(d.edited, e.edited);
    if (wasEdited) return refuseNUpdate(EVTER4[lang]);

    let sameTrial = issame(d.isTrial, e.isTrial),
      sameBookSum = issame(d.clients[myid]?.sum, paidsum);
    if (!sameTrial || !sameBookSum) return refuseNUpdate(EVTER5[lang]);

    // if no fee change and the class is booked by pass, check pass left duration 'durLeft' if it covers the new booking duration
    if (passID && !freeChange) {
      let pass = await getPass(passID),
        actvPass = isactivePass(pass),
        canRescdl2 = actvPass && pass?.durLeft >= dur;

      if (!canRescdl2)
        return goBack(), denyPassRescdle(actvPass, pass?.durLeft, 0, lang);
    }

    return { doc: d };
  };

  const save = async () => {
    if (noChanges) return goBack();
    if (offline()) return offlineToast();

    let { doc: d } = (await dochecks()) || {};
    if (!d) return setLoad();
    if (offline()) return offlineToast();

    const saveData = { id, from, to, slotID };
    if (passID) saveData.passID = passID;
    else saveData.price = sumDiff ? price : d.price;

    if (passID || sumTopay <= 0) {
      if (!passID) saveData.clientNewSum = newsum;
      if (passID && !freeChange) saveData.passNewCharge = -dur;

      let refund = passID ? null : -sumTopay;

      let proceed = () => {
        if (offline()) return offlineToast();
        setParams({ saving: true });
        rescdleEvent(saveData, refund, setLoad);
      };

      if (freeChange || !passID) return proceed();

      if (passID && !freeChange)
        return callAlert(
          ...ALRTPASS[lang](dur),
          [
            { label: "OK", onClick: proceed },
            { label: rus ? "Закрыть" : "Close", onClick: () => setLoad() },
          ],
          "strict"
        );
    }

    // next are the PAID re-schedules (extra duration or no free changes due to less 8 hours till the start )
    // We cancel booking and order a new one

    let { clients, clientsIds, ...rest } = d,
      isReschedule = true,
      applyRefund = freeChange;

    let newOrderCart = [
      Object.assign(
        { custom: true },
        { ...rest, ...saveData, client: { ...client, sum: newsum } }
      ),
    ];

    let newOrderData = { cart: newOrderCart, total: newsum, isReschedule };

    let proceed = async () => {
      if (offline()) return offlineToast();

      setParams({ saving: true });
      let afterCancel = () => createOrder(newOrderData);
      cancelBook({ id, isReschedule }, applyRefund, afterCancel, setLoad);
    };

    let buttons = [
      { label: rus ? "Да" : "Yes", onClick: proceed },
      { label: rus ? "Нет" : "No", onClick: () => setLoad() },
    ];

    if (applyRefund)
      return callAlert(...ALRTNEWDUR[lang](paidsum, newsum), buttons, "strict");

    // if paid re-schedule
    buttons.push({
      label: REFNDPOL[lang],
      onClick: () => (setLoad(), openRefundPlcy()),
    });

    return callAlert(...ALRTPAID2[lang](newsum), buttons, "strict");
  };

  return (
    <>
      <View style={{ flex: 1 }} />

      {!noChanges && sumTopay !== 0 && (
        <SumRow
          big
          name={SUMTXT[lang](sumTopay)}
          sum={sumTopay > 0 ? sumTopay : -sumTopay}
          style={{ marginTop: 36, marginBottom: -4 }}
        />
      )}

      <Button
        big
        inactive={offline()}
        text={BUTNTXT[lang](noChanges)}
        onPress={save}
        style={{ marginTop: !sumTopay ? 40 : 16 }}
      />

      <RefundsComp />
    </>
  );
});

export let denyPassRescdle = (isactive, durLeft, label1, lang) =>
  callAlert(...DENYPASS[lang](isactive, durLeft), [label1, refundsAlertButton]);

let denyTrialRescdle = ({ from, id }, hhfrmt, lang) => {
  let rus = lang === "ru",
    timetext = dayjs(from).format("ddd D MMM, " + hhfrmt),
    openBook = () => rootNavEvent({ id });

  return callAlert(...DENYTRIAL[lang](timetext), [
    { label: rus ? "Открыть занятие" : `Open class`, onClick: openBook },
  ]);
};

let {
    TIMER,
    DURER,
    ALRTPAID,
    EVTER1,
    EVTER2,
    EVTER3,
    EVTER4,
    EVTER5,
    ALRTPASS,
    ALRTNEWDUR,
    ALRTPAID2,
    SUMTXT,
    BUTNTXT,
    DENYPASS,
    DENYTRIAL,
  } = translates.Rescheduler,
  { REFNDPOL } = translates;
