import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { FlatList } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { onSnapshot } from "firebase/firestore";
import dayjs from "dayjs";
import { useAuth, useCart, useClient, useSchool } from "../commons/Stores";
import {
  dbOrders,
  showToast,
  parseOrder,
  copytext,
  isDesktop,
} from "../commons/utils";
import {
  Loader,
  Title2,
  Row,
  MarkerComp,
  Refresher,
  Container,
  AbsLoader,
  TmznComp,
  IDCaption,
} from "../commons/UI";
import CoachCard from "../comp/CoachCard";
import { OrderEvent } from "../comp/OrderCard";
import OrderFooter from "../comp/OrderFooter";
import { translates } from "../translates";

export default observer(({ navigation: nav, route: { params: p } }) => {
  const mount = useRef(true),
    { navigate, replace, goBack, push } = nav,
    { myid, lang, hhfrmt, setAfterLogin } = useAuth();

  useEffect(() => {
    if (!myid)
      navigate("ProfileStack", { screen: "Login" }),
        showToast(AUTHER[lang]),
        setAfterLogin("Order", p);
  }, []);

  const orderID = p.orderID || p.id,
    { programs } = useSchool(),
    {
      orders: { [orderID]: o },
      setOrder,
      getOrder,
    } = useClient(),
    { add: addCart } = useCart(),
    { coachID, created } = o || {},
    parsed = parseOrder(o, lang),
    { paid, pending, expired, cancelled, eventsArr } = parsed,
    oneActiveEvent = eventsArr?.filter((e) => e.active)?.length === 1,
    [initWait] = useState(p.init && paid && oneActiveEvent),
    dbref = dbOrders(orderID),
    [load, setLoad] = useState(initWait);

  let title = TTL[lang];
  if (created)
    title += dayjs(created).format(
      (dayjs().diff(created, "d") < 8 ? " dddd," : "") +
        " D MMMM YYYY, ddd " +
        hhfrmt
    );

  useLayoutEffect(() => {
    nav.setOptions({ title: title + " – BLOSS.AM" });
  }, [!created]);

  // If the user just ordered and auto-navigated here (params.init), show load && <AbsLoader/> and close it after the payment url opened
  useEffect(() => {
    if (initWait) {
      let { id } = eventsArr[0];
      // have a different behavior when desktop or mobile, because of some bug with cycled "navigate" on desktop
      let openEvent = () => (
        setLoad(false), (isDesktop ? replace : navigate)("Event", { id })
      );
      setTimeout(openEvent, 2000);
    }

    let refuse = () => (goBack(), showToast(UIDER[lang]));
    let ondb = (doc) => {
      if (!mount.current) return;
      if (!doc.exists()) return p.init ? 0 : refuse();
      let d = doc.data();
      if (d.client !== myid) return refuse();
      setOrder(d);
    };

    const listener = onSnapshot(dbref, ondb);
    return () => ((mount.current = false), listener());
  }, []);

  const onRefresh = useCallback(() => getOrder(orderID), []);

  if (!myid || !o) return <Loader big />;

  let idtext = `id ${orderID}`;
  if (orderID.startsWith("admin-")) idtext += CRTDBY[lang](o.createdBy);

  const ListHeaderComponent = (
    <>
      <Row>
        <Title2 style={{ flex: 1 }} selectable>
          {title}
        </Title2>

        {(cancelled || expired || pending) && (
          <MarkerComp
            text={parsed.statusText}
            color={parsed.statusColor}
            style={{ marginLeft: 18 }}
          />
        )}
      </Row>

      <Row>
        <IDCaption onPress={() => copytext(idtext)}>{idtext}</IDCaption>
      </Row>

      <CoachCard small order {...{ coachID }} style={{ marginTop: 4 }} />
      <TmznComp style={{ marginTop: 32, marginBottom: 12 }} />
    </>
  );

  const renderItem = ({ item: e }) => (
    <OrderEvent
      {...{ e, myid, lang, hhfrmt, addCart, push, navigate }}
      orderPaid={paid && o.method}
      orderExpired={cancelled || expired}
      prog={programs[e.progID]}
    />
  );

  return (
    <Container>
      <FlatList
        data={eventsArr}
        {...{
          keyExtractor,
          renderItem,
          ListHeaderComponent,
          ItemSeparatorComponent,
        }}
        ListFooterComponent={<OrderFooter {...p} {...{ orderID, setLoad }} />}
        ListFooterComponentStyle={{ flexGrow: 1 }}
        contentContainerStyle={{ flexGrow: 1, padding: 24, paddingBottom: 16 }}
        refreshControl={<Refresher update={onRefresh} />}
      />
      {load && <AbsLoader />}
    </Container>
  );
});

let keyExtractor = (e) => e.id;

let ItemSeparatorComponent = styled.View`
  height: 16px;
`;

let { AUTHER, UIDER, TTL, CRTDBY } = translates.Order;
