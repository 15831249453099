import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { ScrollView, View } from "react-native";
import { observer } from "mobx-react-lite";
import { onSnapshot } from "firebase/firestore";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import { useAuth, useCart, useClient, useSchool } from "../commons/Stores";
import {
  dbCoaches,
  dbEvents,
  dbPasses,
  showToast,
  vibroToast,
  isactivePass,
  wwidth,
} from "../commons/utils";
import {
  Loader,
  Container,
  BlankView,
  BlankText,
  TitleNCloseRow,
  TrialComp as UITrialComp,
  Button,
  AbsLoader,
} from "../commons/UI";
import TimesBuilder from "../comp/TimesBuilder";
import { translates } from "../translates";

export default observer(
  ({
    navigation: nav,
    route: {
      params: { id, reschedule, passID, saving, ...p },
    },
  }) => {
    const mount = useRef(true),
      { navigate } = nav,
      { myid, rus, lang, setAfterLogin, hhfrmt } = useAuth(),
      {
        books: { [reschedule || "00"]: e },
        passes: { [passID || "00"]: pass },
        canTrial: clientCanTrial,
        handlePassListener,
      } = useClient(),
      { coachID } = e || p,
      {
        coaches: { [coachID]: coach },
        setCoach,
      } = useSchool(),
      {
        commonCart: { [passID || coachID]: cart },
        coachCartEarliest,
      } = useCart(),
      initEvt = reschedule ? e : id ? cart[id] : null,
      { name, slots, hasTrial } = coach || {},
      slotsArr = Object.values(slots || {}),
      [load, setLoad] = useState(!slotsArr[0] || (passID && !pass));

    useEffect(() => {
      return () => (mount.current = false);
    }, []);

    useLayoutEffect(() => {
      if (name) nav.setOptions({ title: WEBTTL[lang](name) });
    }, [!name]);

    useEffect(() => {
      if (coachID) {
        const ondb = (dc) => {
          if (!mount.current || !dc.exists()) return;
          setCoach(dc.data());
          if (!passID) setLoad(false);
          if (!saving) showToast(SLTS1[lang], 1500);
        };

        const coachListener = onSnapshot(dbCoaches(coachID), ondb);
        return () => coachListener();
      }
    }, [!saving, coachID]);

    const timetx = initEvt && dayjs(initEvt.from).format(" D MMM " + hhfrmt),
      title = reschedule
        ? (rus ? "Перенос" : "Re-schedule") + timetx
        : id
        ? (rus ? "Редактировать" : "Edit class") + timetx
        : TTL[lang](name);

    const Wrap = ({ children }) => (
      <Container>
        <TitleNCloseRow {...{ title }} />
        <ScrollView {...scrollProps}>{children}</ScrollView>
      </Container>
    );

    const LoadComp = ({ tx }) => (
      <Wrap>
        <Loader big>
          <BlankText>
            <BlankText>{tx || LOAD1[lang](name)}</BlankText>
          </BlankText>
        </Loader>
      </Wrap>
    );

    const EmptyComp = ({ tx }) => (
      <Wrap>
        <BlankView>
          <BlankText>
            <BlankText>{tx || EMPT[lang](name)}</BlankText>
          </BlankText>
        </BlankView>
      </Wrap>
    );

    const onPassListener = (doc) => {
      setLoad(false);
      let d = handlePassListener(doc);
      if (!reschedule && d?.active && d.durLeft < 30) vibroToast(PASSEMP[lang]);
    };

    useEffect(() => {
      if (passID) {
        let passListener = onSnapshot(dbPasses(passID), onPassListener);
        return () => passListener();
      }
    }, [passID]);

    ///////////////////////
    //////// IF RE-SCHEDULE

    if (reschedule) {
      useEffect(() => {
        let ondb = (dc) => {
          if (!mount.current) return;
          let d = dc.exists() && dc.data(),
            dbook = d?.clientsIds?.includes(myid);
          if (saving && !d) return nav.goBack();

          if (d?.active && dbook) return;
          let errorText = EVT1[lang](d?.active);
          return nav.goBack(), vibroToast(errorText);
        };

        const eventListener = onSnapshot(dbEvents(reschedule), ondb);
        return () => eventListener();
      }, [!saving]);

      useEffect(() => {
        if (e && saving) nav.goBack(), vibroToast(RSCLDTX[lang]);
      }, [e?.from, e?.to]);

      if (!e || (load && !slotsArr[0]))
        return <LoadComp tx={!e && EVTLD[lang]} />;
      if (!slotsArr[0]) return <EmptyComp />;

      if (passID) {
        if (!pass) return <EmptyComp tx={PASER1[lang]} />;

        let isactive = isactivePass(pass);
        if (!isactive)
          return (
            <Wrap>
              <BlankView>
                <BlankText>{PASER2[lang]}</BlankText>
                <Button
                  text={rus ? "Назад" : "Go back"}
                  onPress={nav.goBack}
                  style={{ width: wwidth - 48, marginTop: 16 }}
                />
              </BlankView>
            </Wrap>
          );
      }

      return (
        <Container>
          <TitleNCloseRow {...{ title }} />
          <View style={{ flex: 1 }}>
            <ScrollView {...scrollProps}>
              <TimesBuilder
                {...{ reschedule, coachID, initEvt, passID, setLoad }}
                hideDur={e.isTrial || passID || (e.privat && !e.price)}
              />
            </ScrollView>
            {load && (
              <AbsLoader
                text={saving && (rus ? "Перенос..." : "Re-scheduling...")}
              />
            )}
          </View>
        </Container>
      );
    }

    // else are create or edit CustomEvent Product or Package classes
    const othersIncart = orderBy(
      Object.values(cart || {}).filter(
        (e) => e.custom && !e.error && (initEvt ? e.id !== id : true)
      ),
      "from"
    );

    const cartStarts = othersIncart.reduce((res, curr) => {
      let { id, from, to, day } = curr;
      if (res.some((e) => e.from == from)) return res; // can be double crossing starts due to repeat order
      res.push({ id, from, to, day });
      return res;
    }, []);

    ///////////////////////
    // if create or edit Package pass class
    if (passID) {
      if (load) return <LoadComp />;
      if (!slotsArr[0]) return <EmptyComp />;
      if (!pass) return <EmptyComp tx={PASER1[lang]} />;

      return (
        <Container>
          <TitleNCloseRow {...{ title }} />
          <ScrollView {...scrollProps}>
            <TimesBuilder {...{ coachID, passID, initEvt, cartStarts }} />
          </ScrollView>
        </Container>
      );
    }

    // else are create new class or edit cart class
    const canTrial = hasTrial && (clientCanTrial || !myid),
      [showTrial, setShowTrial] = useState(canTrial);

    if (load) return <LoadComp />;
    if (!slotsArr[0]) return <EmptyComp />;

    const checkCartEarliest =
        canTrial && !initEvt && coachCartEarliest(coachID),
      cartEalstTime = dayjs(checkCartEarliest).format("D MMM " + hhfrmt);

    const trialText = !myid
      ? null
      : checkCartEarliest
      ? TRL1[lang](cartEalstTime)
      : TRL2[lang];

    const ontrialPress = myid
      ? null
      : () => (
          navigate("ProfileStack", { screen: "Login" }),
          setAfterLogin("CustomEvent", { coachID })
        );

    const TRIALCOMP = showTrial && (
      <UITrialComp
        text={trialText}
        onPress={ontrialPress}
        hide={() => setShowTrial(false)}
        style={{ marginBottom: 24 }}
      />
    );

    return (
      <Container>
        <TitleNCloseRow {...{ title }} />
        <ScrollView {...scrollProps}>
          <TimesBuilder
            {...{
              coachID,
              initEvt,
              canTrial,
              cartStarts,
              TRIALCOMP,
            }}
          />
        </ScrollView>
      </Container>
    );
  }
);

let scrollProps = {
  showsVerticalScrollIndicator: false,
  contentContainerStyle: {
    flexGrow: 1,
    padding: 24,
    paddingTop: 8,
    paddingBottom: 48,
  },
};

let {
  SLTS1,
  WEBTTL,
  TTL,
  LOAD1,
  EMPT,
  PASSEMP,
  EVT1,
  EVTLD,
  PASER1,
  PASER2,
  TRL1,
  TRL2,
  RSCLDTX,
} = translates.CustomEvent;
