import { makeAutoObservable } from "mobx";
import orderBy from "lodash/orderBy";
import { isbookblEvent, localStor, getDay, checkSlotsCrossed } from "./utils";

export const findCartCross = (e, arr) =>
  arr.some((c) => (c.id == e.id ? false : checkSlotsCrossed(e, c)));

let localCart = JSON.parse(localStor.getItem("cart") || "{}");

// we save locally only common cart classes, which are not build from package (only from a coach's products 1on1 / Groups)

let initCart = Object.keys(localCart)
  .filter((key) => !!Object.keys(localCart[key] || {})[0])
  .reduce((res, key) => {
    let arr = Object.values(localCart[key]).filter((e) => !e.client.passID),
      sorted = orderBy(arr, "from");

    let obj = sorted.reduce((prev, e, i) => {
      if (!isbookblEvent(e)) return prev;
      e.day = getDay(e.from);
      if (e.error) delete e.error;
      let iscross = i > 0 && findCartCross(e, Object.values(prev));
      if (iscross) e.error = "crossing";
      prev[e.id] = e;
      return prev;
    }, {});

    return Object.keys(obj)[0] ? { ...res, [key]: obj } : res;
  }, {});

export default class Cart {
  // commonCart has keys as coachID or packageID (the second ones aren't saved locally)
  // cartBycoach has 'commonCart' part with only coach events
  // cartBypackage has 'commonCart' part with only package events

  commonCart = initCart || {};

  constructor(school, myid) {
    makeAutoObservable(this);
    this.school = school;
    this.myid = myid;
  }

  get cartBycoach() {
    let res = {};
    for (let key in this.commonCart) {
      let data = this.commonCart[key],
        arr = Object.values(data || {}); //.filter(e => !e.client.passID);
      if (!arr[0] || !!arr[0].client.passID) continue;
      // let coachKey = sortedCoachIds.includes(key) ? key : arr[0].coachID;
      res[key] = data;
    }
    return res;
  }

  get cartBypackage() {
    let res = {};
    for (let key in this.commonCart) {
      let data = this.commonCart[key],
        arr = Object.values(data || {}); //.filter(e => !!e.client.passID);
      if (!arr[0] || !arr[0].client.passID) continue;
      res[key] = data;
    }
    return res;
  }

  get cart() {
    return this.cartBycoach;
  }

  get hasAnyCoach() {
    return Object.keys(this.cart).some(
      (c) => this.cart[c] && Object.keys(this.cart[c] || {}).length
    );
  }

  get latestCoachCart() {
    if (!this.hasAnyCoach) return null;

    // find the latest coach cart
    let activeCoaches = Object.keys(this.cart).filter(
      (c) => this.cart[c] && Object.keys(this.cart[c] || {}).length
    );

    let getLatestEvents = (coach) =>
      orderBy(Object.values(this.cart[coach]), "added", "desc");

    let coachID = activeCoaches[activeCoaches.length - 1];

    let defltCoachCart = getLatestEvents(coachID),
      [{ added }] = defltCoachCart;

    if (!activeCoaches[1])
      return { coachID, added, quant: defltCoachCart.length };

    let [evt] = defltCoachCart;

    let latestEvent = activeCoaches.reduce((prev, c) => {
      let [event] = getLatestEvents(c);
      return event.added > prev.added ? event : prev;
    }, evt);

    ({ coachID, added } = latestEvent);
    // added = latestEvent.added;
    return { coachID, added, quant: getLatestEvents(coachID).length };
  }

  getCart = (key) => orderBy(Object.values(this.commonCart[key] || {}), "from");

  coachCartEarliest = (coachID, exceptTime) => {
    let cart = this.getCart(coachID),
      resArr = exceptTime ? cart.filter((e) => e.from !== exceptTime) : cart;
    return resArr[0]?.from;
  };

  setLocal = () => localStor.setItem("cart", JSON.stringify(this.cartBycoach));

  add = (arr) => {
    let [
        {
          coachID,
          client: { passID },
        },
      ] = arr,
      key = passID || coachID,
      sorted = orderBy(arr, "from"),
      {
        myid,
        commonCart: { [key]: currCart },
      } = this,
      currArr = Object.values(currCart || {}),
      added = Date.now();

    let obj = sorted.reduce((res, e, i) => {
      e.added = added;
      if (myid && !e.client.uid) e.client.uid = myid;
      if (e.custom) e.id = added + i;
      else e = { ...this.school.groups[e.id], ...e };

      // find crossing in current cart or events, being added right now
      let iscross = findCartCross(e, currArr);
      if (iscross) e.error = "crossing";
      else if (i > 0) {
        let iscross2 = findCartCross(e, Object.values(res));
        if (iscross2) e.error = "crossing";
      }
      return { ...res, [e.id]: e };
    }, {});

    this.commonCart[key] = { ...currCart, ...obj };
    if (!passID) this.setLocal();
  };

  markError = (key, id, tx) => (this.commonCart[key][id].error = tx);

  update = (key, obj) => {
    if (obj.error?.includes("cross")) delete obj.error;
    let { id } = obj,
      currCart = this.commonCart[key];
    obj.added = Date.now();

    this.commonCart[key][id] = { ...currCart[id], ...obj };

    let currArr = this.getCart(key),
      ispass = !!currArr[0].client.passID;

    if (!currArr[1]) return !ispass && this.setLocal();

    // run through all events and handle crossing or miss-crossing
    currArr.reduce((prev, e) => {
      let { id: eid } = e,
        wasCross = e.error?.includes("cross"),
        iscross = findCartCross(e, prev);

      if (iscross && !wasCross) this.markError(key, eid, "crossing");
      if (!iscross && wasCross) delete this.commonCart[key][eid].error;
      prev.push(e);
      return prev;
    }, []);

    if (!ispass) this.setLocal();
  };

  remove = ({ coachID, passID, id }) => {
    let key = passID || coachID;
    delete this.commonCart[key][id];
    let currArr = this.getCart(key);
    if (!currArr[0]) return !passID && this.setLocal();

    let wereCrossed = currArr.filter((e) => e.error?.includes("cross"));
    wereCrossed.forEach((e) => {
      let iscross = findCartCross(e, currArr);
      if (!iscross) delete this.commonCart[key][e.id].error;
    });

    if (!passID) this.setLocal();
  };

  clearCart = ({ passID, coachID }) => {
    delete this.commonCart[passID || coachID];
    if (!passID) this.setLocal();
  };
}
