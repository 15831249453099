import React from "react";
import { Modal } from "react-native";
import styled from "styled-components/native";
import ImageViewer from "react-native-image-zoom-viewer";
import { useAuth } from "../commons/Stores";
import { isDesktop } from "../commons/utils";
import { CloseIcon, Loader, Press, Text16 } from "../commons/UI";
import { translates } from "../translates";

export default ({ route: { params: p }, navigation: { goBack } }) => {
  const { lang } = useAuth();

  const Header = () => (
    <ClosePress onPress={goBack}>
      <CloseIcon onPress={goBack} color="white" size={44} />
    </ClosePress>
  );

  const Footer = isDesktop ? undefined : () => <FootText>{SWP[lang]}</FootText>;

  return (
    <Modal visible transparent>
      <ImageViewer
        backgroundColor="rgba(0,0,0,0.5)"
        imageUrls={[{ url: p.uri }]}
        enableSwipeDown
        swipeDownThreshold={100}
        renderIndicator={() => null}
        loadingRender={() => <Loader color="#fff" />}
        renderHeader={Header}
        renderFooter={Footer}
        footerContainerStyle={{ width: "100%" }}
        onCancel={goBack}
      />
      {/* <TransformWrapper
          disabled={false}
          doubleClick={{ mode: "reset" }}
          initialScale={1}
          panning={{ disabled: false }}
          onPinchingStop={() => console.log("onPinchingStop")}
        >
          <TransformComponent wrapperStyle={{ flex: 1 }}>
            <img src={p.uri} alt="test" />
          </TransformComponent> 
        </TransformWrapper>*/}
    </Modal>
  );
};

const FootText = styled(Text16)`
    text-align: center;
    color: white;
    padding: 18px 0;
  `,
  ClosePress = styled(Press)`
    z-index: 100;
    height: 120px;
    align-items: flex-end;
    padding: 24px;
    margin-bottom: -120px;
  `;

let { ACER1, SCS, SWP, DWNER, BTNTX } = translates.Image;
