import React, { useCallback, useEffect } from "react";
import { FlatList, View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { useAuth, useCart, useClient, useSchool } from "../commons/Stores";
import { ages, deskPadding, genrtItemLayout } from "../commons/utils";
import {
  Loader,
  RowBetween,
  PageTitle,
  Refresher,
  FilterButton,
  BlankView,
  Touch,
  GrayContainer,
  BLUE,
  BlankText,
  LOGO,
  Row,
  BORDERGRAY,
  EmptyText,
  FilterView,
  Press,
  GRAY,
  DGRAY,
  FilterText,
  BACKGRAY,
} from "../commons/UI";
import CoachCard from "../comp/CoachCard";
import { CartButtonComp } from "./Profile";
import { HomeBanner } from "../comp/PackageCard";
import { DatesEventsList } from "../comp/Dates";
import { translates } from "../translates";

export default observer(({ navigation: { navigate } }) => {
  const { lang, rus } = useAuth(),
    {
      load,
      coachesArr,
      programs,
      filter: { progID },
      setFilter,
      getAllGroups,
    } = useSchool(),
    { hasAnyCoach: hasCart } = useCart(),
    filterProg = programs[progID];

  const onRefresh = async () => getAllGroups();
  // await Promise.all([getSchool(), getAllGroups()]);

  const filteredCoaches = progID
    ? coachesArr.filter((c) => c.programs?.includes(progID))
    : coachesArr;

  const renderCoaches = ({ item: coach }) => (
    <CoachCard full {...{ programs, lang, coach }} />
  );

  if (load)
    return (
      <LoadWrap>
        <Loader />
        <BlankText>{LOAD[lang]}</BlankText>
      </LoadWrap>
    );

  const ListEmptyComponent = (
    <BlankView>
      <EmptyText>{EMPT3[lang](filterProg?.name)}</EmptyText>
      {filterProg && (
        <Touch onPress={() => setFilter((pr) => ({ ...pr, progID: null }))}>
          <EmptyText style={{ color: BLUE }}>
            {rus ? "Сбросить фильтр" : "Reset filter"}
          </EmptyText>
        </Touch>
      )}
    </BlankView>
  );

  return (
    <GrayContainer>
      <FlatList
        {...listProps}
        data={filteredCoaches}
        renderItem={renderCoaches}
        ListHeaderComponent={<Groups {...{ navigate }} />}
        {...{ ListEmptyComponent }}
        contentContainerStyle={screenStyle(hasCart)}
        refreshControl={<Refresher update={onRefresh} />}
      />
      {hasCart && <CartButtonComp {...{ navigate }} />}
    </GrayContainer>
  );
});

const Groups = observer(({ navigate }) => {
  const { lang, rus } = useAuth(),
    {
      groupsLoad,
      filter: { progID, age, level },
      setFilter,
    } = useSchool(),
    { allActiveEvents, nearestBook } = useClient(),
    hasFilter = progID || age || level;

  const filtered = hasFilter
    ? allActiveEvents.filter(
        (e) =>
          (progID ? e.progId === progID : true) &&
          (age ? e.age === age : true) &&
          (level ? !e.level || e.level === level : true)
      )
    : allActiveEvents;

  const title = TTL[lang](nearestBook);

  const ListEmptyComponent = allActiveEvents[0] ? (
    <EmptyView>
      <EmptyText>{EMPT1[lang]}</EmptyText>
      <Touch onPress={() => setFilter({})}>
        <EmptyText style={{ color: BLUE }}>
          {rus ? "Сбросить фильтры" : "Reset filters"}
        </EmptyText>
      </Touch>
    </EmptyView>
  ) : groupsLoad ? (
    <Loader big />
  ) : (
    <EmptyText style={{ flex: 1, marginTop: 32, marginBottom: 16 }}>
      {EMPT2[lang]()}
    </EmptyText>
  );

  return (
    <>
      <HeadRowComp {...{ navigate }} />
      <HomeBanner {...{ lang, navigate }} />
      <DatesEventsList
        home
        events={filtered}
        {...{ title, lang, ListEmptyComponent }}
      />
      <PageTitle style={{ marginTop: 32, marginBottom: 20 }}>
        {rus ? "Тренеры" : "Coaches"}
      </PageTitle>
    </>
  );
});

const HeadRowComp = observer(({ navigate }) => {
  const { rus, lang } = useAuth(),
    {
      filter: { progID, age, level },
      handleLevel,
      programs: { [progID || "00"]: prog },
    } = useSchool();

  const hasFilter = progID || age || level,
    fltAgeName = age && ages[lang].find((a) => a.id >= age).name,
    fltLevName = level && handleLevel(level),
    fltProgName = progID && (prog.short || prog.name);

  const filterTitle = hasFilter
    ? [fltProgName, fltLevName, fltAgeName].filter(Boolean).join(", ")
    : rus
    ? "Фильтры"
    : "Filters";

  return (
    <HeadRowView>
      <Row style={{ flex: 1 }}>
        <FilterButton
          onPress={() => navigate("Filters", { from: "Home" })}
          active={hasFilter}
          text={filterTitle}
          hasIcon
          style={{ width: filterWidth - 8 }}
        />
      </Row>
      <LOGO />
      <Row style={{ flex: 1 }}>
        <View style={{ flex: 1 }} />
        <TimeFormatComp />
      </Row>
    </HeadRowView>
  );
});

export let TimeFormatComp = observer(({ border, ...r }) => {
  const { rus, time24, toggleTimeFormat } = useAuth(),
    txstyle = (active) => (active ? undefined : { style: { color: GRAY } });

  let View12 =
    border && !time24
      ? BorderTimeView
      : border
      ? GrayTimeView
      : !time24
      ? ActiveTimeView
      : TimeFormatView;

  let View24 =
    border && time24
      ? BorderTimeView
      : border
      ? GrayTimeView
      : time24
      ? ActiveTimeView
      : TimeFormatView;

  return (
    <TimeFormatView
      style={{
        width: filterWidth,
        borderWidth: border ? 0 : 1,
        backgroundColor: border ? BACKGRAY : undefined,
        ...r.style,
      }}
    >
      <Press
        onPress={time24 ? toggleTimeFormat : null}
        style={!time24 && { marginRight: -8, zIndex: 1 }}
      >
        <View12>
          <TimeFormat {...txstyle(!time24)}>am/pm</TimeFormat>
        </View12>
      </Press>
      <Press
        onPress={time24 ? null : toggleTimeFormat}
        style={time24 && { marginLeft: -8 }}
      >
        <View24 style={{ width: 56 }}>
          <TimeFormat {...txstyle(time24)}>{rus ? "24ч" : "24h"}</TimeFormat>
        </View24>
      </Press>
    </TimeFormatView>
  );
});

let filterWidth = 72 + 56 - 8,
  keyExtractor = (c) => c.uid || c.id,
  getItemLayout = (_, i) => genrtItemLayout(120 + 12, i),
  ItemSeparatorComponent = styled.View`
    height: 12px;
  `,
  listProps = {
    keyExtractor,
    ItemSeparatorComponent,
    getItemLayout,
    contentContainerStyle: { flexGrow: 1 },
  };

let screenStyle = (hasCart) => ({
  flexGrow: 1,
  padding: deskPadding + 24,
  paddingTop: 16,
  paddingBottom: hasCart && !deskPadding ? 24 * 2 + 60 : 32,
});

let LoadWrap = styled(GrayContainer)`
    justify-content: center;
    padding: 0 ${deskPadding + 24}px;
  `,
  HeadRowView = styled(RowBetween)`
    border-bottom-width: 1px;
    border-bottom-color: ${BORDERGRAY};
    padding: 0 ${deskPadding + 24}px 12px;
    margin: 0 ${-deskPadding - 24}px 24px;
  `,
  EmptyView = styled.View`
    justify-content: center;
    margin: 32px 0 16px;
  `,
  TimeFormat = styled(FilterText)`
    min-width: undefined;
  `,
  TimeFormatView = styled(FilterView)`
    width: 72px;
    padding: 0 0 0 0;
  `,
  GrayTimeView = styled(TimeFormatView)`
    background: ${BACKGRAY};
  `,
  ActiveTimeView = styled(TimeFormatView)`
    background: white;
  `,
  BorderTimeView = styled(TimeFormatView)`
    /* border: 1px ${GRAY} solid; */
    border-width: 1px;
    background: white;
  `;

let trans = translates.Home,
  { TTL, EMPT1, EMPT2, LOAD, EMPT3 } = trans;
