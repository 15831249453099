import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import styled from "styled-components/native";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import { useAuth, useClient, useSchool } from "../commons/Stores";
import {
  copytext,
  wwidth,
  getSuprtAppeal,
  callAlert,
  isDesktop,
  copyDeepLink,
  openURL,
  durtnText,
  ages,
  wheight,
  isactivePass,
  offline,
  offlineToast,
  refundsAlertButton,
} from "../commons/utils";
import {
  RowBetween,
  CopyIcon,
  Row,
  RowCentered,
  Text18,
  DGRAY,
  GRAY,
  Text16,
  Touch,
  Press,
  LITEBORDER,
  BACKGRAY,
  ZoomView,
  BLUE,
  VideoIcon,
  PageTitle,
  shadow4,
  FooterOption,
  TmznComp,
} from "../commons/UI";
import { DurComp, TimeComp } from "../comp/TimeComp";
import { SettingsView, Setting } from "../comp/ProfileHeader";
import { denyPassRescdle } from "./Rescheduler";
import { translates } from "../translates";
import { AppPromo } from "../screens/Login";

export const RowsInfo = observer(({ e, myid, offer, passed, ...r }) => {
  const { lang, rus } = useAuth(),
    { handleLevel } = useSchool(),
    { clients, privat, active, level, from, to } = e,
    book = e.clientsIds?.includes(myid),
    { [myid]: mybook } = book ? clients : {},
    myquant = book ? mybook.quant : 0,
    quant = Object.values(clients || {}).reduce(
      (res, c) => res + (c?.quant || 0),
      0
    );

  let bookMethod = book && mybook.sum + "$";
  if (mybook?.passID) bookMethod = rus ? "абонемент" : "by package";

  const isTrial = e.isTrial || (privat && !e.price),
    type = CLSTYPE[lang](privat, e.clientCreated === myid, isTrial),
    levelName = privat ? null : handleLevel(level).toLowerCase(),
    cancel = !active,
    video = passed && active && e.clientsIds.includes(myid) && e.video;

  let booksText;
  if (book) booksText = rus ? "Другие брони" : "Other bookings";
  else {
    if (rus) booksText = "Бронирования";
    else booksText = "Bookings" + (passed ? "" : " for now");
  }

  const openType = () => r.navigate("AddInfo", { privats: true }),
    openLevel = () => r.navigate("AddInfo", { level });

  return (
    <>
      <DateTimeView>
        {!passed && <TmznComp style={{ marginBottom: 10 }} />}
        <RowCentered>
          <TimeComp big {...{ from, to }} {...{ cancel }} />
          <DurComp big {...{ from, to, passed }} />
        </RowCentered>
      </DateTimeView>

      <ItemRow>
        <TypeName>{rus ? "Длительность" : "Duration"}</TypeName>
        <Type>{durtnText(to - from, "f", rus)}</Type>
      </ItemRow>

      <ItemRow>
        <TypeName>{rus ? "Тип" : "Type"}</TypeName>
        <Press onPress={openType}>
          <Type style={{ color: BLUE }}>{type}</Type>
        </Press>
      </ItemRow>

      <ItemRow>
        <TypeName>{rus ? "Возраст" : "Age"}</TypeName>
        <Type>{ages[lang].find((a) => e.age <= a.id).name}</Type>
      </ItemRow>

      {levelName && (
        <ItemRow>
          <TypeName>{rus ? "Уровень" : "Level"}</TypeName>
          <Press onPress={openLevel}>
            <Type style={{ color: BLUE }}>{levelName}</Type>
          </Press>
        </ItemRow>
      )}

      {book && (
        <ItemRow>
          <TypeName numberOfLines={1}>
            {rus ? "Мое бронирование" : "My booking"}
          </TypeName>
          <Press onPress={r.togglePopup}>
            <Type style={{ color: BLUE }}>
              {rus
                ? myquant + " чел, "
                : myquant + " person" + (myquant > 1 ? "s, " : ", ")}
              {bookMethod}
            </Type>
          </Press>
        </ItemRow>
      )}

      {(e.group || quant > myquant) && (
        <ItemRow>
          <TypeName>{booksText}</TypeName>
          <Type>{quant - myquant}</Type>
        </ItemRow>
      )}

      {video && (
        <VideoRow>
          <Touch onPress={() => openURL(video)} style={{ flexShrink: 0 }}>
            <RowCentered style={{ flexShrink: 0 }}>
              <VideoIcon color={BLUE} style={{ marginRight: 8 }} />
              <BlueText>
                {rus ? "Видео-запись" : "Video record"}
                {e.videoSize ? `, ${Math.round(e.videoSize)}mb` : ""}
              </BlueText>
            </RowCentered>
          </Touch>
        </VideoRow>
      )}
      {!active && (
        <CancelView>
          <PageTitle>{rus ? "Отменено" : "Cancelled"}</PageTitle>
        </CancelView>
      )}
    </>
  );
});

export const BookSettings = observer(
  ({ e, footer, togglePopup: toggle, setLoad, ...r }) => {
    const { id, from, privat, price } = e,
      { myid, rus, lang } = useAuth(),
      { time, orderID, sum, passID } = e.clients[myid],
      {
        cancelBook,
        passes: { [passID || "00"]: pass },
        getPass,
      } = useClient(),
      passed = r.passed || e.to < Date.now(),
      activeBook = e.active && !passed,
      isTrial = e?.isTrial || (privat && !price && !passID),
      orderTime = dayjs(time).format("(D MMM)"),
      dur = (e.to - from) / 60000;

    let canRescdle =
      !passed &&
      !e.clientsIds[1] &&
      (privat || e.clientCreated === myid) &&
      (passID ? isactivePass(pass) : true);

    useEffect(() => {
      if (passID && !pass) getPass(passID);
    }, [passID && !pass]);

    const openOrder = () => (
        r.navigate("Order", { orderID }), !footer && toggle()
      ),
      openPass = () => (r.navigate("Package", { passID }), !footer && toggle()),
      textSupport = () => (
        getSuprtAppeal({ myid, id, passID, orderID }, lang), !footer && toggle()
      );

    let checkFreeChange = () => isTrial || from > Date.now() + 8 * 60 * 60000;

    const rescdle = () => {
      if (!footer) toggle();
      if (offline()) return offlineToast();

      let forFree = checkFreeChange();
      let proceed = () => r.navigate("CustomEvent", { reschedule: id, passID });

      if (forFree) return proceed();

      if (passID && pass && pass?.durLeft < dur) {
        let option1 = {
          label: rus ? "Отменить занятие" : "Cancel class",
          onClick: cancel,
        };
        return denyPassRescdle("active", pass.durLeft, option1, lang);
      }

      return callAlert(
        rus ? "Продолжить?" : `Continue?`,
        PAYRSCD1[lang] + (passID ? PAYRSCD2[lang](dur) : PAYRSCD3[lang](sum)),
        [{ text: "Ok", onPress: proceed }, refundsAlertButton]
      );
    };

    const cancel = () => {
      if (!footer) toggle();
      if (offline()) return offlineToast();

      let applyRefund = (passID || sum) && checkFreeChange(),
        denyRefund = !applyRefund && (passID || sum);

      let alrtitle = CNCTTL[lang];
      if (denyRefund) alrtitle += CNCTTL2[lang](passID);
      else alrtitle += "?";

      let alrtext;
      if (applyRefund)
        alrtext = passID ? CNCLRF1[lang](dur) : CNCLRF2[lang](sum);
      else if (passID || sum) alrtext = CNCLNRF1[lang](passID);

      let proceed = async () => {
        if (offline()) return offlineToast();
        setLoad(true);
        r.setParams({ cancelling: true });
        await cancelBook(
          { id, passID },
          applyRefund,
          privat ? null : setLoad,
          setLoad
        );
      };

      return callAlert(alrtitle, alrtext, [
        { label: CNCALRT[lang], onClick: proceed },
        price && refundsAlertButton,
      ]);
    };

    if (footer)
      return (
        <>
          <FooterOption
            onPress={openOrder}
            text={OPT1[lang] + orderTime}
            border
            style={{ marginTop: 48 }}
          />
          {passID && (
            <FooterOption onPress={openPass} text={OPT2[lang]} border />
          )}
          {activeBook && (
            <>
              {canRescdle && (
                <FooterOption text={OPT3[lang]} onPress={rescdle} border />
              )}
              <FooterOption
                onPress={cancel}
                text={CNCTTL[lang]}
                border
                noarrow
              />
            </>
          )}
          <FooterOption
            onPress={textSupport}
            text={CNTSPRT[lang]}
            border
            noarrow
          />
          <AppPromo book style={{ marginBottom: -8 }} />
        </>
      );

    return (
      <>
        <SettingsWrap hasImage={activeBook} {...{ toggle }}>
          <Touch onPress={openOrder}>
            <Setting>{OPT1[lang] + orderTime}</Setting>
          </Touch>
          {passID && (
            <Touch onPress={openPass}>
              <Setting>{OPT2[lang]}</Setting>
            </Touch>
          )}
          {activeBook && (
            <>
              {canRescdle && (
                <Touch onPress={rescdle}>
                  <Setting>{OPT3[lang]}</Setting>
                </Touch>
              )}
              <Touch onPress={cancel}>
                <Setting>{CNCTTL[lang]}</Setting>
              </Touch>
            </>
          )}
          <Touch onPress={textSupport}>
            <Setting>{CNTSPRT[lang]}</Setting>
          </Touch>
        </SettingsWrap>
      </>
    );
  }
);

export const AddCalList = observer(({ setPopup, e }) => {
  const {
    lang,
    rus,
    profile: { timezoneName },
  } = useAuth();

  let { id, progName, from, to } = e,
    zoom = (e.zoom || " ").replace(/ /g, ""),
    title = "BLOSS " + (progName || (rus ? "занятие" : "class")),
    [classLink, setLink] = useState("https://app.bloss.am/class/" + id);

  let zoomLink = e.zoomLink || (zoom && "https://zoom.us/j/" + zoom);

  useEffect(() => {
    copyDeepLink("classes/" + id, "ignore").then(setLink);
  }, []);

  let desc0 = "";
  if (zoom)
    desc0 =
      `Zoom [url]${zoomLink}[/url] [br][br]` +
      (rus ? "Пароль" : "Password") +
      ` ${e.zoomPass} [br][br]ID ${zoom} [br][br]`;

  let desc = desc0 + BOOKLNK[lang] + `[url]${classLink}[/url]`,
    [[startDate, startTime], [endDate, endTime]] = [from, to].map((it) =>
      dayjs(it).format("YYYY-MM-DD HH:mm").split(" ")
    );

  const toggle = () => setPopup(0);

  return (
    <SettingsWrap {...{ toggle }} calendar>
      <AddToCalendarButton
        name={title}
        description={desc}
        {...{ startDate, startTime, endDate, endTime }}
        options="'Google','Apple','iCal','Microsoft 365'"
        location={zoomLink || undefined}
        iCalFileName={title}
        inline
        buttonsList
        hideBackground
        lightMode="light"
        trigger="click"
        buttonStyle="round"
        size="4"
        timeZone={timezoneName}
        styleLight={
          "--btn-background: #ffffff; --btn-border: " +
          LITEBORDER +
          "; --btn-shadow: null; --btn-shadow-hover: null; --btn-shadow-active: null; --btn-underline: #fff; --list-background: #fff; --list-background-hover: #ffffff; --btn-text: #444444; --list-text: #444444; --font: 'CeraPro-Regular';"
        }
      />
    </SettingsWrap>
  );
});
// Define a link text with the following schema: [url]https://....|URL Text[/url].

let SettingsWrap = (pr) => (
  <Press
    onPress={pr.toggle}
    style={{
      ...StyleSheet.absoluteFill,
      backgroundColor: "rgba(255,255,255,0.7)",
    }}
  >
    {pr.calendar ? (
      <AddCalView {...pr} style={shadow4} />
    ) : (
      <SettingsView
        {...pr}
        style={
          isDesktop
            ? { marginRight: 24 + 40, marginTop: pr.hasImage ? wwidth / 2 : 16 }
            : { marginTop: 16 }
        }
      />
    )}
  </Press>
);

export const ZoomComp = observer(({ e, lang, style }) => {
  const { zoom, zoomPass } = e,
    rus = lang === "ru",
    link =
      zoom && (e.zoomLink || "https://zoom.us/j/" + zoom.split(" ").join(""));

  const renderItems = (it, i) => {
    let title = i === 1 ? "Zoom ID" : ZOOM1[lang](i),
      text = i ? it : it?.replace("https://", ""),
      copy = () => copytext(i ? text : link);

    return (
      <Touch
        onPress={!it ? null : i ? copy : () => openURL(it)}
        key={String(i)}
      >
        <RowCentered style={{ paddingVertical: 8 }}>
          <ZoomTitle>{title}</ZoomTitle>
          {!it && (
            <ZoomTitle style={{ color: GRAY }}>
              {rus ? "скоро" : "soon"}
            </ZoomTitle>
          )}

          {it && (
            <ZoomRow>
              <Text18 style={!i && { color: BLUE }} numberOfLines={1}>
                {text}
              </Text18>
              <Touch onPress={copy}>
                <CopyIcon />
              </Touch>
            </ZoomRow>
          )}
        </RowCentered>
      </Touch>
    );
  };

  return (
    <ZoomView {...{ style }}>
      {[link, zoom, zoomPass].map(renderItems)}
    </ZoomView>
  );
});

let DateTimeView = styled.View`
    margin: 20px -24px 16px 0;
  `,
  Text = styled(Text16)`
    color: ${DGRAY};
  `,
  BlueText = styled(Text16)`
    color: ${BLUE};
    flex-shrink: 0;
  `,
  ZoomTitle = styled(Text)`
    flex-shrink: 0;
    width: 92px;
    margin-top: 1px;
  `,
  ZoomRow = styled(Row)`
    flex: 1;
    flex-shrink: 0;
  `,
  ItemRow = styled(RowBetween)`
    border-top-width: 1px;
    border-color: ${LITEBORDER};
    padding: 13px 0;
  `,
  TypeName = styled(Text)`
    flex-shrink: 0;
    flex: 1;
  `,
  Type = styled(TypeName)`
    text-align: right;
  `,
  VideoRow = styled(ItemRow)`
    justify-content: flex-start;
    padding-bottom: 20px;
  `,
  CancelView = styled.View`
    justify-content: center;
    align-items: center;
    background: ${BACKGRAY};
    height: 72px;
    border-radius: 10px;
    margin-top: 8px;
  `,
  AddCalView = styled.View`
    align-self: center;
    background: white;
    max-width: 300px;
    border-radius: 18px;
    z-index: 1;
    padding: 16px;
    margin-top: ${wheight / 3}px;
  `;

let { CNTSPRT, CLSTYPE } = translates,
  {
    PAYRSCD1,
    PAYRSCD2,
    PAYRSCD3,
    CNCLRF1,
    CNCLRF2,
    CNCTTL,
    CNCTTL2,
    CNCLNRF1,
    CNCALRT,
    ZOOM1,
    OPT1,
    OPT2,
    OPT3,
    BOOKLNK,
  } = translates.EventComps;
