import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import dayjs from "dayjs";
import { useAuth, useCart, useClient, useSchool } from "../commons/Stores";
import {
  dbEvents,
  dbPassed,
  isDesktop,
  showToast as toast,
  wwidth,
  callAlert,
  dayAgo,
  contactsAlert,
  ages,
} from "../commons/utils";
import {
  Button,
  Row,
  RowCentered,
  DGRAY,
  Text16,
  QuantButton,
  Touch,
  ShowMore,
  Press,
  Loader,
  Body,
  PageImage,
  GREEN,
  Container,
  SettingsIcon,
  ZoomView,
  CommentIcon,
  ShareIcon,
  BLUE,
  MarkerComp,
  CommentImage,
  Refresher,
  Title2,
  LITEBORDER,
  Text15,
  AbsLoader,
  HyperlinkWrap,
} from "../commons/UI";
import CoachCard from "../comp/CoachCard";
import {
  AddCalList,
  BookSettings,
  RowsInfo,
  ZoomComp,
} from "../comp/EventComps";
import { translates } from "../translates";

export default observer(({ navigation: nav, route: r }) => {
  const { id, passed: passed0, from: fromScreen, cancelling } = r.params,
    { myid, lang, rus } = useAuth(),
    {
      passed: { [id]: passedEvt },
      privats: { [id]: priv },
      groups: { [id]: group },
      deleteEvent,
      setPassed,
    } = useClient(),
    e = passedEvt || priv || group,
    { active, progID } = e || {},
    {
      programs: { [progID || "00"]: prog },
      deleteGroup,
    } = useSchool(),
    passed = passed0 || !!passedEvt || e?.to < Date.now(),
    book = e?.clientsIds?.includes(myid),
    activeBook = active && book && !passed,
    offer = active && !book && e?.from > Date.now() - 10 * 60000,
    uri = !passed && active && prog?.image,
    [popup, setPopup] = useState(0),
    [load, setLoad] = useState(false);

  let progName = prog?.name;

  const togglePopup = useCallback(() => setPopup((pr) => (pr ? 0 : 1)), []);

  const sharePath =
    (book ? "classes/" : "class/") + id + (passed ? "?passed=1" : "");

  const ShareComp = (
    <ShareIcon path={sharePath} style={{ paddingRight: book ? 16 : 24 }} />
  );

  useLayoutEffect(() => {
    const HeaderRight = () => (
      <RowCentered>
        {ShareComp}
        {book && (
          <Press onPress={togglePopup}>
            <SettingsIcon
              style={{ margin: 8, marginLeft: 16, marginRight: 24 }}
            />
          </Press>
        )}
      </RowCentered>
    );
    nav.setOptions({
      title: WEBTTL[lang](progName),
      headerRight: isDesktop ? null : HeaderRight,
    });
  }, [!book, progName]);

  // if someone's private class was just booked and appeared in Store Privats, delete group
  useEffect(() => {
    if (group && priv) deleteGroup(id);
  }, [group && priv]);

  useEffect(() => {
    if (passedEvt && (priv || group)) deleteEvent(id);
  }, [passedEvt && (priv || group)]);

  const onPassedRefresh = useCallback(async () => {
    let d = await getDoc(dbPassed(id));
    if (!d.exists()) return nav.goBack(), toast(CNCL1[lang]);
    setPassed(d.data());
  }, []);

  if (!e && cancelling) return <Gobacker {...nav} />;

  if (!e) {
    let COMP = passed ? Passed : Current;
    return <COMP {...nav} {...{ id }} />;
  }

  if (!progName)
    progName = e.progName || (rus ? "Программа " : "Program ") + progID;
  let ageText =
    (rus ? "для " : "for ") + ages[lang].find((a) => e.age <= a.id).full;

  let title = progName;
  if (e.privat) title += rus ? ", частное" : " private";
  else title += rus ? ", групповое" : " group";
  if (progName.length > 12) title += rus ? "\nзанятие " : "\nclass ";
  else title += rus ? " занятие\n" : " class\n";
  title += ageText;

  const Settings =
    book && popup === 1 ? (
      <BookSettings {...nav} {...{ e, togglePopup, setLoad, passed }} />
    ) : null;

  return (
    <Container>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        refreshControl={passed && <Refresher update={onPassedRefresh} />}
      >
        {uri && (
          <PageImage
            style={{ height: wwidth / 2, backgroundImage: `url(${uri})` }}
            onClick={() =>
              nav.navigate(
                offer ? "Image" : "AddInfo",
                offer ? { uri } : { progID }
              )
            }
          >
            {activeBook && (
              <MarkerComp
                text={rus ? "бронь" : "booked"}
                color={GREEN}
                onPress={togglePopup}
                style={{ position: "absolute", right: 24, top: 20 }}
              />
            )}
          </PageImage>
        )}

        <Body
          style={uri ? { paddingTop: 24 } : { flexGrow: 1, borderRadius: 0 }}
        >
          <Row style={{ flexShrink: 1 }}>
            <Name>{title}</Name>

            {isDesktop && (
              <DesctopIcons {...{ book, sharePath, togglePopup }} />
            )}
          </Row>

          <RowsInfo
            {...{ e, myid, offer, passed, togglePopup }}
            navigate={nav.navigate}
          />

          {passed ? (
            <Passed {...nav} {...{ e, cancelling }} />
          ) : (
            <Current
              {...nav}
              canAddCart={["Coach", "Product", "Cart"].includes(fromScreen)}
              {...{ e, prog, offer, cancelling, setPopup }}
            />
          )}

          <View style={{ flex: 1 }} />
          {book && <BookSettings footer {...nav} {...{ e, setLoad, passed }} />}
        </Body>

        {isDesktop && Settings}
      </ScrollView>
      {popup == 2 && <AddCalList {...{ e, setPopup }} />}

      {!isDesktop && Settings}
      {load && <AbsLoader />}
    </Container>
  );
});

let Gobacker = (pr) => {
  useEffect(() => {
    setTimeout(() => pr.goBack(), 50);
  }, []);
  return <Loader big />;
};

let Current = observer(({ e, prog, offer, canAddCart, ...r }) => {
  const mount = useRef(true),
    id = e?.id || r.id,
    { active, coachID, from, progID, privat } = e || {},
    { name: progName, desc } = prog || {},
    { myid, hhfrmt, rus, lang } = useAuth(),
    { setEvent, deleteEvent } = useClient(),
    {
      add: addCart,
      cart: { [coachID]: cart },
      remove: removeCart,
    } = useCart(),
    book = e?.clientsIds?.includes(myid),
    activeBook = book && active,
    incart = canAddCart && cart?.[id],
    cantBuy = offer && privat && e.clientsIds[0],
    [quant, setQuant] = useState(incart ? incart.client.quant : 1),
    sum = e?.price * quant,
    dbref = dbEvents(id);

  useEffect(() => {
    return () => (mount.current = false);
  }, []);

  const localRemove = (type) => {
    if (!book) r.goBack();
    let ispassed = type === "passed";
    if (e && (ispassed || !book)) deleteEvent(id);
    toast(timeName(from, hhfrmt, rus) + CNCL2[lang](ispassed));
  };

  useEffect(() => {
    if (!e && !r.cancelling) {
      const getEvent = async () => {
        let dc = await getDoc(dbref);
        if (!mount.current) return;
        if (!dc.exists())
          return r.replace("Event", { id, passed: true, noanim: true });
        let d = dc.data();
        // if booking or active group, add event
        if (d.active || d.clientsIds?.includes(myid)) setEvent(d);
        else localRemove("cancel");
      };
      getEvent();
    }
  }, [r.cancelling]);

  // offer listener.
  // No need for book listener, since auto-update comes from all books listener (EffectsProvider.js)
  useEffect(() => {
    if (offer) {
      const ondb = (dc) => {
        if (!mount.current) return;
        if (!dc.exists()) return localRemove("passed");
        let d = dc.data();
        if (!d.active) return localRemove("cancel");

        setEvent(d);
        let dbook = d.clientsIds?.includes(myid);
        if (dbook || d.edited !== e?.edited) toast(CHNG1[lang](dbook), 3000);
      };

      const listener = onSnapshot(dbref, ondb);
      return () => listener();
    }
  }, [!offer]);

  useEffect(() => {
    if (e && prog && e.progName !== progName) updateDoc(dbref, { progName });
  }, [e && prog && e.progName !== progName]);

  if (!e) return <Loader big />;

  const tocart = () => {
    if (cantBuy) return onbuyPrivatError(myid, id, lang);

    if (from < Date.now() - 10 * 60000)
      return callAlert(CNTBK1[lang], CNTBK2[lang]);

    if (canAddCart)
      return (
        addCart([{ coachID, id, client: { uid: myid, quant, sum } }]),
        r.navigate("Cart", { coachID })
      );
    else r.navigate("Cart", { eventID: id, quant, sum });
  };

  let offerText = "";
  if (cantBuy) offerText = CNTJIN1[lang];
  else {
    if (incart) offerText += rus ? "Обновить корзину" : "Update cart";
    else if (canAddCart)
      offerText += rus ? "Добавить в корзину" : "Add to cart";
    else offerText += rus ? "Забронировать" : "Book now";
    offerText += `, ${sum}$`;
  }

  const minusPerson = quant > 1 ? () => setQuant((prv) => prv - 1) : null;

  const removeFromCart = () => removeCart({ coachID, id });

  return (
    <>
      {offer && desc && (
        <Touch
          onPress={() => r.navigate("AddInfo", { progID })}
          style={{ marginTop: 16 }}
        >
          <Desc numberOfLines={2} selectable>
            {desc}
          </Desc>
          <ShowMore />
        </Touch>
      )}

      {activeBook && (
        <>
          <Button
            transp
            onPress={() => r.setPopup(2)}
            style={{ marginTop: 8, borderColor: LITEBORDER }}
          >
            <Comment style={{ color: BLUE }}>{WEBADDCLNDR[lang]}</Comment>
          </Button>

          {activeBook && (
            <ZoomComp {...{ e, lang }} style={{ marginTop: 16 }} />
          )}
        </>
      )}

      <CoachCard {...{ myid, coachID }} style={{ marginTop: 24 }} />

      {offer && (
        <>
          <View style={{ flex: 1 }} />
          <OfferButtons>
            {!cantBuy && (
              <QuantButton
                text={(rus ? "Человек: " : "Persons: ") + quant}
                plus={() => setQuant((prv) => prv + 1)}
                minus={minusPerson}
              />
            )}
            <Button
              big
              text={offerText}
              inactive={cantBuy}
              color={cantBuy && DGRAY}
              onPress={tocart}
              style={{ marginTop: 20 }}
            />
            {incart && (
              <RemoveText onPress={removeFromCart}>{CRTRMV[lang]}</RemoveText>
            )}
          </OfferButtons>
        </>
      )}
    </>
  );
});

let Passed = observer(({ e, navigate, ...pr }) => {
  const mount = useRef(true),
    id = e?.id || pr.id,
    { active, to, coachID, commentImage: uri, coachComment: comment } = e || {},
    { myid, rus, lang } = useAuth(),
    { setPassed } = useClient(),
    {
      coaches: { [coachID || "00"]: coach },
    } = useSchool();

  const needListener = (!e && !pr.cancelling) || (active && to > dayAgo());

  useEffect(() => {
    if (needListener) {
      let ondb = (dc) => {
        if (!mount.current) return;
        if (dc.exists()) return setPassed(dc.data());
        else pr.goBack(), toast(CNCL1[lang]);
      };

      const listener = onSnapshot(dbPassed(id), ondb);
      return () => listener();
    }
  }, [comment || uri]);

  if (pr.cancelling) return <Gobacker {...pr} />;
  if (!e) return <Loader big />;

  let comntTitle = rus
    ? "Комментарий " + (coach?.name || "тренера")
    : coach
    ? coach.name + "'s comment"
    : "Coach comment";

  return (
    <>
      {comment && (
        <ZoomView style={{ marginTop: 8 }}>
          <RowCentered style={{ marginTop: 4 }}>
            <CommentIcon size={20} style={{ marginTop: -4, marginRight: 12 }} />
            <Text16 style={{ flex: 1 }} numberOfLines={1}>
              {comntTitle}
            </Text16>
          </RowCentered>

          <HyperlinkWrap style={{ marginTop: 8 }}>
            <Comment selectable>{comment}</Comment>
          </HyperlinkWrap>

          {uri && (
            <CommentImage
              full
              {...{ uri, navigate }}
              style={{ marginTop: 12 }}
            />
          )}
        </ZoomView>
      )}

      <CoachCard
        {...{ myid, coachID }}
        style={{ marginTop: active && !comment ? 8 : 24 }}
      />
    </>
  );
});

let timeName = (tm, hhfrmt, rus) => {
  let tx = rus ? "Занятие" : "The class";
  if (tm) tx += (rus ? " " : " on ") + dayjs(tm).format("D MMM " + hhfrmt);
  return tx;
};

let onbuyPrivatError = (myid, id, lang) => {
  return callAlert(CNTJIN2[lang], СNTJIN3[lang], [
    { label: "OK" },
    {
      label: CNTSPRT[lang],
      onClick: () =>
        contactsAlert("JOIN A PRIVATE", CNTJIN4[lang](myid) + id, 2, lang),
    },
  ]);
};

let DesctopIcons = observer(
  ({ book, sharePath: path, togglePopup: onPress }) => (
    <IconsDesktopRow>
      <ShareIcon {...{ path }} style={{ paddingRight: book ? 16 : 24 }} />
      {book && (
        <Press {...{ onPress }}>
          <SettingsIcon
            style={{ padding: 16, paddingLeft: 8, paddingRight: 24 }}
          />
        </Press>
      )}
    </IconsDesktopRow>
  )
);

let Name = styled(Title2)`
    flex: 1;
    line-height: 28px;
  `,
  IconsDesktopRow = styled(RowCentered)`
    height: 56px;
    margin: -14px -24px 0 0;
  `,
  OfferButtons = styled.View`
    justify-content: flex-end;
    margin-top: 40px;
  `,
  RemoveText = styled(Text16)`
    color: ${BLUE};
    text-align: center;
    padding: 24px;
    margin-bottom: -32px;
  `,
  Comment = styled(Text16)`
    color: ${DGRAY};
  `,
  Desc = styled(Text15)`
    line-height: 22px;
    color: ${DGRAY};
  `;

let {
  WEBTTL,
  CNCL1,
  CNCL2,
  CHNG1,
  CNTBK1,
  CNTBK2,
  CNTJIN1,
  CNTJIN2,
  СNTJIN3,
  CNTJIN4,
  CRTRMV,
  WEBADDCLNDR,
} = translates.Event;
let { CNTSPRT } = translates;
