import React, { useEffect, useLayoutEffect } from "react";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { onSnapshot } from "firebase/firestore";
import { useAuth, useSchool } from "../commons/Stores";
import {
  dbCoaches,
  getRate,
  wwidth,
  isDesktop,
  screenWidth,
  tabbarHeight,
  wheight,
  modalWidth,
  isMobl,
} from "../commons/utils";
import {
  DGRAY,
  Row,
  RowCentered,
  Touch,
  PageTitle as Title,
  ShowMore,
  Body,
  Loader,
  Text12,
  Text14,
  ShareIcon,
  StatsComp,
  TrialComp,
  GRAY,
  BlankView,
  PageImage,
  Press,
} from "../commons/UI";
// import Program, { width as progWidth } from "../comp/ProgramCard";
import { ChatIconComp } from "./CoachCard";
import { translates } from "../translates";

let rowView = isDesktop;
export let bodyWidth =
  rowView &&
  (screenWidth < 700
    ? screenWidth * 0.67
    : screenWidth < 1000
    ? screenWidth * 0.6
    : screenWidth < 1200
    ? screenWidth - wwidth
    : screenWidth - modalWidth);

export let rightSpace = rowView && bodyWidth > 500 ? bodyWidth - 500 : 0;

export default observer(({ coachID, scrollref, navigate, ...r }) => {
  const full = !r.modal,
    // rowView = isDesktop && full,
    { rus, lang, myid, canTrial, setAfterLogin } = useAuth(),
    {
      coaches: { [coachID]: c },
      setCoach,
    } = useSchool(),
    { bio, name } = c || {},
    prename = rus ? "Тренер " : "Coach ",
    isactive = c?.status === "approved",
    rate = getRate(c?.rates),
    scoresqty = Object.keys(c?.rates || {}).length;

  useEffect(() => {
    onSnapshot(dbCoaches(coachID), (d) => d.exists() && setCoach(d.data()));
  }, []);

  useEffect(() => {
    if (scrollref.current && full && r.initOffset) {
      let rateGap = rate ? 70 : 0,
        offset = (isDesktop ? 0 : wwidth) + 720 + rateGap;
      scrollref.current.scrollTo({ y: offset });
    }
  }, [scrollref.current && full && r.initOffset]);

  useLayoutEffect(() => {
    let title = prename + name + " – BLOSS.AM";
    let ShareComp = () =>
      isMobl ? (
        <ShareIcon path={"coach/" + coachID} style={shareStyle} />
      ) : null;

    if (isactive && name && full)
      r.setOptions({ title, headerRight: ShareComp });
  }, [isactive && name]);

  if (!c) return <Loader big />;

  const uri = isactive && c.photo,
    hasTrial = isactive && c.hasTrial;

  return (
    <>
      {hasTrial && !myid && (
        <TrialComp
          onPress={() => (
            navigate("ProfileStack", { screen: "Login" }),
            setAfterLogin("HomeStack", { screen: "Coach", params: { coachID } })
          )}
          style={{ paddingHorizontal: 16, borderRadius: 0 }}
        />
      )}

      {hasTrial && canTrial && full && (
        <TrialComp
          onPress={() => scrollref.current.scrollToEnd()}
          style={{ paddingHorizontal: 16, borderRadius: 0 }}
        />
      )}

      {uri && !rowView && (
        <PageImage
          style={{ height: wwidth, backgroundImage: `url(${uri})` }}
          onClick={() => navigate("Image", { uri })}
        />
      )}

      <Body
        style={[
          { paddingBottom: 0, paddingRight: rightSpace },
          !uri && { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
        ]}
      >
        <RowCentered
          style={{ justifyContent: rowView ? "flex-start" : "center" }}
        >
          <Title>{name || prename + coachID}</Title>
          {isactive && <ChatIconComp style={{ marginRight: -16 }} />}
        </RowCentered>

        {bio && (
          <Touch onPress={() => navigate("AddInfo", { coachID })}>
            <Desc numberOfLines={3}>{bio}</Desc>
            <ShowMore />
          </Touch>
        )}

        <RowCentered style={{ marginTop: 32 }}>
          <StatsComp
            row={!rate}
            num1={c.expCoach || 4}
            cap1={rus ? "лет" : "years"}
            desc1={rus ? "Опыт\nтренером" : "Coach\nexperience"}
            num2={c.expAthl || 7}
            cap2={rus ? "лет" : "years"}
            desc2={rus ? "Опыт\nспортсменом" : "Athlete\nexperience"}
            colored
            style={{ flexGrow: rowView ? 1 : 3 }}
          />
          {rate && (
            <Row style={{ flexGrow: 1 }}>
              <RateCircle>
                <RateText>{rate}</RateText>
                <RateCap>{SCRS[lang](scoresqty)}</RateCap>
              </RateCircle>
            </Row>
          )}
        </RowCentered>

        {!isactive && (
          <BlankView>
            <Inactive>{rus ? "неактивен" : "inactive"}</Inactive>
          </BlankView>
        )}

        {r.modalChild}
      </Body>
    </>
  );
});

export let RowViewImage = (pr) => {
  const {
    coaches: { [pr.coachID]: coach },
  } = useSchool();
  if (!coach) return null;
  const { photo: uri, status } = coach || {};
  if (status !== "approved") return null;
  return (
    <PageImage
      style={{
        width: screenWidth - bodyWidth,
        height: wheight - tabbarHeight,
        backgroundImage: `url(${uri})`,
      }}
      onClick={() => pr.navigate("Image", { uri })}
    />
  );
};

let shareStyle = rowView
  ? { marginTop: -6, marginBottom: -12 }
  : isDesktop
  ? undefined
  : { paddingRight: 24 };

let RateCircle = styled.View`
    width: 120px;
    height: 120px;
    border-radius: 60px;
    justify-content: center;
    align-items: center;
    border: 1px solid #e4e4e4;
  `,
  RateText = styled(Title)`
    font-size: 45px;
    line-height: undefined;
  `,
  RateCap = styled(Text12)`
    color: ${DGRAY};
    position: absolute;
    top: 10px;
    right: -20px;
    z-index: 1;
  `,
  Desc = styled(Text14)`
    color: ${DGRAY};
    margin-top: 20px;
  `,
  Inactive = styled(Title)`
    text-align: center;
    color: ${GRAY};
  `;

let { SCRS } = translates.CoachHeader;
