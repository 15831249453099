import React, { useLayoutEffect } from "react";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { useAuth, useSchool } from "../commons/Stores";
import {
  DGRAY,
  PageTitle,
  Text15,
  PageImage,
  Body,
  Medium15,
  FilterView,
  BACKGRAY,
  Press,
  Text16,
  Text14,
  HyperlinkWrap,
  GRAY,
} from "../commons/UI";
import { isDesktop, wheight, wwidth } from "../commons/utils";
import BottomSheet from "../comp/BottomSheet";
import { translates } from "../translates";

export default observer(
  ({ navigation: { navigate, goBack, ...nav }, route: r }) => {
    const { lang, rus } = useAuth(),
      { progID, coachID, privats, level: levID } = r.params || {};

    let title = "";

    useLayoutEffect(
      () => nav.setOptions({ title: title + " – BLOSS.AM" }),
      [title]
    );

    if (privats) {
      title = PGTTL1[lang];
      return (
        <BottomSheet scroll height={500} {...{ goBack }}>
          <Body style={{ paddingTop: isDesktop ? 44 : 20 }}>
            <Desc selectable>
              <Bold>{rus ? "Групповое занятие" : "A group class"}</Bold>
              {GRP[lang]}
              <Bold>{rus ? "Частное занятие" : "A private class"}</Bold>
              {PRV[lang]}
            </Desc>
          </Body>
        </BottomSheet>
      );
    }

    const {
      coaches: { [coachID]: coach },
      programs: { [progID]: program },
      levels: { [levID]: level },
    } = useSchool();

    const item = levID ? level : coachID ? coach : program;

    const { name, bio, edu, image: uri, desc } = item;

    title = PGTTL2[lang](name, coachID, level);

    if (coachID)
      return (
        <BottomSheet scroll height={wheight * 0.7} {...{ goBack }}>
          <Body style={{ paddingTop: isDesktop ? 44 : 20 }}>
            {bio && (
              <HyperlinkWrap>
                <Desc selectable>{bio}</Desc>
              </HyperlinkWrap>
            )}
            {edu && (
              <Desc selectable>
                {(rus ? "Образование: " : "Education: ") + edu}
              </Desc>
            )}
          </Body>
        </BottomSheet>
      );

    if (progID || level)
      return (
        <BottomSheet
          scroll
          snaps={[wheight * (uri ? 0.9 : level ? 0.5 : 0.66), wheight * 0.94]}
          {...{ goBack }}
        >
          {uri && (
            <PageImage
              style={{
                backgroundImage: `url(${uri})`,
                height: wwidth / 2 + 18,
              }}
              onClick={() => navigate("Image", { uri })}
            />
          )}
          <Body>
            <PageTitle selectable>{name + (level ? " level" : "")}</PageTitle>
            {desc && <Desc selectable>{desc}</Desc>}
          </Body>
        </BottomSheet>
      );
  }
);

export const LangPicker = observer(({ navigation: { goBack } }) => {
  return (
    <BottomSheet height={160} {...{ goBack }}>
      <Body>
        <LangPickerComp onpick={goBack} style={{ marginTop: 4 }} />
      </Body>
    </BottomSheet>
  );
});

export const LangPickerComp = observer(({ onpick, small, ...r }) => {
  const { rus, setLang } = useAuth();
  const pick = (ll) => (setLang(ll), onpick ? onpick(ll) : null);

  let Text = small ? SmallLangText : LangText,
    txstyle = (active) => (active ? undefined : { style: { color: GRAY } }),
    viewStyle = { height: small ? 44 : 60 };

  let renderLang = (ll, i) => {
    let isru = ll === "ru",
      active = isru ? rus : !rus,
      style = active && { zIndex: 1 };

    let Comp = active ? BorderLangView : GrayLangView;
    let onPress = active ? null : () => pick(ll);

    return (
      <LangPress {...{ onPress, style }} key={ll}>
        <Comp style={viewStyle}>
          <Text {...txstyle(active)}>{isru ? "Русский" : "English"}</Text>
        </Comp>
      </LangPress>
    );
  };

  return (
    <LangView style={[viewStyle, r.style]}>
      {["en", "ru"].map(renderLang)}
    </LangView>
  );
});

let LangText = styled(Text16)`
    flex: 1;
    text-align: center;
  `,
  SmallLangText = styled(Text14)`
    flex: 1;
    text-align: center;
  `,
  LangPress = styled(Press)`
    flex: 1;
  `,
  LangView = styled(FilterView)`
    height: 60px;
    background: ${BACKGRAY};
    border-radius: 30px;
    padding: 0 0 0 0;
    border-width: 0;
  `,
  GrayLangView = styled(LangView)`
    /* flex: 1; */
    background: ${BACKGRAY};
  `,
  BorderLangView = styled(LangView)`
    /* flex: 1; */
    border-width: 1px;
    background: white;
  `;

let Desc = styled(Text15)`
    line-height: 22px;
    color: ${DGRAY};
    margin-top: 12px;
  `,
  Bold = styled(Medium15)`
    line-height: 22px;
  `;

let { PGTTL1, PGTTL2, GRP, PRV } = translates.AddInfo;
