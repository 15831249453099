import React, { useState, useEffect, useRef } from "react";
import { FlatList, ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import { useAuth, useClient, useSchool } from "../commons/Stores";
import { genrtItemLayout, getDay, localLang } from "../commons/utils";
import {
  Loader,
  PageTitle,
  TmznComp,
  Press,
  Text14,
  ACTIVEGRAY,
  DGRAY,
  BORDERGRAY,
  GREEN,
} from "../commons/UI";
import EventCard from "./EventCard";

export const DateParent = ({ onPress, active, day, width, ...r }) => {
  let lang = r.lang || localLang(),
    rus = lang == "ru",
    text =
      day !== getDay()
        ? dayjs(day).format("D MMM\nddd")
        : rus
        ? "сего\nдня"
        : "today";

  let InnerDateView = active ? ActiveInnerView : InnerView,
    DateWrap =
      r.first && r.last
        ? RoundDateView
        : r.first
        ? LeftDateView
        : r.last
        ? RightDateView
        : DateView;
  return (
    <Press {...{ onPress }} style={{ alignItems: "center" }}>
      <DateWrap style={width && { width }}>
        <InnerDateView>
          <DateText style={active && { color: "white" }}>{text}</DateText>
          {r.mark && <BookMark />}
        </InnerDateView>
      </DateWrap>
      {r.children}
    </Press>
  );
};

export const DatesEventsList = observer(
  ({ home, events, style, gray, ...r }) => {
    const { myid, hhfrmt, rus, lang } = useAuth(),
      { coaches, programs, load, groupsLoad, handleLevel } = useSchool(),
      { nearestBook } = useClient(),
      navigate = r.navigate || useNavigation().navigate,
      listref = useRef(),
      book1Day = nearestBook?.day,
      loading = home && (load || groupsLoad),
      title = r.title || (rus ? "Бронирования" : "Booked classes");

    const dates = events.reduce((pr, e) => {
      let day = getDay(e.from);
      !pr.includes(day) && pr.push(day);
      return pr;
    }, []);

    const [date1] = dates,
      initDate = home ? book1Day || date1 : date1,
      [day, setDay] = useState(initDate),
      dayEvents = events.filter((e) => e.day === (day || date1));

    useEffect(() => {
      if (date1 && (!day || !dates.includes(day) || !dayEvents[0]))
        setDay(date1), listref.current?.scrollToIndex({ index: 0 });
    }, [!dayEvents[0], dates.length]);

    // useEffect(() => {if (home && book1Day && book1Day !==day) setDay(book1Day); }, [home && book1Day]);

    if (loading)
      return (
        <View {...{ style }}>
          {title && <PageTitle>{title}</PageTitle>}
          <Loader big />
        </View>
      );

    const initDateIndex = home && book1Day && dates.indexOf(book1Day);

    const showDaysMarks = !["booked class", "брони"].some((tx) =>
      title?.toLowerCase().includes(tx)
    );

    const daysMarks =
      showDaysMarks &&
      new Set(
        events.filter((e) => e.clientsIds?.includes(myid)).map((e) => e.day)
      );

    const renderDate = ({ item: d, index: i }) => {
      let active = d === (day || date1);
      return (
        <DateParent
          {...{ active, lang }}
          day={d}
          first={!i}
          last={i === dates.length - 1}
          onPress={() => setDay(d)}
          mark={!active && daysMarks && daysMarks.has(d)}
        />
      );
    };

    const renderEvent = ({ item: e }) => {
      let coach = coaches[e.coachID] || {
        name: (rus ? "Тренер " : "Coach ") + e.coachID,
      };
      return (
        <EventCard
          {...{ coach, myid, lang, hhfrmt, gray, navigate, handleLevel }}
          event={e}
          program={programs[e.progID]}
        />
      );
    };

    return (
      <View {...{ style }}>
        {title && <PageTitle>{title}</PageTitle>}
        {date1 && (
          <>
            <TmznComp style={{ marginTop: 16 }} />
            <DatesView>
              <FlatList
                {...datesListProps()}
                ref={listref}
                data={dates}
                renderItem={renderDate}
                initialIndex={home && initDateIndex > 2 ? initDateIndex - 2 : 0}
              />
            </DatesView>
          </>
        )}
        <ScrollView
          horizontal
          scrollEnabled={false}
          contentContainerStyle={{
            flexGrow: 1,
            paddingHorizontal: home ? 48 : 24,
          }}
          showsHorizontalScrollIndicator={false}
          style={{ marginHorizontal: -24 }}
        >
          <FlatList
            {...eventsListProps}
            {...r}
            data={dayEvents}
            renderItem={renderEvent}
          />
        </ScrollView>
      </View>
    );
  }
);

let dateKeys = (d) => d,
  datesLayout = (_, i) => genrtItemLayout(82, i),
  wideDatesLayout = (_, i) => genrtItemLayout(90, i);

export const datesListProps = (wide) => ({
  keyExtractor: dateKeys,
  getItemLayout: wide ? wideDatesLayout : datesLayout,
  initialNumToRender: 7,
  maxToRenderPerBatch: 14,
  windowSize: 7,
  horizontal: true,
  showsHorizontalScrollIndicator: false,
  contentContainerStyle: { paddingHorizontal: 24 },
  style: { marginHorizontal: -24 },
});

let eventsKeys = (c) => c.id,
  eventLayout = (_, i) => genrtItemLayout(120 + 12, i),
  ItemSeparatorComponent = styled.View`
    height: 12px;
  `,
  eventsListProps = {
    keyExtractor: eventsKeys,
    ItemSeparatorComponent,
    getItemLayout: eventLayout,
    scrollEnabled: false,
    contentContainerStyle: { flexGrow: 1, paddingHorizontal: 24 },
    style: { flexGrow: 1, marginHorizontal: -24 },
  };

export let BookMark = styled.View`
  position: absolute;
  background: ${GREEN};
  top: 10px;
  right: 6px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
`;

let DatesView = styled.View`
    height: 52px;
    margin: 12px 0 24px;
  `,
  DateView = styled.View`
    justify-content: center;
    background: ${DGRAY};
    height: 52px;
    width: 82px;
  `,
  LeftDateView = styled(DateView)`
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  `,
  RightDateView = styled(DateView)`
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  `,
  RoundDateView = styled(LeftDateView)`
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  `,
  InnerView = styled.View`
    height: 52px;
    justify-content: center;
  `,
  ActiveInnerView = styled(InnerView)`
    background: ${ACTIVEGRAY};
    border-radius: 10px;
  `,
  DateText = styled(Text14)`
    line-height: 17px;
    color: ${BORDERGRAY};
    text-align: center;
    /* margin-top: 0px; */
  `;
