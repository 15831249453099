import React, { useState } from "react";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import orderBy from "lodash/orderBy";
import { useAuth, useSchool } from "../commons/Stores.js";
import { ages, issame, wheight } from "../commons/utils";
import {
  DGRAY,
  CheckboxIcon,
  RowCentered,
  Text21,
  PageTitle,
  Text18,
  Text24,
  Press,
  shadow4,
  QuestMark,
  Button,
} from "../commons/UI";
import BottomSheet from "../comp/BottomSheet.js";
import { translates } from "../translates";

export default observer(
  ({
    navigation: { navigate, goBack },
    route: {
      params: { from, ...p },
    },
  }) => {
    const global = from == "Home",
      { lang } = useAuth();

    if (global) {
      const { programs, levels, filter, setFilter } = useSchool(),
        { progID: fprog, age: fage, level: flevel } = filter,
        levelsArr = orderBy(Object.values(levels), "num"),
        [progID, setProgID] = useState(fprog),
        [age, setAge] = useState(fage),
        [level, setLevel] = useState(flevel),
        progs = Object.values(programs);

      const renderGroups = (items, ind) => {
        let isprogs = ind === 1,
          isage = !ind,
          title = TTL[lang](isprogs, isage);

        let renderItem = ({ id, ...f }) => {
          let active = id == (isprogs ? progID : isage ? age : level),
            setState = isprogs ? setProgID : isage ? setAge : setLevel,
            onPress = () => setState(!active && id),
            markPress = () =>
              navigate("AddInfo", isprogs ? { progID: id } : { level: id });
          return (
            <Filter
              hasmark={!isage}
              {...{ id, active, onPress, markPress }}
              name={isage ? f.full : f.name}
              key={id}
            />
          );
        };
        return (
          <View key={String(ind)}>
            <Title2 style={{ marginTop: isage ? 0 : 24 }}>{title}</Title2>
            {items.map(renderItem)}
          </View>
        );
      };

      const save = () => {
        let nochange =
          issame(progID, fprog) && issame(age, fage) && issame(level, flevel);

        return nochange
          ? goBack()
          : (setFilter({ progID, age, level }), goBack());
      };

      return (
        <BottomSheet height={wheight * 0.95} {...{ goBack }}>
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              padding: 24,
              paddingBottom: 100,
            }}
            showsVerticalScrollIndicator={false}
          >
            {[ages[lang], progs, levelsArr].map(renderGroups)}
          </ScrollView>
          <FloatButton onPress={save} style={shadow4}>
            <CheckboxIcon active size={32} />
          </FloatButton>
        </BottomSheet>
      );
    }

    if (!global) {
      const {
          coaches: { [p.coachID]: coach },
          programs,
        } = useSchool(),
        items = p.coachID
          ? coach.programs.map((id) => programs[id])
          : Object.values(programs);

      const renderItem = ({ id, name }) => {
        let progID = id,
          active = id == p.progID;

        let onPress = () =>
          active ? goBack() : navigate(from, { ...p, progID });
        let markPress = () => navigate("AddInfo", { progID });

        return (
          <Filter
            big
            hasmark
            {...{ id, active, name, onPress, markPress }}
            key={id}
          />
        );
      };

      return (
        <BottomSheet scroll {...{ goBack }}>
          <Body>
            <PageTitle style={{ marginBottom: 24 }}>{TTL[lang](1)}</PageTitle>
            {items.map(renderItem)}
          </Body>
        </BottomSheet>
      );
    }
  }
);

export const Filter = ({ id, name, active, big, onPress, ...r }) => {
  let Text = big ? Text21 : Text18,
    hasmark = !active && r.hasmark;
  return (
    <Button
      {...{ big, onPress }}
      inactive={!active}
      style={{ marginBottom: 14, ...r.style }}
    >
      <FilterRow>
        <Text style={active && { color: "white" }} numberOfLines={1}>
          {name}
        </Text>
        {hasmark && (
          <QuestMark
            onPress={r.markPress}
            style={{ marginLeft: 10, marginRight: -8 }}
          />
        )}
      </FilterRow>
      {active && <CheckboxIcon active style={{ marginRight: 18 }} />}
    </Button>
  );
};

let Title2 = styled(Text24)`
    color: ${DGRAY};
    margin: 2px 0 16px;
  `,
  Body = styled.View`
    flex: 1;
    padding: 32px 24px 24px;
  `,
  FilterRow = styled(RowCentered)`
    flex: 1;
    margin: 0 16px;
  `,
  FloatButton = styled(Press)`
    position: absolute;
    bottom: 24px;
    right: 24px;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: black;
  `;

let { TTL } = translates.Filters;
