import React, { useRef } from "react";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { useAuth, useCart, useClient, useSchool } from "../commons/Stores";
import { isDesktop, wwidth } from "../commons/utils";
import {
  Row,
  Refresher,
  Container,
  GrayContainer,
  CartButton,
  BACKGRAY,
} from "../commons/UI";
import CoachHeader, { RowViewImage } from "../comp/CoachHeader";
import CoachBody from "../comp/CoachBody";
import { DatesEventsList } from "../comp/Dates";

let rowView = isDesktop;

const Coach = observer(
  ({
    navigation: nav,
    route: {
      params: { coachID, from },
    },
    modal,
  }) => {
    const initOffset = ["Cart", "CoachModal"].includes(from),
      scrollref = useRef(null),
      full = true, //!modal,
      { navigate } = nav;

    const Content = (
      <>
        <ScrollView
          ref={scrollref}
          contentContainerStyle={{ flexGrow: 1 }}
          style={{ backgroundColor: BACKGRAY }}
          contentOffset={
            initOffset ? { y: isDesktop ? 700 : wwidth + 600 } : undefined
          }
          refreshControl={full && <Refresh {...{ coachID }} />}
        >
          <CoachHeader {...nav} {...{ coachID, scrollref, initOffset }} />
          <CoachBody {...nav} {...{ coachID }} />
          <CoachBooks {...{ coachID }} />
        </ScrollView>
        <CoachCartButton {...{ coachID, navigate }} />
      </>
    );

    if (rowView)
      return (
        <Row style={{ flex: 1 }}>
          <RowViewImage {...{ coachID, navigate }} />
          <Container>{Content}</Container>
        </Row>
      );
    else return <GrayContainer>{Content}</GrayContainer>;

    // const openFull = useCallback(() => resetStackRoute("Coach", { coachID, from: "CoachModal" }),    []  );
  }
);

export default Coach;
export const CoachModal = (pr) => <Coach {...pr} modal />;

const CoachBooks = observer(({ coachID }) => {
  const {
      coaches: { [coachID]: c },
    } = useSchool(),
    {
      cart: { [coachID]: coachCart },
    } = useCart(),
    { activeBooks } = useClient();

  if (!c || c.status !== "approved") return null;

  const events = activeBooks.filter((e) => e.coachID == coachID),
    hasCart = Object.keys(coachCart || {})[0];

  if (!events[0])
    return hasCart ? (
      <View style={{ height: 80, backgroundColor: "white" }} />
    ) : null;

  return (
    <BooksView style={hasCart && { paddingBottom: 120 }}>
      <DatesEventsList books {...{ events }} />
    </BooksView>
  );
});

let Refresh = observer(({ coachID, ...pr }) => {
  const { myid } = useAuth(),
    { getCoach, getCoachGroups } = useSchool();

  return (
    <Refresher
      update={() =>
        Promise.all([getCoach(coachID), getCoachGroups(coachID, myid)])
      }
      {...pr}
    />
  );
});

export const CoachCartButton = observer((pr) => {
  const {
      cart: { [pr.coachID]: cartObj },
    } = useCart(),
    cart = Object.keys(cartObj || {});
  if (!cart[0]) return null;
  return <CartButton {...pr} quant={cart.length} />;
});

let BooksView = styled.View`
  padding: 40px 24px;
  background-color: ${BACKGRAY};
`;
