import React, { useEffect, useRef, useState } from "react";
import { View, ScrollView, Keyboard, Image } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import {
  ref,
  onValue,
  set as setRdb,
  off,
  get as getRdb,
} from "firebase/database";
import orderBy from "lodash/orderBy";
import { useAuth, useClient, useSchool } from "../commons/Stores";
import {
  rdb,
  rdbDeposits,
  copytext,
  uploadMedia,
  callAlert,
  showToast as toast,
  openPayURL,
  launchImagePicker,
  offline,
  offlineToast,
} from "../commons/utils";
import {
  DGRAY,
  Touch,
  Loader,
  RowCentered,
  Row,
  Text18,
  CopyIcon,
  GRAY,
  CloseIcon,
  Text16,
  Button,
  Container,
  BLUE,
  ZoomView,
  Text28,
  Press,
  RowBetween,
  Text14,
  InputComp as UIInputComp,
  AbsLoader,
  AttachIcon,
  RemoveIcon,
  RED,
  Title2,
  Caption as InputCaption,
} from "../commons/UI";
import { sendStripe } from "../comp/OrderFooter";
import { translates } from "../translates";

export default observer(({ navigation: nav, route: { params: p } }) => {
  const { orderID, passID } = p || {},
    { navigate } = nav,
    hasOrder = orderID || passID,
    { payWays, setPayWays } = useSchool(),
    {
      rus,
      lang,
      balance,
      profile: { balance: bal0 },
      depoApplies,
      setDepoApplies,
      depoAppliesRef: depositsRef,
    } = useAuth(),
    {
      orders: { [orderID || "00"]: orderData },
      passes: { [passID || "00"]: pass },
    } = useClient(),
    hasStory =
      Object.keys(bal0 || {}).length || Object.keys(depoApplies).length,
    order = orderData || pass,
    total = order ? order.total || order.price : null,
    sumdiff = hasOrder && parseFloat((total - balance).toFixed(2)),
    lackOrderSum = hasOrder && (sumdiff < 5 ? 5 : sumdiff),
    initSum = hasOrder ? lackOrderSum : 30,
    [picked, setPicked] = useState(),
    [load, setLoad] = useState();

  const array = orderBy(
    Object.values(payWays).map((w) =>
      w.id === "stripe" ? { ...w, ...CRDPAYWAY[lang] } : w
    ),
    "num"
  );

  useEffect(() => {
    if (!hasStory)
      getRdb(depositsRef).then((v) => v.exists() && setDepoApplies(v.val()));

    let dbref = ref(rdb, "payWays");
    onValue(dbref, (v) => setPayWays(v.val()));
    return () => off(dbref);
  }, []);

  // user's balance listener is in bakcground EffectsProvider
  useEffect(() => {
    if (hasOrder && balance >= total)
      navigate(passID ? "PassOrder" : "Order", p);
  }, [hasOrder && balance >= total]);

  const renderPayWays = (it, i) => (
    <View style={!i && { marginTop: 8 }} key={it.id}>
      <Row>
        <Touch onPress={() => setPicked(it)}>
          <Text16 style={{ color: BLUE, paddingVertical: 12 }}>
            {it.name}
          </Text16>
        </Touch>
      </Row>
      {!i && !array[1] && <Loader left />}
    </View>
  );

  return (
    <Container>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          padding: 24,
          paddingBottom: 32,
        }}
      >
        <RowBetween>
          <Press onPress={() => navigate("BalanceRecords")} style={{ flex: 1 }}>
            <Row>
              <Text numberOfLines={1}>
                {rus ? "Ваш баланс:" : "Your balance:"}
              </Text>
            </Row>
            <Sum>{balance}$</Sum>
          </Press>

          {hasStory > 0 && (
            <Button
              transp
              onPress={() => navigate("BalanceRecords")}
              style={histbutnStyle}
            >
              <Text16 style={{ color: BLUE }}>
                {rus ? "История" : "History"}
              </Text16>
            </Button>
          )}
        </RowBetween>

        {!hasOrder && <Desc>{DEPDSC[lang] + PICKMTHD[lang]}</Desc>}

        {hasOrder && (
          <>
            <Desc>{MINSM[lang]}</Desc>
            <Sum>{lackOrderSum}$</Sum>
            <Desc>{PICKMTHD[lang]}</Desc>
          </>
        )}

        {!picked && array.map(renderPayWays)}

        {picked && (
          <>
            <PayWay
              {...{ orderID, passID, initSum, setLoad }}
              {...picked}
              close={() => setPicked()}
            />

            {picked.id !== "stripe" && (
              <Confirmation
                {...nav}
                payway={picked.name}
                {...{ orderID, passID, initSum, setLoad }}
              />
            )}
          </>
        )}
      </ScrollView>
      {load && (
        <AbsLoader
          text={
            load === 2 && (rus ? "загружаю изображение..." : "loading image...")
          }
        />
      )}
    </Container>
  );
});

let PayWay = ({ orderID = null, passID = null, setLoad, ...r }) => {
  const isstripe = r.id == "stripe";

  if (!isstripe) {
    const renderOpts = (o, i) => (
      <RowCentered style={!i && { marginTop: 8 }} key={o.name}>
        <OptName numberOfLines={1}>{o.name}</OptName>
        <Touch onPress={() => copytext(o.data)} style={{ flexShrink: 1 }}>
          <OptView>
            <Row>
              <Text18 numberOfLines={1}>{o.data}</Text18>
              <CopyIcon />
            </Row>
          </OptView>
        </Touch>
      </RowCentered>
    );
    return <PayWayWrap {...r}>{r.opts?.map(renderOpts)}</PayWayWrap>;
  }

  const hasOrder = orderID || passID,
    { rus, lang, profile, updateFields: updateProfile } = useAuth(),
    {
      orders: { [orderID || "00"]: orderData },
      passes: { [passID || "00"]: pass },
    } = useClient(),
    {
      coaches: { [(order || pass)?.coachID || "00"]: { name: coachName } = {} },
    } = useSchool(),
    order = orderData || pass,
    [sumtext, setSum] = useState(String(r.initSum || 0));

  const sumnum = parseFloat(parseFloat(sumtext).toFixed(2)),
    sum = isNaN(sumnum) || sumnum < 5 ? 5 : sumnum;

  const payCard = async () => {
    if (offline()) return offlineToast();

    setLoad(true);
    let postData = { isDeposit: true, sum };
    if (orderID) (postData.orderID = orderID), (postData.quant = order.quant);
    if (passID) postData.passID = passID;
    if (coachName) postData.coach = coachName;

    let { url } = await sendStripe(
      postData,
      profile,
      updateProfile,
      offline,
      lang
    );

    setLoad();
    if (url) openPayURL(url);
  };

  return (
    <PayWayWrap {...r}>
      <SumInput
        {...{ rus, sumtext, setSum }}
        red={sumnum < 5}
        onSubmitEditing={payCard}
        style={{ marginTop: 16 }}
      />
      <Button
        big
        text={CARDBTN[lang](sum)}
        onPress={payCard}
        style={{ marginTop: 24, marginBottom: 8 }}
      />
    </PayWayWrap>
  );
};

let Confirmation = ({ payway, orderID, passID, initSum, setLoad, ...r }) => {
  const {
      rus,
      lang,
      myid,
      profile: { email, name, phone },
      addDepoApply,
    } = useAuth(),
    [sumtext, setSum] = useState(String(initSum)),
    [comment, setComment] = useState(""),
    [link, setLink] = useState(""),
    [image, setImage] = useState(""),
    [linkref, comref] = [useRef(), useRef()],
    [trlink, uri, trcomment] = [link.trim(), image.trim(), comment.trim()],
    sumnum = parseFloat(parseFloat(sumtext).toFixed(2)),
    sum = isNaN(sumnum) || sumnum < 5 ? 5 : sumnum;

  const pickImage = async () => {
    let url = await launchImagePicker();
    if (url) setImage(url);
  };

  const cantSend = isNaN(sum) || sum < 5 || (!trlink[9] && !uri[9]);

  let butnText;
  if (isNaN(sum) || sum < 5) butnText = rus ? "Минимум 5$" : "Minimum 5$";
  else if (!trlink[9] && !uri[9]) butnText = BTNTX1[lang];
  else butnText = BTNTX2[lang] + ` ${sum}$`;

  const send = async () => {
    Keyboard.dismiss();
    if (isNaN(sum) || sum < 5) return toast(SENDER1[lang]);
    if (!trlink[9] && !uri[9]) return toast(SENDER2[lang]);
    if (offline()) return offlineToast();

    let time = Date.now(),
      imageUrl = "";

    if (uri[9]) {
      let imageIslocal = uri.substring(0, 4) !== "http";
      setLoad(imageIslocal ? 2 : 1);
      imageUrl = imageIslocal
        ? await uploadMedia(`deposits/${myid}/${time}`, uri, setLoad)
        : uri;

      if (offline()) return offlineToast();

      if (imageIslocal && !imageUrl[0]) return toast(SENDER3[lang]), setLoad();
    }

    let id = myid.slice(0, 4) + "–" + time;

    let data = {
      id,
      isapply: true,
      time,
      status: null,
      payway,
      sum,
      email,
      name,
      uid: myid,
    };
    if (trlink[9]) data.link = trlink;
    if (imageUrl[9]) data.image = imageUrl;
    if (trcomment[0]) data.desc = trcomment;
    if (phone) data.phone = phone;
    if (orderID) data.orderID = orderID;
    if (passID) data.passID = passID;

    let error;
    await setRdb(rdbDeposits(id), data).catch((e) => (error = e));

    if (error) return callAlert(SENDER4[lang], SENDER5[lang](error)), setLoad();

    addDepoApply({ [id]: data });
    toast(SCS1[lang]);
    r.replace("BalanceRecords");
    setLoad();
  };

  return (
    <>
      <Desc style={{ marginTop: 32 }}>{CAPT1[lang]}</Desc>

      <PayWayView style={{ paddingBottom: 24 }}>
        <Text style={{ marginBottom: -4 }}>{CAPT2[lang] + payway}</Text>
        <SumInput
          {...{ sumtext, rus, setSum }}
          red={sumnum < 5}
          onSubmitEditing={() => linkref.current.focus()}
          blurOnSubmit={false}
        />

        <InputComp
          reff={linkref}
          caption={CAPT3[lang]}
          defaultValue={link}
          onChangeText={setLink}
          onSubmitEditing={() => comref.current.focus()}
          blurOnSubmit={false}
        />

        <InputCaption style={{ marginTop: 24 }}>{CAPT4[lang]}</InputCaption>
        <Touch onPress={pickImage}>
          <AttachView>
            {!uri[0] && (
              <>
                <AttachIcon style={{ marginRight: 8 }} />
                <OptName>{rus ? "изображение" : "image"}</OptName>
              </>
            )}
            {uri[9] && (
              <>
                <Image
                  source={{ uri }}
                  resizeMode="contain"
                  style={{ width: 60, height: 60 }}
                />
                <RemoveIcon
                  onPress={() => setImage("")}
                  height={72}
                  style={{ position: "absolute", right: 0, zIndex: 1 }}
                />
              </>
            )}
          </AttachView>
        </Touch>

        <InputComp
          reff={comref}
          caption={CAPT5[lang]}
          defaultValue={comment}
          onChangeText={setComment}
          multiline
          inputStyle={{ height: 72 }}
        />

        <Press onPress={() => r.navigate("EditProfile")}>
          <Caption style={{ color: DGRAY, marginTop: 32 }}>
            {CAPT6[lang] + phone}.
            <Caption style={{ color: BLUE }}>
              {rus ? " Изменить контакт" : " Change my contact"}
            </Caption>
          </Caption>
        </Press>

        <Button
          big
          transp={cantSend}
          inactive={cantSend}
          text={butnText}
          onPress={send}
          style={{ marginTop: 20 }}
        />
      </PayWayView>

      <Caption>{CAPT7[lang]}</Caption>
    </>
  );
};

const PayWayWrap = (pr) => (
  <PayWayView>
    <Row>
      <Title2 style={{ flex: 1 }} selectable>
        {pr.name}
      </Title2>
      <CloseIcon
        onPress={pr.close}
        color={DGRAY}
        size={28}
        style={{
          padding: 16,
          marginTop: -22,
          marginBottom: -16,
          marginRight: -16,
        }}
      />
    </Row>
    {pr.desc && (
      <Desc style={{ color: GRAY, marginTop: 8 }} selectable>
        {pr.desc}
      </Desc>
    )}
    {pr.children}
  </PayWayView>
);

const SumInput = ({ sumtext, setSum, red, ...r }) => (
  <InputComp
    caption={r.rus ? "сумма, минимум 5$" : "$ amount, at least 5$"}
    placeholder="20"
    defaultValue={sumtext}
    onChangeText={setSum}
    keyboardType="numeric"
    returnKeyType="send"
    inputStyle={red && { color: RED }}
    {...r}
  />
);

let InputComp = (pr) => (
  <UIInputComp
    {...pr}
    returnKeyType="next"
    inputStyle={{
      backgroundColor: "white",
      paddingLeft: 8,
      paddingRight: 8,
      marginTop: 8,
      ...pr.inputStyle,
    }}
    style={{ marginTop: 24, ...pr.style }}
  />
);

let histbutnStyle = {
  height: 48,
  paddingHorizontal: 16,
  marginLeft: 16,
  marginTop: -4,
  width: 120,
};

let Text = styled(Text16)`
    color: ${DGRAY};
  `,
  Desc = styled(Text)`
    margin-top: 16px;
  `,
  PayWayView = styled(ZoomView)`
    padding-top: 20px;
    margin-top: 16px;
  `,
  OptName = styled(Text16)`
    color: ${GRAY};
    flex-shrink: 0;
    margin-top: 1px;
  `,
  OptView = styled.View`
    padding: 6px 0;
    flex-shrink: 1;
    margin-left: 16px;
  `,
  Caption = styled(Text14)`
    color: ${GRAY};
    margin-top: 24px;
  `,
  Sum = styled(Text28)``,
  AttachView = styled(RowCentered)`
    height: 72px;
    background: white;
    justify-content: center;
    padding: 6px 16px;
    margin-top: 8px;
  `;

let {
  DEPDSC,
  PICKMTHD,
  MINSM,
  BTNTX1,
  BTNTX2,
  CARDBTN,
  SENDER1,
  SENDER2,
  SENDER3,
  SENDER4,
  SENDER5,
  SCS1,
  CAPT1,
  CAPT2,
  CAPT3,
  CAPT4,
  CAPT5,
  CAPT6,
  CAPT7,
  CRDPAYWAY,
} = translates.Balance;
