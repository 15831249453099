import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import { useAuth } from "../commons/Stores";
import { durtnText, getDay, offsetName, showToast } from "../commons/utils";
import {
  RowCentered,
  GREEN,
  GRAY,
  DGRAY,
  LINEGRAY,
  Text14,
  BACKGRAY,
  BACKGREEN,
  Text16,
  Text18,
  Text12,
  Medium14,
  Press,
} from "../commons/UI";
import { translates } from "../translates";

export const TimeComp = observer(
  ({ big, med, from, to, cancel, strict, ...r }) => {
    const { rus, hhfrmt } = useAuth();

    let now = Date.now(),
      passed = r.passed || to < now,
      active = !cancel && !passed,
      going = r.going || (active && from < now && to > now),
      dateColor = r.color || (going ? GREEN : cancel && !big ? GRAY : DGRAY),
      timeColor = r.color || (active ? GREEN : dateColor);

    let Time = big ? Text18 : med ? Text16 : Text14,
      TMZ = big || med ? Text16 : Text14;

    let dateText;
    if (!strict && getDay(from) === getDay())
      dateText = rus ? "Сегодня" : "Today";
    else if (!strict && getDay(from) === getDay(dayjs().add(1, "d")))
      dateText = rus ? "Завтра" : "Tomorrow";
    else
      dateText = dayjs(from).format(
        big && passed ? "D MMM YYYY, dddd" : big ? "D MMMM, dddd" : "D MMM, ddd"
      );

    return (
      <RowCentered {...r}>
        <Time style={{ color: dateColor }} numberOfLines={1} selectable>
          {dateText}
          <Time style={{ color: LINEGRAY }}>{big ? `  |  ` : ` | `}</Time>
          <Time style={{ color: timeColor }} numberOfLines={1}>
            {dayjs(from).format(hhfrmt).toLowerCase()}
          </Time>
        </Time>

        {r.tmz && !going && active && (
          <TMZ numberOfLines={1} style={{ color: GRAY, marginLeft: 8 }}>
            GMT{offsetName}
          </TMZ>
        )}
      </RowCentered>
    );
  }
);

export const DurComp = observer(({ big, cancel, from, to, ...r }) => {
  const { lang, rus, hhfrmt } = useAuth();

  let now = Date.now(),
    dur = to - from,
    passed = r.passed || to < now,
    active = !cancel && !passed,
    going = r.going || (active && from < now && to > now),
    showGoing = big && going,
    endtext = DURTX[lang](passed) + dayjs(to).format(hhfrmt);

  let Dur = showGoing ? Medium14 : big ? Text14 : Text12,
    Wrap = big ? Press : View,
    color = active ? GREEN : cancel && !big ? GRAY : DGRAY;

  return (
    <Wrap
      onPress={() => showToast(endtext, 1500)}
      style={{ marginLeft: big ? 10 : 8, ...r.style }}
    >
      <DurView
        style={[
          big && { height: 24, paddingHorizontal: 12 },
          { backgroundColor: active ? BACKGREEN : BACKGRAY },
        ]}
      >
        <Dur style={{ color }}>
          {showGoing ? endtext : durtnText(dur, r.full, rus)}
        </Dur>
      </DurView>
    </Wrap>
  );
});

let DurView = styled.View`
  justify-content: center;
  height: 20px;
  border-radius: 12px;
  padding: 0 8px;
`;

let { DURTX } = translates.TimeComp;
