import React, { useCallback, useEffect, useState } from "react";
import { View, ScrollView, FlatList } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { getDocs } from "firebase/firestore";
import useStore, {
  useAuth,
  useCart,
  useClient,
  useSchool,
} from "../commons/Stores";
import {
  appsAlert,
  dbBooks,
  deskPadding,
  genrtItemLayout,
  getSuprtAppeal,
  resetStackRoute,
} from "../commons/utils";
import {
  SafeArea,
  Loader,
  Refresher,
  GRAY,
  Text16,
  RowBetween,
  FilterButton,
  CartButton,
  TmznComp,
  AbsLoader,
  PageTitle,
  Row,
  RowCentered,
  Touch,
  Press,
  StatsComp,
} from "../commons/UI";
import Header, { Setting } from "../comp/ProfileHeader";
import BookCard, { CommentCard, bookWidth, bookHeight } from "../comp/BookCard";
import PackageCard, { packHeight } from "../comp/PackageCard";
import OrderCard from "../comp/OrderCard";
import { AppPromo, Legals, rowView } from "./Login";
import { translates } from "../translates";

let paramScreen = {
  orderID: "Order",
  passID: "PassOrder",
  id: "Event",
  balance: "BalanceRecords",
};

export default observer(({ navigation: nav, route: { params: p } }) => {
  // params  after push not-n or toast pressed OR dynamic links order
  const hasParams = p && Object.keys(paramScreen).find((k) => k in p);
  useEffect(() => {
    if (hasParams) resetStackRoute(paramScreen[hasParams], p);
  }, [p]);

  const { navigate } = nav,
    {
      myid,
      lang,
      rus,
      checkDBUser,
      profile: { stat },
    } = useAuth(),
    { handleBooksListener } = useClient(),
    [load, setLoad] = useState();

  const props = { lang, navigate };

  const onRefresh = useCallback(
    () =>
      Promise.all([getDocs(dbBooks(myid)), checkDBUser()]).then(([q]) =>
        handleBooksListener(q, "getter")
      ),
    []
  );

  const renderSettings = (tx, i) => (
    <SettingComp
      {...{ tx, i, myid, lang, navigate }}
      style={!i && { marginTop: 14 }}
      key={tx}
    />
  );

  return (
    <SafeArea>
      <ScrollView
        refreshControl={<Refresher update={onRefresh} />}
        contentContainerStyle={{
          flexGrow: 1,
          padding: 24,
          paddingHorizontal: deskPadding || 24,
        }}
      >
        <Header nav={nav} {...{ setLoad }} />
        <BooksComp {...props} />
        <PassesComp {...props} />
        <OrderComp {...props} />
        <PackageComp {...props} />
        {/* <PassedClass {...props} /> */}
        {/* {!unpaidOrder && <OrderComp {...props} />} */}
        {/* {!unpaidPack && <PackageComp {...props} />} */}

        <Comment {...props} />

        {stat?.classes && (
          <StatsRow>
            <Press onPress={() => navigate("History")}>
              <StatsComp
                row
                num1={stat?.classes || 0}
                cap1={rus ? "занятий" : "classes"}
                num2={Math.round(stat?.hours || 0)}
                cap2={rus ? "часов" : "hours"}
              />
            </Press>
          </StatsRow>
        )}
        {MENU[lang].map(renderSettings)}

        <View style={{ flex: 1 }} />
        {!rowView && <AppPromo profile />}
        <Legals profile />
      </ScrollView>

      <CartButtonComp {...{ navigate }} />
      {rowView && <AppPromo />}

      {load && <AbsLoader />}
    </SafeArea>
  );
});

let SettingComp = ({ tx, i, myid, navigate, ...r }) => (
  <RowCentered {...r}>
    <Touch
      onPress={() =>
        i === 3
          ? getSuprtAppeal({ myid }, r.lang)
          : navigate(
              !i
                ? "History"
                : i === 1
                ? "Orders"
                : i === 2
                ? "Passes"
                : i === 4
                ? "LangPicker"
                : "AddInfo"
            )
      }
    >
      <Setting numberOfLines={1}>{tx}</Setting>
    </Touch>
  </RowCentered>
);

export let CartButtonComp = observer((pr) => {
  const { latestCoachCart: cart } = useCart();
  if (!cart) return null;
  return <CartButton {...pr} {...cart} />;
});

let BooksComp = observer(({ lang, navigate }) => {
  const { myid } = useAuth(),
    { coaches, programs } = useSchool(),
    { load, allBooksArr, markViewed } = useClient(),
    allQnt = allBooksArr.length,
    title = TTL1[lang](0);

  if (!allQnt)
    return (
      <>
        <TitleComp text={title} />
        {load ? (
          <Loader big style={{ minHeight: 240 }} />
        ) : (
          <EmptyText>{BOOKSEMP[lang]}</EmptyText>
        )}
      </>
    );

  const renderItem = ({ item: event }) => (
    <BookCard
      profile
      {...{ lang, event, coaches, navigate, markViewed, myid }}
      program={programs[event.progID]}
    />
  );

  return (
    <>
      <TitleComp text={title + ` (${allQnt})`} />
      <TmznComp style={{ marginTop: -4 }} />
      <View style={{ height: bookHeight, marginTop: 12 }}>
        <FlatList {...booksListProps} data={allBooksArr} {...{ renderItem }} />
      </View>
    </>
  );
});

let PassesComp = observer(({ lang, navigate }) => {
  const { hhfrmt } = useAuth(),
    { coaches } = useSchool(),
    { activePassesArr: arr } = useClient(),
    allQnt = arr.length;

  if (!arr[0]) return null;

  const title = TTL1[lang](1) + ` (${allQnt})`;

  const renderItem = ({ item: p }) => (
    <PackageCard
      ispass
      showCoach
      {...{ p, lang, hhfrmt, navigate }}
      coach={coaches[p.coachID]}
    />
  );

  return (
    <>
      <TitleComp text={title} />
      {allQnt && (
        <View style={{ height: packHeight() }}>
          <FlatList {...booksListProps} data={arr} {...{ renderItem }} />
        </View>
      )}
    </>
  );
});

const OrderComp = observer(({ lang, navigate }) => {
  // let { lastOrder: o } = useClient(),
  let { hasUnpaidOrder: o } = useClient(),
    {
      coaches: { [o?.coachID || "00"]: coach },
      programs,
    } = useSchool();

  if (!o) return null;
  return (
    <>
      {/* <TitleComp {...{ lang }} type="orders" text={TTL2WEB[lang](0, 1)} /> */}
      <TitleComp text={TTL2[lang](0)} />
      <OrderCard order={o} {...{ coach, lang, programs, navigate }} />
    </>
  );
});

const PackageComp = observer(({ lang, navigate }) => {
  // let { lastPackage: o, hhfrmt } = useClient(),
  const { myid, hhfrmt } = useAuth(),
    { hasUnpaidPackage: o } = useClient(),
    {
      coaches: { [o?.coachID || "00"]: coach },
    } = useSchool();

  if (!o) return null;
  return (
    <>
      {/* <TitleComp {...{ lang }} type="packages" text={TTL2WEB[lang](1)} /> */}
      <TitleComp text={TTL2[lang](1)} />
      <OrderCard
        ispackage
        order={o}
        {...{ coach, hhfrmt, myid, lang, navigate }}
      />
    </>
  );
});

const Comment = observer(({ lang, navigate }) => {
  const {
      myid,
      lastComment: comnt,
      setPassed,
      coaches: { [comnt?.coachID]: coach },
      programs: { [comnt?.progID]: program },
    } = useClient(),
    rus = lang === "ru";

  // useEffect(() => {
  //   if (!comnt)
  //     mydbComments(myid)
  //       .limit(1)
  //       .get()
  //       .then((q) => !q.empty && setPassed(q.docs[0].data()));
  // }, []);

  if (!comnt) return null;
  return (
    <>
      <Press onPress={() => navigate("Comments")}>
        <TitleComp text={rus ? "Комментарии тренеров" : "Coaches' comments"}>
          <FilterButton
            text={rus ? "Все" : "View all"}
            onPress={() => navigate("Comments")}
            style={{ marginLeft: 12 }}
          />
        </TitleComp>
      </Press>
      <CommentCard event={comnt} {...{ lang, coach, program, navigate }} />
    </>
  );
});

const PassedClass = observer(({ lang, navigate }) => {
  const { myid, lastPassed: ev } = useClient(),
    {
      coaches,
      programs: { [ev?.progID]: program },
    } = useSchool();

  if (!ev) return null;
  return (
    <>
      <TitleComp {...{ lang }} type="classes" text={TTL2WEB[lang]()} />
      <BookCard
        event={ev}
        passed
        {...{ lang, coaches, navigate, myid, program }}
      />
    </>
  );
});

let keyExtractor = (e) => e.id;
let getItemLayout = (_, i) => genrtItemLayout(bookWidth + 12, i),
  ItemSeparatorComponent = styled.View`
    width: 12px;
  `;

let booksListProps = {
  horizontal: true,
  keyExtractor,
  ItemSeparatorComponent,
  getItemLayout,
  initialNumToRender: 1,
  windowSize: 9,
  contentContainerStyle: { paddingHorizontal: deskPadding + 24 },
  style: { marginHorizontal: -24 - deskPadding },
  showsHorizontalScrollIndicator: false,
};

const TitleComp = ({ lang, ...pr }) => {
  let rus = lang == "ru";
  return (
    <TitleRow>
      <Title>{pr.text}</Title>
      {pr.type && (
        <FilterButton
          transp
          text={rus ? "Все" : "View all"}
          onPress={() => appsAlert(APPSPROMO[lang](pr.type))}
          style={{ marginLeft: 12 }}
        />
      )}
      {pr.children}
    </TitleRow>
  );
};

let TitleRow = styled(Row)`
    justify-content: space-between;
    margin: 32px 0 20px;
  `,
  Title = styled(PageTitle)`
    flex: 1;
  `,
  EmptyText = styled(Text16)`
    color: ${GRAY};
    margin-top: -8px;
  `,
  StatsRow = styled(Row)`
    margin-top: 40px;
  `;

let { MENU, BOOKSEMP, TTL1, TTL2, TTL2WEB, APPSPROMO } = translates.Profile;
