import React, { useEffect } from "react";
import { FlatList, View } from "react-native";
import styled from "styled-components/native";
import { LinearGradient } from "expo-linear-gradient";
import { observer } from "mobx-react-lite";
import { equalTo, onValue, orderByChild, query, off } from "firebase/database";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
dayjs.extend(calendar);
import orderBy from "lodash/orderBy";
import { useAuth, useClient, useSchool } from "../commons/Stores";
import {
  rdbPackages,
  genrtItemLayout,
  wwidth,
  getFreeSlots,
  getDay,
  classTypeDesc,
  isvalidPackage,
  horizCardWidth,
} from "../commons/utils";
import {
  DGRAY,
  PageTitle as Title,
  ShowMore,
  Body,
  Medium18,
  BACKGRAY,
  Touch,
  RowBetween,
  Text14,
  ACTIVEGRAY,
  Loader,
  Medium28,
} from "../commons/UI";
import PackageCard, { BackNumberText } from "./PackageCard";
import { translates } from "../translates";

export default observer(({ coachID, navigate }) => {
  const { rus } = useAuth(),
    {
      programs,
      coaches: { [coachID]: c },
    } = useSchool();

  if (!c || c.status !== "approved") return null;

  const renderProgs = ({ item: id }) => (
    <Program {...programs[id]} {...{ navigate }} key={id} />
  );

  return (
    <>
      <Body style={{ paddingTop: 32, paddingBottom: 48 }}>
        <Title>{rus ? "Программы" : "Programs"}</Title>
        <View style={{ height: progHeight + 20 }}>
          <FlatList {...listProps} data={c.programs} renderItem={renderProgs} />
        </View>
        <Packages {...{ coachID, navigate }} />
        <Products {...{ c, navigate }} />
      </Body>
    </>
  );
});

const Packages = observer(({ coachID, navigate }) => {
  const { lang, hhfrmt } = useAuth(),
    {
      coaches: { [coachID]: c },
      setPackages,
    } = useSchool(),
    {
      avlblPackageOffersBycoach: { [coachID]: offers },
      activePassesCoaches: { [coachID]: passes },
    } = useClient();

  useEffect(() => {
    let packsref = query(
      rdbPackages(),
      orderByChild("coachID"),
      equalTo(coachID)
    );

    let ondb = (v) => {
      if (v.exists()) {
        let res1 = v.val(),
          res = {};
        for (let key in res1) {
          let p = res1[key];
          if (isvalidPackage(p)) res[key] = p;
        }
        if (Object.keys(res).length) setPackages(v.val());
      }
    };

    onValue(packsref, ondb);
    return () => off(packsref);
  }, []);

  if (!c || c.status !== "approved") return null;
  if (!offers?.[0] && !passes?.[0]) return null;

  const sortedPasses = passes ? orderBy(passes, "to") : [],
    passesIds = new Set(sortedPasses.map((p) => p.id));

  const combined = sortedPasses
    .concat(offers?.filter((p) => !passesIds.has(p.id)) || [])
    .filter(Boolean);

  if (!combined[0]) return null;

  const renderPackage = ({ item: p, index: i }) => (
    <PackageCard
      coachScreen
      ispass={passesIds.has(p.id)}
      {...{ p, lang, hhfrmt, navigate }}
      coachRate={c.price}
    />
  );

  return (
    <>
      <Title style={{ marginTop: 32 }}>{PCKSTTL[lang]}</Title>
      <FlatList
        {...listProps}
        keyExtractor={packsKeys}
        data={combined}
        renderItem={renderPackage}
      />
    </>
  );
});

const Products = observer(({ c, navigate }) => {
  const { lang, rus, hhfrmt } = useAuth();

  if (!c || c.status !== "approved") return null;

  return (
    <>
      <Title style={{ marginTop: 32, marginBottom: 20 }}>
        {rus ? "Занятия" : "Classes"}
      </Title>
      <Product priv {...{ c, lang, hhfrmt, navigate }} />
      <ProgSeparate />
      <Product {...{ c, lang, hhfrmt, navigate }} />
    </>
  );
});

const Product = observer(({ c, lang, priv, hhfrmt, navigate }) => {
  const { uid: coachID } = c,
    rus = lang === "ru",
    loaded = priv ? !!c.slots : !useSchool().groupsLoad;

  let nearest = priv
    ? findNearestPrivat(c)
    : useSchool().groupsArr.find((e) => e.coachID == coachID)?.from;

  let onPress =
    loaded && !nearest
      ? null
      : () => navigate(priv ? "CoachPrivats" : "CoachGroups", { coachID });

  return (
    <Touch {...{ onPress }}>
      <ProdView
        colors={priv ? ["#F8F6E7", "#EBE4C1"] : ["#F4F4F4", "#D7D8D8"]}
        start={{ x: 0.1, y: 1.1 }}
        end={{ x: 0.9, y: -0.1 }}
      >
        <BackText>{BOLDTYPE[lang](priv)}</BackText>
        <View style={{ flex: 1 }} />
        <ProgName>{PRDTL[lang](priv)}</ProgName>
        <Desc>{classTypeDesc(priv, 0, c.grPrice, lang)}</Desc>

        <PriceRow>
          {!loaded && <Loader small />}
          {loaded && (
            <NearestText>
              {nearest
                ? (rus ? "С " : "From ") + nearestText(nearest, hhfrmt, rus)
                : NOSLT1[lang](priv)}
            </NearestText>
          )}
          <ProgName>
            {(priv ? c.price : c.grPrice) + (rus ? "$ / час" : "$ / hour")}
          </ProgName>
        </PriceRow>

        {loaded && !nearest && (
          <Overlay>
            <OverlayText>{NOSLT2[lang](priv)}</OverlayText>
          </Overlay>
        )}
      </ProdView>
    </Touch>
  );
});

let progHeight = 136,
  progsKeys = (it) => (typeof it == "object" ? it.id : it),
  packsKeys = (it) => it.packID || it.id,
  progLayout = (_, i) => genrtItemLayout(horizCardWidth + 16, i),
  ProgSeparate = styled.View`
    width: 16px;
    height: 20px;
  `;

let listProps = {
  keyExtractor: progsKeys,
  ItemSeparatorComponent: ProgSeparate,
  getItemLayout: progLayout,
  horizontal: true,
  showsHorizontalScrollIndicator: false,
  contentContainerStyle: { paddingHorizontal: 24, paddingTop: 20 },
  style: { marginHorizontal: -24 },
};

const Program = ({ id: progID, name, desc, navigate }) => {
  let onPress = () => navigate("AddInfo", { progID });
  return (
    <Touch {...{ onPress }}>
      <ProgView>
        <ProgName>{name}</ProgName>
        {desc && (
          <>
            <Desc selectable numberOfLines={2}>
              {desc}
            </Desc>
            <ShowMore {...{ onPress }} />
          </>
        )}
      </ProgView>
    </Touch>
  );
};

export const findNearestPrivat = (c) => {
  let slots = Object.values(c.slots || {}).filter(
      (s) => s.to > Date.now() + 60 * 60000
    ),
    busy = Object.values(c.busy || {}),
    nearest;

  orderBy(slots, "from").find((s) => {
    let [free] = getFreeSlots([s], busy);
    if (free) nearest = free.from;
    return !!free;
  });

  return nearest;
};

export const nearestText = (tm, hhfrmt, rus) => {
  let hhtext = dayjs(tm).format(" " + hhfrmt),
    istoday = getDay(tm) === getDay(),
    istmrw = getDay(tm) === getDay(dayjs().add(1, "d")),
    daytext;

  if (istoday) daytext = rus ? "сегодня" : "today";
  else if (istmrw) daytext = rus ? "завтра" : "tomorrow";
  else daytext = dayjs(tm).format("D MMM");

  return daytext + hhtext;
};

let prodWidth = wwidth - 48,
  prodHeight = prodWidth * 0.7;

let ProgView = styled.View`
    background: ${BACKGRAY};
    width: ${horizCardWidth}px;
    height: ${progHeight}px;
    border-radius: 18px;
    padding: 20px 18px 0;
  `,
  ProdView = styled(LinearGradient)`
    width: ${prodWidth}px;
    height: ${prodHeight}px;
    border-radius: 18px;
    padding: 20px 18px 24px;
  `,
  ProgName = styled(Medium18)`
    color: ${DGRAY};
  `,
  Desc = styled(Text14)`
    color: ${DGRAY};
    margin-top: 8px;
  `,
  NearestText = styled(Text14)`
    color: ${ACTIVEGRAY};
    margin-bottom: 1px;
  `,
  Overlay = styled.View`
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    width: ${prodWidth}px;
    height: ${prodHeight}px;
    background: rgba(255, 255, 255, 0.7);
  `,
  OverlayText = styled(Medium28)`
    z-index: 1;
    text-align: center;
  `,
  BackText = styled(BackNumberText)`
    font-size: ${prodHeight / 5.5}px;
    line-height: ${prodHeight / 5.5}px;
    letter-spacing: 0;
    top: 24px;
    /* left: 20px; */
  `,
  PriceRow = styled(RowBetween)`
    align-items: flex-end;
    margin-top: 12px;
  `;

let { PRDTL, NOSLT1, NOSLT2, PCKSTTL, BOLDTYPE } = translates.CoachBody;
