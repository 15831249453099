export let loccoaches = {
    xRkkDYZLOQPwYBVJhucwo0cMHa43: {
      programs: [
        "1644433221158",
        "1657214001589",
        "1714904089794",
        "1679046814482",
        "1679046800493",
      ],
      expCoach: 6,
      photoSize: 101077,
      grPrice: 20,
      bioru:
        'In aesthetic group gymnastics in the Madonna team: World Championship gold medalist, winner of the European Championship.\nChampion of the international tournament "Children of Asia" in the group, Absolute champion of "hope of Russia".\nI’m rhythmic and aesthetic gymnastics coach at bloss.am school. I trained with Mgfso under coach L.I. Savitskaya. I have been a member of the Moscow national rhythmic gymnastics team and the Russian national aesthetic group gymnastics team.\nI’m a candidate for Master of Sports in rhythmic gymnastics and master of sports in aesthetic group gymnastics. Education: studying at the University of Physical Culture in Moscow.',
      name: "Alice",
      status: "approved",
      bio: 'In aesthetic group gymnastics in the Madonna team: World Championship gold medalist, winner of the European Championship.\nChampion of the international tournament "Children of Asia" in the group, Absolute champion of "hope of Russia".\nI’m rhythmic and aesthetic gymnastics coach at bloss.am school. I trained with Mgfso under coach L.I. Savitskaya. I have been a member of the Moscow national rhythmic gymnastics team and the Russian national aesthetic group gymnastics team.\nI’m a candidate for Master of Sports in rhythmic gymnastics and master of sports in aesthetic group gymnastics. Education: studying at the University of Physical Culture in Moscow.',
      uid: "xRkkDYZLOQPwYBVJhucwo0cMHa43",
      nameru: "Alice",
      expAthl: 15,
      hasTrial: true,
      nameen: "Alice",
      bioen:
        'In aesthetic group gymnastics in the Madonna team: World Championship gold medalist, winner of the European Championship.\nChampion of the international tournament "Children of Asia" in the group, Absolute champion of "hope of Russia".\nI’m rhythmic and aesthetic gymnastics coach at bloss.am school. I trained with Mgfso under coach L.I. Savitskaya. I have been a member of the Moscow national rhythmic gymnastics team and the Russian national aesthetic group gymnastics team.\nI’m a candidate for Master of Sports in rhythmic gymnastics and master of sports in aesthetic group gymnastics. Education: studying at the University of Physical Culture in Moscow.',
      photo:
        "https://firebasestorage.googleapis.com:443/v0/b/rgonline-1d3e0.appspot.com/o/coaches%2FAlisa.jpeg?alt=media&token=373f2306-07b5-4445-8c92-4e91ebca9515",
      price: 35,
    },
    wYiTHfEW2uUNU18whacQ9g5RqII2: {
      programs: ["1644572404794", "1657214001589"],
      expCoach: 2,
      photoSize: 21834,
      grPrice: 20,
      bioru:
        "I’m a current ballet dancer at the Bolshoi National Theatre of Belarus. I’m graduated from Vaganova Ballet Academy. Coach- Ludmila Kovaleva. Performed on the Mariinsky stage. I have ballet dancer and teacher’s degree. Also I’m trained under soloists of Mariinsky theatre.",
      name: "Ksenia",
      status: "approved",
      bio: "I’m a current ballet dancer at the Bolshoi National Theatre of Belarus. I’m graduated from Vaganova Ballet Academy. Coach- Ludmila Kovaleva. Performed on the Mariinsky stage. I have ballet dancer and teacher’s degree. Also I’m trained under soloists of Mariinsky theatre.",
      uid: "wYiTHfEW2uUNU18whacQ9g5RqII2",
      nameru: "Ksenia",
      expAthl: 12,
      hasTrial: true,
      nameen: "Ksenia",
      bioen:
        "I’m a current ballet dancer at the Bolshoi National Theatre of Belarus. I’m graduated from Vaganova Ballet Academy. Coach- Ludmila Kovaleva. Performed on the Mariinsky stage. I have ballet dancer and teacher’s degree. Also I’m trained under soloists of Mariinsky theatre.",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/rgonline-1d3e0.appspot.com/o/Ksenia.jpg?alt=media&token=c2d7ec9d-2351-4bd3-8d75-fc73c6094a78",
      price: 30,
    },
    atFxsMVXYEQ1JMjnLQt5dCYEATV2: {
      programs: ["1679044987905"],
      expCoach: 10,
      photoSize: 129373,
      grPrice: 25,
      bioru:
        "First rank in ice hockey, second rank in paragliding, multiple participant and winner of bodybuilding championships. Current athlete. \n\nWelcome to my online classes at home or gym!",
      name: "Anton",
      status: "approved",
      bio: "First rank in ice hockey, second rank in paragliding, multiple participant and winner of bodybuilding championships. Current athlete. \n\nWelcome to my online classes at home or gym!",
      uid: "atFxsMVXYEQ1JMjnLQt5dCYEATV2",
      nameru: "Anton",
      expAthl: 25,
      hasTrial: true,
      nameen: "Anton",
      bioen:
        "First rank in ice hockey, second rank in paragliding, multiple participant and winner of bodybuilding championships. Current athlete. \n\nWelcome to my online classes at home or gym!",
      photo:
        "https://firebasestorage.googleapis.com:443/v0/b/rgonline-1d3e0.appspot.com/o/coaches%2FAnton.jpeg?alt=media&token=67c2a683-4104-483b-9614-9d3777d3e117",
      price: 30,
    },
    SxYzntNZWMRJQjPSGoJeFC1POpq2: {
      programs: [
        "1644433221158",
        "1679046800493",
        "1679046814482",
        "1657214001589",
      ],
      expCoach: 10,
      photoSize: 26633,
      grPrice: 30,
      bioru:
        "Head coach and the founder of the Bloss.am. Retired professional rhythmic gymnast from Russia. I’m Master of Sports of Russia in rhythmic gymnastics. I have been a member of the national team of Sverdlovsk region in a group and an individual routines, champion of Sverdlovsk region, champion of Ural Federal District in a group routine, winner of the Ural Federal District in the team competition in individual routines, bronze medalist of the international tournament named Gorenkova, winner of all-Russian competitions.I graduated from economics university and university of physical culture and sports in Russia",
      name: "Anastasia",
      status: "approved",
      bio: "Head coach and the founder of the Bloss.am. Retired professional rhythmic gymnast from Russia. I’m Master of Sports of Russia in rhythmic gymnastics. I have been a member of the national team of Sverdlovsk region in a group and an individual routines, champion of Sverdlovsk region, champion of Ural Federal District in a group routine, winner of the Ural Federal District in the team competition in individual routines, bronze medalist of the international tournament named Gorenkova, winner of all-Russian competitions.I graduated from economics university and university of physical culture and sports in Russia",
      uid: "SxYzntNZWMRJQjPSGoJeFC1POpq2",
      nameru: "Anastasia",
      expAthl: 11,
      hasTrial: false,
      nameen: "Anastasia",
      bioen:
        "Head coach and the founder of the Bloss.am. Retired professional rhythmic gymnast from Russia. I’m Master of Sports of Russia in rhythmic gymnastics. I have been a member of the national team of Sverdlovsk region in a group and an individual routines, champion of Sverdlovsk region, champion of Ural Federal District in a group routine, winner of the Ural Federal District in the team competition in individual routines, bronze medalist of the international tournament named Gorenkova, winner of all-Russian competitions.I graduated from economics university and university of physical culture and sports in Russia",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/rgonline-1d3e0.appspot.com/o/Anastasia.jpeg?alt=media&token=ac5bedbd-86ed-46e4-9d84-b6f71fd2bcda",
      price: 40,
    },
    "9ibWIEBxAVO2ORsbd404W6QadOg2": {
      programs: ["1657214001589", "1679046800493", "1644433221158"],
      expCoach: 15,
      photoSize: 114056,
      grPrice: 20,
      bioru:
        "I’m retired professional gymnast from Belarus.I have been a member of the Regional  team and a champion of Minsk Region.I graduated from University of physical culture and sport in Belarus. Already 25 years in sport,  13 years I worked  in RSA as a rhythmic coach and have trained more the one champion of Johannesburg аnd RSA.",
      name: "Natallia",
      status: "approved",
      bio: "I’m retired professional gymnast from Belarus.I have been a member of the Regional  team and a champion of Minsk Region.I graduated from University of physical culture and sport in Belarus. Already 25 years in sport,  13 years I worked  in RSA as a rhythmic coach and have trained more the one champion of Johannesburg аnd RSA.",
      uid: "9ibWIEBxAVO2ORsbd404W6QadOg2",
      nameru: "Наталья",
      expAthl: 12,
      hasTrial: true,
      nameen: "Natallia",
      bioen:
        "I’m retired professional gymnast from Belarus.I have been a member of the Regional  team and a champion of Minsk Region.I graduated from University of physical culture and sport in Belarus. Already 25 years in sport,  13 years I worked  in RSA as a rhythmic coach and have trained more the one champion of Johannesburg аnd RSA.",
      photo:
        "https://firebasestorage.googleapis.com:443/v0/b/rgonline-1d3e0.appspot.com/o/coaches%2FNatallia.jpeg?alt=media&token=ba2d15a9-6f59-49cd-8b50-5bb600b00c5b",
      price: 30,
    },
    "75uwmNAGgMafFErRdm830Llj4752": {
      programs: ["1644572404794", "1679044987905"],
      expCoach: 7,
      photoSize: 74831,
      grPrice: 25,
      bioru:
        "Hey there, I’m a current ballet dancer at the Bolshoi National Theatre of Belarus. I studied at the Tallinn Ballet school and worked in the Estonian national ballet.\n\nI’m a nutritionist. I develop personalized nutrition plans considering nutrients, calorie intake, meal timing, and provide recommendations on adding proteins, carbohydrates, fats, and vitamins for optimal support of training and recovery.\n\nWelcome to training with me!",
      bioen:
        "Hey there, I’m a current ballet dancer at the Bolshoi National Theatre of Belarus. I studied at the Tallinn Ballet school and worked in the Estonian national ballet.\n\nI’m a nutritionist. I develop personalized nutrition plans considering nutrients, calorie intake, meal timing, and provide recommendations on adding proteins, carbohydrates, fats, and vitamins for optimal support of training and recovery.\n\nWelcome to training with me!",
      nameen: "Daria",
      name: "Daria",
      status: "approved",
      bio: "Hey there, I’m a current ballet dancer at the Bolshoi National Theatre of Belarus. I studied at the Tallinn Ballet school and worked in the Estonian national ballet.\n\nI’m a nutritionist. I develop personalized nutrition plans considering nutrients, calorie intake, meal timing, and provide recommendations on adding proteins, carbohydrates, fats, and vitamins for optimal support of training and recovery.\n\nWelcome to training with me!",
      uid: "75uwmNAGgMafFErRdm830Llj4752",
      nameru: "Daria",
      expAthl: 12,
      packages: {},
      price: 35,
      hasTrial: true,
      photo:
        "https://firebasestorage.googleapis.com/v0/b/rgonline-1d3e0.appspot.com/o/Daria.jpeg?alt=media&token=582358fd-50a1-460b-b82d-b3fbba7df176",
    },
  },
  locprogs = {
    1679046800493: {
      nameru: "Апп",
      descen:
        "The apparatus used in rhythmic gymnastics requires a high level of technique to perform intricate combinations, tosses, rolls, and catches with grace and fluidity. Each piece of equipment has distinct characteristics that the gymnast must understand to handle them effectively. For example, the hoop requires circular movements, rolling and flipping. On the other hand, the ball is thrown and caught during the routine, requiring the gymnast to maintain a certain level of force and speed.\n\nIn rhythmic gymnastics, the handling of the apparatus is just as important as the gymnast's movements themselves. The athlete must use the equipment as an extension of their body, and the apparatus's manipulation should enhance the routine's beauty and expression. The gymnast must also maintain control of the apparatus at all times, ensuring it never drops, bounces, or flies out of control. Every movement is calculated to ensure the equipment is a part of the performance, not a distraction.\n\nTraining in apparatus handling requires discipline, repetition, and a creative mindset. It involves various exercises to enhance coordination, balance, and fluidity while using the equipment. Learning to handle the apparatus also requires mental strength as the gymnast becomes responsible for creating and designing their unique routine, adding creativity and artistry to the performance",
      desc: "The apparatus used in rhythmic gymnastics requires a high level of technique to perform intricate combinations, tosses, rolls, and catches with grace and fluidity. Each piece of equipment has distinct characteristics that the gymnast must understand to handle them effectively. For example, the hoop requires circular movements, rolling and flipping. On the other hand, the ball is thrown and caught during the routine, requiring the gymnast to maintain a certain level of force and speed.\n\nIn rhythmic gymnastics, the handling of the apparatus is just as important as the gymnast's movements themselves. The athlete must use the equipment as an extension of their body, and the apparatus's manipulation should enhance the routine's beauty and expression. The gymnast must also maintain control of the apparatus at all times, ensuring it never drops, bounces, or flies out of control. Every movement is calculated to ensure the equipment is a part of the performance, not a distraction.\n\nTraining in apparatus handling requires discipline, repetition, and a creative mindset. It involves various exercises to enhance coordination, balance, and fluidity while using the equipment. Learning to handle the apparatus also requires mental strength as the gymnast becomes responsible for creating and designing their unique routine, adding creativity and artistry to the performance",
      shortru: "Apparatus",
      imageSize: 20957,
      nameen: "Apparatus handling",
      short: "Apparatus",
      image:
        "https://firebasestorage.googleapis.com:443/v0/b/rgonline-1d3e0.appspot.com/o/programs%2FApparatus%20handling.jpeg?alt=media&token=c4d2b43e-15fc-4789-bb24-40b234889bab",
      descru:
        "The apparatus used in rhythmic gymnastics requires a high level of technique to perform intricate combinations, tosses, rolls, and catches with grace and fluidity. Each piece of equipment has distinct characteristics that the gymnast must understand to handle them effectively. For example, the hoop requires circular movements, rolling and flipping. On the other hand, the ball is thrown and caught during the routine, requiring the gymnast to maintain a certain level of force and speed.\n\nIn rhythmic gymnastics, the handling of the apparatus is just as important as the gymnast's movements themselves. The athlete must use the equipment as an extension of their body, and the apparatus's manipulation should enhance the routine's beauty and expression. The gymnast must also maintain control of the apparatus at all times, ensuring it never drops, bounces, or flies out of control. Every movement is calculated to ensure the equipment is a part of the performance, not a distraction.\n\nTraining in apparatus handling requires discipline, repetition, and a creative mindset. It involves various exercises to enhance coordination, balance, and fluidity while using the equipment. Learning to handle the apparatus also requires mental strength as the gymnast becomes responsible for creating and designing their unique routine, adding creativity and artistry to the performance",
      id: "1679046800493",
      shorten: "Apparatus",
      name: "Apparatus handling",
    },
    1714904089794: {
      nameru: "Aesthetic gymnastics",
      descen:
        "The training program of aesthetic gymnastics includes exercises for developing flexibility, coordination, strength, body aesthetics, and choreography to enhance the beauty of movements. The difference from artistic gymnastics is a greater focus on aesthetics and expressiveness rather than complex tricks",
      desc: "The training program of aesthetic gymnastics includes exercises for developing flexibility, coordination, strength, body aesthetics, and choreography to enhance the beauty of movements. The difference from artistic gymnastics is a greater focus on aesthetics and expressiveness rather than complex tricks",
      shortru: "Aesthetic",
      imageSize: 74854,
      nameen: "Aesthetic gymnastics",
      short: "Aesthetic",
      image:
        "https://firebasestorage.googleapis.com:443/v0/b/rgonline-1d3e0.appspot.com/o/programs%2FAesthetic%20gymnastics.jpeg?alt=media&token=8e9bdf8e-f6b1-42e6-888d-01cc953fe00a",
      descru:
        "The training program of aesthetic gymnastics includes exercises for developing flexibility, coordination, strength, body aesthetics, and choreography to enhance the beauty of movements. The difference from artistic gymnastics is a greater focus on aesthetics and expressiveness rather than complex tricks",
      id: "1714904089794",
      shorten: "Aesthetic",
      name: "Aesthetic gymnastics",
    },
    1679044987905: {
      nameru: "Fitness",
      descen:
        "Fitness practice is all about making sure that the body is in good shape and functioning optimally. Proper fitness practice requires a proper structure, which helps in ensuring that the body is well-toned and revitalized.\n\nIn conclusion, the structure of fitness practice is significant in achieving the desired results. Proper warm-up, main workout session, cool-down and rest and recovery phase are all necessary components that must be factored in to match an individual's experience and fitness level. Preparing one’s practice in this way will result in improved health, strength and agility.",
      desc: "Fitness practice is all about making sure that the body is in good shape and functioning optimally. Proper fitness practice requires a proper structure, which helps in ensuring that the body is well-toned and revitalized.\n\nIn conclusion, the structure of fitness practice is significant in achieving the desired results. Proper warm-up, main workout session, cool-down and rest and recovery phase are all necessary components that must be factored in to match an individual's experience and fitness level. Preparing one’s practice in this way will result in improved health, strength and agility.",
      shortru: "Fitness",
      imageSize: 37609,
      nameen: "Fitness",
      short: "Fitness",
      image:
        "https://firebasestorage.googleapis.com:443/v0/b/rgonline-1d3e0.appspot.com/o/programs%2FFitness.jpeg?alt=media&token=f4072c79-b0ad-4a54-98c0-c0027dc6b3c1",
      descru:
        "Fitness practice is all about making sure that the body is in good shape and functioning optimally. Proper fitness practice requires a proper structure, which helps in ensuring that the body is well-toned and revitalized.\n\nIn conclusion, the structure of fitness practice is significant in achieving the desired results. Proper warm-up, main workout session, cool-down and rest and recovery phase are all necessary components that must be factored in to match an individual's experience and fitness level. Preparing one’s practice in this way will result in improved health, strength and agility.",
      id: "1679044987905",
      shorten: "Fitness",
      name: "Fitness",
    },
    1657214001589: {
      nameru: "Stretching",
      descen:
        "The structure of practice in stretching is essential to maximizing its benefits and avoiding potential injuries. Stretching routines should start with a warm-up to prepare the muscles and joints for exercise, followed by a series of specific stretches intended to target different muscle groups.\n\nIn conclusion, the structure of practice in stretching involves a warm-up phase, followed by a specific routine of stretches, and ending with a cool down. The purpose of stretching is to increase the flexibility and range of motion of the muscles, while also reducing the risk of injury. Following a structured routine can help maximize the benefits of stretching and ensure that it is done safely.",
      desc: "The structure of practice in stretching is essential to maximizing its benefits and avoiding potential injuries. Stretching routines should start with a warm-up to prepare the muscles and joints for exercise, followed by a series of specific stretches intended to target different muscle groups.\n\nIn conclusion, the structure of practice in stretching involves a warm-up phase, followed by a specific routine of stretches, and ending with a cool down. The purpose of stretching is to increase the flexibility and range of motion of the muscles, while also reducing the risk of injury. Following a structured routine can help maximize the benefits of stretching and ensure that it is done safely.",
      shortru: "Stretch",
      imageSize: 14638,
      nameen: "Stretching",
      short: "Stretch",
      image:
        "https://firebasestorage.googleapis.com:443/v0/b/rgonline-1d3e0.appspot.com/o/programs%2FStretch.jpeg?alt=media&token=e132007a-19e8-4e55-baaf-48df928eeb65",
      descru:
        "The structure of practice in stretching is essential to maximizing its benefits and avoiding potential injuries. Stretching routines should start with a warm-up to prepare the muscles and joints for exercise, followed by a series of specific stretches intended to target different muscle groups.\n\nIn conclusion, the structure of practice in stretching involves a warm-up phase, followed by a specific routine of stretches, and ending with a cool down. The purpose of stretching is to increase the flexibility and range of motion of the muscles, while also reducing the risk of injury. Following a structured routine can help maximize the benefits of stretching and ensure that it is done safely.",
      id: "1657214001589",
      shorten: "Stretch",
      name: "Stretching",
    },
    1644572404794: {
      nameru: "Ballet",
      descen:
        "Ballet is a highly disciplined and technical form of dance that has been practiced for centuries. One of the keys to success in ballet is having a structured practice routine.\n\nThe structure of practice in ballet typically begins with warm-up exercises. These can include stretches, pliés, and tendus, which are designed to loosen up the muscles and prepare the body for more complex movements. Following warm-up exercises, ballet dancers will typically move on to practicing specific steps or techniques. These can include pirouettes, jumps, and other movements that require precision and control.\n\nIn addition to practicing specific steps, ballet dancers will also spend time working on their overall technique and posture. This can involve practicing proper alignment, balance, and fluidity of movement. Ballet technique is built on a series of movements that are carefully designed to build muscle memory and improve overall strength and flexibility.\n\nAnother important aspect of practicing ballet is developing musicality. Ballet is often performed to classical music, and dancers must learn to move in time with the rhythm and melody of the music.",
      desc: "Ballet is a highly disciplined and technical form of dance that has been practiced for centuries. One of the keys to success in ballet is having a structured practice routine.\n\nThe structure of practice in ballet typically begins with warm-up exercises. These can include stretches, pliés, and tendus, which are designed to loosen up the muscles and prepare the body for more complex movements. Following warm-up exercises, ballet dancers will typically move on to practicing specific steps or techniques. These can include pirouettes, jumps, and other movements that require precision and control.\n\nIn addition to practicing specific steps, ballet dancers will also spend time working on their overall technique and posture. This can involve practicing proper alignment, balance, and fluidity of movement. Ballet technique is built on a series of movements that are carefully designed to build muscle memory and improve overall strength and flexibility.\n\nAnother important aspect of practicing ballet is developing musicality. Ballet is often performed to classical music, and dancers must learn to move in time with the rhythm and melody of the music.",
      shortru: "Ballet",
      imageSize: 11312,
      nameen: "Ballet",
      short: "Ballet",
      image:
        "https://firebasestorage.googleapis.com:443/v0/b/rgonline-1d3e0.appspot.com/o/programs%2FBallet.jpeg?alt=media&token=6a98490b-878e-405d-a9ce-c1e935ee4a65",
      descru:
        "Ballet is a highly disciplined and technical form of dance that has been practiced for centuries. One of the keys to success in ballet is having a structured practice routine.\n\nThe structure of practice in ballet typically begins with warm-up exercises. These can include stretches, pliés, and tendus, which are designed to loosen up the muscles and prepare the body for more complex movements. Following warm-up exercises, ballet dancers will typically move on to practicing specific steps or techniques. These can include pirouettes, jumps, and other movements that require precision and control.\n\nIn addition to practicing specific steps, ballet dancers will also spend time working on their overall technique and posture. This can involve practicing proper alignment, balance, and fluidity of movement. Ballet technique is built on a series of movements that are carefully designed to build muscle memory and improve overall strength and flexibility.\n\nAnother important aspect of practicing ballet is developing musicality. Ballet is often performed to classical music, and dancers must learn to move in time with the rhythm and melody of the music.",
      id: "1644572404794",
      shorten: "Ballet",
      name: "Ballet",
    },
    1644433221158: {
      nameru: "Rhythmic gymnastics",
      descen:
        "Rhythmic gymnastics is a sport that combines the beauty of dance with the technical skills of gymnastics. With its graceful movements, flexibility, and precision, rhythmic gymnastics requires a great deal of discipline, training, and practice to achieve success. Therefore, the structure of practice is very important in developing the skills necessary for rhythmic gymnastics.\n\nThe structure of practice in rhythmic gymnastics consists of three main components: warm-up, skill development, and conditioning.\n\nOverall, the structure of practice in rhythmic gymnastics is essential to the success of athletes within the sport. It is through consistent practice, focusing on warm-up, skill development, and conditioning, that gymnasts learn the skills necessary to perform well in rhythmic gymnastics competitions. With dedication and commitment to this structure of practice, rhythmic gymnasts can achieve incredible feats of athleticism and artistry.",
      desc: "Rhythmic gymnastics is a sport that combines the beauty of dance with the technical skills of gymnastics. With its graceful movements, flexibility, and precision, rhythmic gymnastics requires a great deal of discipline, training, and practice to achieve success. Therefore, the structure of practice is very important in developing the skills necessary for rhythmic gymnastics.\n\nThe structure of practice in rhythmic gymnastics consists of three main components: warm-up, skill development, and conditioning.\n\nOverall, the structure of practice in rhythmic gymnastics is essential to the success of athletes within the sport. It is through consistent practice, focusing on warm-up, skill development, and conditioning, that gymnasts learn the skills necessary to perform well in rhythmic gymnastics competitions. With dedication and commitment to this structure of practice, rhythmic gymnasts can achieve incredible feats of athleticism and artistry.",
      shortru: "RG",
      imageSize: 14963,
      nameen: "Rhythmic gymnastics",
      short: "RG",
      image:
        "https://firebasestorage.googleapis.com:443/v0/b/rgonline-1d3e0.appspot.com/o/programs%2FRG.jpeg?alt=media&token=5cd8fd4f-5a46-47a7-8fee-5f4694436f6f",
      descru:
        "Rhythmic gymnastics is a sport that combines the beauty of dance with the technical skills of gymnastics. With its graceful movements, flexibility, and precision, rhythmic gymnastics requires a great deal of discipline, training, and practice to achieve success. Therefore, the structure of practice is very important in developing the skills necessary for rhythmic gymnastics.\n\nThe structure of practice in rhythmic gymnastics consists of three main components: warm-up, skill development, and conditioning.\n\nOverall, the structure of practice in rhythmic gymnastics is essential to the success of athletes within the sport. It is through consistent practice, focusing on warm-up, skill development, and conditioning, that gymnasts learn the skills necessary to perform well in rhythmic gymnastics competitions. With dedication and commitment to this structure of practice, rhythmic gymnasts can achieve incredible feats of athleticism and artistry.",
      id: "1644433221158",
      shorten: "RG",
      name: "Rhythmic gymnastics",
    },
    1679046814482: {
      nameru: "Routine creation",
      descen:
        "Creating a routine in rhythmic gymnastics takes a lot of effort and planning. The routine consists of four components: the music, the choice of apparatus, the composition, and the execution. Each element plays a vital role in the overall performance of the routine.\n\nCreating a routine in rhythmic gymnastics is a highly artistic process. It requires careful planning and attention to the various components of the routine, including the music, apparatus, composition, and execution. It's essential for gymnasts to embrace the creative freedom of their sport, experimenting with different elements and routines to showcase their strengths and abilities. With each routine, gymnasts have the opportunity to showcase their talent and love for their sport, leaving a lasting impression on the audience.",
      desc: "Creating a routine in rhythmic gymnastics takes a lot of effort and planning. The routine consists of four components: the music, the choice of apparatus, the composition, and the execution. Each element plays a vital role in the overall performance of the routine.\n\nCreating a routine in rhythmic gymnastics is a highly artistic process. It requires careful planning and attention to the various components of the routine, including the music, apparatus, composition, and execution. It's essential for gymnasts to embrace the creative freedom of their sport, experimenting with different elements and routines to showcase their strengths and abilities. With each routine, gymnasts have the opportunity to showcase their talent and love for their sport, leaving a lasting impression on the audience.",
      shortru: "Routines",
      imageSize: 11732,
      nameen: "Routine creation",
      short: "Routines",
      image:
        "https://firebasestorage.googleapis.com:443/v0/b/rgonline-1d3e0.appspot.com/o/programs%2FRoutine%20creation.jpeg?alt=media&token=d4206732-28a2-45f0-8e8b-f6f146e4de7f",
      descru:
        "Creating a routine in rhythmic gymnastics takes a lot of effort and planning. The routine consists of four components: the music, the choice of apparatus, the composition, and the execution. Each element plays a vital role in the overall performance of the routine.\n\nCreating a routine in rhythmic gymnastics is a highly artistic process. It requires careful planning and attention to the various components of the routine, including the music, apparatus, composition, and execution. It's essential for gymnasts to embrace the creative freedom of their sport, experimenting with different elements and routines to showcase their strengths and abilities. With each routine, gymnasts have the opportunity to showcase their talent and love for their sport, leaving a lasting impression on the audience.",
      id: "1679046814482",
      shorten: "Routines",
      name: "Routine creation",
    },
  },
  loclevels = {
    0: {
      name: "Beginners",
      desc: "You're just starting to engage in the chosen sport. You learn the basic skills and techniques, developing physical fitness, and essential physical qualities.",
      descen:
        "You're just starting to engage in the chosen sport. You learn the basic skills and techniques, developing physical fitness, and essential physical qualities.",
      nameru: "Начинающие",
      num: 1,
      id: "0",
      nameen: "Beginners",
      descru: "Начинающий уровень",
    },
    1: {
      name: "Amateurs",
      desc: "You possess basic skills and training experience. You actively train and participate in amateur-level competitions. The goals of an amateur athlete might be to improve results and gain additional experience",
      descen:
        "You possess basic skills and training experience. You actively train and participate in amateur-level competitions. The goals of an amateur athlete might be to improve results and gain additional experience",
      nameru: "Amateurs",
      num: 2,
      id: "1",
      nameen: "Amateurs",
      descru:
        "You possess basic skills and training experience. You actively train and participate in amateur-level competitions. The goals of an amateur athlete might be to improve results and gain additional experience",
    },
    2: {
      name: "Advanced",
      desc: "You already have significant experience, a high level of skills, and physical preparation. You train systematically, compete at regional or national levels and strive for high achievements.",
      descen:
        "You already have significant experience, a high level of skills, and physical preparation. You train systematically, compete at regional or national levels and strive for high achievements.",
      nameru: "Продвинутые",
      num: 3,
      id: "2",
      nameen: "Advanced",
      descru: "Продвинутый уровень",
    },
  },
  loccolors = {
    silver: ["#DDDDDD", "#B8B8B8"],
    bronze: ["#F6D3C6", "#C19D91"],
    gold: ["#EBDCC1", "#D4BD92"],
    green: ["#D7E0C6", "#A3B08B"],
    blue: ["#CED4E9", "#AAB5D6"],
    black: ["#666666", "#444444"],
  };
