import React, { useEffect } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { useNavigation } from "@react-navigation/native";
import { useAuth, useClient, useSchool } from "../commons/Stores";
import { appsAlert, wwidth } from "../commons/utils";
import {
  UserPic,
  Row,
  Text18,
  Text11,
  DGRAY,
  GRAY,
  GREEN,
  BACKGREEN,
  Touch,
  ChatIcon,
  Loader,
  LITEBORDER,
  RowBetween,
  ACTIVEGRAY,
  RowCentered,
  Text12,
  Medium16,
  TextDot,
  Text14,
} from "../commons/UI";
import { translates } from "../translates";

export default observer(
  ({ full, small, programs, color = DGRAY, style, ...r }) => {
    const { lang, rus } = useAuth(),
      prename = !r.order ? "" : rus ? "Тренер " : "Coach ";

    if (r.mini)
      return (
        <RowCentered style={{ flexShrink: 1, ...style }}>
          <UserPic {...r} mini />
          <Text14 style={{ color }} numberOfLines={1}>
            {prename + (r.name || r.uid)}
          </Text14>
        </RowCentered>
      );

    const coachID = full ? r.coach.uid : r.coachID,
      { navigate } = useNavigation();

    const c = full ? r.coach : useSchool().coaches[coachID],
      { getCoach } = full ? {} : useSchool(),
      { programs: progs, name } = c || { name: coachID },
      isactive = c?.status === "approved";

    useEffect(() => {
      if (!full && !c) getCoach(coachID);
    }, []);

    let showTrial;

    if (full) {
      const { canTrial, myid } = useClient();
      showTrial = c?.hasTrial && (canTrial || !myid);
    }

    const onPress = () => navigate("Coach", { coachID });

    const PRICES = !c ? null : small ? (
      <RowCentered>
        <SmallPrice children={`${c.grPrice}$`} />
        {PriceBlank}
        <TextDot color={GRAY} />
        {PriceBlank}
        <SmallPrice children={c.price + (rus ? "$ в час" : "$ per hour")} />
      </RowCentered>
    ) : full ? (
      <Price children={(rus ? "от" : "from") + ` ${c.grPrice}$`} />
    ) : (
      <Price style={{ color: GRAY }}>
        {c.price + (rus ? "$ час 1-на-1" : "$ hour 1-on-1")}
      </Price>
    );

    if (small)
      return (
        <SmallCardWrap
          {...{ onPress, style }}
          Image={
            <UserPic
              small
              {...{ name }}
              photo={isactive && c.photo}
              color={!isactive && ACTIVEGRAY}
              style={{ marginRight: 12 }}
            />
          }
        >
          <SmallName numberOfLines={1}>{prename + (name || uid)}</SmallName>
          {PRICES}
        </SmallCardWrap>
      );

    const renderProgs = (id) => {
      let { name: pname, short } = programs[id] || {};
      return short || pname || "";
    };

    return (
      <Touch {...{ onPress, style }}>
        <Container style={full ? { height: 120 } : border}>
          <UserPic
            big={full}
            {...{ name }}
            photo={isactive && c.photo}
            color={!isactive && ACTIVEGRAY}
          />
          <Body style={{ paddingBottom: full ? 9 : 7 }}>
            <RowBetween style={{ alignItems: "flex-start" }}>
              <Text18 numberOfLines={1}>{name}</Text18>
              {full ? (
                showTrial && (
                  <TrialView>
                    <ExpCaption style={{ marginTop: 1 }}>
                      {rus ? "пробный" : "free trial"}
                    </ExpCaption>
                  </TrialView>
                )
              ) : (
                <ChatIconComp />
              )}
            </RowBetween>

            {!c && (
              <Row>
                <Loader small style={{ marginTop: 8 }} />
              </Row>
            )}

            {progs && (
              <>
                <View style={{ flex: 1 }}>
                  {full && (
                    <Programs numberOfLines={1}>
                      {progs.map(renderProgs).join(", ")}
                    </Programs>
                  )}
                </View>

                <RowBetween style={{ alignItems: "flex-end" }}>
                  <RowCentered style={{ flex: 1 }}>
                    <Circle>
                      <Expr>{c.expCoach || 5}</Expr>
                    </Circle>
                    <ExpCaption>
                      {rus ? "лет\nопыта" : "years\npractise"}
                    </ExpCaption>
                  </RowCentered>

                  {PRICES}
                </RowBetween>
              </>
            )}
          </Body>
        </Container>
      </Touch>
    );
  }
);

export let SmallCardWrap = ({ style, onPress, Image, ...pr }) => (
  <Row>
    <Touch {...{ onPress, style }}>
      <Container style={{ borderRadius: 12, ...border }}>
        {Image}
        <SmallBody>{pr.children}</SmallBody>
      </Container>
    </Touch>
  </Row>
);

export let ChatIconComp = (pr) => {
  const { lang } = useAuth();
  return (
    <Touch
      onPress={() => appsAlert(APPSPROMO[lang])}
      style={{
        margin: -8,
        marginTop: -10,
        marginRight: -14,
        padding: 8,
        paddingLeft: 24,
        paddingRight: 14,
        ...pr.style,
      }}
    >
      <ChatIcon />
    </Touch>
  );
};

let PriceBlank = <Text11 style={{ fontSize: 9 }} children=" " />;

export let border = { borderWidth: 1, borderColor: LITEBORDER };

let Container = styled(Row)`
    background: white;
    flex-shrink: 1;
    max-width: ${wwidth - 24 * 2}px;
    border-radius: 18px;
    padding: 5px;
  `,
  Body = styled.View`
    flex: 1;
    flex-shrink: 1;
    padding: 10px 10px 9px 0;
  `,
  SmallBody = styled.View`
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    padding-right: 8px;
    /* margin-left: -6px; */
  `,
  SmallName = styled(Text14)`
    min-width: 72px;
    color: ${DGRAY};
    margin: 1px 0 4px;
  `,
  Price = styled(Text14)`
    color: ${DGRAY};
  `,
  SmallPrice = styled(Text12)`
    color: ${GRAY};
    line-height: 14px;
  `,
  Programs = styled(Text14)`
    color: ${GRAY};
    margin: 4px 8px 0 0;
    /* letter-spacing: 0.3px; */
  `,
  Circle = styled.View`
    width: 32px;
    height: 32px;
    border-radius: 20px;
    background: ${BACKGREEN};
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  `,
  Expr = styled(Medium16)`
    color: ${GREEN};
  `,
  ExpCaption = styled(Text11)`
    color: ${GREEN};
    line-height: 12px;
  `,
  TrialView = styled.View`
    flex-shrink: 0;
    background: ${BACKGREEN};
    padding: 4px 10px;
    border-radius: 12px;
    margin-top: -1px;
  `;

let { APPSPROMO } = translates.CoachCard;
