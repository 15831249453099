import { useEffect, useRef, useState } from "react";
import { Image } from "react-native";
import { confirmAlert } from "react-confirm-alert"; // Import
import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  orderBy as dbOrderBy,
  getDocs,
  limit,
} from "firebase/firestore";
import { getDatabase, ref } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
  uploadString,
} from "firebase/storage";
import * as ImagePicker from "expo-image-picker";
import Toast from "react-native-toast-message";
import axios from "axios";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import app from "../../config";
import { rootNavg } from "./RootNavigation";
import { BLUE, DGRAY, GREEN } from "./UI";
import { translates } from "../translates";

let TRNS = translates.utils,
  { CLSQNT, REFNDPOL } = translates;

let utc = require("dayjs/plugin/utc"),
  zone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(zone);

export const { innerHeight: wheight, localStorage: localStor } = window,
  screenWidth = isNaN(window.innerWidth) ? 390 : window.innerWidth,
  isDesktop = screenWidth > 450,
  isweb = true,
  isIOS = false,
  device = "web",
  isMobl = !isDesktop,
  wwidth = isDesktop ? 450 : isNaN(screenWidth) ? 390 : screenWidth,
  modalWidth = screenWidth > 900 ? 550 : wwidth,
  deskPadding = (screenWidth - wwidth) / 2,
  paddTop = 0,
  paddBottom = 0,
  tabbarHeight = 62,
  horizCardWidth = 270,
  SUPPORT = "anastasia_bunny@mail.ru",
  { clipboard } = navigator;

export const db = getFirestore(app),
  rdb = getDatabase(app),
  storage = getStorage(app),
  rdbProgs = ref(rdb, "programs"),
  rdbLevels = ref(rdb, "levels"),
  rdbPackages = (id) => ref(rdb, "packages" + (id ? `/${id}` : "")),
  rdbPromo = (id) => ref(rdb, "promos/" + id),
  rdbDeposits = (id) => ref(rdb, "deposits" + (id ? `/${id}` : "")),
  rdbBalances = (uid, id) => ref(rdb, `balances/${uid}` + (id ? `/${id}` : "")),
  rdbUsers = (id) => ref(rdb, "users" + (id ? `/${id}` : "")),
  dbCoaches = (id) => (id ? doc(db, "coaches", id) : collection(db, "coaches")),
  dbEvents = (id) => (id ? doc(db, "events", id) : collection(db, "events")),
  dbUsers = (id) => (id ? doc(db, "users", id) : collection(db, "users")),
  dbOrders = (id) => (id ? doc(db, "orders", id) : collection(db, "orders")),
  dbPassed = (id) => (id ? doc(db, "passed", id) : collection(db, "passed")),
  dbPasses = (id) =>
    id ? doc(db, "packagePasses", id) : collection(db, "packagePasses"),
  dbBooks = (myid) =>
    query(dbEvents(), where("clientsIds", "array-contains", myid || "00")),
  mydbPassed = (myid) =>
    query(dbPassed(), where("clientsIds", "array-contains", myid || "00")),
  mydbComments = (myid) =>
    query(
      dbPassed(),
      where("clientsIds", "array-contains", myid || "00"),
      where("hasCoachComment", "==", true),
      where("active", "==", true),
      dbOrderBy("to", "desc")
    );

export let localLang = () => {
  let stored = localStor.getItem("lang");
  if (stored) return stored;
  let ll = navigator.language || navigator.userLanguage;
  return ll?.split("_")[0] || "en";
};

export let localRus = () => localLang() === "ru";

export const dayAgo = () => Date.now() - 24 * 60 * 60000;

export let callAlert = (title, message, buttons0 = [], strict) => {
  let rus = localRus();
  const actives = buttons0
      .filter((b) => !!b)
      .map((b) => (b.label ? b : { label: b.text, onClick: b.onPress })),
    buttons = strict
      ? actives
      : actives.length > 2
      ? actives
      : [...actives, { label: rus ? "Закрыть" : "Close", onClick: null }];

  return confirmAlert({
    title,
    message: message || undefined,
    buttons,
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    overlayClassName: "react-confirm-alert-class",
  });
};

export const contactsAlert = (subj, text, isSecond, lang0) => {
  let lang = lang0 ?? localLang(),
    rus = lang === "ru";

  return setTimeout(
    () =>
      callAlert(
        (isSecond ? "2. " : "") + `Whatsapp ${rus ? "или" : "or"} e-mail?`,
        TRNS.SPRT4[lang],
        [
          {
            label: "Whatsapp",
            onClick: () => openURL(`https://wa.me/79126144799?text=${text}`),
          },
          {
            label: "E-mail",
            onClick: () =>
              openURL(`mailto:${SUPPORT}?subject=${subj}&body=${text}`),
          },
        ]
      ),
    100
  );
};

export const getDay = (from) => dayjs(from).format("YYYY-MM-DD");

export const parseBalance = (obj) =>
  obj
    ? parseFloat(
        Object.values(obj)
          .reduce((res, curr) => {
            if (curr.isapply) return res;
            else return res + (curr.sum || 0);
          }, 0)
          .toFixed(1)
      )
    : 0;

export const handleRoutesCheck = () => {
  let {
      index: rootIndex,
      routes: [route0],
    } = rootNavg.current?.getRootState() || {},
    isInTabNavgIndex = !rootIndex && route0.state?.index,
    isINProfileStack = isInTabNavgIndex === 1;
  return { isINProfileStack };
};

let getTabState = () => {
  let tabNavg = rootNavg.current.getRootState().routes[0],
    tabRoutes = tabNavg?.state?.routes;
  return { tabNavg, tabRoutes };
};

export const resetStackRoute = async (target, params) => {
  // target means target screen  we need to navigate
  let { tabNavg, tabRoutes } = getTabState();

  if (!tabRoutes) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    ({ tabNavg, tabRoutes } = getTabState());
  }
  if (!tabRoutes)
    return console.log("resetStackRoute 2 NO tabRoutes, CANCEL redirect ");

  let authed = getLocalUid(),
    inhome =
      ["Coach", "Home"].includes(target) ||
      (target == "Event" && (params?.from == "Coach" || !authed)) ||
      (target == "Package" && params?.packID),
    inprofile = !inhome,
    tabIndex = inhome ? 0 : 1,
    gotologin = inprofile && !authed;

  let tabRoute0 = {
    name: inhome ? "Home" : authed ? "Profile" : "Login",
    params: undefined,
  };

  tabRoutes[tabIndex] = {
    ...tabRoutes[tabIndex],
    state: {
      routes: gotologin ? [tabRoute0] : [tabRoute0, { name: target, params }],
      index: gotologin ? 0 : 1,
    },
    params: undefined,
  };

  console.log("resetStackRoute 3", target, tabRoutes[1].state);

  return rootNavg.current.resetRoot({
    index: 0,
    routes: [
      {
        ...tabNavg,
        state: { ...tabNavg.state, routes: tabRoutes, index: tabIndex },
      },
    ],
  });
};

export const rootNavEvent = (pr) => resetStackRoute("Event", pr),
  rootNavBalance = () => resetStackRoute("BalanceRecords"),
  rootNavCoach = (pr) => resetStackRoute("Coach", pr);

export const OLDgetFreeSlots = ({ id, from, to, busy = {} }, busyArr) => {
  let now = Date.now(),
    inhour = now + 60 * 60000;
  if (to < inhour) return []; // remove slots ending in less than 60 min

  let busiesFiltrd = (busyArr || Object.values(busy)).filter(
    (b) => b.from < to && b.to > from
  );
  if (!busiesFiltrd[0]) return [{ id, from, to }];

  let busies = orderBy(busiesFiltrd, "from");

  // first
  let splitted = [{ id, from, to: busies[0].from }];

  // from second to last-1
  if (busies[1])
    busies.forEach(
      (b, i) =>
        i > 0 && splitted.push({ id, from: busies[i - 1].to, to: b.from })
    );

  // + last
  let lastBusy = busies[busies.length - 1];
  if (lastBusy.to <= to - 30 * 60000)
    splitted.push({ id, from: lastBusy.to, to });

  return splitted.filter(
    (s) => s.to > inhour && s.to - s.from >= 30 * 60000 //  only ending in 45+ mins & have duration minimum 30 mins
  );
};

let MIN30 = 30 * 60000;

export const getFreeSlots = (slotsArr0, busy0, maxFrom) => {
  let now = Date.now(),
    inhour = now + 60 * 60000;

  let slotsArr = orderBy(
    (slotsArr0 || []).filter(
      (s) =>
        s.to > inhour &&
        s.to - s.from >= MIN30 &&
        (maxFrom ? s.from <= maxFrom : true)
    ),
    "from"
  );

  if (!slotsArr[0]) return [];

  let busy = busy0.filter((b) => b.to > now);

  if (!busy[0]) return slotsArr;

  let freeSlots = [];

  let handleSlot = ({ id, from, to }) => {
    let [FROM, TO] = [from, to];
    if (TO < inhour) return; // remove slots ending in less than 60 min

    let slotBusy = busy.filter((b) => b.from < TO && b.to > FROM);
    if (!slotBusy[0]) return freeSlots.push({ id, from, to });

    let busyArr = orderBy(slotBusy, "from"),
      currFrom = FROM;

    busyArr.forEach((b) => {
      // Если начало занятого события позже текущего старта и промежуток времени больше или равен GAP
      if (b.from - currFrom >= MIN30)
        freeSlots.push({ id, from: currFrom, to: b.from });
      // Обновляем старт до конца текущего занятого события
      currFrom = Math.max(currFrom, b.to);
    });

    // Проверяем, есть ли оставшийся свободный промежуток времени после последнего занятого события
    if (TO - currFrom >= MIN30) freeSlots.push({ id, from: currFrom, to: TO });
  };

  slotsArr.forEach(handleSlot);

  //  only ending in 60+ mins & have duration minimum 30 mins
  return freeSlots.filter(
    (s) =>
      s.to > inhour &&
      s.to - s.from >= MIN30 &&
      (maxFrom ? s.from <= maxFrom : true)
  );
};

export const copytext = (tx, ignoreToast) => {
  if (clipboard) clipboard.writeText(tx);
  else document.ex.execCommand("copy", true, tx);
  if (!ignoreToast) showToast(localRus() ? "скопировано" : "copied", 500);
};

export const showToast = (tx, dur, offset, onPress) => {
  let iscopy = ["copied", "скопирован"].some((c) =>
    tx.toLowerCase().includes(c)
  );

  let bottomOffset = offset || (iscopy ? wheight / 2 - 44 : tabbarHeight + 24),
    style = iscopy ? { width: wwidth / 2 } : undefined;

  return Toast.show({
    position: "bottom",
    visibilityTime: dur || 3500,
    type: "basic",
    text1: tx,
    bottomOffset,
    onPress: onPress ? () => (onPress(), Toast.hide()) : Toast.hide,
    contentContainerStyle: style,
    style,
  });
};

export const vibroToast = (...args) => (
  showToast(...args), "vibrate" in navigator && navigator.vibrate(100)
);

export const offlineToast = () => vibroToast(TRNS.ONOFFLN[localLang()], 1500);

let ages0 = [
    { id: 5, n: "3-5" },
    { id: 8, n: "6-8" },
    { id: 14, n: "9-14" },
    { id: 17, n: "15-17" },
    { id: 25, n: "18-25" },
    { id: 35, n: "26-35" },
    { id: 99, n: "36+" },
  ],
  engages = ages0.map(({ id, n }) => ({
    id,
    name: n + " y.o.",
    full: n + " years old",
  })),
  ruages = ages0.map(({ id, n }) => ({
    id,
    name: n + " лет",
    full: n + " лет",
  }));

export const ages = { en: engages, ru: ruages };

export const colors = [
  "#FFC08A",
  "#605884",
  "#B86C88",
  "#FF988C",
  "#273E65",
  "#0CA0AE",
  "#D9AE91",
  "#A77887",
  "#87CCC5",
  "#743F97",
  "#E1578A",
  "#4400B2",
  "#B9789F",
  "#C23A94",
  "#CE3D1D",
  "#922D25",
  "#F6522E",
  "#DCABAE",
  "#FF7A2F",
  "#EEDC7C",
  "#8EAF0C",
  "#77BD8B",
  "#117243",
  "#00CF91",
  "#2398AB",
  "#BDCCFF",
  "#51EAFF",
  "#5199FF",
];

export const durtnText = (dur0, full0, rus0) => {
  let rus = rus0 ?? localRus(),
    dur = Math.abs(dur0);
  if (!dur) return rus ? "0 мин" : "0 min";

  let num = dur > 60000 ? dur / 60000 : dur,
    hh = Math.floor(num / 60),
    mm = Math.round(num % 60),
    full = full0 ? !mm || hh < 1 : false,
    mintext = full && rus ? " мин" : full ? " min" : rus ? "м" : "m";

  let hhtext = !full && (rus ? "ч" : "h");
  if (full)
    hhtext = rus
      ? ` час${hh > 4 ? "ов" : hh > 1 ? "а" : ""}`
      : ` hour${hh > 1 ? "s" : ""}`;

  let mmres = mm ? mm + mintext : "",
    hhres = hh ? hh + hhtext + (mm ? " " : "") : "";
  return hhres + mmres;
};

export const getRate = (obj) => {
  let rates = Object.values(obj || {}),
    rate = rates[12] && rates.reduce((s, r) => s + r, 0) / rates.length;
  return rate?.toFixed(1);
};

export const dbQueryToObj = (query, setState) => {
  let obj = {};
  if (query && !query.empty)
    query.forEach((d) => {
      let [id, doc] = [d.id, d.data()];
      if (doc.from) doc.day = getDay(doc.from);
      return (obj[id] = doc);
    });
  if (setState) return setState(obj);
  else return obj;
};

export const capitalize = (str) => str[0].toUpperCase() + str.substring(1);

export const groupBy = (array, property) =>
  array.reduce((result, item) => {
    const value = item[property];
    (result[value] = result[value] || []).push(item);
    return result;
  }, {});

export const offset = dayjs().utcOffset(),
  offsetHH = Math.floor(Math.abs(offset) / 60),
  offsetFract = Math.abs(offset % 60),
  offsetName = (offset >= 0 ? "+" : "-") + offsetHH + `:${offsetFract || "00"}`,
  offsetText = "GMT" + offsetName,
  tmzn = offset / 60,
  timezoneName = dayjs.tz.guess();

export const openRefundPlcy = () => openURL("https://bloss.am/refunds");
export const refundsAlertButton = {
  text: REFNDPOL[localLang()],
  onPress: openRefundPlcy,
};

export let classTypeDesc = (privat, _, grRate, lang) => {
  if (!privat) return TRNS.CLSTP1[lang];
  let desc = TRNS.CLSTP2[lang]().replace(/  /g, " ");
  if (grRate) desc += `. \n` + grRate + TRNS.CLSRT[lang];
  return desc;
};

export let checkCantrial = async (lastOrder = {}, myid, setOrder) => {
  if (lastOrder.status == "paid") {
    let orderEvents = Object.values(lastOrder.events);
    if (orderEvents.some((e) => e.active)) return false;
  }

  let client = myid || getLocalUid();
  if (!client) return;

  let q = await getDocs(
    query(
      dbOrders(),
      where("client", "==", client),
      where("status", "==", "paid"),
      dbOrderBy("created", "desc"),
      limit(2)
    )
  );
  if (q.empty) return true;
  if (q.size > 1) {
    if (setOrder) setOrder(q.docs[1].data());
    return false;
  }

  let d = q.docs[0].data();
  // if (d.created !== lastOrder.created) return false;
  let eventsArr = Object.values(d.events);
  return eventsArr.some((e) => e.active) ? false : null;
};

export let isbookblEvent = (e) =>
  ("active" in e ? e.active : true) &&
  e.from > Date.now() + (e.custom || e.privat ? 30 * 60000 : -10 * 60000);

export const uploadMedia = async (path, file, onerror) => {
  let lang = localLang(),
    fileref = storageRef(storage, path),
    // localUrl = isIOS ? uri.replace("file://", "") : uri,
    // task = fileref.putFile(localUrl)
    url;
  await uploadString(fileref, file, "data_url", {
    cacheControl: "public, max-age=36000",
  })
    .then(async (res) => {
      await getDownloadURL(res.ref).then((u) => (url = u));
    })
    .catch((er) => {
      url = false;
      if (onerror) onerror();
      compressImageAlert(...TRNS.IMGUPL[lang]);
    });

  return url;
};

export const compressImageAlert = (title, text0) => {
  let lang = localLang();
  return callAlert(title, text0 + TRNS.IMGCMPRS[lang], [
    { text: "Tinypng.com", onPress: () => openURL(`https://tinypng.com/`) },
  ]);
};

export let openURL = (url) => window.open(url, "_blank");
// Linking.openURL(url).catch(  () => (copytext(url),alert(  `Couldn't open your browser. The link is copied, open your browser and paste it there`)  ));

export let openPayURL = (url) => window.open(url, "_blank");
// Linking.openURL(url).catch(() =>  callAlert("Couldn't open a new tab","Please, copy the payment link, then open a new tab in your browser and paste it there",[{ label: "Copy link", onClick: () => copytext(url) }]  ));

export const copyDeepLink = async (path, ignore) => {
  let rus = localRus();
  let longDynamicLink = `https://bloss.page.link/?link=https://app.bloss.am/${path}&apn=com.rgonline.app&afl=https://app.bloss.am/${path}&isi=1559365312&ibi=com.rgonline.app&ifl=https://app.bloss.am/${path}`;
  if (!ignore) {
    copytext(longDynamicLink, "ignore");
    showToast(rus ? "Ссылка скопирована" : "Share link copied");
  }
  let res = await axios.post(
    "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCvv4YF7T8gCbd97ZrbZ-7LPNQI0k4HCHA",
    { longDynamicLink },
    { headers: { "Content-Type": "application/json" } }
  );
  if (!res) return longDynamicLink;
  let { shortLink: url } = res.data;
  if (!ignore) copytext(url, "ignore");
  if (ignore) return url;
};

export let genrtItemLayout = (len, i) => ({
  length: len,
  offset: len * i,
  index: i,
});

export const issame = (tx0, tx) => {
  if (!tx0 && !tx) return true;
  if ((!tx0 && !!tx) || (!!tx0 && !tx)) return false;
  return typeof tx0 == "string"
    ? tx?.toLowerCase() === tx0?.toLowerCase()
    : tx === tx0;
};

export const launchImagePicker = async () => {
  let lang = localLang(),
    res = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 0.9,
      base64: false,
      allowsMultipleSelection: false,
      allowsEditing: false,
      exif: false,
    });

  if (res.canceled) return null;

  let [{ uri, fileSize }] = res.assets,
    mb = fileSize / 1000 / 1000,
    mbtext = mb.toFixed(2) + " MB";
  if (mb > 1) {
    compressImageAlert(...TRNS.IMGCMPRS2[lang](mbtext));
    return null;
  }
  return uri;
};

export let openAppStore = () =>
    openURL("https://apps.apple.com/app/bloss/id1559365312"),
  openGooglePlay = () =>
    openURL("https://play.google.com/store/apps/details?id=com.rgonline.app");

let { isAndroid } = require("react-device-detect");

export const appsAlert = (tx) => {
  let iosbut = { label: "Apple", onClick: openAppStore },
    andrbut = { label: "Android", onClick: openGooglePlay };
  return callAlert(
    ...TRNS.APPSPROMO[localLang()](tx),
    isAndroid ? [andrbut, iosbut] : [iosbut, andrbut]
  );
};

export const durations = (max) =>
  new Array(23) // Dur-ns array length = the Period divided by 15 mins minus one interval, because we remove 0 min and 15 min intervals
    .fill(1)
    .map((_, i) => (i + 2) * 15)
    .filter((d) => d <= max);

export const getIMGRatio = async (img, setState) => {
  let rat;
  if (String(img).startsWith("http")) {
    await Image.getSize(img, (wh, hh) => (rat = hh / wh));
  } else {
    let a = Image.resolveAssetSource(img);
    rat = a?.hasOwnProperty("width") ? a.width / a.height : null;
  }

  rat = typeof rat === "number" ? rat : null;
  if (rat && setState) setState(rat);
  return rat;
};

export const parseChangeText = (change, lang0) => {
  let lang = lang0 ?? localLang();
  let ind = [
    "slots",
    "expired",
    "late",
    "changed",
    "booked",
    "already started",
    "cross",
    "free trial",
    "cancelled",
  ].findIndex((t) => change.includes(t));

  let NUM = ind > -1 ? ind + 1 : 1;
  return TRNS["CLSCHG" + NUM][lang];
};

export let localTM24 = () => (localStor.getItem("timeFormat") || "24") == "24";
export let formhh = (time24) =>
  time24 === false ? "h:mm A" : time24 || localTM24() ? "HH:mm" : "h:mm A";

export const getSuprtAppeal = ({ myid, id, orderID, passID }, lang0) => {
  let lang = lang0 ?? localLang(),
    rus = lang === "ru",
    iscommon = !id && !orderID && !passID;

  let start = TRNS.SPRT1[lang](myid) + "\n" + TRNS.SPRT2[lang](iscommon);

  let item = "";
  if (id) {
    if (rus)
      item = `бронирования ${id}` + (!orderID ? "" : ` (заказ ${orderID})`);
    else item = `booked class ${id}` + (!orderID ? "" : ` (order ${orderID})`);
  }
  if (!id && orderID) item = (rus ? "заказа " : `order `) + orderID;
  else if (passID) item = (rus ? "покупки пакета " : "package pass ") + passID;

  let mess = (start + item + ":  ").replace(/  /g, " ");

  let proceed = () => (
    copytext(mess, "ignore"),
    contactsAlert(
      id
        ? "BOOKING ISSUE"
        : orderID
        ? "ORDER ISSUE."
        : passID
        ? "PACKAGE ISSUE."
        : "COMMON SUPPORT.",
      mess,
      2,
      lang
    )
  );

  return setTimeout(
    () =>
      callAlert(...TRNS.SPRT3[lang](mess), [
        { label: rus ? "Копировать" : "Copy & contact us", onClick: proceed },
      ]),
    100
  );
};

export let coachesNames = ["Alice", "Anastasia", "Daria", "Ksenia"],
  sortedCoachIds = [
    // 'lgeXqnXmnvXphzOsOQsTjC3Fr7s2',
    "xRkkDYZLOQPwYBVJhucwo0cMHa43",
    "SxYzntNZWMRJQjPSGoJeFC1POpq2",
    "75uwmNAGgMafFErRdm830Llj4752",
    "wYiTHfEW2uUNU18whacQ9g5RqII2",
    "9ibWIEBxAVO2ORsbd404W6QadOg2",
    "atFxsMVXYEQ1JMjnLQt5dCYEATV2",
  ];

export let parseOrder = (o, lang0) => {
  if (!o) return {};
  if (o.name && !o.events) return parsePackage(o, null, lang0);

  let lang = lang0 ?? localLang(),
    { status, quant } = o || {},
    recent = o.created > dayAgo(),
    pending = !status || status === "pending",
    paid = status === "paid",
    paidByPass = paid && ["package", "pass"].includes(o.method),
    paidByBalance = paid && o.method === "balance",
    eventsArr = orderBy(Object.values(o.events || {}), "from"),
    totalDur = eventsArr.reduce((pr, e) => pr + (e.to - e.from), 0) / 60000,
    progs = [...new Set(eventsArr.map((e) => e.progID))],
    sameProgram = progs.length === 1 ? progs[0] : false,
    canbook = pending && eventsArr.some(isbookblEvent),
    canbuy = canbook && recent,
    cancelled = status === "cancelled",
    expired = status === "expired" || (!cancelled && !paid && !canbuy),
    statusColor = cancelled || expired ? DGRAY : paid ? GREEN : BLUE;

  let quantText = CLSQNT[lang](quant),
    statusText = TRNS.ORDERSTS[lang](cancelled, expired, paidByPass, paid);

  return {
    orderID: o.id,
    recent,
    pending,
    paid,
    canbook,
    canbuy,
    cancelled,
    expired,
    paidByBalance,
    paidByPass,
    quant,
    quantText,
    progs,
    sameProgram,
    eventsArr,
    totalDur,
    statusText,
    statusColor,
  };
};

export let _ispass = (o) => o?.packID && o?.from && o?.to;

export let parsePackage = (o, myid, lang0) => {
  if (!o) return {};
  let lang = lang0 ?? localLang(),
    { status, offerLimit: limit, to, limitUsers: users } = o || {},
    isorder = !!o.packID,
    ispass = _ispass(o),
    passed = (ispass ? to : limit || "999") < Date.now(),
    isactive = ispass ? isactivePass(o) : isvalidPackage(o),
    pending = status === "pending" || (isorder && !status),
    paid = ["paid", "enrolled", "completed", "finished"].includes(status),
    paidByBalance = paid && o.method === "balance",
    uid = typeof myid === "string" ? myid : getLocalUid(),
    ongoingPass = ispass && isactive && o.uid === uid,
    hasActiveBook = Object.values(o.uses || {}).some(
      (e) => e.isevent && !e.passed && !e.cancelled
    ),
    canbook = ispass && isactive && o.durLeft > 15,
    completed =
      status === "completed" || (paid && o.durLeft < 30 && !hasActiveBook),
    cancelled = status === "cancelled",
    finished = status === "finished" || completed || (paid && !isactive);
  // expired, unlike completed & finished, means the pass order (purchase) wasn't paid and can't be paid anymore

  let canbuy = !isorder && isactive;
  if (
    isorder &&
    !canbuy &&
    pending &&
    o.uid === uid &&
    o.active !== false &&
    (limit ? limit > Date.now() : true)
  ) {
    canbuy = true;
  }

  let expired =
    status === "expired" ||
    (ispass ? !cancelled && !paid && !canbuy : limit < Date.now());

  let passStatusColor = ispass && (cancelled ? DGRAY : finished ? BLUE : GREEN),
    orderStatusColor = cancelled || expired ? DGRAY : paid ? GREEN : BLUE;

  let passStatusText =
    ispass && TRNS.PASSSTS[lang](cancelled, completed, finished);

  let orderStatusText = TRNS.PACKORDRSTS[lang](cancelled, expired, paid);
  let endTimeStatus = TRNS.PASSENDSTS[lang](completed, finished, passed);

  let allowed = !users;

  if (users) {
    let { type, uids } = users,
      includes = uids.includes(uid);
    allowed = type === "allow" ? includes : !uid || !includes;
  }

  return {
    ispass,
    ispackage: true,
    passID: ispass && o.id,
    packID: o.packID || o.id,
    isactive,
    pending,
    paid,
    canbuy,
    canbook,
    completed,
    finished,
    cancelled,
    expired,
    paidByBalance,
    ongoingPass,
    passStatusText,
    passStatusColor,
    orderStatusText,
    orderStatusColor,
    endTimeStatus,
    allowed,
  };
};

export let isactivePass = (p) => p?.active && p?.to > Date.now(),
  isvalidPackage = (p) =>
    p?.active && (!p.offerLimit || p.offerLimit > Date.now());

export let getLocalUid = () => {
  let user = localStor.getItem("user");
  return user ? JSON.parse(user)?.uid : null;
};

export const filterPassEvents = (e, pass) => {
  let passDur = pass?.durLeft ?? pass?.duration;
  return (
    e.privat &&
    (e.custom ? e.client.quant < 2 : !e.clientsIds[0]) &&
    e.price &&
    e.from <= pass.to &&
    (e.to - e.from) / 60000 <= passDur
  );
};

export let checkSlotsCrossed = (s, s2) =>
  (s.from >= s2.from && s.from < s2.to) ||
  (s2.from >= s.from && s2.from < s.to);

export let offline = () => !window.navigator.onLine;

export let wait10 = async () => {
  // Симуляция проверки, которая занимает некоторое время
  return new Promise((resolve) => setTimeout(resolve, 7000));
};

export let handleError = (action, err, isOffline, next) => {
  if (offline()) return offlineToast(), next && next();
  if (next) next();
  let lang = localLang();
  callAlert(...TRNS.HNDLERR[lang](action, err));
};

export let saveButnText = (nochange, rus = localRus()) =>
  nochange ? (rus ? "Назад" : "Go back") : rus ? "Сохранить" : "Save";
