import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { useAuth, useClient } from "../commons/Stores";
import {
  ages,
  callAlert,
  copytext,
  deskPadding,
  offline,
} from "../commons/utils";
import {
  SocIcon,
  UserPic,
  Text14,
  Row,
  RowCentered,
  Press,
  GRAY,
  DGRAY,
  Text18,
  SettingsIcon,
  Touch,
  shadow4,
  Text16,
  BLUE,
  Medium14,
} from "../commons/UI";

export default observer(({ nav, setLoad }) => {
  const { navigate } = nav,
    { profile: p, balance, rus, lang } = useAuth(),
    { handleLogout } = useClient(),
    { phone, bio, age, email } = p,
    hasBalance = Object.keys(p.balance || {}).length,
    [showSets, setShowSets] = useState(false),
    toggleSets = () => setShowSets(!showSets);

  useEffect(() => {
    const onblur = nav.addListener("blur", () => setShowSets(false));
    return () => onblur();
  }, []);

  if (!p) return null;

  const askLogout = () => {
    let proceed = () => {
      if (offline()) return offlineToast();
      setLoad(true);
      handleLogout();
    };

    return callAlert(
      rus ? "Выйти из аккаунта?" : "Log out?",
      null,
      [
        {
          label: rus ? "Да, выйти" : "Log out",
          onClick: proceed,
        },
        { label: rus ? "Закрыть" : "Close", onClick: toggleSets },
      ],
      "strict"
    );
  };

  return (
    <>
      <Press onPress={toggleSets}>
        <Wrap>
          <UserPic big {...p} style={{ height: 124 }} />
          <Body>
            <RowCentered>
              <Text18 style={{ flex: 1 }} numberOfLines={1}>
                {p.name}
              </Text18>
              <SettingsIcon style={{ marginLeft: 20 }} />
            </RowCentered>
            <Bio numberOfLines={1} selectable>
              {ages[lang].find((a) => a.id >= age).name + " " + (bio || "")}
            </Bio>

            <Row>
              <Touch
                onPress={
                  phone
                    ? toggleSets
                    : () => navigate("EditProfile", { phone: "1" })
                }
              >
                <Bio style={{ color: GRAY }} numberOfLines={1} selectable>
                  {phone || (rus ? "добавить контакт" : "add contact")}
                </Bio>
              </Touch>
            </Row>

            <Row>
              <Touch onPress={() => navigate("Balance")}>
                <BalanceWrap
                  style={
                    hasBalance ? { backgroundColor: BLUE } : { borderWidth: 1 }
                  }
                >
                  <Medium14 style={{ color: hasBalance ? "white" : BLUE }}>
                    {(rus ? "Баланс " : "Balance ") + balance}$
                  </Medium14>
                </BalanceWrap>
              </Touch>
            </Row>
          </Body>
        </Wrap>
      </Press>

      {showSets && (
        <Press
          style={{ ...StyleSheet.absoluteFill, zIndex: 10 }}
          onPress={toggleSets}
        >
          <SettingsView style={settsStyle}>
            <Touch onPress={() => copytext(email)}>
              <RowCentered style={{ paddingVertical: 10 }}>
                <SocIcon {...p} hasback />
                <Email numberOfLines={1}>{email}</Email>
              </RowCentered>
            </Touch>
            <Touch onPress={() => navigate("EditProfile")}>
              <Setting>{rus ? "Редактировать" : "Edit profile"}</Setting>
            </Touch>
            <Touch onPress={askLogout}>
              <Setting>{rus ? "Выйти" : "Log out"}</Setting>
            </Touch>
          </SettingsView>
        </Press>
      )}
    </>
  );
});

let settsStyle = {
  marginRight:
    deskPadding > 220 ? deskPadding - 220 : deskPadding ? deskPadding + 32 : 56,
};

export let SettingsView = styled.View({
    backgroundColor: "white",
    alignSelf: "flex-end",
    minWidth: 180,
    maxWidth: 240,
    borderRadius: 18,
    padding: "12px 24px",
    marginTop: 24,
    marginRight: 24,
    zIndex: 100,
    ...shadow4,
  }),
  Setting = styled(Text16)`
    padding: 14px 0;
  `;

let Wrap = styled(Row)`
    padding-right: 24px;
    margin-right: -24px;
  `,
  Body = styled.View`
    flex: 1;
    flex-shrink: 1;
    margin-top: 4px;
  `,
  Bio = styled(Text14)`
    color: ${DGRAY};
    margin-top: 5px;
  `,
  Email = styled(Text14)`
    color: ${GRAY};
    max-width: 160px;
  `,
  BalanceWrap = styled.View`
    justify-content: center;
    align-items: center;
    height: 36px;
    border-radius: 22px;
    border: 0 solid ${BLUE};
    padding: 0 16px;
    margin-top: 12px;
  `;
