import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { View, ScrollView } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { onSnapshot } from "firebase/firestore";
import {
  dbrefCoachGroups,
  useAuth,
  useCart,
  useClient,
  useSchool,
} from "../commons/Stores";
import { ages as ages0, classTypeDesc } from "../commons/utils";
import {
  Loader,
  PageTitle,
  QuantButton,
  DGRAY,
  BLUE,
  Press,
  EmptyText,
  Button,
  Medium24,
  Divider40,
  Text16,
  GRAY,
  Medium16,
  Text18,
  Container,
} from "../commons/UI";
import { DatesEventsList } from "../comp/Dates";
import { findNearestPrivat, nearestText } from "../comp/CoachBody";
import { CoachCartButton } from "./Coach";
import { translates } from "../translates";

const CoachProduct = observer(
  ({
    navigation: { navigate, push, setOptions },
    route: {
      params: { coachID },
    },
    privat,
  }) => {
    const { lang, rus, hhfrmt } = useAuth(),
      {
        coaches: { [coachID]: c },
      } = useSchool(),
      { getCart } = useCart(),
      { name } = c || {};

    useLayoutEffect(() => {
      if (name) {
        let title = TTL1[lang](name, privat);
        setOptions({
          title: title + " – BLOSS.AM",
          headerTitle: () => (
            <Text18 style={{ color: DGRAY }} numberOfLines={1}>
              {title}
            </Text18>
          ),
        });
      }
    }, [!name]);

    if (!c || c.status !== "approved") return null;

    const loaded = privat && !!c.slots,
      nearest = loaded && findNearestPrivat(c),
      hasCart = getCart(coachID).length,
      canReadMore = !!privat;

    return (
      <Container>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            padding: 24,
            paddingTop: 32,
            paddingBottom: hasCart ? 60 + 24 + 32 : 32,
          }}
        >
          <PageTitle>{TTL2[lang](privat)}</PageTitle>

          <Press
            onPress={
              canReadMore ? () => navigate("AddInfo", { privats: true }) : null
            }
          >
            <Desc selectable>
              {classTypeDesc(privat, 0, null, lang)}
              {canReadMore && (
                <Desc style={{ color: BLUE }}>{GETDIFF[lang]}</Desc>
              )}
              .
            </Desc>

            {canReadMore && (
              <Desc style={{ marginTop: 8 }}>
                <MiniPrice>
                  {c.grPrice + (rus ? "$ в час " : "$ per hour ")}
                </MiniPrice>
                {PRCTERM[lang]}
              </Desc>
            )}
          </Press>

          <PriceRate>
            {privat ? c.price : c.grPrice}
            {rus ? "$ в час" : "$ per hour"}
          </PriceRate>

          {privat &&
            (loaded ? (
              <Button
                big
                text={PRVBTN[lang]}
                onPress={() => navigate("CustomEvent", { coachID })}
                style={{ marginTop: 24 }}
              />
            ) : (
              <Loader big />
            ))}

          {nearest && (
            <CenterDesc>
              {rus ? "Ближайшее: " : "Nearest time: "}
              {nearestText(nearest, hhfrmt, rus)}
            </CenterDesc>
          )}

          {privat ? (
            <CustomBooks {...{ coachID }} />
          ) : (
            <CoachGroups {...{ coachID, push }} />
          )}
        </ScrollView>

        <CoachCartButton {...{ coachID, navigate }} />
      </Container>
    );
  }
);

export default CoachProduct;

export const CoachPrivats = (pr) => <CoachProduct {...pr} privat />;

const CustomBooks = observer(({ coachID }) => {
  const { meCreatedActiveBooks } = useClient(),
    books = meCreatedActiveBooks.filter((e) => e.coachID === coachID);

  if (!books[0]) return null;

  return (
    <>
      <Divider40 style={{ marginTop: 48 }} />
      <DatesEventsList events={books} books />
    </>
  );
});

const CoachGroups = observer(({ coachID, push }) => {
  const mount = useRef(true),
    { myid, lang, rus, age: myage } = useAuth(),
    {
      groupsArr,
      handleDBCoachGroups,
      filter: { age: age0 },
    } = useSchool();

  const ages = ages0[lang],
    groups = groupsArr.filter(
      (e) => e.coachID === coachID && (myid ? e.clientCreated !== myid : true)
    ),
    [age, setAge] = useState(
      age0 || myage
        ? ages.find((a) => a.id >= (age0 || myage)).id
        : groups[0]
        ? groups[0].age
        : 14
    ),
    filtered = groups.filter((e) => e.age === age),
    ageIndex = ages.findIndex((a) => age <= a.id),
    [load, setLoad] = useState(true);

  const handleDB = (q) => {
    if (mount.current) handleDBCoachGroups(q, coachID, myid), setLoad(false);
  };

  useEffect(() => {
    const listener = onSnapshot(dbrefCoachGroups(coachID), handleDB);
    return () => ((mount.current = false), listener());
  }, []);

  return (
    <View>
      <Divider40 />
      <PageTitle>{rus ? "Групповые занятия" : "Group classes"}</PageTitle>
      {load && !groups[0] && <Loader style={{ marginTop: 28 }} />}

      {groups[0] && (
        <QuantButton
          text={(rus ? "Возраст: " : "Age: ") + ages[ageIndex].name}
          plus={age < 36 ? () => setAge(ages[ageIndex + 1].id) : null}
          minus={age > 5 ? () => setAge(ages[ageIndex - 1].id) : null}
          style={{ marginTop: 24 }}
        />
      )}

      {filtered[0] && (
        <DatesEventsList
          gray
          navigate={push}
          events={filtered}
          style={{ marginTop: 12 }}
        />
      )}

      {!load && !filtered[0] && (
        <EmptyText style={{ marginTop: 30 }}>
          {NOGRP[lang](groups[0])}
        </EmptyText>
      )}
    </View>
  );
});

let Desc = styled(Text16)`
    color: ${DGRAY};
    line-height: 24px;
    margin-top: 16px;
  `,
  MiniPrice = styled(Medium16)`
    color: ${DGRAY};
    line-height: 24px;
  `,
  CenterDesc = styled(Text16)`
    color: ${GRAY};
    text-align: center;
    margin-top: 16px;
  `,
  PriceRate = styled(Medium24)`
    color: ${DGRAY};
    margin-top: 20px;
  `;

let { TTL1, TTL2, PRVBTN, PRCTERM, GETDIFF, NOGRP } = translates.Product;
