import { initializeApp } from "firebase/app";
import {
  initializeAuth,
  browserLocalPersistence,
  getAuth,
  browserSessionPersistence,
  setPersistence,
} from "firebase/auth";

let firebaseConfig = {
  apiKey: "AIzaSyCvv4YF7T8gCbd97ZrbZ-7LPNQI0k4HCHA",
  authDomain: "app.bloss.am", // "rgonline-1d3e0.firebaseapp.com", //  "localhost:19006",
  // Add the new authDomain to your OAuth provider's list of authorized redirect URIs.
  // How you do this will depend on the provider, but in general you can follow the "Before you begin" section in any provider for exact instructions (for example, the Facebook provider).
  //  The updated URI to authorize looks like https://<the-domain-that-serves-your-app>/__/auth/handler — the trailing /__/auth/handler is important
  databaseURL:
    "https://rgonline-1d3e0-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "rgonline-1d3e0",
  storageBucket: "rgonline-1d3e0.appspot.com",
  messagingSenderId: "339329916104",
  appId: "1:339329916104:web:5b7318cd1b9e7af58b59fd",
  measurementId: "G-XDN62S8NVN",
};

// Initialize Firebase
let app = initializeApp(firebaseConfig);
export default app;
export let auth = getAuth(app);
// setPersistence(auth, browserSessionPersistence);
