import React, { useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { useAuth } from "../commons/Stores";
import {
  getSuprtAppeal,
  deskPadding,
  openRefundPlcy,
  openGooglePlay,
  openAppStore,
  openURL,
  wwidth,
  rdb,
} from "../commons/utils";
import {
  Container,
  BlankView,
  SocButtons,
  AbsLoader,
  Touch,
  GRAY,
  Row,
  GooglePlayIcon,
  AppleIcon,
  LINEGRAY,
  Press,
  Text16,
  BORDERGRAY,
  LITEBORDER,
  VerticalDots,
  BlankText,
  BLUE,
  Text14,
} from "../commons/UI";
import { translates } from "../translates";
import { Setting } from "../comp/ProfileHeader";

export let rowView = deskPadding > 224;

let lang = "ru";
export default observer(({ navigation: nav }) => {
  const { appleLogin, googleLogin, load, rus } = useAuth(),
    browsr = String(navigator.userAgent).toLowerCase(),
    [isWebview] = useState(browsr.includes("wv") || browsr.includes("webview"));

  const LegalsComp = isWebview ? <Legals support /> : <Legals login />;

  return (
    <Container>
      <LoginView>
        <Touch onPress={() => nav.navigate("LangPicker")}>
          <Setting style={{ textAlign: "right" }}>{LANG[lang]}</Setting>
        </Touch>
        <BlankView style={{ marginBottom: -100 }}>
          {isWebview ? (
            <BlankText style={{ marginBottom: 16 }}>
              {WEBVIEW[lang](<VerticalDots />)}
            </BlankText>
          ) : (
            <SocButtons {...{ appleLogin, googleLogin, rus }} />
          )}

          {!rowView && LegalsComp}
        </BlankView>

        {rowView && LegalsComp}

        {!rowView && <AppPromo login notext={isWebview} />}
      </LoginView>
      {rowView && <AppPromo />}
      {load && <AbsLoader />}
    </Container>
  );
});

export const Legals = observer(({ cart, freeCancel, support, profile }) => {
  const { myid } = useAuth(),
    rus = lang === "ru",
    isone = freeCancel || support;

  let TextComp = cart ? CartText : isone ? BlueText16 : Text;

  let Item = (pr) => (
    <Touch
      onPress={
        pr.onPress ||
        (() => openURL("https://bloss.am/" + (pr.terms ? "terms" : "privacy")))
      }
      style={pr.style}
    >
      <TextComp style={pr.small && { paddingTop: 8 }}>{pr.text}</TextComp>
    </Touch>
  );

  if (freeCancel) return <Item terms text={REFNDPOL[lang]} />;

  if (support)
    return (
      <Item onPress={() => getSuprtAppeal({ myid }, lang)} text={CNTC[lang]} />
    );

  if (cart) {
    return (
      <Row style={{ marginTop: 8 }}>
        <Item terms text={TERMS[lang]} style={{ flex: 1 }} />
        <Item text={PRIV[lang]} style={{ flex: 1 }} />
      </Row>
    );
  }

  if (profile) {
    let style = { style: { flex: 1, paddingHorizontal: 2 } };
    return (
      <Row style={{ marginTop: 24 }}>
        <Item
          onPress={openRefundPlcy}
          text={REFNDPOL[lang].replace(" ", "\n")}
          {...style}
        />
        <Item
          terms
          text={rus ? TERMS.ru.replace(" ", "\n") : "Terms of\nservice"}
          {...style}
        />
        <Item text={PRIV[lang].replace(" ", "\n")} {...style} />
      </Row>
    );
  }

  // else are LOGIN screen

  return (
    <Row
      style={{
        width: rowView ? undefined : wwidth - 48,
        marginTop: 32,
      }}
    >
      <View style={{ flex: 1 }}>
        <Item
          onPress={() => getSuprtAppeal({ myid }, lang)}
          text={CNTSPRT[lang]}
        />
        <Item small={rus} terms text={TERMS[lang]} />
      </View>
      <View style={{ flex: 1 }}>
        <Item text={REFNDPOL[lang]} onPress={openRefundPlcy} />
        <Item text={PRIV[lang]} small={rus} />
      </View>
    </Row>
  );
});

export const AppPromo = observer(({ book, profile, notext, ...r }) => {
  const { lang } = useAuth(),
    Wrap = rowView && !book ? DesctopAppPromoView : View,
    showDivider = book || (profile && !rowView);

  let Divider = showDivider && (
    <DivideLine
      style={[
        book && { marginTop: 0 },
        profile && { backgroundColor: BORDERGRAY },
      ]}
    />
  );

  return (
    <>
      {book && Divider}
      <Wrap style={{ marginTop: 40, minHeight: notext ? 64 : 120, ...r.style }}>
        <Row>
          <StoreView onPress={openAppStore}>
            <AppleIcon width={25} height={30} style={{ marginTop: -3 }} />
          </StoreView>
          <StoreView onPress={openGooglePlay}>
            <GooglePlayIcon width={26} height={27} style={{ marginLeft: 3 }} />
          </StoreView>
        </Row>
        {!notext && <PromoText>{APPSPROMO[lang]}</PromoText>}
      </Wrap>
      {!book && Divider}
    </>
  );
});

let LoginView = styled.View`
    flex: 1;
    padding: 16px ${deskPadding + 24}px;
  `,
  Text = styled(Text14)`
    color: ${GRAY};
    text-align: center;
    flex-shrink: 0;
    padding: 12px 0;
  `,
  CartText = styled(Text)`
    text-align: left;
  `,
  BlueText16 = styled(Text)`
    font-size: 16px;
    color: ${BLUE};
  `,
  StoreView = styled(Press)`
    width: 54px;
    height: 54px;
    background: ${LINEGRAY};
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
  `,
  DesctopAppPromoView = styled.View`
    position: absolute;
    left: 24px;
    bottom: 24px;
    width: ${deskPadding > 348 ? 300 : deskPadding - 2 * 24}px;
  `,
  PromoText = styled(Text16)`
    font-size: 14px;
    color: ${GRAY};
    margin-top: 16px;
  `,
  DivideLine = styled.View`
    background: ${LITEBORDER};
    height: 1px;
    margin-top: 32px;
  `;

let { CNTSPRT, REFNDPOL } = translates,
  { TERMS, PRIV, CNTC, LANG, APPSPROMO, WEBVIEW } = translates.Login;
