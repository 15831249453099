import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Keyboard, ScrollView, View } from "react-native";
import styled from "styled-components/native";
import rstyled from "styled-components";
import { observer } from "mobx-react-lite";
import { useAuth, useClient } from "../commons/Stores";
import {
  ages as ages0,
  showToast,
  wwidth,
  colors,
  isDesktop,
  paddBottom,
  launchImagePicker,
  uploadMedia,
  issame,
  offline,
  offlineToast,
  saveButnText,
} from "../commons/utils";
import {
  Button,
  AbsLoader,
  InputComp,
  Caption,
  QuantButton,
  Body,
  BlankUserChar,
  Container,
  Row,
  Touch,
  RemoveCircle,
  CardImage,
  PlusIcon,
  Text14,
  LogOut,
  RowCentered,
  SocIcon,
  Medium18,
} from "../commons/UI";
import { translates } from "../translates";
import { LangPickerComp } from "./AddInfo";

let toast = (tx) => showToast(tx, 0, 120);

export default observer(({ navigation: nav, route: r }) => {
  const {
      lang: lang0,
      rus,
      profile: prf,
      updateFields,
      addDBUser,
      setAfterLogin,
    } = useAuth(),
    {
      uid,
      name: name0,
      photo: photo0,
      bio: bio0,
      age: age0,
      phone: phone0,
      provider,
    } = prf;

  if (!uid)
    return (
      nav.navigate("ProfileStack", { screen: "Login" }),
      setAfterLogin("EditProfile")
    );

  const [lang, setLang] = useState(lang0 || "en"),
    [color] = useState(prf.color || colors[Math.round(Math.random() * 27)]),
    [name, setName] = useState(name0 || ""),
    [photo, setPhoto] = useState(photo0 || ""),
    [bio, setBio] = useState(bio0 || ""),
    [phone, setPhone] = useState(String(phone0 || "")),
    [age, setAge] = useState(age0 || 8),
    ages = ages0[lang],
    ageIndex = ages.findIndex((a) => age <= a.id),
    [load, setLoad] = useState(),
    [nameref, telref, bioref] = [useRef(), useRef(), useRef()],
    [trname, trphoto, trbio, trphone] = trm([name, photo, bio, phone]);

  useLayoutEffect(() => {
    if (age0)
      nav.setOptions({
        headerStyle: { backgroundColor: color },
        headerTitle: () => (
          <Medium18 numberOfLines={1} style={{ color: "white" }}>
            {HDRTTL[lang]}
          </Medium18>
        ),
      });
  }, []);

  const noChange =
    issame(trname, name0) &&
    issame(trphoto, photo0) &&
    issame(trbio, bio0) &&
    age == age0 &&
    issame(trphone, phone0);

  const pickImage = async () => {
    let uri = await launchImagePicker();
    if (uri) setPhoto(uri);
  };

  const SignupHeader = (
    <HeaderRow style={{ backgroundColor: color }}>
      <RowCentered style={{ flex: 1, paddingTop: 2 }}>
        <SocIcon hasback {...{ provider }} />
        <Text14 style={{ color: "white" }} numberOfLines={1}>
          {prf.email}
        </Text14>
      </RowCentered>
      <LogOut white />
    </HeaderRow>
  );

  const save = async () => {
    if (noChange) return nav.goBack();
    Keyboard.dismiss();
    if (load) return;

    if (!trname[1]) return toast(TST1[lang]);
    if (!trphone[0]) return toast(TST2[lang]);
    if (!trphone[5]) return toast(TST3[lang]);
    if (trbio[0] && !trbio[2]) return toast(TST4[lang]);
    if (trphoto[0] && !trphoto[9]) return toast(TST5[lang]);

    if (offline()) return offlineToast();

    let imageIslocal = trphoto.includes("data:"); // && trphoto.substring(0, 4) !== 'http';
    setLoad(imageIslocal ? 2 : 1);

    let imageUrl = imageIslocal
      ? await uploadMedia(`avatars/${uid}`, trphoto, setLoad)
      : trphoto || null;

    if (offline()) return offlineToast();

    let obj = {
      lang,
      name: trname,
      phone: trphone,
      age,
      color,
      photo: imageUrl,
      bio: trbio || null,
    };

    if (!age0) return addDBUser({ ...prf, ...obj });
    updateFields(obj, nav.goBack);
  };

  return (
    <Container>
      {!age0 && SignupHeader}
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{ flexGrow: 1 }}
        style={{ backgroundColor: color }}
      >
        <BackColor style={{ backgroundColor: color }}>
          {!trphoto[9] && (
            <Touch onPress={pickImage}>
              <AddImageRow>
                <BlankUserChar style={{ fontSize: 0.27 * wwidth }}>
                  {name[0]?.toUpperCase() || "a"}
                </BlankUserChar>
                <PlusIcon color="white" height={2} width={2} size={40} />
              </AddImageRow>
            </Touch>
          )}
        </BackColor>

        {trphoto[9] && (
          <ImageBack>
            <ImageBack2 style={{ backgroundColor: color }} />
            <Touch onPress={pickImage}>
              <Image
                style={{
                  backgroundColor: color,
                  backgroundImage: `url(${trphoto})`,
                }}
              >
                <RemoveCircle
                  onPress={() => setPhoto("")}
                  style={{ position: "absolute", right: 0, margin: 14 }}
                />
              </Image>
            </Touch>
          </ImageBack>
        )}

        <Body
          style={{
            paddingTop: age0 ? 16 : 40,
            paddingBottom: age0 ? 40 : 40 + paddBottom,
          }}
        >
          <View style={{ flex: 1 }}>
            {!age0 && (
              <>
                <Caption>{rus ? "Ваш язык" : "Your language"}</Caption>
                <Row>
                  <LangPickerComp
                    small
                    onpick={setLang}
                    style={{ width: 176, marginTop: 12 }}
                  />
                </Row>
              </>
            )}

            <InputComp
              caption={rus ? "Ссылка на фото" : "Photo link"}
              defaultValue={photo}
              onChangeText={setPhoto}
              onSubmitEditing={() => nameref.current.focus()}
              blurOnSubmit={false}
              returnKeyType="next"
              style={{ marginTop: 28 }}
            />
            <InputComp
              reff={nameref}
              caption={rus ? "Имя" : "Name"}
              defaultValue={name}
              onChangeText={setName}
              onSubmitEditing={() => telref.current.focus()}
              blurOnSubmit={false}
              returnKeyType="next"
              autoFocus={!age0}
              style={{ marginTop: 28 }}
            />
            <InputComp
              reff={telref}
              caption={INP1[lang]}
              placeholder={(rus ? "напр. " : "f.e. ") + "WhatsApp 85212345678"}
              defaultValue={phone}
              onChangeText={setPhone}
              onSubmitEditing={() => bioref.current.focus()}
              blurOnSubmit={false}
              returnKeyType="next"
              autoFocus={!!r.params?.phone}
              style={{ marginTop: 28 }}
            />
            <InputComp
              reff={bioref}
              caption={INP2[lang]}
              placeholder={INP3[lang]}
              defaultValue={bio}
              onChangeText={setBio}
              inputStyle={{ height: 72 }}
              multiline
              style={{ marginTop: 28 }}
            />

            <Caption style={{ marginTop: 28 }}>{AGECAP[lang]}</Caption>
            <QuantButton
              text={ages[ageIndex].name}
              plus={age < 36 ? () => setAge(ages[ageIndex + 1].id) : null}
              minus={age > 5 ? () => setAge(ages[ageIndex - 1].id) : null}
              style={{ marginTop: 16 }}
            />
          </View>

          <Button
            big // ={!age0}
            text={saveButnText(noChange, rus)}
            onPress={save}
            style={{ marginTop: 48 }}
          />
        </Body>
      </ScrollView>
      {load && <AbsLoader text={load === 2 && SVIMG[lang]} />}
    </Container>
  );
});

let trm = (arr) => arr.map((t) => t.trim()),
  imgwidth = isDesktop ? 300 : wwidth - 2 * 24 - 2 * 3;

let BackColor = styled.View({
    height: imgwidth + 40,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 24,
    marginBottom: -24,
  }),
  ImageBack = styled.View({
    position: "absolute",
    top: 24,
    left: 24,
    width: imgwidth + 6,
    height: imgwidth + 6,
    backgroundColor: "#fff",
    borderRadius: 17,
    zIndex: 1,
    overflow: "hidden",
  }),
  ImageBack2 = styled.View({
    position: "absolute",
    width: imgwidth + 6,
    height: imgwidth + 6,
    opacity: 0.4,
  }),
  Image = rstyled(CardImage)`
  width: ${imgwidth}px;
  height: ${imgwidth}px;
  margin: 3px;
  z-index: 10;
  `,
  AddImageRow = styled(Row)`
    align-items: flex-end;
    margin-right: -40px;
  `,
  HeaderRow = styled(RowCentered)`
    border-color: rgba(255, 255, 255, 0.3);
    border-bottom-width: 1px;
    padding-left: 16px;
  `;

function PhoneTextInput({ onChange, ...rest }, ref) {
  const onChangeText = useCallback(
    (value) => {
      onChange({
        preventDefault() {
          this.defaultPrevented = true;
        },
        target: { value },
      });
    },
    [onChange]
  );
  return <InputComp reff={ref} {...{ onChangeText, ...rest }} />;
}
PhoneTextInput = React.forwardRef(PhoneTextInput);

let { HDRTTL, TST1, TST2, TST3, TST4, TST5, INP1, INP2, INP3, AGECAP, SVIMG } =
  translates.EditProfile;
