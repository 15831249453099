import { makeAutoObservable, runInAction } from "mobx";
import {
  getDoc,
  writeBatch,
  deleteField,
  arrayRemove,
  onSnapshot,
  increment,
} from "firebase/firestore";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import { navigate, getRoute } from "./RootNavigation";
import {
  db,
  dbCoaches,
  dbEvents,
  dbOrders,
  dbPassed,
  dbPasses,
  dbUsers,
  dbQueryToObj,
  getDay,
  tabbarHeight,
  handleRoutesCheck,
  resetStackRoute,
  parseBalance,
  callAlert,
  checkCantrial,
  vibroToast,
  durtnText,
  parseChangeText,
  groupBy,
  formhh,
  localStor,
  isactivePass,
  parsePackage,
  parseOrder,
  device,
  offline,
  offlineToast,
  issame,
  rootNavEvent,
} from "./utils";
import {
  dbbatchOrderEvents,
  orderChecks,
  prebuyPackageChecks,
  paidPassUpdates,
} from "./orderChecks";
import { translates } from "../translates";

let ifmyActivePass = (p, myid) => isactivePass(p) && p.uid == myid;

export default class Client {
  privats = {};
  orders = {};
  passed = {}; // passed events
  passes = {};
  load = true;
  ordersLoad = true;

  constructor(school, auth) {
    makeAutoObservable(this);
    this.school = school;
    this.auth = auth;
  }

  get lang() {
    return this.auth.lang;
  }
  get rus() {
    return this.auth.rus;
  }
  get myid() {
    return this.auth.myid;
  }
  get profile() {
    return this.auth.profile;
  }
  get balance() {
    return this.auth.balance;
  }
  get canTrial() {
    return this.auth.canTrial;
  }
  get time24() {
    return this.auth.time24;
  }
  get hhfrmt() {
    return this.auth.hhfrmt;
  }

  get coaches() {
    return this.school.coaches;
  }
  get programs() {
    return this.school.programs;
  }
  get groups() {
    return this.school.groups;
  }

  get privatsArr() {
    return Object.values(this.privats);
  }

  get activePrivats() {
    return orderBy(
      this.privatsArr.filter((e) => e.active && e.to > Date.now()),
      "from"
    );
  }

  get allActiveEvents() {
    return orderBy(this.school.groupsArr.concat(this.activePrivats), "from");
  }

  get allBooksArr() {
    return this.load
      ? []
      : orderBy(
          Object.values(this.groups || {})
            .filter((e) => e?.clientsIds?.includes(this.myid))
            .concat(
              this.privatsArr.filter((e) => e?.clientsIds?.includes(this.myid))
            ),
          "from"
        );
  }

  get books() {
    return this.allBooksArr.reduce((pr, e) => ({ ...pr, [e.id]: e }), {});
  }

  get activeBooks() {
    return this.allBooksArr.filter((e) => e?.active);
  }

  get meCreatedActiveBooks() {
    return this.myid
      ? this.activeBooks.filter((e) => e?.clientCreated == this.myid)
      : [];
  }

  get nearestBook() {
    return this.activeBooks[0];
  }

  get booksQuant() {
    return this.allBooksArr.length;
  }

  get passedArr() {
    return orderBy(
      Object.values(this.passed).filter((e) =>
        e.clientsIds?.includes(this.myid)
      ),
      "from",
      "desc"
    );
  }

  get comments() {
    return orderBy(
      this.passedArr.filter((e) => !!e.coachComment),
      "to",
      "desc"
    );
  }

  get lastComment() {
    return this.comments[0];
  }

  get lastPassed() {
    return this.passedArr[0];
  }

  get passedQuant() {
    return this.passedArr.length;
  }

  get lastOrder() {
    return orderBy(
      Object.values(this.orders).filter((o) => o.client == this.myid),
      "created",
      "desc"
    )[0];
  }

  get hasUnpaidOrder() {
    let { lastOrder: o } = this;
    if (!o) return false;
    let { canbuy } = parseOrder(o);
    return canbuy && o;
  }

  get passesArr() {
    return orderBy(
      Object.values(this.passes).filter((p) => p.uid == this.myid),
      "created",
      "desc"
    );
  }

  get activePassesArr() {
    return orderBy(
      this.passesArr.filter((p) => ifmyActivePass(p, this.myid)),
      "to"
    );
  }

  get activePasses() {
    return this.activePassesArr.reduce((pr, e) => ({ ...pr, [e.id]: e }), {});
  }

  get lastPackage() {
    return this.passesArr[0];
  }

  get hasUnpaidPackage() {
    let { lastPackage: p } = this;
    if (!p) return false;
    let { canbuy } = parsePackage(p, this.myid);
    return canbuy && p;
  }

  get packageAllPasses() {
    let { passesArr: arr } = this;
    if (!arr[0]) return {};
    return groupBy(arr, "packID");
  }

  get packageActivePass() {
    let data = this.packageAllPasses,
      packIds = Object.keys(data),
      res = {};
    if (!packIds[0]) return {};

    packIds.forEach((id) => {
      let actives = data[id].filter((p) => ifmyActivePass(p, this.myid)),
        [pass] = orderBy(actives, "to", "desc");
      if (pass) res[id] = pass;
    });

    return res;
  }

  get activePassesCoaches() {
    let { activePassesArr: arr } = this;
    if (!arr[0]) return {};
    return groupBy(arr, "coachID");
  }

  get avlblPackages() {
    let { myid } = this,
      all = this.school.packagesArr.filter((p) => {
        let { type, uids } = p.limitUsers || {};
        if (!uids) return true;

        let includeMe = uids.includes(myid || "00");
        return type == "allow" ? includeMe : !includeMe;
      });

    let [allowed, common] = [[], []];

    all.forEach((p) =>
      p.limitUsers?.type == "allow" ? allowed.push(p) : common.push(p)
    );

    let sortedCommon = common[1] ? orderBy(common, "price") : common;

    if (!allowed[0]) return sortedCommon;

    // need to show only 1 latest package with 'allow'-users limit per coach
    let groupsBycoach = groupBy(allowed, "coachID"),
      filterAllowed = [];

    for (let cid in groupsBycoach) {
      let arr = groupsBycoach[cid];
      let [last] = arr[1] ? orderBy(arr, "time", "desc") : arr;
      filterAllowed.push(last);
    }

    let sortedAllowed = orderBy(filterAllowed, "time", "desc");

    return sortedAllowed.concat(sortedCommon);
  }

  get avlblPackageOffers() {
    let { avlblPackages: arr, myid, activePassesArr: passes } = this;
    if (!arr[0]) return [];
    if (!myid || !passes[0]) return arr;

    let passPacks = new Set(passes.map((e) => e.packID));
    return arr.filter((e) => !passPacks.has(e.id));
  }

  get firstPackageOffer() {
    let { myid, avlblPackageOffers: arr } = this,
      [first] = arr;
    if (!myid || !first) return null;

    if (first.mainHidden || localStor.getItem("mainHiddenPack-" + first.id))
      return null;

    return first.limitUsers?.type == "allow" ? first : null;
  }

  get avlblPackageOffersBycoach() {
    let { avlblPackageOffers: arr } = this;
    if (!arr[0]) return {};
    return groupBy(arr, "coachID");
  }

  setLoad = (val) => (this.load = val || null);
  setOrdersLoad = (val) => (this.ordersLoad = val || null);
  checkLocalBook = (id, passed) => (passed ? this.passed[id] : this.books[id]);

  setEvent = (e) => {
    if (e.from) e.day = getDay(e.from);
    return e.id in this.privats ||
      (e.privat && e.clientsIds.includes(this.myid))
      ? (this.privats[e.id] = { ...this.privats[e.id], ...e })
      : this.school.updateGroup(e);
  };

  deleteEvent = (id) =>
    id in this.privats ? delete this.privats[id] : this.school.deleteGroup(id);

  deleteBook = (id) => {
    if (id in this.privats) delete this.privats[id];
    else this.groups[id] && this.school.deleteMybook(id, this.myid);
  };

  setBooks = (obj = {}) => {
    let privats = {},
      groups = {};
    Object.values(obj).forEach((e) => {
      e.day = getDay(e.from);
      if (e.privat && e.id in this.groups) this.school.deleteGroup(e.id);
      return e.privat ? (privats[e.id] = e) : (groups[e.id] = e);
    });
    this.privats = privats;
    // if we can book someone else's private class, need { ...this.privats, ...privats }
    if (Object.keys(groups)[0]) this.school.addGroups(groups);
    this.setLoad(false);
  };

  markViewed = (id) => {
    let { [id]: curr } = this.books,
      batch = writeBatch(db);
    this.deleteEvent(id);
    batch.delete(dbEvents(id));
    batch.set(dbPassed(id), Object.assign({ viewed: true }, curr));
    batch.commit();
    if (curr.to < Date.now()) this.setPassed(curr);
  };

  setPassed = (e) => {
    if (e.from) e.day = getDay(e.from);
    return (this.passed[e.id] = e);
  };

  addPassedDBQuery = (q) =>
    (this.passed = { ...this.passed, ...dbQueryToObj(q) });

  setOrder = (obj) => (
    (this.orders[obj.id] = { ...this.orders[obj.id], ...obj }),
    this.setOrdersLoad(false)
  );

  getOrder = async (id) =>
    await getDoc(dbOrders(id)).then(
      (d) => d?.exists() && this.setOrder(d.data())
    );

  addOrdersDBQuery = (q) =>
    (this.orders = { ...this.orders, ...dbQueryToObj(q) });

  setPasses = (obj) => obj && (this.passes = { ...this.passes, ...obj });

  setPass = (obj) => (this.passes[obj.id] = { ...this.passes[obj.id], ...obj });

  getPass = async (id) => {
    if (!id) return;
    let d = await getDoc(dbPasses(id)),
      pass = d.data();
    if (d?.exists()) this.setPass(pass);
    return pass;
  };

  handlePassListener = (doc, onerror) => {
    let callback = (err) => (onerror && onerror(err), vibroToast(err));
    let d = doc.exists() && doc.data();

    if (!d || !d.active) callback(PASER[this.lang](!!d));
    if (!d) return {};
    this.setPass(d);
    return d;
  };

  dblistenPass = (id) =>
    onSnapshot(dbPasses(id), (d) => {
      if (d.exists()) this.setPass(d.data());
    });

  handleLogout = async () => {
    if (offline()) return offlineToast();
    await this.auth.logout();
    runInAction(() => ((this.privats = {}), (this.orders = {})));
    this.setLoad(true);
    this.setOrdersLoad(true);
  };

  buyPackage = async (coachID, id, next, onError0) => {
    let onError = onError0 ? onError0 : () => {};
    let { myid, lang, rus } = this,
      {
        packages: { [id]: currPack },
        setPackage,
        getCoach,
      } = this.school,
      checkData = await prebuyPackageChecks(
        currPack,
        myid,
        setPackage,
        getCoach,
        this.auth.checkDBUser
      ),
      { error: checkError, pack, paybyBalance } = checkData;

    if (offline()) return offlineToast();

    if (checkError) {
      if (onError) onError(checkError);
      let { alertButtons: btns } = checkData;
      return callAlert(BUYPCKG1[lang], checkError, btns);
    }

    let { price, duration, term, name, color, offerLimit } = pack,
      time = Date.now(),
      passID = myid.slice(0, 4) + "-" + time,
      batch = writeBatch(db);

    let pass = {
      id: passID,
      created: time,
      name,
      color,
      term,
      coachID,
      uid: myid,
      duration,
      price,
      packID: id,
      status: paybyBalance ? "paid" : "pending",
      device,
    };

    if (offerLimit) pass.offerLimit = offerLimit;

    if (paybyBalance)
      pass = { ...pass, ...paidPassUpdates(passID, duration, term) };

    batch.set(dbPasses(passID), pass);

    if (paybyBalance) {
      let coach = this.coaches[coachID].name,
        durtext = durtnText(duration, "f", rus);
      let desc =
        PASSPRCH[lang](name, coach) + `: ${durtext} ` + BUYPCKG3[lang](term);

      let balanceRec = { time, sum: -price, passID, desc };
      batch.update(dbUsers(myid), { [`balance.${time}`]: balanceRec });
    }

    let error;
    await batch.commit().catch((er) => (error = er));

    if (offline()) return offlineToast();
    if (error) return onError(), callAlert(...BUYPCKG4[lang]);

    this.setPass(pass);
    resetStackRoute("PassOrder", { passID, init: "1" });
    if (next) next();
  };

  createOrder = async (
    { cart, total: initSum, applyTrial: applyTrial0, passID, isReschedule },
    applyPayBalance,
    next,
    onError0
  ) => {
    let onError = onError0 || (() => {});
    let [{ coachID }] = cart,
      {
        myid,
        lang,
        profile,
        lastOrder,
        booksQuant,
        setOrder,
        balance: balance0,
      } = this,
      { name, phone, level: mylevel } = profile,
      uid = myid,
      time = Date.now(),
      orderID = myid.slice(0, 4) + "-" + time,
      events = {},
      totalDur = cart.reduce((pr, e) => pr + (e.to - e.from), 0) / 60000,
      balance = balance0,
      canTrial,
      pass,
      passDur;

    if (passID) {
      pass = await this.getPass(passID);
      passDur = pass?.durLeft || 0;
      let isactivePass = ifmyActivePass(pass, myid);

      if (!isactivePass || passDur < totalDur)
        return callAlert(...ORDPASER[lang](isactivePass));
    }

    if (!passID && (initSum > 0 || applyTrial0)) {
      let user = await this.auth.checkDBUser();
      balance = parseBalance(user.balance);
      ({ canTrial } = user);
    }

    if (offline()) return offlineToast(), onError && onError();

    let balanceChanged = !passID && balance !== balance0;

    let applyTrial = applyTrial0 && canTrial !== false;
    let doTrial =
      applyTrial && !booksQuant && (!lastOrder || lastOrder?.status !== "paid");

    // if applied for trial, need extra check
    if (applyTrial && !doTrial) {
      doTrial = await checkCantrial(lastOrder, myid, setOrder);
      if (!doTrial) {
        this.auth.updateFields({ canTrial: false });
        callAlert(...ORDTRL[lang]);
        return onError();
      }
    }

    if (offline()) return offlineToast(), onError && onError();

    let total = passID ? 0 : doTrial ? initSum - cart[0].client.sum : initSum,
      payBalance = !passID && balance >= total,
      paybyPass = pass && passDur >= totalDur,
      method = paybyPass ? "pass" : payBalance ? "balance" : null,
      paid = paybyPass || payBalance;

    cart.forEach(({ ...e }, ind) => {
      let orderNum = ind + 1;

      if (doTrial && !ind) {
        e.isTrial = true;
        e.privat = true;
        if (e.group) e.group = false;
        e.price = 0;
        e.client = { ...e.client, sum: 0, phone };
      }

      if (paybyPass) e.client = { ...e.client, sum: 0, passID };

      e.client = { ...e.client, uid, name, orderID, method, orderNum };
      e.progName = this.programs[e.progID].name;

      let id = e.custom ? orderID + "-" + orderNum : e.id;

      if (e.custom) {
        e.id = id;
        e.created = time;
        if (!e.privat) (e.clientCreated = myid), (e.clientCreatedRefund = 0);
      } else delete e.clients, delete e.clientsIds;

      events[id] = e;
    });

    let order = {
      id: orderID,
      created: time,
      coachID,
      client: myid,
      name,
      quant: cart.length,
      total,
      events,
      method,
      status: paid ? "paid" : "pending",
      time: paid ? time : null,
      passID: paybyPass ? passID : null,
      passName: paybyPass ? pass.name : null,
      device,
    };

    let batch = writeBatch(db);
    batch.set(dbOrders(orderID), order);

    // if pay by balance, handle events  db batch updates
    if (paid) {
      batch = dbbatchOrderEvents(events, batch);
      batch.update(dbUsers(myid), { lastOrder: time, canTrial: false });
    }

    if (paybyPass) {
      batch.update(dbPasses(passID), {
        lastUsed: time,
        durLeft: increment(-totalDur),
      });

      let handlePassEvent = (e) => {
        let { id: eid, from, to } = e,
          dur = (to - from) / 60000;
        let passUpd = {
          id: eid,
          isevent: true,
          time,
          orderID,
          from,
          to,
          duration: -dur,
        };
        batch.update(dbPasses(passID), { [`uses.${eid}`]: passUpd });
      };

      Object.values(events).forEach(handlePassEvent);
    }

    if (payBalance) {
      let blrec = { id: orderID, time, sum: -total, orderID };

      if (isReschedule) {
        let [{ id: event, from, to }] = cart;
        blrec = { ...blrec, event, from, to };
        blrec.desc = ORDBAL1[lang];
      }

      batch.update(dbUsers(myid), { [`balance.${time}`]: blrec });
    }

    let error;
    batch.commit().catch((er) => (error = er));

    if (error) {
      callAlert(ORDER1[lang], translates.NET[lang](error));
      return onError();
    }

    setOrder(order);
    resetStackRoute("Order", { orderID, init: "1" });
    if (next) next();

    // update coach slots
    if (paid)
      Object.values(events).forEach(({ id, from, to }) =>
        this.school.setCoachBusySlot(coachID, { id, from, to })
      );

    if (balanceChanged && applyPayBalance && !payBalance)
      callAlert(...ORDBAL2[lang]);

    let level = cart.find((e) => e.level)?.level,
      needUpdateLevel = level && level !== mylevel;
    if (needUpdateLevel) this.auth.updateFields({ level });
  };

  rescdleEvent = async (
    { clientNewSum, passID, passNewCharge, ...data },
    refund,
    onError0,
    next
  ) => {
    let onError = onError0 || (() => {});
    let { id, from, to } = data,
      newSlot = { id, from, to },
      {
        myid,
        lang,
        rus,
        books: { [id]: curr },
      } = this;

    if (!curr) return onError(), callAlert(RSCER1[lang], RSCERTXT[lang]);

    let {
        coachID,
        clients: { [myid]: client },
      } = curr,
      { orderID } = client,
      time = Date.now(),
      batch = writeBatch(db),
      dbref = dbEvents(id);

    data.edited = time;
    data.editedBy = "client";
    if (offline()) return offlineToast(), onError();

    let { changed } = await orderChecks(
      { [id]: Object.assign({ custom: true, coachID }, data) },
      (_, slot) => (data.slotID = slot), // for privats checks, update slotID if needed
      null,
      this.school.getCoach
    );

    if (offline()) return offlineToast(), onError();

    if (changed[0]) {
      let changeText = parseChangeText(changed[0].change, lang);
      return denyRescdle(changeText, lang, onError);
    }

    let fields = { ...data, edited: time, editedBy: "client" };

    batch.update(dbref, fields);

    batch.update(dbCoaches(coachID), {
      [`busy.${id}`]: newSlot,
      [`slots.${data.slotID}.busy.${id}`]: newSlot,
    });

    if (data.slotID !== curr.slotID)
      batch.update(dbCoaches(coachID), {
        [`slots.${curr.slotID}.busy.${id}`]: deleteField(),
      });

    batch.update(dbOrders(orderID), {
      [`events.${id}.time`]: time,
      [`events.${id}.reschedule`]: true,
      [`events.${id}.rescheduleBy`]: "client",
      [`events.${id}.updates.${time}`]: { time, from, to, refund },
      [`events.${id}.` + (passID ? "passRefund" : "refund")]:
        (passID ? passNewCharge : refund) || null,
    });

    let balncDesc = RSC2[lang];

    let balanceRec = {
      time,
      event: id,
      orderID,
      desc: balncDesc,
      prevFrom: curr.from,
      prevTo: curr.to,
      from,
      to,
    };

    if (passID) {
      // console.log('re-schedule time diff',
      balanceRec.duration = passNewCharge || 0; //(curr.to - curr.from - (to - from)) / 60000,      );
      batch.update(dbPasses(passID), {
        [`uses.${time}`]: balanceRec,
        lastUsed: time,
        durLeft: increment(passNewCharge || 0),
      });
    }

    if (!passID) {
      balanceRec.sum = refund || 0;
      batch.update(dbUsers(myid), { [`balance.${time}`]: balanceRec });
      batch.update(dbref, { [`clients.${myid}.sum`]: clientNewSum });
    }

    let error;
    await batch.commit().catch((er) => (error = er));

    if (error) return denyRescdle(error, lang, onError);
    if (next) next();
    this.school.setCoachBusySlot(coachID, newSlot);
  };

  cancelBook = async (
    { id, passID, isReschedule },
    applyRefund,
    next,
    onError0
  ) => {
    let onError = onError0 || (() => {});
    let { myid, lang, rus, deleteEvent, setEvent, deleteBook } = this,
      { [id]: book } = this.books,
      evref = dbEvents(id),
      doc = await getDoc(evref);

    if (offline()) return offlineToast(), onError();

    let e = doc.exists() && doc.data();
    if (!e?.active) {
      denyCancelling(rus ? "Занятие" : "The class", lang, next);
      return deleteEvent(id);
    }

    if (!e.clientsIds.includes(myid)) {
      denyCancelling(rus ? "Ваше бронирование" : "Your booking", lang, next);
      return deleteBook(id);
    }

    if (e.edited !== book?.edited) setEvent(e);

    const {
        coachID,
        from,
        to,
        privat,
        clients: { [myid]: client },
      } = e,
      privat1client = privat && !e.clientsIds[1],
      ishisGroup = !privat && e.clientCreated == myid;

    let { sum, orderID } = client,
      dur = (to - from) / 60000,
      bypass = passID || client.passID,
      orderRef = dbOrders(orderID);

    const time = Date.now(),
      batch = writeBatch(db),
      canRefund = applyRefund && from > Date.now() + (8 * 60 - 2) * 60000,
      refund = canRefund && sum && !bypass ? sum : 0,
      passRefund = canRefund ? dur : 0;

    if (applyRefund && !canRefund) return callAlert(RFND1[lang], RFND2[lang]);

    if (privat1client) {
      batch.delete(evref);
      batch.update(dbCoaches(coachID), {
        [`busy.${id}`]: deleteField(),
        [`slots.${e.slotID}.busy.${id}`]: deleteField(),
      });
    }

    if (!privat1client) {
      let evupd = {
        [`clients.${myid}`]: deleteField(),
        [`clientsIds`]: arrayRemove(myid),
      };
      if (ishisGroup)
        (evupd.clientCreated = null),
          (evupd.clientCreatedRefund = deleteField());

      batch.update(evref, evupd);
    }

    let orderDesc = isReschedule
      ? CNCLDSC1[lang]
      : CNCLDSC2[lang](privat1client);

    batch.update(orderRef, {
      [`events.${id}.active`]: false,
      [`events.${id}.time`]: time,
      [`events.${id}.cancelType`]: orderDesc,
      [`events.${id}.cancelBy`]: "client",
      [`events.${id}.` + (bypass ? "passRefund" : "refund")]:
        (bypass ? passRefund : refund) || null,
    });

    let balncDesc = orderDesc + (rus ? " клиентом" : " by client");

    let balanceRec = {
      time,
      orderID,
      event: id,
      desc: balncDesc,
      prevFrom: from,
      prevTo: to,
    };

    if (bypass) {
      if (passRefund)
        batch.update(dbPasses(bypass), { durLeft: increment(passRefund) });

      balanceRec.duration = passRefund;
      batch.update(dbPasses(bypass), {
        [`uses.${time}`]: balanceRec,
        [`uses.${id}.cancelled`]: true,
        [`uses.${id}.passed`]: true,
      });
    }

    if (!bypass) {
      balanceRec.sum = refund;
      batch.update(dbUsers(myid), { [`balance.${time}`]: balanceRec });
    }

    let error;
    await batch.commit().catch((er) => (error = er));

    if (error) {
      onError();
      return callAlert(...CNCL1[lang]);
    }

    if (next) next();
    this.deleteBook(id);
    this.school.setCoachBusySlot(coachID, id);
  };

  handleBooksListener = async (q, refresher) => {
    let newState = dbQueryToObj(q);
    if (this.load) return this.setBooks(newState);

    let {
        myid,
        books,
        school: { deleteGroup },
      } = this,
      { lang, hhfrmt } = this.auth,
      updates = q.docChanges(),
      [added, changed, removed] = [[], [], []];

    let handleUpdate = (u) => {
      let { type } = u,
        d = u.doc.data(),
        { [d.id]: curr } = books;
      if (type == "removed") return curr && removed.push(d);

      // rest are 'modified' or 'added', but 1 of the 'modified' cases is when other client booked event – so no need to be handled
      if (!curr || !curr.clientsIds?.includes(myid)) return added.push(d);

      let nochange =
        issame(d.active, curr.active) &&
        issame(d.edited, curr.edited) &&
        issame(d.zoom !== curr.zoom) &&
        issame(d.zoomPass !== curr.zoomPass);
      return nochange ? null : changed.push(d);
    };

    updates.forEach(handleUpdate);
    if (!added[0] && !removed[0] && !changed[0]) return;

    if (added[0]) {
      let toastData = added[1] ? added.length : added[0];
      if (!refresher) booksToast("add", toastData, 0, lang, hhfrmt); // toast if not a first app launch (load=true) & not a manual pull-to-refresh getter

      // if all events are new, setBooks,
      if (added.length == updates.length) return this.setBooks(newState);
    }

    // if events quantity changes (added OR passed OR private removed), we'll setBooks for all changes at once, so won't need to update event separately
    let needSetState =
      added[0] || removed.some((d) => d.privat || d.to < Date.now());

    // changed events may be cancelled (active: false) or just edited
    if (changed[0]) {
      let handleChange = (d, ind) => {
        let { [d.id]: curr } = books,
          index = ind + (added[0] ? 1 : 0);
        //  need to toast & then update with setTimeout, because each new toast cancels previous one
        let type = !d.active
          ? "cancel"
          : !issame(d.edited, curr.edited)
          ? "edit"
          : "zoom";

        if (d.privat && !curr.privat) deleteGroup(d.id);
        if (!needSetState) this.setEvent(d); // setEvent after toast current data, so inside setTimeout

        setTimeout(
          () => booksToast(type, curr, index, lang, hhfrmt),
          index * 2000
        );
      };

      orderBy(changed, "from").forEach(handleChange);
    }

    // removed are those which are passed OR haven't the user's uid in clients anymore
    if (removed[0]) {
      let handleRemove = (d, ind) => {
        let { id } = d;

        // if passed
        if (d.to < Date.now()) {
          if (d.group) deleteGroup(id); // if privat, it'll be auto-deleted by 'needSetState && setBooks'. But 'setbooks' doesn't delete group classes by itself
          if (d.clientsIds?.includes(myid)) this.setPassed(d);
          return;
        }

        // else, toast + un-book it (deleteBook), means delete event if it's a private OR just remove user's uid if it's a group
        let { [id]: curr } = books,
          index = ind + (added[0] ? 1 : 0) + changed.length;

        // need to toast & then delete with setTimeout, because each new toast cancels previous one
        if (!needSetState) this.deleteBook(id); // if needSetState = true, it will be deleted by setBooks
        setTimeout(
          () => booksToast("cancel", curr, index, lang, hhfrmt),
          index * 2000
        );
      };

      orderBy(removed, "from").forEach(handleRemove);
    }

    return needSetState && this.setBooks(newState);
  };
}

let denyCancelling = (type, lang, next) => {
  if (next) next();
  setTimeout(() => callAlert(...CNCL2[lang](type)), 100);
};

let denyRescdle = (text, lang, next) => {
  if (next) next();
  setTimeout(() => callAlert(RSCER1[lang], text.message || text), 100);
};

let booksToast = (type, data, index = 0, lang, hhfrmt) => {
  let added = type == "add",
    cancld = type == "cancel",
    id = data?.id,
    { name: screen, params: p } = getRoute();

  let infocus = id ? screen == "Event" && p?.id == id : screen == "Profile",
    timeText =
      infocus || !id ? "" : dayjs(data.from).format(" D MMM " + hhfrmt);

  let text;
  if (!id) {
    text = TOAST1[lang](data);
  } else if (type === "zoom") {
    text = TOAST2[lang](infocus, timeText);
  } else if (cancld) {
    text = TOAST3[lang](timeText);
  } else {
    text = (added ? TOAST4 : TOAST5)[lang](infocus, timeText);
  }

  let onPress = () => {
    if (infocus) return;
    let { isINProfileStack } = handleRoutesCheck(),
      params = id ? { id } : undefined;

    return isINProfileStack
      ? navigate(id ? "Event" : "Profile", params)
      : id
      ? rootNavEvent(params)
      : navigate("ProfileStack", { screen: "Profile" });
  };

  return vibroToast(
    text,
    3500,
    tabbarHeight + 120 + index * (54 + 16),
    onPress
  );
};

let {
    PASER,
    BUYPCKG1,
    BUYPCKG3,
    BUYPCKG4,
    ORDPASER,
    ORDTRL,
    ORDBAL1,
    ORDER1,
    ORDBAL2,
    RSCERTXT,
    RSC2,
    CNCL1,
    CNCL2,
    CNCLDSC1,
    CNCLDSC2,
    RFND1,
    RFND2,
    TOAST1,
    TOAST2,
    TOAST3,
    TOAST4,
    TOAST5,
    RSCER1,
  } = translates.ClientStore,
  { PASSPRCH } = translates;
