import React, { useRef, useCallback, useEffect, useState } from "react";
import { FlatList, View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { onValue, off } from "firebase/database";
import orderBy from "lodash/orderBy";
import { useAuth, useClient } from "../commons/Stores";
import { durtnText, parseBalance } from "../commons/utils";
import {
  Container,
  LITEBORDER,
  Loader,
  Text28,
  PageTitle,
} from "../commons/UI";
import { Filter } from "./Filters";
import BalanceRecord, { DepoApplyRecord } from "../comp/BalanceRecord";
import PackageCard from "../comp/PackageCard";
import { translates } from "../translates";

export default observer(({ navigation: nav, route: { params: p } }) => {
  const mount = useRef(true),
    { orderID: order, passID: pass } = p || {},
    paramFilter = pass || order,
    { navigate } = nav,
    {
      lang,
      rus,
      hhfrmt,
      profile: { balance: bal0, uid },
      depoApplies,
      depoAppliesRef: depositsRef,
      setDepoApplies,
    } = useAuth(),
    [filter, setFilter] = useState(paramFilter);

  useEffect(() => {
    onValue(depositsRef, (v) => {
      if (v.exists() && mount.current) setDepoApplies(v.val());
    });
    return () => ((mount.current = false), off(depositsRef));
  }, []);

  useEffect(() => {
    const navListener = nav.addListener("focus", () => {
      if (paramFilter && !filter) setFilter(true);
    });
    return navListener;
  }, [paramFilter, filter]);

  const toggleFilter = useCallback(() => setFilter((pr) => !pr), []);

  const title = filter
    ? TTL1[lang]()
    : (rus ? "Баланс: " : "Balance: ") + parseBalance(bal0) + "$";

  let filterName = paramFilter && FLTRBTN[lang](pass) + paramFilter;

  const arr = Object.values(bal0 || {}).concat(Object.values(depoApplies));

  const filtered = orderBy(
    filter
      ? arr.filter((r) => r[pass ? "passID" : "orderID"] === paramFilter)
      : arr,
    "time",
    "desc"
  );

  const renderItem = ({ item: rec }) => {
    let Record = rec.isapply ? DepoApplyRecord : BalanceRecord;
    return <Record {...{ rec, uid, lang, hhfrmt, navigate }} />;
  };

  const ListHeaderComponent = (
    <View style={{ marginBottom: 16 }}>
      <Text28>{title}</Text28>
      {paramFilter && (
        <Filter
          name={filterName}
          active={filter}
          onPress={toggleFilter}
          style={{ marginTop: 12 }}
        />
      )}
    </View>
  );

  return (
    <Container>
      <FlatList
        data={filtered}
        {...listProps}
        {...{ renderItem, ListHeaderComponent }}
      />
    </Container>
  );
});

export const PassBalance = observer(
  ({
    navigation: nav,
    route: {
      params: { passID, orderID, from },
    },
  }) => {
    const { navigate } = nav,
      { myid: uid, lang, rus, hhfrmt } = useAuth(),
      {
        passes: { [passID]: pass },
        dblistenPass,
      } = useClient(),
      [filter, setFilter] = useState(orderID);

    useEffect(() => {
      let dbListener = dblistenPass(passID);
      return () => dbListener();
    }, []);

    useEffect(() => {
      const navListener = nav.addListener("focus", () => {
        if (orderID && !filter) setFilter(true);
      });
      return navListener;
    }, [orderID, filter]);

    const toggleFilter = useCallback(() => setFilter((pr) => !pr), []);

    if (!pass) return <Loader big />;

    const { duration, created: time, durLeft } = pass;
    const title = filter
      ? TTL1[lang](1)
      : TTL2[lang] +
        durLeft +
        (durLeft > 60 ? "\n(" + durtnText(durLeft, "f", rus) + ")" : "");

    const arr = [{ started: true, duration, time }].concat(
        Object.values(pass.uses || {})
      ),
      filtered = orderBy(
        filter ? arr.filter((r) => r.orderId === orderID) : arr,
        "time",
        "desc"
      );

    const renderItem = ({ item: rec }) => (
      <BalanceRecord ispass {...{ rec, uid, lang, hhfrmt, navigate }} />
    );

    const ListHeaderComponent = (
      <View style={{ marginBottom: 16 }}>
        <PageTitle>{title}</PageTitle>

        {from !== "Package" && (
          <PackageCard
            small
            p={pass}
            {...{ lang, hhfrmt, navigate }}
            style={{ marginVertical: 8 }}
          />
        )}

        {orderID && (
          <Filter
            name={FLTRBTN[lang]() + orderID}
            active={filter}
            onPress={toggleFilter}
            style={{ marginTop: 12 }}
          />
        )}
      </View>
    );

    return (
      <Container>
        <FlatList
          {...listProps}
          data={filtered}
          {...{ renderItem, ListHeaderComponent }}
        />
      </Container>
    );
  }
);

let keyExtractor = (r) => r.id || r.time,
  ItemSeparatorComponent = styled.View`
    height: 1px;
    background-color: ${LITEBORDER};
    margin: 16px 0 12px;
  `;

let listProps = {
  keyExtractor,
  ItemSeparatorComponent,
  initialNumToRender: 10,
  maxToRenderPerBatch: 10,
  windowSize: 7,
  contentContainerStyle: { flexGrow: 1, padding: 24 },
};

let { TTL1, TTL2, FLTRBTN } = translates.BalanceRecords;
