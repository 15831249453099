import React, { useContext } from "react";
import { makeAutoObservable, runInAction } from "mobx";
import { query, getDocs, where, getDoc, documentId } from "firebase/firestore";
// import {get as getRdb,ref as rdbref,query as rdbquery,orderByChild,equalTo,} from "firebase/database";
import orderBy from "lodash/orderBy";
import {
  dbCoaches,
  dbEvents,
  dbQueryToObj,
  getDay,
  getFreeSlots,
  groupBy,
  isvalidPackage,
  localLang,
  localStor,
  sortedCoachIds,
} from "./utils";
import { loccoaches, locprogs, loclevels, loccolors } from "./locdata";
import AuthStore from "./AuthStore";
import CartStore from "./CartStore";
import ClientStore from "./ClientStore";
import dayjs from "dayjs";

const Context = React.createContext(),
  useStore = () => useContext(Context);
export default useStore;

export const StoresProvider = ({ children }) => {
  const school = new SchoolStore(),
    auth = new AuthStore(school),
    client = new ClientStore(school, auth),
    cart = new CartStore(school, auth.myid);

  return (
    <Context.Provider value={{ school, auth, cart, client }}>
      {children}
    </Context.Provider>
  );
};

export const useSchool = () => useStore().school,
  useClient = () => useStore().client,
  useAuth = () => useStore().auth,
  useCart = () => useStore().cart;

let stripePayWay = { id: "stripe", num: -1 };

class SchoolStore {
  initCoaches = loccoaches;
  initPrograms = locprogs;
  initLevels = loclevels;
  initPackages = {};
  packagesColors = loccolors || {};
  groups = {}; //locevents; //{}; // could be also not user's  privates, which were gained by share link
  groupsLoad = true; //
  filter = {};
  initPayWays = { stripe: stripePayWay };
  lang = localLang();

  constructor() {
    makeAutoObservable(this);
  }

  get coaches() {
    return handleDataLang(this.initCoaches, this.lang);
  }

  get load() {
    return !Object.keys(this.initPrograms)[0] || !Object.keys(this.coaches)[0];
  }

  get programs() {
    if (this.load) return {};
    let activeProgs = Object.values(this.coaches).reduce((res, c) => {
      c.programs?.forEach(res.add, res);
      return res;
    }, new Set());

    let filtrd = Object.values(this.initPrograms).reduce((res, cur) => {
      if (activeProgs.has(cur.id)) res[cur.id] = cur;
      return res;
    }, {});

    return handleDataLang(filtrd, this.lang);
  }

  get levels() {
    let res = handleDataLang(this.initLevels, this.lang);
    return res;
  }

  get packages() {
    return handleDataLang(this.initPackages, this.lang);
  }
  get payWays() {
    return handleDataLang(this.initPayWays, this.lang);
  }

  get groupsArr() {
    return orderBy(
      Object.values(this.groups || {}).filter(
        // need filter for 'e.group', thus there could be also not user's  privates, which were gained by share link
        (e) => e.active && e.group && e.to > Date.now()
      ),
      "from"
    );
  }

  get coachesArr() {
    let arr = Object.values(this.coaches),
      slotsLoaded = arr.some((c) => !!c.slots);

    let filtered = arr.filter((c) => {
      if (c.status !== "approved") return false;
      if (!slotsLoaded || this.groupsLoad) return true; // for initial launch, to show local data

      let hasSlot = Object.values(c.slots || {}).some(
        (sl) => getFreeSlots([sl], Object.values(c.busy || {}))[0]
      );
      if (hasSlot) return true;

      if (this.groupsArr.some((e) => e.coachID == c.uid)) return true;
      return false;
    });

    let sorted = sortedCoachIds
      .map((id) => filtered.find((c) => c.uid == id))
      .filter(Boolean);
    return sorted
      .concat(filtered.filter((c) => !sortedCoachIds.includes(c.uid)))
      .filter(Boolean);
  }

  get packagesArr() {
    let filtered = Object.values(this.packages).filter(isvalidPackage);
    return orderBy(filtered, "price");
  }

  get packagesBycoach() {
    return groupBy(this.packagesArr, "coachID");
  }

  setLang = (ll) => {
    // console.log('school setLang', ll, this.lang);
    if (this.lang !== ll) this.lang = ll;
    if (localLang() !== ll) localStor.setItem("lang", ll);
    dayjs.locale(ll);
  };

  setState = (key, v) => {
    let empty = !Object.keys(v || {}).length;
    if (!empty || !this[key]) runInAction(() => (this[key] = v));
  };

  setPayWays = (data = {}) => {
    data["stripe"] = stripePayWay;
    this.initPayWays = data;
  };

  setGroups = (obj = {}) => {
    let arr = Object.values(obj);
    if (arr[0]) arr.forEach((e) => (obj[e.id].day = getDay(e.from)));
    return (this.groups = obj);
  };

  addGroups = (obj = {}) => {
    let arr = Object.values(obj);
    if (arr[0]) arr.forEach((e) => (obj[e.id].day = getDay(e.from)));
    return (this.groups = { ...this.groups, ...obj });
  };

  updateGroup = (e) => {
    if (e.from) e.day = getDay(e.from);
    return (this.groups[e.id] = { ...this.groups[e.id], ...e });
  };

  deleteGroup = (id) => delete this.groups[id];

  deleteMybook = (id, myid) => {
    let e = this.groups[id];
    let clientsIds = e.clientsIds.filter((c) => c !== myid);
    delete e.clients[myid];
    if (e.clientCreated == myid) delete e.clientCreated;
    this.groups[id] = { ...e, clientsIds };
  };

  setFilter = (obj) => (this.filter = obj);

  handleLevel = (lev) => (this.levels[lev] || this.levels["0"]).name;

  setPackages = (obj, all) =>
    obj && (this.initPackages = all ? obj : { ...this.initPackages, ...obj });

  setPackage = (p) => {
    if (!p) return null;
    let isremove = typeof p === "string",
      id = isremove ? p : p.id;
    if (isremove) return delete this.initPackages[id];
    this.initPackages[id] = { ...this.initPackages[id], ...p };
  };

  getAllGroups = async () => {
    this.groupsLoad = true; // need to re-render Home Dates component, having issue with last book date render
    let q = await getDocs(query(dbEvents(), ...dbGroupsWheres));
    // console.log("getAllGroups", q.size);
    if (!q.empty) this.setGroups(dbQueryToObj(q));
    runInAction(() => (this.groupsLoad = false));
  };

  setCoach = (ob) => runInAction(() => (this.initCoaches[ob.uid] = ob));

  setCoachBusySlot = (coachID, data) => {
    let removal = typeof data == "string",
      id = removal ? data : data.id;
    if (removal) delete this.initCoaches[coachID].busy?.[id];
    else
      this.initCoaches[coachID].busy = {
        ...this.initCoaches[coachID].busy,
        [id]: data,
      };
  };

  getCoach = async (id, next) => {
    let d = await getDoc(dbCoaches(id)),
      coach = d.data();
    if (d?.exists()) this.setCoach(coach);
    if (next) next();
    return coach;
  };

  getCoaches = async (ids) => {
    if (!ids?.[0]) return;
    if (!ids[1]) return this.getCoach(ids[0]);
    let q = await getDocs(
      query(dbCoaches(), where(documentId(), "in", ids.slice(0, 10)))
    );
    if (q.size) q.forEach((d) => this.setCoach(d.data()));
  };

  handleDBCoachGroups = (q, coachID, myid) => {
    if (q.empty) return;
    let obj = dbQueryToObj(q),
      newIds = Object.keys(obj),
      currEvents = this.groupsArr.filter((e) => e.coachID == coachID),
      outdated = currEvents.filter((e) => !newIds.includes(e.id));

    // The booked ones arent needed to be deleted, since they getting handled automatically by listener in EffectsProvider
    if (outdated[0]) {
      let now = Date.now();
      let needDelete = myid
        ? outdated.filter((e) => e.to < now || !e.clientsIds?.includes(myid))
        : outdated;
      needDelete.forEach((e) => this.deleteGroup(e.id));
    }
    // udate Groups if there is a new or edited group. No need to check for updated bookins since they are handled by listener in EffectsProvider
    let currIds = currEvents.map((e) => e.id);
    let hasNewOREdited = newIds.some(
      (id) =>
        !currIds.includes(id) || obj[id].edited !== this.groups[id]?.edited
    );
    return hasNewOREdited && this.addGroups(obj);
  };

  getCoachGroups = async (coachID, myid) =>
    getDocs(dbrefCoachGroups(coachID)).then((q) =>
      this.handleDBCoachGroups(q, coachID, myid)
    );
}

let dbGroupsWheres = [
  where("group", "==", true),
  where("active", "==", true),
  where("to", ">", Date.now()),
];

export let dbrefCoachGroups = (coachID) =>
  query(dbEvents(), where("coachID", "==", coachID), ...dbGroupsWheres);

let handleDataLang = (data, lang = localLang()) => {
  if (lang === "en") return data;

  let keys = ["name", "bio", "desc", "title", "short"],
    upds = { ...data };

  for (let id in upds) {
    let o = upds[id];
    if (typeof o !== "object") continue;

    let handle = (k) => {
      let res = o[k + lang] ?? o[k];
      upds[id] = { ...upds[id], [k]: res };
    };

    keys.filter((k) => k in o).forEach(handle);
  }
  return upds;
};
