import React from "react";
import styled from "styled-components/native";
import dayjs from "dayjs";
import {
  callAlert,
  contactsAlert,
  openURL,
  offsetText as offset,
  copytext,
} from "../commons/utils";
import {
  DGRAY,
  ApplyIcon,
  GRAY,
  GREEN,
  PageTitle,
  Row,
  RowBetween,
  Text14,
  Touch,
} from "../commons/UI";
import { translates } from "../translates";

let offsetText = ` (${offset})`;
let getTimeText = (time, hhfrmt, full, rus) => {
  let basetx = dayjs(time).format("D MMM YYYY, " + hhfrmt);
  if (!full) return basetx;
  return (rus ? " " : " on ") + basetx + offsetText;
};

const Record = ({ rec, navigate, uid, lang, hhfrmt, ispass }) => {
  const rus = lang === "ru",
    { who, event, orderID, passID, duration: dur } = rec,
    sum = ispass ? dur : rec.sum,
    isplus = sum > 0,
    timeText = getTimeText(rec.time, hhfrmt),
    iscancel =
      event && ["cancelled", "отменен"].some((tx) => rec.desc?.includes(tx)),
    hasOrder = passID || orderID,
    orderIDTime = hasOrder?.split("-")[hasOrder.split("-").length - 1],
    orderTime =
      hasOrder && dayjs(Number(orderIDTime)).format("D MMM " + hhfrmt);

  let title;

  if (ispass)
    title = rec.isevent
      ? EVTYPE1[lang]
      : event
      ? EVTYPE2[lang](isplus, iscancel)
      : isplus && rec.started
      ? PASPRCH[lang]
      : rec.finished
      ? PASEND[lang](sum)
      : PASCSTM[lang](isplus);

  if (!ispass)
    title = passID
      ? BLNCPACK[lang](isplus)
      : !orderID
      ? BLNCCSTM[lang](isplus)
      : !event
      ? BLNCORD[lang](isplus)
      : EVTYPE2[lang](isplus, iscancel);

  let { prevFrom, from } = rec,
    desc = rec.desc || title,
    classWord =
      (event || rec.isevent) &&
      ["class", "booking", "занятие", "бронирование"].find((w) =>
        desc.toLowerCase().includes(w)
      );

  if (classWord && (prevFrom || from)) {
    let classWordIndex = desc.toLowerCase().indexOf(classWord),
      insertIndex = classWordIndex + classWord.length + 1;

    let hhfrt = hhfrmt,
      from0 = prevFrom || from,
      to0 = rec.prevTo || rec.to || from0 + dur * 60000,
      timetx =
        dayjs(from0).format("D MMM " + hhfrt + " – ") +
        dayjs(to0).format(hhfrt + " ");

    desc = desc.slice(0, insertIndex) + timetx + desc.slice(insertIndex);

    if (prevFrom && from) {
      let to = rec.to || from + dur * 60000;
      desc +=
        RSDLDTM[lang] +
        dayjs(from).format("D MMM " + hhfrt) +
        dayjs(to).format(" – " + hhfrt);
    }
  }

  if (desc === title) desc = null;

  const onPress = () =>
    balRecordAlert(
      rec,
      ispass,
      title,
      desc,
      orderTime,
      lang,
      hhfrmt,
      uid,
      navigate
    );

  const SumComp = ispass ? MiniSum : Sum;

  return (
    <Touch {...{ onPress }}>
      <RowBetween style={{ alignItems: "flex-start" }}>
        <RecordDescView>
          <Title numberOfLines={1}>{timeText}</Title>
          {/* <DateTitle numberOfLines={1}>{timeText}</DateTitle> */}
          <Title style={{ color: DGRAY }} numberOfLines={1}>
            {title}
          </Title>
          {(who || desc) && (
            <Desc numberOfLines={3}>
              {desc || ""}
              {who ? RECWHO[lang](who, desc) : ""}
            </Desc>
          )}
          {hasOrder && !desc && (
            <Desc numberOfLines={1}>{CNCTN[lang](passID) + orderTime}</Desc>
          )}
        </RecordDescView>

        <Row>
          <SumComp
            style={{ color: isplus ? GREEN : "black" }}
            numberOfLines={1}
          >
            {(isplus ? "+" : "") + sum + (ispass ? (rus ? "м" : "m") : "$")}
          </SumComp>
        </Row>
      </RowBetween>
    </Touch>
  );
};

export default Record;

let balRecordAlert = (
  { image: img, link, status, orderID, passID, ...r },
  ispass,
  title2,
  desc,
  orderTime,
  lang,
  hhfrmt,
  uid,
  navigate
) => {
  let rus = lang === "ru",
    sum = ispass ? r.duration : r.sum,
    isplus = sum > 0,
    sumtype = ispass ? (rus ? " мин." : " min.") : "$",
    sumText = (isplus ? "+" + sum : sum) + sumtype,
    hasOrder = orderID || passID,
    timeText = getTimeText(r.time, hhfrmt, 1, rus);

  let firstRow = (rus ? "Операция " : "Operation ") + sumText + timeText;

  let fulltext = `${firstRow}. \n${title2}. \n`;
  if (desc) fulltext += (rus ? "Описание" : "Description") + `: ${desc}. \n`;
  if (r.who) fulltext += (rus ? "От" : "Made by") + `: ${r.who}. \n`;
  if (hasOrder) fulltext += ORDTMTX[lang](passID) + orderTime + ".";

  let title = fulltext.split(sumtype)[0] + sumtype;

  const openOrder = () =>
    navigate(passID ? "PassOrder" : "Order", passID ? { passID } : { orderID });

  let ids = "";
  if (r.event) ids += COPYID[lang](r.event);
  if (orderID) ids += COPYID[lang](0, orderID);
  if (passID) ids += COPYID[lang](0, 0, passID);

  let copyData = fulltext.replace("#", "№") + ids,
    concactText = CNTCTTXT[lang](ispass) + uid + ` \n${copyData}`;

  let oncontact = () => (
    copytext(copyData),
    contactsAlert(CNTCTTTL[lang](ispass), concactText, 2, lang)
  );

  return callAlert(title, fulltext, [
    hasOrder && {
      label: ALRTLBL1[lang](passID),
      onClick: openOrder,
    },
    { label: CNTCTBTN[lang], onClick: oncontact },
  ]);
};

export const DepoApplyRecord = ({ rec, uid, lang, hhfrmt }) => {
  const rus = lang === "ru",
    { sum, time, time2, status } = rec,
    approved = status === "approved",
    sum2 = approved && rec.approveSum;

  let applyStatus = DEPSTS[lang](status);
  if (sum2 && sum2 !== sum) applyStatus += ` ${sum2}$`;

  const title = DEPTTL[lang],
    timeText = getTimeText(time, hhfrmt);

  const statusText = !status
    ? DEPSENT[lang]
    : applyStatus +
      (rus ? " в " : " at ") +
      dayjs(time2).format(hhfrmt + ", D MMM");

  const desc = DEPTRANS[lang] + rec.payway + (rec.desc ? ". " + rec.desc : "");

  const onPress = () => depoApplyAlert(rec, uid, lang, hhfrmt);

  return (
    <Touch {...{ onPress }}>
      <RowBetween style={{ alignItems: "flex-start" }}>
        <RecordDescView>
          <Title numberOfLines={1}>{timeText + `,  ` + applyStatus}</Title>
          <Title numberOfLines={1}>{title}</Title>
          <Desc numberOfLines={2}>{desc}</Desc>
          <Desc numberOfLines={1}>{statusText}</Desc>
        </RecordDescView>
        <Row>
          <ApplyIcon
            {...{ status }}
            style={{
              marginTop: status ? 6 : 8,
              marginRight: status ? -20 : -16,
              marginLeft: 24,
            }}
          />
          <Sum style={{ color: GRAY }} numberOfLines={1}>
            {sum}$
          </Sum>
        </Row>
      </RowBetween>
    </Touch>
  );
};

let depoApplyAlert = (
  { sum, desc, image: img, link, status, orderID, passID, ...r },
  uid,
  lang,
  hhfrmt
) => {
  let rus = lang === "ru",
    approved = status === "approved",
    sum2 = approved && r.approveSum,
    timeText = getTimeText(r.time, hhfrmt, 1, rus);

  let time2text =
    sum2 &&
    (rus ? " " : " on ") +
      dayjs(r.time2).format("D MMM, " + hhfrmt) +
      offsetText;

  let firstRow = DEPTTL[lang] + ` ${sum}$` + timeText;

  let fulltext = `${firstRow}. \n${DEPTRANS[lang]} ${r.payway}. \n`;
  if (desc) fulltext += (rus ? "Описание" : "Description") + `: ${desc}. \n`;

  fulltext += DEPCNFRM1[lang](img, link);

  if (status) {
    fulltext += DEPSTS[lang](status, "full");
    if (sum2 && sum2 !== sum) {
      fulltext += (rus ? " на сумму" : " for") + ` ${sum2}$`;
    }
    fulltext += time2text;
  }
  if (!status) fulltext += (rus ? "Статус: " : "Status: ") + DEPSENT[lang];

  let title = fulltext.split("$")[0] + "$",
    links = DEPCNFRM2[lang](img, link);

  let copyData = fulltext.replace("#", "№") + links,
    concactText = CNTCTTXT2[lang] + uid + `. \n${copyData}`;

  let oncontact = () => (
    copytext(copyData),
    contactsAlert(DEPTTL[lang].toUpperCase(), concactText, 2, lang)
  );

  return callAlert(title, fulltext, [
    {
      label: ALRTDEPLBL[lang](img),
      onClick: () => openURL(img || link),
    },
    { label: CNTCTBTN[lang], onClick: oncontact },
  ]);
};

let Sum = styled(PageTitle)`
    flex-shrink: 0;
    color: black;
    margin-left: 24px;
  `,
  MiniSum = styled(Sum)``,
  RecordDescView = styled.View`
    flex-shrink: 1;
    margin-top: 2px;
  `,
  Title = styled(Text14)`
    margin-top: 2px;
  `,
  Desc = styled(Title)`
    color: ${GRAY};
  `;

let {
  EVTYPE1,
  EVTYPE2,
  PASPRCH,
  PASEND,
  PASCSTM,
  BLNCPACK,
  BLNCCSTM,
  BLNCORD,
  RSDLDTM,
  RECWHO,
  CNCTN,
  DEPTTL,
  DEPSTS,
  DEPSENT,
  DEPTRANS,
  DEPCNFRM1,
  DEPCNFRM2,
  ORDTMTX,
  COPYID,
  ALRTLBL1,
  ALRTDEPLBL,
  CNTCTTTL,
  CNTCTTXT,
  CNTCTTXT2,
  CNTCTBTN,
} = translates.BalanceRecord;
