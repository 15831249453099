import React, { useState, useEffect, useCallback } from "react";
import { FlatList, ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { useNavigation, useRoute } from "@react-navigation/native";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import { useAuth, useCart, useClient, useSchool } from "../commons/Stores";
import {
  durtnText,
  ages,
  callAlert,
  vibroToast,
  formhh,
  localLang,
  getDay,
} from "../commons/utils";
import {
  Press,
  Button,
  QuantButton,
  DropDown,
  GRAY,
  DGRAY,
  BORDERGRAY,
  CloseIcon,
  RowCentered,
  Text14,
  CheckboxIcon,
  Text16,
  BLUE,
  QuestMark,
  SumRow,
} from "../commons/UI";
import { translates } from "../translates";

let toast = (tx, dur = 3000, offset = 120) => vibroToast(tx, dur, offset);

export default observer(
  ({
    coachID,
    e0,
    picked,
    removeStart,
    dur,
    unactualStart,
    canTrial,
    multiPick,
    passID,
    ...r
  }) => {
    const { navigate, replace } = useNavigation(),
      {
        myid,
        rus,
        lang,
        hhfrmt,
        profile: { age: myage, level: mylevel },
      } = useAuth(),
      {
        coaches: { [coachID]: c },
        programs,
        levels,
        filter: { progID: fltrProg },
      } = useSchool(),
      { canTrial: clientCanTrial } = useClient(),
      { add: addCart, update: updateCart, coachCartEarliest } = useCart(),
      { price: prvPrice, grPrice, programs: progIds, hasTrial } = c;

    const {
        id,
        from: from0,
        to: to0,
        progID: progID0,
        age: age0,
        level: level0,
        privat: privat0,
        client: client0,
      } = e0 || {},
      { quant: persons0 } = client0 || {},
      [first] = picked,
      pickedQuant = picked.length,
      only1pick = pickedQuant === 1;

    const [progID, setProgID] = useState(
        e0 ? progID0 : progIds.includes(fltrProg) ? fltrProg : progIds[0]
      ),
      [age, setAge] = useState(e0 ? age0 : myage || 14),
      ageIndex = ages[lang].findIndex((a) => age <= a.id),
      [level, setLevel] = useState(level0 || mylevel || levels[0].id),
      levelsIds = orderBy(Object.values(levels), "num").map((l) => l.id),
      levelIndex = levelsIds.indexOf(level),
      [persons, setPersons] = useState(e0 ? persons0 : 1),
      [privat, setPrivat] = useState(e0 ? privat0 || false : true),
      isPrivat = privat || persons > 1,
      price = (persons > 1 ? grPrice : prvPrice) * (dur / 60) * persons,
      groupPriceDiff = (prvPrice - grPrice) * (dur / 60),
      sum = price * pickedQuant,
      totalDur = dur * pickedQuant;

    const cartEarliest = canTrial && coachCartEarliest(coachID, r.initStart),
      isEarliest = canTrial
        ? !cartEarliest || first?.from <= cartEarliest
        : null,
      // notEarliest = canTrial && dur <= 45 && !isEarliest,
      doTrial =
        clientCanTrial && hasTrial && dur <= 45 && persons === 1 && isEarliest,
      isTrial = doTrial && only1pick,
      discont = doTrial ? price : 0,
      discontSum = sum - discont;

    const {
      params: { progID: prmsProgID },
    } = useRoute();

    useEffect(() => {
      if (prmsProgID) setProgID(prmsProgID);
    }, [prmsProgID]);

    const changeAge = (ch) =>
        setAge(ages[lang][ageIndex + (ch === -1 ? -1 : 1)].id),
      minusAge = () => changeAge(-1);

    const minusLevel =
      levelIndex > 0 ? () => setLevel(levelsIds[levelIndex - 1]) : null;

    const plusLevel =
      levelIndex < levelsIds.length - 1
        ? () => setLevel(levelsIds[levelIndex + 1])
        : null;

    const changeProg = progIds[1]
      ? () => {
          let params = { from: "CustomEvent", progID, id, passID, coachID };
          return navigate("Filters", params);
        }
      : null;

    const renderPickedButtons =
      multiPick &&
      useCallback(
        ({ item: st }) => (
          <PickedButton {...{ st, lang, hhfrmt, removeStart }} />
        ),
        [lang, hhfrmt]
      );

    let classesText = "";
    if (isTrial) classesText = rus ? "1 пробное занятие" : "1 trial class";
    else classesText = TOTALCLS[lang](isPrivat, pickedQuant);

    let priceDetails = "";
    if (pickedQuant > 1)
      priceDetails = price + (rus ? "$ за занятие" : "$ per class");
    if (pickedQuant > 1 && persons > 1) priceDetails += ", ";
    if (persons > 1)
      priceDetails += price / persons + (rus ? "$ за чел." : `$ per person`);

    const PriceDetailsText =
      !first || passID ? null : persons === 1 && only1pick ? null : (
        <Text16 style={{ color: GRAY, marginTop: 8 }}>{priceDetails}</Text16>
      );

    let buttonText = "";
    if (r.nopicked) buttonText = BTNTX1[lang](multiPick);
    else if (e0 && first)
      buttonText =
        (rus ? "Сохранить" : "Save") + (passID ? "" : ", " + discontSum + "$");
    else {
      buttonText = BTNTX2[lang](first);
      if (first && !passID) buttonText += `, ${discontSum}$`;
    }

    const save = () => {
      if (r.nopicked) return;
      if (!first) return toast(SVER1[lang] + durtnText(dur, 1, rus), 5000);

      if (unactualStart) {
        let timetext = dttext(unactualStart, 0, lang, hhfrmt);
        return callAlert(...SVER2[lang](timetext)), removeStart(unactualStart);
      }

      let cartCrossed = picked.find(r.checkCartCross);
      if (cartCrossed)
        return toast(dttext(cartCrossed, 1, lang, hhfrmt) + SVER3[lang]);

      let group = !isPrivat,
        // classDefPrice is a default public price for other clients to join
        classDefPrice =
          (group || persons > 1 ? grPrice : prvPrice) * (dur / 60),
        clientData = { uid: myid, quant: persons };
      if (passID) clientData.passID = passID;
      else clientData.sum = price;

      let generate = ({ from, day, slotID }) => ({
        privat: isPrivat,
        group,
        custom: true,
        active: true,
        coachID,
        progID,
        from,
        slotID,
        to: from + dur * 60000,
        day,
        age,
        level: isPrivat ? null : level,
        price: classDefPrice,
        client: clientData,
      });

      let obj = !multiPick && generate(first),
        afterSave = () => replace("Cart", { coachID, passID });

      if (e0) {
        obj.id = id;
        if (e0.error && (obj.from !== from0 || obj.to !== to0))
          obj.error = null;
        return updateCart(passID || coachID, obj), afterSave();
      }

      if (!multiPick) return addCart([obj]), afterSave();

      removeStart("all");
      addCart(picked.map(generate));
      afterSave();
    };

    const PickedButtonsComp = first && multiPick && (
      <View style={{ flexShrink: 1 }}>
        <SumRow
          big
          {...{ discont }}
          isdur={!!passID}
          name={(rus ? `Итого ` : `Total `) + classesText}
          sum={passID ? totalDur : discontSum}
          style={{ marginTop: 36, marginBottom: -4 }}
        />
        {PriceDetailsText}
        {pickedQuant > 0 && (
          <FlatList
            data={picked}
            renderItem={renderPickedButtons}
            keyExtractor={startKeys}
            numColumns={2}
            scrollEnabled={false}
            ItemSeparatorComponent={ConfirmsSeparat}
            contentContainerStyle={{ paddingRight: 24 }}
            style={{ marginVertical: 10, marginRight: -24 - 8 }}
          />
        )}
      </View>
    );

    return (
      <>
        <View style={{ flex: 1 }}>
          <DropDown
            hasIcon={!!progIds[1]}
            text={programs[progID].name}
            onPress={changeProg}
            style={{ marginTop: 20 }}
          />
          <QuantButton
            text={(rus ? "Возраст: " : "Age: ") + ages[lang][ageIndex].name}
            plus={age < 36 ? changeAge : null}
            minus={age > 5 ? minusAge : null}
            style={{ marginTop: 20 }}
          />

          {!passID && (
            <QuantButton
              text={(rus ? "Учеников: " : "Persons: ") + persons}
              plus={() => setPersons((pr) => pr + 1)}
              minus={persons > 1 ? () => setPersons((pr) => pr - 1) : null}
              style={{ marginTop: 20 }}
            />
          )}

          {!passID && persons === 1 && (
            <>
              {isTrial ? null : (
                <OfferRowPress onPress={() => setPrivat((pr) => !pr)}>
                  <View style={{ width: 34 + 8 }}>
                    <CheckboxIcon
                      active={!privat}
                      size={34}
                      color={BLUE}
                      style={{ marginTop: -4 }}
                    />
                  </View>

                  <OfferBody>
                    <OfferText>
                      {MAKGRP[lang](only1pick, groupPriceDiff)}
                    </OfferText>
                  </OfferBody>
                </OfferRowPress>
              )}

              {!privat && (
                <QuantButton
                  text={(rus ? "Уровень: " : "Level: ") + levels[level].name}
                  plus={plusLevel}
                  minus={minusLevel}
                  style={{ marginTop: 20 }}
                >
                  <QuestMark
                    onPress={() => navigate("AddInfo", { level })}
                    style={{ marginLeft: -8, width: 28, height: 28 }}
                  />
                </QuantButton>
              )}
            </>
          )}
        </View>

        {PickedButtonsComp}

        <Button
          big
          text={buttonText}
          onPress={save}
          inactive={!first}
          style={{ marginTop: first && multiPick ? 16 : 40 }}
        />
      </>
    );
  }
);

export let dttext = (data, week, lang = localLang(), hhfrmt = formhh()) => {
  let isobj = typeof data === "object",
    dt = isobj ? data.from : data,
    to = data?.to,
    rus = lang === "rus";

  let datetx =
    getDay() !== getDay(dt)
      ? dayjs(dt).format(week ? "D MMM, ddd" : "D MMM")
      : rus
      ? "сего\nдня"
      : "today";

  let timetx = [dt, to]
    .filter(Boolean)
    .map((tt) => dayjs(tt).format(hhfrmt))
    .join("–")
    .toLowerCase();

  return datetx + " " + timetx;
};

let startKeys = (it) => it.from;

const PickedButton = ({ st, lang, hhfrmt, removeStart }) => (
  <PickedButtonRow>
    <PickedButtonText>{dttext(st.from, 1, lang, hhfrmt)}</PickedButtonText>
    <Press>
      <CloseIcon
        size={22}
        color={BORDERGRAY}
        onPress={() => removeStart(st.from)}
        style={{ height: 44, width: 40 }}
      />
    </Press>
  </PickedButtonRow>
);

let PickedButtonRow = styled(RowCentered)`
    height: 44px;
    border: 1px solid ${BORDERGRAY};
    border-radius: 12px;
    padding-left: 8px;
    margin-right: 8px;
  `,
  PickedButtonText = styled(Text14)`
    color: ${DGRAY};
  `,
  ConfirmsSeparat = styled.View`
    width: 8px;
    height: 8px;
  `,
  OfferRowPress = styled(Press)`
    flex-direction: row;
    margin-top: 24px;
  `,
  OfferBody = styled.View`
    flex: 1;
    flex-shrink: 1;
  `,
  OfferText = styled(Text16)`
    color: ${DGRAY};
  `;

let { TOTALCLS, BTNTX1, BTNTX2, SVER1, SVER2, SVER3, MAKGRP } =
  translates.EventBuilder;
