import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { off as rdboff, onValue } from "firebase/database";
import {
  query,
  onSnapshot,
  where,
  orderBy as dborderBy,
  limit as dblimit,
} from "firebase/firestore";
import dayjs from "dayjs";
import { useAuth, useCart, useClient, useSchool } from "../commons/Stores";
import {
  rdbPackages,
  dbPasses,
  dbQueryToObj,
  durtnText,
  getSuprtAppeal,
  isvalidPackage,
  parsePackage,
  vibroToast,
  isMobl,
  offline,
  offlineToast,
} from "../commons/utils";
import {
  Container,
  CartButton,
  PageTitle,
  RowCentered,
  GRAY,
  Button,
  AbsLoader,
  Body,
  Text16,
  DGRAY,
  Medium18,
  Divider20,
  Divider40,
  Medium24,
  Text18,
  Loader,
  Touch,
  ShareIcon,
  BLUE,
  MarkerComp,
  FooterOption,
  Row,
} from "../commons/UI";
import { PackImage, getPeriodText, getTimeText } from "../comp/PackageCard";
import CoachCard from "../comp/CoachCard";
import { DatesEventsList } from "../comp/Dates";
import { AppPromo } from "./Login";
import { translates } from "../translates";

export default observer(({ navigation: nav, route: r }) => {
  let pr = r.params;
  for (let key in pr) if (pr[key] === "undefined") delete pr[key];

  const mount = useRef(true),
    { navigate, push, goBack } = nav,
    { myid, lang, rus, hhfrmt, setAfterLogin } = useAuth(),
    {
      packageActivePass: { [pr.packID || pr.id || "00"]: packPass },
      setPasses,
      dblistenPass,
      buyPackage,
    } = useClient(),
    ispass = pr.passID || packPass?.id,
    passID = ispass,
    { [passID || "00"]: pass } = useClient().passes,
    packID = pr.packID || pr.id || pass?.packID,
    {
      packages: { [packID]: packg },
      coaches: { [(pass || packg || "00").coachID]: c },
      setPackage,
    } = useSchool(),
    data = ispass ? pass : packg,
    ismypass = pass?.uid === myid,
    parsedData = parsePackage(data, myid, lang),
    activePackg = isvalidPackage(packg),
    { [packID || "00"]: allPasses } = useClient().packageAllPasses,
    { name, price, duration: dur, durLeft, coachID, color } = data || {},
    title = TTL[lang](name, ispass),
    [load, setLoad] = useState(false);

  useEffect(() => {
    return () => (mount.current = false);
  }, []);

  useLayoutEffect(() => {
    let ShareComp = () => (
      <View style={{ marginRight: -12 }}>
        <ShareIcon path={"package/" + packID} style={{ paddingRight: 24 }} />
      </View>
    );

    if (activePackg && title) {
      console.log("useLayoutEffect ", title);
      nav.setOptions({ title: title + " – BLOSS.AM", headerRight: ShareComp });
    }
  }, [!activePackg, title]);

  useEffect(() => {
    if (packID) {
      let dbref = rdbPackages(packID);

      let ondb = (v) => {
        if (!mount.current) return;
        let val = v.exists() && v.val();
        setPackage(val || packID);
        if (!val && !pass) return goBack(), vibroToast(RMVD1[lang]);
      };

      onValue(dbref, ondb);
      return () => rdboff(dbref);
    }
  }, [!packID]);

  useEffect(() => {
    const ondbPasses = (q) => {
      if (!mount.current) return;
      if (q && !q.empty) setPasses(dbQueryToObj(q));
    };

    let passesref =
      myid &&
      packID &&
      query(
        dbPasses(),
        where("uid", "==", myid),
        where("packID", "==", packID),
        dborderBy("created", "desc"),
        dblimit(2)
      );

    let dbListener = ispass
      ? dblistenPass(passID)
      : myid && packID
      ? onSnapshot(passesref, ondbPasses)
      : () => {};
    return () => dbListener();
  }, [!!myid && !!packID]);

  if (!c || !data) return <Loader big />;

  const lastOrders = allPasses?.filter((p) => p.uid === myid),
    hadPaidOrder = ismypass || lastOrders?.some((p) => !!p.to);

  const openMinsBalance = ismypass
    ? () => push("PassBalance", { passID, from: "Package" })
    : null;

  const openOrder = ismypass ? () => push("PassOrder", { passID }) : null;

  const buy = async () => {
    if (!myid)
      return (
        navigate("ProfileStack", { screen: "Login" }),
        setAfterLogin("Package", ispass ? { passID } : { packID })
      );

    if (offline()) return offlineToast();

    let after = () => setLoad(false);

    let proceed = async () => {
      if (offline()) return offlineToast();
      setLoad(true);
      await buyPackage(coachID, packID, after, after);
    };

    //callAlert(`Buy package?`, `You're going to pay ${price}$ for ${name} package for ${data.term} days`,      [{ text: "Confirm", onPress: proceed }]    );
    return proceed();
  };

  let DataComp;

  if (ispass) {
    let {
        passStatusText,
        endTimeStatus,
        passStatusColor,
        passed,
        finished,
        canbook,
      } = parsedData,
      canRenew = finished && isvalidPackage(packg),
      timeText = " " + getTimeText(pass.to, hhfrmt, lang, 1, 1),
      { method } = pass;

    let fulldurText = durtnText(dur, "f", rus);
    if (rus && dur >= 120 && fulldurText.endsWith("са"))
      fulldurText = fulldurText.slice(0, -1) + "ов";

    let renewText = RENEWBTN[lang](
      durtnText(packg?.duration, "f", rus),
      packg?.price
    );

    DataComp = (
      <>
        <RowCentered>
          <Title2 numberOfLines={1}>{rus ? "Статус:" : "Status:"}</Title2>
          <MarkerComp
            text={passStatusText}
            color={passStatusColor}
            style={{ marginTop: 16, marginLeft: 8 }}
          />
        </RowCentered>

        <Touch onPress={openMinsBalance}>
          <BottomRow>
            <Title2 numberOfLines={1}>
              {rus ? "Остаток:" : "Time left:"}
              <BoldTitle2 style={ismypass && { color: BLUE }}>
                {" " + durtnText(durLeft, "f", rus)}
              </BoldTitle2>
            </Title2>
            <Caption numberOfLines={1}>
              {(rus ? "из " : "out of ") + fulldurText}
            </Caption>
          </BottomRow>
        </Touch>

        <Title2 numberOfLines={1}>
          {rus ? "Начало:" : "Started:"}
          <BoldTitle2>
            {dayjs(pass.from).format(
              (passed ? " D MMM YYYY, " : " D MMM ") + hhfrmt
            )}
          </BoldTitle2>
        </Title2>

        {ismypass && (
          <Touch onPress={openOrder}>
            <BottomRow>
              <Title2 numberOfLines={1}>
                {rus ? "Оплата:" : "Payment:"}
                <BoldTitle2 style={{ color: BLUE }}>
                  {" " + pass.price + "$ " + PAYMTHD[lang](method)}
                </BoldTitle2>
              </Title2>
              {/* {method && <Caption>{PAYMTHD[lang](method)}</Caption>} */}
            </BottomRow>
          </Touch>
        )}

        <Title2 numberOfLines={1}>
          {endTimeStatus}:<BoldTitle2>{timeText}</BoldTitle2>
        </Title2>

        {ismypass && canbook && (
          <Button
            big
            text={NEWBKBTN[lang]}
            onPress={() => navigate("CustomEvent", { coachID, passID })}
            style={{ marginTop: 32 }}
          />
        )}
        {ismypass && canRenew && (
          <Button
            big
            text={renewText}
            onPress={buy}
            style={{ marginTop: 32 }}
          />
        )}
      </>
    );
  }

  if (!ispass) {
    const { offerLimit: limit } = data,
      timeText = getTimeText(limit, hhfrmt, lang, 0, "1");

    let buyText = BUYBTN[lang](activePackg, myid, hadPaidOrder);

    DataComp = (
      <>
        <Title2 numberOfLines={1}>
          {rus ? "Длительность:" : "Duration:"}
          <BoldTitle2> {durtnText(dur, "f", rus)}</BoldTitle2>
        </Title2>

        <Title2 numberOfLines={1}>
          {TERMTTL[lang]}
          <BoldTitle2>{" " + data.term + (rus ? " дней" : " days")}</BoldTitle2>
        </Title2>

        {limit && (
          <>
            <Title2 numberOfLines={1}>{AVLBTX[lang]}</Title2>
            <BoldTitle2 style={{ marginTop: 4 }}>{timeText}</BoldTitle2>
          </>
        )}

        <Divider20 />
        <BottomRow>
          <Price>{price}$</Price>
          <Caption style={{ marginBottom: 2 }}>
            {(rus ? "экономия " : "save ") + (c?.price * (dur / 60) - price)}$
          </Caption>
        </BottomRow>

        <Button
          big
          inactive={!activePackg}
          text={buyText}
          onPress={activePackg ? buy : null}
          style={{ marginTop: 24 }}
        />
      </>
    );
  }

  const renderPassOption = (num) => {
    let text = PASSOPTS[lang](num);
    let onPress =
      num === 1
        ? openMinsBalance
        : num == 3
        ? openOrder
        : () => push("Orders", { passID });

    return <FooterOption {...{ text, onPress }} border key={String(num)} />;
  };

  const Footer = lastOrders?.length > 0 && (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1, minHeight: 48 }} />

      {ismypass && [1, 2, 3].map(renderPassOption)}

      <FooterOption
        onPress={() => push("Passes", { packID })}
        text={PASORDRS[lang](ismypass)}
        border={ismypass}
      />

      {ismypass && (
        <FooterOption
          onPress={() => getSuprtAppeal({ myid, passID }, lang)}
          text={CNTSPRT[lang]}
          border
          noarrow
        />
      )}
      {ispass && <AppPromo book style={{ marginBottom: 16 }} />}
    </View>
  );

  return (
    <Container>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <PackImage huge {...{ dur, color }} />

        <Body style={{ paddingBottom: myid ? 16 : 32 }}>
          <PageTitle style={{ lineHeight: 32 }} selectable>
            {title + (ispass ? " " + getPeriodText(pass.from, pass.to) : "")}
          </PageTitle>
          {!ispass && (
            <Desc numberOfLines={1} style={{ marginTop: 8 }}>
              {PRIVDESC[lang]}
            </Desc>
          )}

          {!pr.fromCoachScreen && (
            <CoachCard
              small
              order
              {...{ coachID }}
              style={{ marginVertical: 12 }}
            />
          )}

          {DataComp}
          {pass && <PassBooks {...{ passID }} />}
          {Footer}
        </Body>
      </ScrollView>

      {load && <AbsLoader />}
      {ispass && <CartButtonComp {...{ coachID, pass, navigate }} />}
    </Container>
  );
});

let PassBooks = observer(({ passID }) => {
  const { myid } = useAuth(),
    { activeBooks } = useClient(),
    events = activeBooks.filter((e) => e.clients[myid]?.passID == passID);

  if (!events[0]) return null;
  return (
    <>
      <Divider40 />
      <DatesEventsList books {...{ events }} />
    </>
  );
});

let CartButtonComp = observer(({ pass, ...pr }) => {
  const { id: passID } = pass || {},
    { getCart } = useCart(),
    cart = getCart(passID);

  if (!cart[0] || !pass) return null;
  return <CartButton {...pr} {...{ passID }} quant={cart.length} />;
});

let Title2 = styled(Text18)`
    color: ${DGRAY};
    flex-shrink: 0;
    margin-top: 16px;
  `,
  Desc = styled(Text16)`
    color: ${GRAY};
    flex-shrink: 0;
  `,
  Caption = styled(Desc)`
    margin-left: 6px;
  `,
  BoldTitle2 = styled(Medium18)`
    flex-shrink: 0;
    margin-top: 16px;
  `,
  Price = styled(Medium24)`
    flex-shrink: 0;
  `,
  BottomRow = styled(Row)`
    align-items: flex-end;
  `;

let { CNTSPRT } = translates,
  {
    RMVD1,
    NEWBKBTN,
    AVLBTX,
    PASORDRS,
    PAYMTHD,
    RENEWBTN,
    BUYBTN,
    TERMTTL,
    PASSOPTS,
    TTL,
    PRIVDESC,
  } = translates.Package;
