import React, { useCallback, useEffect, useRef, useState } from "react";
import { FlatList } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import {
  query,
  startAt,
  where,
  orderBy as dbOrderBy,
  getDocs,
  limit,
} from "firebase/firestore";
import orderBy from "lodash/orderBy";
import { useAuth, useClient, useSchool } from "../commons/Stores";
import {
  mydbPassed,
  mydbComments,
  dbOrders,
  dbPasses,
  dbQueryToObj,
  genrtItemLayout,
  _ispass,
} from "../commons/utils";
import {
  Loader,
  BlankText,
  BlankView,
  BACKGRAY,
  Refresher,
} from "../commons/UI";
import BookCard, {
  CommentCard,
  commentHeight,
  bookHeight,
} from "../comp/BookCard";
import OrderCard, { getOrderHeight, packHeight } from "../comp/OrderCard";
import { translates } from "../translates";

export default observer(({ navigation: { navigate } }) => {
  const mount = useRef(true),
    { myid, lang } = useAuth(),
    { passedArr, addPassedDBQuery, coaches, programs } = useClient(),
    passedIds = new Set(passedArr.map((e) => e.id)),
    [load, setLoad] = useState(true),
    lastQueryDoc = useRef(2 * Date.now());

  const getPassed = useCallback(
    async (arg) => {
      if (!mount.current) return;
      let ignoreTime = arg === "ignore";
      if (!load && !ignoreTime) return;

      let dbref = query(
        mydbPassed(myid),
        dbOrderBy("from", "desc"),
        startAt(ignoreTime ? 2 * Date.now() : lastQueryDoc.current - 1),
        limit(12)
      );

      let q = await getDocs(dbref);
      if (!mount.current) return;
      if (q?.size) addPassedDBQuery(q);
      if (q.empty || q.size < 12) return setLoad();
      lastQueryDoc.current = q.docs[q.size - 1].data().from;

      // if all docs are already in orders, there'll be no rerender & no new onendreached call, always <Loader /> until u scroll up and then re-scroll down
      let allInstate = q.docs.every((d) => passedIds.has(d.id));
      if (allInstate) return getPassed();
    },
    [!load, passedIds.size, lastQueryDoc.current]
  );

  useEffect(() => {
    getPassed();
    return () => (mount.current = false);
  }, []);

  const onRefresh = useCallback(
    async () => (setLoad(true), getPassed("ignore")),
    [getPassed]
  );

  const renderItem = ({ item: event }) => (
    <BookCard
      passed
      program={programs[event.progID]}
      {...{ event, coaches, lang, navigate }}
    />
  );

  const coachesSet = new Set(passedArr.map((e) => e.coachID));
  useCoaches(coachesSet);

  return (
    <FlatList
      {...historyListProps(load, "classes", lang, onRefresh)}
      {...{ renderItem, getItemLayout }}
      data={passedArr}
      onEndReached={getPassed}
    />
  );
});

export const Comments = observer(({ navigation: { navigate } }) => {
  const mount = useRef(true),
    { myid, lang } = useAuth(),
    { comments, addPassedDBQuery, coaches, programs } = useClient(),
    commentsIds = new Set(comments.map((e) => e.id)),
    [load, setLoad] = useState(true),
    lastQueryDoc = useRef(2 * Date.now());

  const getComments = useCallback(
    async (arg) => {
      if (!mount.current) return;
      let ignoreTime = arg === "ignore";
      if (!load && !ignoreTime) return;

      let dbref = query(
        mydbComments(myid),
        startAt(ignoreTime ? 2 * Date.now() : lastQueryDoc.current - 1),
        limit(12)
      );

      let q = await getDocs(dbref);
      if (!mount.current) return;
      if (q?.size) addPassedDBQuery(q);
      if (q.empty || q.size < 12) return setLoad();
      lastQueryDoc.current = q.docs[q.size - 1].data().to;

      // if all docs are already in orders, there'll be no rerender & no new onendreached call, always <Loader /> until u scroll up and then re-scroll down
      let allInstate = q.docs.every((d) => commentsIds.has(d.id));
      if (allInstate) return getComments();
    },
    [!load, commentsIds.size, lastQueryDoc.current]
  );

  useEffect(() => {
    getComments();
    return () => (mount.current = false);
  }, []);

  const onRefresh = useCallback(
    async () => (setLoad(true), getComments("ignore")),
    [getComments]
  );

  const renderItem = ({ item: e }) => (
    <CommentCard
      event={e}
      program={programs[e.progID]}
      coach={coaches[e.coachID]}
      {...{ navigate, lang }}
    />
  );

  const coachesSet = new Set(comments.map((e) => e.coachID));
  useCoaches(coachesSet);

  return (
    <FlatList
      {...historyListProps(load, "comments", lang, onRefresh)}
      {...{ renderItem }}
      data={comments}
      getItemLayout={comntsLayout}
      onEndReached={getComments}
    />
  );
});

export const Orders = observer(
  ({ navigation: { push: navigate }, route: { params: p } }) => {
    const mount = useRef(true),
      { passID } = p || {},
      { myid, lang, hhfrmt } = useAuth(),
      { programs, coaches } = useSchool(),
      { orders, addOrdersDBQuery } = useClient(),
      allOrders = Object.values(orders || {}),
      filtered = passID
        ? allOrders.filter((o) => o.passID === passID)
        : allOrders,
      sorted = orderBy(filtered, "created", "desc"),
      ordersIds = new Set(sorted.map((e) => e.id)),
      [load, setLoad] = useState(true),
      lastQueryDoc = useRef(Date.now());

    let dbref = query(dbOrders(), where("client", "==", myid));
    if (passID) dbref = query(dbref, where("passID", "==", passID));

    const getOrders = useCallback(
      async (arg) => {
        if (!mount.current) return;
        let ignoreTime = arg === "ignore";
        if (!load && !ignoreTime) return;

        dbref = query(
          dbref,
          dbOrderBy("created", "desc"),
          startAt(ignoreTime ? Date.now() : lastQueryDoc.current - 1),
          limit(12)
        );

        let q = await getDocs(dbref);
        if (!mount.current) return;
        if (q?.size) addOrdersDBQuery(q);
        if (q.empty || q.size < 12) return setLoad();
        lastQueryDoc.current = q.docs[q.size - 1].data().created;

        // if all docs are already in orders, there'll be no rerender & no new onendreached call, always <Loader /> until u scroll up and then re-scroll down
        let allInstate = q.docs.every((d) => ordersIds.has(d.id));
        if (allInstate) return getOrders();
      },
      [!load, ordersIds.size, lastQueryDoc.current]
    );

    useEffect(() => {
      getOrders();
      return () => (mount.current = false);
    }, []);

    const onRefresh = useCallback(
      async () => (setLoad(true), getOrders("ignore")),
      [getOrders]
    );

    const coachesSet = new Set(sorted.map((e) => e.coachID));
    useCoaches(coachesSet);

    const renderItem = ({ item: order }) => (
      <OrderCard
        coach={coaches[order.coachID]}
        {...{ order, programs, lang, hhfrmt, navigate }}
      />
    );

    return (
      <FlatList
        {...historyListProps(load, "orders", lang, onRefresh)}
        {...{ renderItem }}
        data={sorted}
        getItemLayout={ordersLayout}
        onEndReached={getOrders}
      />
    );
  }
);

export const Passes = observer(({ navigation: nav, route: { params: p } }) => {
  const mount = useRef(true),
    { packID } = p || {},
    { push: navigate } = nav,
    { myid, lang, hhfrmt } = useAuth(),
    { programs, coaches } = useSchool(),
    { passes, setPasses } = useClient(),
    unfiltered = Object.values(passes || {}),
    filtered = packID
      ? unfiltered.filter((p) => p.packID === packID)
      : unfiltered,
    passesArr = orderBy(filtered, "created", "desc"),
    passesIds = new Set(passesArr.map((e) => e.id)),
    [load, setLoad] = useState(true),
    lastQueryDoc = useRef(Date.now());

  // let dbref = dbPasses.where("uid", "==", myid);
  let dbref = query(dbPasses(), where("uid", "==", myid));
  if (packID) dbref = query(dbref, where("packID", "==", packID));

  const getPasses = useCallback(
    async (arg) => {
      if (!mount.current) return;
      let ignoreTime = arg === "ignore";
      if (!load && !ignoreTime) return;

      dbref = query(
        dbref,
        dbOrderBy("created", "desc"),
        startAt(ignoreTime ? Date.now() : lastQueryDoc.current - 1),
        limit(12)
      );

      let q = await getDocs(dbref);
      if (!mount.current) return;
      if (q?.size) setPasses(dbQueryToObj(q));
      if (q.empty || q.size < 12) return setLoad();
      lastQueryDoc.current = q.docs[q.size - 1].data().created;

      let allInstate = q.docs.every((d) => passesIds.has(d.id));
      if (allInstate) return getPasses();
    },
    [!load, passesIds.size, lastQueryDoc.current]
  );

  useEffect(() => {
    getPasses();
    return () => (mount.current = false);
  }, []);

  const onRefresh = useCallback(
    async () => (setLoad(true), getPasses("ignore")),
    [getPasses]
  );

  const coachesSet = new Set(passesArr.map((e) => e.coachID));
  useCoaches(coachesSet);

  const renderItem = ({ item: p }) => (
    <OrderCard
      ispackage
      coach={coaches[p.coachID]}
      order={p}
      {...{ programs, myid, lang, hhfrmt, navigate }}
    />
  );

  return (
    <FlatList
      {...historyListProps(load, "packages purchases", lang, onRefresh)}
      {...{ renderItem }}
      data={passesArr}
      getItemLayout={packsLayout}
      onEndReached={getPasses}
    />
  );
});

const useCoaches = (set) => {
  const { coaches, getCoaches } = useSchool(),
    missCoaches = [...set].filter((c) => !coaches[c]);

  useEffect(() => {
    if (missCoaches.length) getCoaches(missCoaches);
  }, [missCoaches.length]);
};

let keyExtractor = (e) => e.id,
  getItemLayout = (_, i) => genrtItemLayout(bookHeight + 18, i),
  comntsLayout = (_, i) => genrtItemLayout(commentHeight + 18, i),
  packsLayout = (ar, i) => genrtItemLayout(packHeight(_ispass(ar[i])) + 18, i),
  ordersLayout = (ar, i) => {
    let eventsQty = Object.keys(ar[i].events || {}).length,
      hg = getOrderHeight(eventsQty || 1);
    return genrtItemLayout(hg + 18, i);
  },
  ItemSeparatorComponent = styled.View`
    height: 18px;
  `;

const historyListProps = (load, type, lang, onRefresh) => ({
  ItemSeparatorComponent,
  keyExtractor,
  onEndReachedThreshold: 0.1,
  refreshControl: onRefresh ? <Refresher update={onRefresh} /> : undefined,
  ListFooterComponent: load && <Loader style={{ marginTop: 24 }} />,
  ListEmptyComponent: !load && (
    <BlankView>
      <BlankText>{EMPTY[lang](type)}</BlankText>
    </BlankView>
  ),
  initialNumToRender: 3,
  maxToRenderPerBatch: 5,
  windowSize: 9,
  contentContainerStyle: { flexGrow: 1, padding: 24 },
  style: { backgroundColor: BACKGRAY },
});

let { EMPTY } = translates.History;
