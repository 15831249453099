import React from "react";
import styled from "styled-components/native";
import dayjs from "dayjs";
import { useSchool } from "../commons/Stores";
import {
  ages,
  getSuprtAppeal,
  callAlert,
  isbookblEvent,
  parseOrder,
  durtnText,
  parsePackage,
  localLang,
  refundsAlertButton,
  horizCardWidth,
  _ispass,
} from "../commons/utils";
import {
  Touch,
  DGRAY,
  GRAY,
  Text16,
  Text14,
  shadow4,
  LITEBORDER,
  RowCentered,
  Medium16,
} from "../commons/UI";
import { BookCardRow, BookCardWrap } from "./BookCard";
import { DurComp, TimeComp } from "./TimeComp";
import PackageCard from "./PackageCard";
import { translates } from "../translates";

export default ({ order: o, ispackage, coach, hhfrmt, ...r }) => {
  if (!o) return null;

  let { id } = o,
    lang = r.lang || localLang(),
    rus = lang === "ru",
    parsed = ispackage ? parsePackage(o, r.myid, lang) : parseOrder(o, lang),
    {
      paid,
      quantText,
      eventsArr,
      paidByPass,
      totalDur,
      cancelled,
      expired,
      ispass,
    } = parsed,
    name = coach?.name || o.coachID,
    status = ispackage ? parsed.orderStatusText : parsed.statusText;

  let color = expired || (cancelled && !o.from) ? GRAY : DGRAY,
    txtStyle = { style: { color } };

  let TitleRowComp = (
    <TitleRow>
      <RowCentered style={{ flex: 1 }}>
        <Medium16 {...txtStyle}>
          {TTL1[lang](ispackage && paid) +
            dayjs(o.created).format(" D MMM YYYY")}
        </Medium16>
      </RowCentered>

      <Status
        style={{
          color: parsed[ispackage ? "orderStatusColor" : "statusColor"],
        }}
      >
        {status}
      </Status>
    </TitleRow>
  );

  const onPress = () =>
    r.navigate(ispass ? "Package" : ispackage ? "PassOrder" : "Order", {
      [ispackage ? "passID" : "orderID"]: id,
    });

  if (ispackage) {
    return (
      <Touch {...{ onPress }}>
        <PackageWrap style={ispass && { height: packHeight("big") }}>
          {TitleRowComp}

          {!ispass && (
            <BookCardRow style={{ marginTop: 8 }}>
              <Text {...txtStyle} numberOfLines={1}>
                {rus
                  ? o.name + " пакет тренера " + name
                  : name + "'s " + o.name + " package"}
              </Text>
            </BookCardRow>
          )}

          <BookCardRow style={{ marginTop: 8 }}>
            <Text style={{ flex: 1, color }} numberOfLines={1}>
              {durtnText(o.duration, "f", rus) + PASINCLD[lang]}
            </Text>
            <Price {...txtStyle}>{o.price + "$"}</Price>
          </BookCardRow>

          {ispass && (
            <PackageCard
              order
              ispass
              showCoach
              p={o}
              {...{ lang, hhfrmt, coach, onPress }}
              style={{ width: horizCardWidth, marginTop: 16 }}
            />
          )}
        </PackageWrap>
      </Touch>
    );
  }

  const sumtext = paidByPass
    ? durtnText(totalDur, totalDur <= 60, rus)
    : o.total + "$";

  const renderEvent = (e) => (
    <EventRow key={e.id}>
      <OrderEvent
        mini
        {...{ e }}
        orderPaid={paid && o.method}
        orderExpired={cancelled || expired}
        prog={r.programs[e.progID]}
      />
    </EventRow>
  );

  return (
    <Touch {...{ onPress }}>
      <OrderWrap style={{ height: getOrderHeight(eventsArr.length) }}>
        {TitleRowComp}

        <RowCentered style={{ marginVertical: 8 }}>
          <Text16 style={{ color, flex: 1 }} numberOfLines={1}>
            {quantText + (rus ? " с " : " with ") + name}:
          </Text16>
          <Price {...txtStyle}>{sumtext}</Price>
        </RowCentered>

        {eventsArr.map(renderEvent)}
      </OrderWrap>
    </Touch>
  );
};

export const OrderEvent = ({ orderPaid, navigate, ...r }) => {
  const { handleLevel } = useSchool(),
    lang = r.lang || localLang(),
    rus = lang === "ru";

  let e = r.e || r.event,
    { id, active, privat, custom, from, to, coachID } = e,
    { quant, sum, passID } = e.client,
    booked = orderPaid && active,
    rescdled = active && e.reschedule,
    cancelled = !!e.cancelType,
    expired = !active && !cancelled,
    changed = !active || rescdled,
    passed = to < Date.now(),
    refund = e.refund || (orderPaid === "card" && !active && sum),
    passRefund = passID && e.passRefund,
    gray = expired || r.orderExpired || (r.mini && cancelled),
    color = gray ? GRAY : DGRAY,
    greenTime = booked && !passed,
    txtStyle = { style: { color } };

  let progName = r.prog?.name || (rus ? "Программа " : "Program ") + e.progID;

  const First2Rows = (
    <>
      <BookCardRow>
        <RowCentered style={{ flex: 1 }}>
          <TimeComp
            {...{ from }}
            color={greenTime ? undefined : color}
            strict
          />
          <DurComp
            {...{ from, to }}
            cancel={gray}
            passed={greenTime ? undefined : true}
          />
        </RowCentered>

        {changed && <Text>{EVSTTS[lang](cancelled, rescdled)}</Text>}
      </BookCardRow>

      <BookCardRow style={{ marginVertical: 8 }}>
        <Text {...txtStyle}>{progName}</Text>

        <GrayText>
          {e.group && e.level
            ? `  ${handleLevel(e.level).toLowerCase()}, `
            : "  "}
          {ages[lang].find((a) => a.id >= e.age).name}
        </GrayText>
      </BookCardRow>
    </>
  );

  if (r.mini) return First2Rows;

  const { myid: uid, hhfrmt, addCart } = r,
    canOpen = booked || (!custom && !passed);

  const openEvent = canOpen ? () => navigate("Event", { id }) : null;

  const onchange = () =>
    changedMarkPress(
      e,
      refund || passRefund,
      orderPaid,
      r.push,
      openEvent,
      lang,
      hhfrmt
    );

  const onaddCart = () => {
    if (passed || !isbookblEvent(e))
      return callAlert(...ALRTEXPRD[lang](passed));

    let cartObj = { ...e, client: { uid, quant, sum, passID } };
    let add = () => (addCart([cartObj]), navigate("Cart", { coachID }));

    return callAlert(...(custom ? CSTMADDCART : GRPADDCART)[lang], [
      { label: ADDCARTLBL[lang], onClick: add },
    ]);
  };

  const onPress = changed
    ? onchange
    : canOpen
    ? openEvent
    : passed && r.orderExpired
    ? null
    : onaddCart;

  return (
    <Touch {...{ onPress }}>
      <EventWrap style={canOpen && { ...shadow4, height: 112, borderWidth: 0 }}>
        {First2Rows}
        <BookCardRow>
          <Text style={{ flex: 1, color }}>
            {quant || 1}
            {rus ? " чел, " : ` person${quant < 2 ? "" : "s"}, `}
            {CLSTYPE[lang](privat, custom, e.isTrial)}
          </Text>
          {/* {cancelled && refund && <Refund>refund</Refund>} */}
          {!passID && <Price {...txtStyle}>{sum + "$"}</Price>}
        </BookCardRow>
      </EventWrap>
    </Touch>
  );
};

let changedMarkPress = (
  e,
  refund,
  orderPaid,
  push,
  openEvent,
  lang,
  hhfrmt
) => {
  let {
      id,
      client: { uid: myid, orderID, method, passID },
    } = e,
    rus = lang === "ru",
    paidByPass = orderPaid && method === "pass",
    sum = paidByPass ? true : e.client.sum,
    reschedule = e.active && e.reschedule,
    cancelled = !!e.cancelType,
    changedBy = cancelled ? e.cancelBy : e.rescheduleBy,
    changedByText = "";

  if (changedBy) changedByText = CHGBY[lang](changedBy);

  let time = e.cancelTime || e.time,
    timeText = "";
  if (time)
    timeText = (rus ? "" : "on ") + dayjs(time).format("D MMM " + hhfrmt);

  let refundText = refund
    ? (paidByPass ? PASREFNTX : BALREFNTX)[lang](refund)
    : "";

  let title = CHGDETTL1[lang](cancelled);
  let text;

  if (cancelled) {
    text =
      `${e.cancelType + changedByText} ${timeText}` +
      (refund ? refundText : sum ? CHGENOREF1[lang](paidByPass) : "");
  }

  if (reschedule && !cancelled) {
    let { from, to } = e.updates?.[time] || {},
      rescText = reschedule || "";
    if (from)
      rescText =
        RSCLDTM[lang] +
        dayjs(from).format("D MMM " + hhfrmt) +
        dayjs(to).format(" – " + hhfrmt) +
        ` (${durtnText(to - from, "f", rus)})`;

    text =
      RSCLDTX1[lang] +
      changedByText +
      ` ${timeText}. \n` +
      rescText +
      refundText;
  }

  if (!reschedule && !cancelled) {
    title = OTHCHGTTL[lang](orderPaid);

    let beginText = OTHCHGTX1[lang](e.custom || e.privat),
      midText = OTHCHGTX2[lang](orderPaid);

    text = beginText + midText + (refund ? `${refundText} ${timeText}` : "");
  }

  let openBalance = () =>
    paidByPass
      ? push("PassBalance", { passID, orderID })
      : push("BalanceRecords", { orderID });

  return callAlert(title, text, [
    openEvent && { label: OPNCLS[lang], onClick: openEvent },
    refund && { label: OPNBLNC[lang](paidByPass), onClick: openBalance },
    cancelled && sum && !refund && refundsAlertButton,
    {
      label: CNTSPRT[lang],
      onClick: () => getSuprtAppeal({ myid, id, orderID }, lang),
    },
  ]);
};

export let getOrderHeight = (qty) => 92 + qty * 81,
  orderHeight = getOrderHeight(1),
  packHeight = (big) => (big ? 276 : 114),
  eventHeight = 114;

let OrderWrap = styled(BookCardWrap)`
    height: ${orderHeight}px;
    padding: 20px 16px 0;
  `,
  PackageWrap = styled(OrderWrap)`
    height: ${packHeight()}px;
  `,
  EventWrap = styled(BookCardWrap)`
    border: 1px solid ${LITEBORDER};
    height: ${eventHeight}px;
  `,
  TitleRow = styled(BookCardRow)`
    height: 22px;
  `,
  Text = styled(Text14)`
    color: ${DGRAY};
  `,
  GrayText = styled(Text)`
    color: ${GRAY};
  `,
  Status = styled(Text14)`
    color: ${DGRAY};
    flex-shrink: 0;
  `,
  Price = styled(Text16)`
    line-height: 18px;
    margin-left: 16px;
  `,
  EventRow = styled.View`
    border-top-width: 1px;
    border-color: ${LITEBORDER};
    padding-top: 16px;
    margin-top: 8px;
  `;

let { CNTSPRT, CLSTYPE } = translates,
  {
    TTL1,
    EVSTTS,
    ALRTEXPRD,
    CSTMADDCART,
    GRPADDCART,
    ADDCARTLBL,
    CHGBY,
    PASREFNTX,
    BALREFNTX,
    CHGDETTL1,
    CHGENOREF1,
    RSCLDTM,
    RSCLDTX1,
    OTHCHGTTL,
    OTHCHGTX1,
    OTHCHGTX2,
    OPNCLS,
    OPNBLNC,
    PASINCLD,
  } = translates.OrderCard;
