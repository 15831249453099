export const translates = {
  NET: {
    en: (e) =>
      `Make sure you have internet connection, then try again.\n\n` +
      (e.message || e),
    ru: (e) =>
      `Убедитесь, что вы подключены к интернету, затем попробуйте снова.\n\n` +
      (e.message || e),
  },
  CNTSPRT: {
    en: "Contact support",
    ru: "Написать в поддержку",
  },
  CLSQNT: {
    en: (qnt) => (qnt === 1 ? "1 class" : qnt + " classes"),
    ru: (qnt) =>
      qnt === 1 ? "1 занятие" : qnt + " заняти" + (qnt < 5 ? "я" : "й"),
  },
  PASSPRCH: {
    en: (name, coach) => coach + `'s ${name} package purchase`,
    ru: (name, coach) => `Покупка пакета ${name} у тренера ` + coach,
  },
  REFNDPOL: {
    en: "Refunds policy",
    ru: "Политика возвратов",
  },
  CLSTYPE: {
    en: (privat, custom, trial) =>
      trial
        ? "free trial"
        : privat
        ? "private class"
        : custom
        ? "my group"
        : "group",
    ru: (privat, custom, trial) =>
      trial
        ? "пробный урок"
        : privat
        ? "частное занятие"
        : custom
        ? "моя группа"
        : "группа",
  },

  App: {
    CDPSH: {
      en: "Download a small update with some bug fixes (~1.1 MB)?",
      ru: "Скачать небольшое обновление с исправлением некоторых ошибок (~1.1 МБ)?",
    },
    PASORDRS: {
      en: "Package classes orders",
      ru: "Заказы занятий по абонементу",
    },
    PCKPASESS: {
      en: "Package purchases",
      ru: "Покупки пакета",
    },
    HEADTTL: {
      en: (s) =>
        s === "Balance"
          ? "Make a deposit"
          : s === "History"
          ? "Passed classes history"
          : s === "Orders"
          ? "Classes orders history"
          : s === "Passes"
          ? "Packages purchases"
          : s === "Comments"
          ? `Coaches' comments`
          : s === "BalanceRecords"
          ? "Balance history"
          : s === "PassBalance"
          ? "Package minutes history"
          : s === "CoachGroups"
          ? "Coach's group classes"
          : s === "CoachPrivats"
          ? "Coach's 1-on-1 classes"
          : "",
      ru: (s) =>
        s === "Balance"
          ? "Баланс аккаунта"
          : s === "History"
          ? "Прошедшие занятия"
          : s === "Orders"
          ? "Заказы занятий"
          : s === "Passes"
          ? "Покупки пакетов"
          : s === "Comments"
          ? "Комментарии тренеров"
          : s === "BalanceRecords"
          ? "История баланса"
          : s === "PassBalance"
          ? "История минут абонемента"
          : s === "CoachGroups"
          ? "Групповые занятия с тренером"
          : s === "CoachPrivats"
          ? "Частные занятия с тренером"
          : "",
    },

    WEBTTL: {
      en: (s) =>
        s === "Home"
          ? "Rhythmic gymnastics & ballet online classes"
          : s === "Profile"
          ? "My profile"
          : ["Coach", "CoachModal"].includes(s)
          ? "Coach"
          : s === "Event"
          ? "Class"
          : s === "Order"
          ? "Order"
          : s === "CustomEvent"
          ? "Book a custom class"
          : s === "Cart"
          ? "Cart"
          : s === "AddInfo"
          ? "Information"
          : s === "Filters"
          ? "Filters"
          : s === "Image"
          ? "Image"
          : s === "EditProfile"
          ? "Edit my profile"
          : s === "Login"
          ? "Sign in"
          : s === "Promo"
          ? "Get a bonus"
          : s === "Package"
          ? "Package"
          : s === "PassOrder"
          ? "Package purchase"
          : "",
      ru: (s) =>
        s === "Home"
          ? "Художественная гимнастика и балет онлайн"
          : s === "Profile"
          ? "Мой профиль"
          : ["Coach", "CoachModal"].includes(s)
          ? "Тренер"
          : s === "Event"
          ? "Занятие"
          : s === "Order"
          ? "Заказ"
          : s === "CustomEvent"
          ? "Забронировать занятие"
          : s === "Cart"
          ? "Корзина"
          : s === "AddInfo"
          ? "Информация"
          : s === "Filters"
          ? "Фильтры"
          : s === "Image"
          ? "Изображение"
          : s === "EditProfile"
          ? "Редактировать профиль"
          : s === "Login"
          ? "Войти в аккаунт"
          : s === "Promo"
          ? "Получить бонус"
          : s === "Package"
          ? "Пакет"
          : s === "PassOrder"
          ? "Покупка пакета"
          : "",
    },
  },

  AuthStore: {
    GOER1: {
      en: [
        "Error with Google",
        'Google Play Services are outdated or missing. This is a necessary app from Android. To update, launch the Google app on this phone and search there "Google Play Services" or "Google Play Services"',
      ],
      ru: [
        "Ошибка с Google",
        'Службы Google Play устарели или отсутствуют. Это необходимое приложение от Android. Чтобы обновить, запустите приложение Google на этом телефоне и найдите там "Google Play Services" или "Google Play Services"',
      ],
    },

    APER: {
      en: [
        "Error with Apple",
        "No token received. Make sure you have an internet access, then restart the app and try again",
      ],
      ru: [
        "Ошибка с Apple",
        "Токен не получен. Убедитесь, что у вас есть доступ в интернет, затем перезапустите приложение и попробуйте снова",
      ],
    },
    INTRNTERR: {
      en: (er, provider) => [
        "Error with " + provider,
        `Make sure you have an internet access, then restart the app and try again\n\n` +
          (er.message || String(er)),
      ],
      ru: (er, provider) => [
        "Ошибка с " + provider,
        `Убедитесь, что у вас есть доступ в интернет, затем перезапустите приложение и попробуйте снова\n\n` +
          (er.message || String(er)),
      ],
    },

    ERTYPE1: {
      en: "update your profile data",
      ru: "обновить данные вашего профиля",
    },
  },
  ClientStore: {
    PASER: {
      en: (exists) =>
        "The package " + (exists ? "isn't active anymore" : "was cancelled"),
      ru: (exists) =>
        "Абонемент " + (exists ? "больше не активен" : "был отменен"),
    },

    BUYPCKG1: {
      en: "Couldn't buy package",
      ru: "Не удалось купить пакет",
    },
    BUYPCKG3: {
      en: (term) => `for ${term} days`,
      ru: (term) => `на ${term} дней`,
    },
    BUYPCKG4: {
      en: [
        "Couldn't buy package",
        "Please, check your internet connection and try again",
      ],
      ru: [
        "Не удалось купить пакет",
        "Пожалуйста, проверьте доступ в интернет и попробуйте снова",
      ],
    },
    ORDPASER: {
      en: (isactivePass) => [
        "Package was changed",
        isactivePass
          ? "The package has not enough minutes to cover these classes. Please, check your cart and confirm the order again"
          : "Your package was changed just before the order. Please, check your cart and confirm the order again",
      ],
      ru: (isactivePass) => [
        "Абонемент изменился",
        isactivePass
          ? "На абонементе недостаточно минут для покрытия этих занятий. Пожалуйста, проверьте вашу корзину и подтвердите заказ снова"
          : "Ваш абонемент изменился прямо перед заказом. Пожалуйста, проверьте вашу корзину и подтвердите заказ снова",
      ],
    },

    ORDTRL: {
      en: [
        "No free trial",
        "Free trial is not available for you anymore. Please, review your cart, change it if needed and confirm the order again",
      ],
      ru: [
        "Пробное занятие недоступно",
        "Бесплатное пробное занятие больше не доступно. Пожалуйста, проверьте вашу корзину, измените ее при необходимости и подтвердите заказ снова",
      ],
    },
    ORDBAL1: {
      en: "Rescheduled class payment",
      ru: "Оплата переноса занятия",
    },
    ORDER1: {
      en: "Order failed",
      ru: "Не удалось оформить заказ",
    },
    ORDBAL2: {
      en: [
        "Balance updated",
        `Your balance had been reduced before the order, so we couldn't charge it`,
      ],
      ru: [
        "Баланс обновлен",
        `Ваш баланс уменьшился перед заказом, поэтому мы не смогли его списать`,
      ],
    },
    RSCER1: {
      en: "Couldn't re-schedule",
      ru: "Не удалось перенести",
    },
    RSCERTXT: {
      en: "Your booking was just removed. You can go back to it and try again",
      ru: "Ваша бронь занятия только что отменена. Вы можете вернуться назад и попробовать снова",
    },
    RSC2: {
      en: `Class was re-scheduled by client`,
      ru: `Занятие перенесено клиентом`,
    },
    CNCL1: {
      en: ["Couldn't cancel", "Couldn't cancel the booking, please try again"],
      ru: [
        "Не удалось отменить",
        "Не удалось отменить бронь, пожалуйста, попробуйте снова",
      ],
    },
    CNCL2: {
      en: (type) => type + ` has already been cancelled before`,
      ru: (type) => type + ` уже отменилось ранее`,
    },
    CNCLDSC1: {
      en: "Class was cancelled and replaced with a re-scheduled one",
      ru: "Занятие отменено для создания нового в другое время",
    },
    CNCLDSC2: {
      en: (hisPrivat) => (hisPrivat ? "Class" : "Booking") + " was cancelled",
      ru: (hisPrivat) => (hisPrivat ? "Занятие" : "Бронирование") + " отменено",
    },
    RFND1: {
      en: "Too late for refund",
      ru: "Возврат средств недоступен",
    },
    RFND2: {
      en: `Sorry, you've confirmed too late – the class starts in less than 8 hours from now. So, the refund isn't available anymore. If you want to continue, press "Cancel booking" again`,
      ru: `Извините, вы подтвердили слишком поздно – занятие начинается менее чем через 8 часов. Возврат средств больше не доступен. Если вы хотите продолжить, нажмите "Отменить бронь" снова`,
    },
    TOAST1: {
      en: (added) => added + ` booking(s) added`,
      ru: (added) =>
        `Добавлено ${added} бронировани` + (added === 1 ? "е" : "я(й)"),
    },
    TOAST2: {
      en: (infocus, time) =>
        "Zoom data was updated" + (infocus ? "" : " in your class " + time),
      ru: (infocus, time) =>
        "Обновились данные доступа в Zoom" +
        (infocus ? "" : " к занятию " + time),
    },
    TOAST3: {
      en: (time) => `Your booking ${time} was cancelled`,
      ru: (time) => `Ваше бронирование ${time} отменено`,
    },
    TOAST4: {
      en: (infocus, time) =>
        infocus ? "This booking was added" : `A booking ${time} was added`,
      ru: (infocus, time) =>
        infocus ? "Бронирование добавлено" : `Добавлено бронирование` + time,
    },
    TOAST5: {
      en: (infocus, time) =>
        infocus ? "This class was updated" : `Your class ${time} was updated`,
      ru: (infocus, time) =>
        infocus ? "Занятие обновилось" : "Обновилось ваше занятие" + time,
    },
  },
  EffectsProvider: {
    BALTX: {
      en: (diff) =>
        `Your balance has been ${diff > 0 ? "in" : "de"}creased by ${diff}$`,
      ru: (diff) =>
        `Ваш баланс ${diff > 0 ? "увелич" : "уменьш"}ился на ${diff}$`,
    },
  },
  UI: {
    REFND: {
      en: (cart) =>
        `Free class re-schedule ${
          cart ? "or cancellation with 100% refund, " : ""
        }if you apply no later than 8 hours before the class starts.`,
      ru: (cart) =>
        `Бесплатный перенос занятия ${
          cart ? "или отмена занятия с возвратом 100% средств " : ""
        }не позднее, чем за 8 часов до начала.`,
    },
    TRL: {
      en: (myid) =>
        myid
          ? `To book a free trial class, create a private 30- or 45-minute class down the page`
          : "To get a free trial class, sign up first",
      ru: (myid) =>
        myid
          ? `Для бесплатного пробного занятия создайте свое 30- или 45-минутное занятие внизу страницы`
          : "Зарегистрируйтесь для получения бесплатного пробного занятия",
    },
    TMZTX: {
      en: "your timezone",
      ru: "ваш часовой пояс",
    },
    SHOWMORE: {
      en: "  Show more",
      ru: "  Показать больше",
    },
  },
  utils: {
    SPRT1: {
      en: (myid) =>
        "Hello! " +
        (myid
          ? "My user id is " + myid + ". I use"
          : "I'm unregistered user of") +
        " your web app. ",
      ru: (myid) =>
        "Добрый день! " +
        (myid
          ? `Мой id – ${myid}, использую ваше веб-приложение. `
          : "Я не зарегистрированный пользователь вашего веб-приложения. "),
    },
    SPRT2: {
      en: (iscommon) => "I have an issue" + (iscommon ? "" : " with my "),
      ru: (iscommon) => "У меня вопрос" + (iscommon ? "" : " насчет "),
    },
    SPRT3: {
      en: (text) => [
        `1. Copy your data`,
        "Please, press Copy these data and then paste it into your message\n\n" +
          text,
      ],
      ru: (text) => [
        `1. Скопируйте данные`,
        "Нажмите Копировать эти данные и затем вставьте их в ваше сообщение\n\n" +
          text,
      ],
    },
    SPRT4: {
      en: "Choose the way to contact us",
      ru: "Выберите способ связи",
    },

    ORDERSTS: {
      en: (cancel, expired, bypass, paid) =>
        cancel
          ? "cancelled"
          : expired
          ? "expired"
          : bypass
          ? "package"
          : paid
          ? "paid"
          : "pending",
      ru: (cancel, expired, bypass, paid) =>
        cancel
          ? "отменен"
          : expired
          ? "истек"
          : bypass
          ? "абонемент"
          : paid
          ? "оплачен"
          : "ожидание",
    },
    PASSSTS: {
      en: (cancel, completed, finished) =>
        cancel
          ? "cancelled"
          : completed
          ? "completed"
          : finished
          ? "finished"
          : "enrolled",
      ru: (cancel, completed, finished) =>
        cancel
          ? "отменен"
          : completed
          ? "использован"
          : finished
          ? "завершен"
          : "активный",
    },
    PASSENDSTS: {
      en: (completed, finished, passed) =>
        completed
          ? "Completed"
          : finished
          ? "Finished"
          : passed
          ? "Expired"
          : "Ends on",
      ru: (completed, finished, passed) =>
        completed
          ? "Завершен"
          : finished
          ? "Закончился"
          : passed
          ? "Истек"
          : "Закончится",
    },
    PACKORDRSTS: {
      en: (cancelled, expired, paid) =>
        cancelled
          ? "cancelled"
          : expired
          ? "expired"
          : paid
          ? "paid"
          : "pending",
      ru: (cancelled, expired, paid) =>
        cancelled
          ? "отменено"
          : expired
          ? "истекло"
          : paid
          ? "оплачено"
          : "ожидание",
    },

    CLSTP1: {
      en: "Book a scheduled group class with other students (usually 1 student)",
      ru: "Запишитесь на групповое занятие с другими учениками (обычно 1 ученик)",
    },
    CLSTP2: {
      en: (testMode) =>
        "Book your own time and program" +
        (testMode
          ? " in a private class"
          : "! \nMake it private or a public group"),
      ru: (testMode) =>
        "Создайте собственное " +
        (testMode
          ? "частное занятие в удобное время и с любой программой!"
          : "занятие в удобное время и с любой программой! Сделайте его частным или групповым"),
    },
    CLSRT: {
      en: "$ per hour if booking 2+ persons",
      ru: "$ в час при бронировании 2+ человек",
    },

    APPSPROMO: {
      en: (text) => [
        "Get our mobile app",
        text + " and other features for your convenience – in our mobile app!",
      ],
      ru: (text) => [
        "Установите приложение",
        text + " и другие удобства – в нашем мобильном приложении!",
      ],
    },

    CLSCHG1: {
      en: `the start time or duration isn't available for now`,
      ru: `время начала или продолжительность сейчас недоступны`,
    },
    CLSCHG2: {
      en: "it's got expired or the coach pricing had changed",
      ru: "срок действия истек или часовая ставка тренера изменилась",
    },
    CLSCHG3: {
      en: "it's too late to book this start time",
      ru: "бронировать это время уже нельзя",
    },
    CLSCHG4: {
      en: "the class has been changed",
      ru: "занятие изменилось",
    },
    CLSCHG5: {
      en: `it's already been booked`,
      ru: "уже забронировано",
    },
    CLSCHG6: {
      en: "it's already started",
      ru: "уже началось",
    },
    CLSCHG7: {
      en: "the timing is crossed by the previous or the next class",
      ru: "время пересекается с предыдущим или следующим занятием",
    },
    CLSCHG8: {
      en: "you can't book a free trial class anymore",
      ru: "бесплатное пробное занятие больше недоступно",
    },
    CLSCHG9: {
      en: "it's been cancelled or already passed",
      ru: "отменено или уже прошло",
    },
    IMGCMPRS: {
      en: " should be no more than 1 MB. Here are 2 ways to reduce it:\n1. Through a web-service Tinypng.com\n2. Via your messenger (f.e. WhatsApp or Telegram): send this photo to somebody, the messenger will compress it, save the sent photo back on the phone and upload it here again",
      ru: " не должен превышать 1 МБ. Вот 2 способа уменьшить его:\n1. Через веб-сервис Tinypng.com\n2. Через мессенджер (WhatsApp или Telegram): отправьте это фото кому-то, мессенджер его уменьшит, сохраните отправленный файл обратно на телефон и снова загрузите сюда",
    },
    IMGCMPRS2: {
      en: (size) => ["Too big file size", `The image wheight is ${size}, but`],
      ru: (size) => ["Слишком большой файл", `Вес изображения ${size}, а`],
    },
    BOOKLNK: {
      en: `Booking link – `,
      ru: `Ссылка на бронирование – `,
    },
    IMGUPL: {
      en: [
        "Error uploading image",
        "We couldn't upload your image. Please check your internet connection and image wheight. The image wheight",
      ],
      ru: [
        "Ошибка с загрузкой изображения",
        "Не удалось загрузить ваше изображение в базу данных. Пожалуйста, проверьте доступ в интернет и вес изображения. Вес изображения",
      ],
    },

    OPNURL: {
      en: "Couldn't open your browser. The link is copied, launch your browser, then open a new tab there and paste the link into the address bar",
      ru: "Не удалось открыть ваш браузер. Ссылка скопирована, откройте ваш браузер и новую вкладку в нем, затем вставьте ссылку в адресную строку",
    },
    OPNPAY: {
      en: [
        "Couldn't launch your browser",
        "Please, press Copy the payment link, then launch your browser, then open a new tab there and paste paste the link into the address bar",
      ],
      ru: [
        "Не удалось открыть ваш браузер",
        "Нажмите Копировать ссылку на оплату, затем откройте ваш браузер и новую вкладку в нем, вставьте ссылку в адресную строку",
      ],
    },
    ONOFFLN: {
      en: "No internet connection",
      ru: "Нет доступа в интернет",
    },
    HNDLERR: {
      en: (action, err) => [
        "Got an error",
        `Couldn't ${action}, make sure you have internet connection and try again.\n\n` +
          (err.message || err),
      ],
      ru: (action, err) => [
        "Ошибка",
        `Не удалось ${action}, убедитесь, что у вас есть доступ в интернет и попробуйте снова.\n\n` +
          (err.message || err),
        ,
      ],
    },
  },
  orderChecks: {
    CCHER: {
      en: "The coach isn't active anymore",
      ru: "Тренер больше не активен",
    },
    PKGER1: {
      en: "The package isn't available anymore",
      ru: "Пакет больше не доступен",
    },
    PKGER2: {
      en: "The package price has changed to $",
      ru: "Цена пакета изменилась до $",
    },
    PKGER3: {
      en: "The package duration has changed to ",
      ru: "Продолжительность пакета изменилась до ",
    },
    PKGER4: {
      en: "The package valid period has changed to ",
      ru: "Период действия пакета изменился до ",
    },
    PKGER5: {
      en: "You're not allowed to buy this package anymore",
      ru: "Вам больше недоступен этот пакет",
    },
    PASER1: {
      en: "Package pass was finished by client for a renewal",
      ru: "Абонемент завершен клиентом для продления",
    },
    PASER2: {
      en: "You already have an active package membership till ",
      ru: "У вас уже есть активный абонемент до ",
    },
    PASER3: {
      en: "You have a current active booking for this package on ",
      ru: "По этому абонементу есть действующее занятие ",
    },
  },
  notifs: {
    PERALRT: {
      en: [
        "Need notifications permission",
        `We'll notify of your bookings updates, coaches' comments and messages`,
      ],
      ru: [
        "Требуется разрешение на уведомления",
        "Мы будем уведомлять вас об изменениях в ваших бронированиях, о комментариях тренеров и сообщениях от них",
      ],
    },
  },

  index: {
    INTRNT: {
      en: (bad) => (bad ? "Bad" : "No") + " internet connection",
      ru: (bad) =>
        bad ? "Слабое интернет-соединение" : "Нет доступа в интернет",
    },
  },

  Login: {
    TERMS: {
      en: "Terms of service",
      ru: "Условия\nиспользования",
    },
    PRIV: {
      en: "Privacy policy",
      ru: "Политика приватности",
    },
    CNTC: {
      en: "Contacts, support",
      ru: "Контакты, поддержка",
    },
    LANG: {
      en: "Language: English",
      ru: "Язык: русский",
    },
    APPSPROMO: {
      en: "Get a mobile app to chat with coaches, sync bookings with your calendar and more conveniences!",
      ru: "Скачайте мобильное приложение для общения с тренерами, синхронизации бронирований с вашим календарем и других удобств!",
    },
    WEBVIEW: {
      en: (DOTS) =>
        `Sorry, we can't authorize you in third-party in-app browsers. Please, open the site in your device's browser.\n\n Usually, it's being done like this:\n\n 1. Find a Settings icon in this window and tap it. Usually, it's in the in the top-right corner looking like this ${DOTS}\n\n2. Select an option like "Open in Chrome / Safari / other browser" in the appearing Settings list`,
      ru: (DOTS) =>
        `К сожалению, мы не можем авторизовать вас в сторонних встроенных браузерах. Пожалуйста, откройте сайт в браузере вашего устройства.\n\n Обычно это делается так:\n\n 1. Найдите значок Настроек в этом окне и нажмите на него. Обычно он находится в правом верхнем углу и выглядит так ${DOTS}\n\n2. Выберите опцию "Открыть в Chrome / Safari / другом браузере" в появившемся списке настроек`,
    },
  },
  Home: {
    TTL: {
      en: (testmode) => (testmode ? "Schedule" : "Group classes"),
      ru: (testmode) => (testmode ? "Расписание" : "Групповые занятия"),
    },
    LOAD: {
      en: "Loading coaches schedule..",
      ru: "Загрузка расписания тренеров..",
    },
    EMPT1: {
      en: "No classes with these filters.",
      ru: "Нет занятий по этим фильтрам",
    },
    EMPT2: {
      en: (testMode) =>
        `No ${testMode ? "scheduled" : "group"} classes for now`,
      ru: (testMode) =>
        (testMode ? "Запланированные" : "Групповые") +
        " занятия сейчас отсутствуют",
    },
    EMPT3: {
      en: (filter = "") =>
        `Sorry, there are no ${filter} classes or coaches with a free time slot for now`,
      ru: (filter = "") =>
        `Извините, сейчас нет занятий ${filter} и таких тренеров со свободными слотами`,
    },
  },
  Chats: {
    EMPT: {
      en: "Your chats with coaches will be here",
      ru: "Здесь будут ваши чаты с тренерами",
    },
  },
  Profile: {
    TTL1: {
      en: (ispasses) => (ispasses ? "My packages" : "My bookings"),
      ru: (ispasses) => (ispasses ? "Мои абонементы" : "Мои бронирования"),
    },
    TTL2: {
      en: (ispass) => (ispass ? "Pending package" : "Pending order"),
      ru: (ispass) => (ispass ? "Пакет в ожидании" : "Заказ в ожидании"),
    },
    TTL2WEB: {
      en: (ispass, isorder) =>
        ispass ? "Latest package" : isorder ? "Latest order" : "Latest class",
      ru: (ispass, isorder) =>
        ispass
          ? "Последний пакет"
          : isorder
          ? "Последний заказ"
          : "Последнее занятие",
    },

    APPSPROMO: {
      en: (type) =>
        type == "orders"
          ? "Orders & classes history"
          : type == "packages"
          ? "Packages & orders history"
          : "Classes & orders history",
      ru: (type) =>
        type == "orders"
          ? "История заказов и занятий"
          : type == "packages"
          ? "История заказов пакетов и занятий"
          : "История занятий и заказов",
    },

    MENU: {
      en: [
        "Passed classes",
        "Classes orders",
        "Packages purchases",
        "Support, Contact us",
        "Language: English",
        "About us",
      ],
      ru: [
        "Прошедшие занятия",
        "Заказы занятий",
        "Покупки пакетов",
        "Поддержка, Связь с нами",
        "Язык: русский",
        "О нас",
      ],
    },
    BOOKSEMP: {
      en: "Your bookings will be here",
      ru: "Здесь будут ваши бронирования",
    },
  },
  AddInfo: {
    PGTTL1: {
      en: "Private and group classes",
      ru: "Частные и групповые занятия",
    },
    PGTTL2: {
      en: (name, coach, level) =>
        coach
          ? "About " + (name || "the coach")
          : name + (level ? " level" : ""),
      ru: (name, coach, level) =>
        coach ? "О тренере " + (name || "") : name + (level ? " уровень" : ""),
    },
    COMN: {
      en: `Rhythmic gymnastics is a popular sport all over the world. We are glad that we can share knowledge and experience about rhythmic gymnastics. Our school employs professional Russian coaches who have extensive experience in rhythmic gymnastics as a gymnast and as a coach.

Coaches in this rhythmic gymnastics online school can help for gymnasts (and not gymnasts) with any level. All coaches are excellent professionals, we have an honorary title in rhythmic gymnastics in Russia. You can choose an individual and a group training. The coaches create training programs of the appropriate level, we take into account abilities, tasks and together we go to the goal.

Rhythmic gymnastics is about beauty and grace, but it is also about energy-consuming trainings that shape the body.`,
      ru: `Художественная гимнастика — популярный вид спорта по всему миру. Мы рады, что можем делиться знаниями и опытом по художественной гимнастике. В нашей школе работают профессиональные российские тренеры, которые имеют обширный опыт в художественной гимнастике как гимнаст и как тренер.

Тренеры в этой онлайн-школе художественной гимнастики могут помочь гимнастам (и не гимнастам) любого уровня. Все тренеры — отличные профессионалы, у нас есть почетное звание в художественной гимнастике в России. Вы можете выбрать индивидуальное и групповое обучение. Тренеры создают программы обучения соответствующего уровня, мы учитываем способности, задачи и вместе идем к цели.

Художественная гимнастика — это красота и грация, но это также энергоемкие тренировки, формирующие тело.`,
    },
    GRP: {
      en: " means a publicly open class. Any BLOSS.AM client can join it.\n\n",
      ru: " означает открытое для других клиентов занятие. Любой клиент BLOSS.AM может присоединиться к нему.\n\n",
    },
    PRV: {
      en: " means it's created by and designed just for 1 client, other BLOSS.AM clients can't join. But booking a private class, a client still can include 2+ persons, training together. Also a coach provides a text & screenshot comment on a client's progress after private class finishes.\n\nCoaches' hourly rate is equal for both group and private class with 2+ students. The rate is higher for a class with 1 student.",
      ru: " означает, что это занятие создано и предназначено только для 1 клиента, другие клиенты BLOSS.AM не могут присоединиться. Но, забронировав частное занятие, клиент все равно может включить в него 2+ человек, занимающихся вместе. Также тренер предоставляет текстовый и скриншот комментарий о прогрессе клиента после окончания частного занятия.\n\nЧасовая ставка тренера одинакова как для группового, так и для частного занятия с 2+ учениками. Ставка выше для занятия с 1 учеником.",
    },
  },
  Balance: {
    DEPDSC: {
      en: "To book classes you can either pay by card or charge your account deposit balance.\n\n",
      ru: "Для бронирования занятий вы можете оплатить картой или пополнить баланс аккаунта.\n\n",
    },
    PICKMTHD: {
      ru: "1. Выберите способ оплаты:",
      en: "1. Choose a payment method:",
    },
    MINSM: {
      en: "To pay the order, top up the balance at least by:",
      ru: "Для оплаты заказа пополните баланс минимум на:",
    },
    CRDPAYWAY: {
      en: {
        name: "By card",
        desc: "Pay on Stripe with auto-confirmation to balance",
      },
      ru: {
        name: "Картой",
        desc: "Оплатите на Stripe с автоматическим зачислением на баланс",
      },
    },
    CRDER1: {
      en: "Didn't get a link from Stripe",
      ru: "Ссылка от Stripe не получена",
    },
    CRDER2: {
      en: `Please, make sure you have an internet connection and try again.\nIf your network is fine and the problem persists, it means we're having a trouble with card payments for now.\n\nTo pay the order, please, pick another method via button "Pay in other ways"`,
      ru: `Убедитесь, что у вас есть доступ в интернет и попробуйте снова.\nЕсли доступ есть и проблема повторится, значит, у нас сейчас проблемы с оплатой картой.\n\nДля оплаты заказа, выберите другой метод через кнопку "Оплатить другими способами"`,
    },
    CARDBTN: {
      en: (sum) => `Pay by card ${sum}$`,
      ru: (sum) => `Оплатить картой ${sum}$`,
    },
    BTNTX1: {
      en: "Add a link or image",
      ru: "Добавьте ссылку или изображение",
    },
    BTNTX2: {
      en: "Confirm transfer",
      ru: "Подтвердить перевод",
    },

    SENDER1: {
      en: "Too small amount, need 5$ at least",
      ru: "Слишком маленькая сумма, нужно минимум 5$",
    },
    SENDER2: {
      en: "Add a correct link or image",
      ru: "Добавьте правильную ссылку или изображение",
    },
    SENDER3: {
      en: "Error uploading image",
      ru: "Ошибка загрузки изображения",
    },
    SENDER4: {
      en: "Error sending request",
      ru: "Ошибка отправки запроса",
    },
    SENDER5: {
      en: (e) =>
        "We've got an error while sending deposit request. Make sure you have a good internet connection and try again.\n" +
        (e.message || e),
      ru: (e) =>
        "При отправке запроса на пополнение баланса возникла ошибка. Убедитесь, что у вас хорошее интернет-соединение и попробуйте снова.\n" +
        (e.message || e),
    },
    SCS1: {
      en: "Your request has been sent",
      ru: "Ваш запрос отправлен",
    },
    CAPT1: {
      en: "2. After a payment, send us a confirmation request. If possible, provide a link or screenshot, confirming your payment.",
      ru: "2. После оплаты отправьте нам запрос на подтверждение. Если возможно, предоставьте ссылку или скриншот, подтверждающий ваш платеж.",
    },
    CAPT2: {
      en: "I confirm i have sent a payment via ",
      ru: "Я подтверждаю, что перевел средства в ",
    },
    CAPT3: {
      en: "either add a link",
      ru: "либо добавьте ссылку",
    },
    CAPT4: {
      en: "or attach an image, screenshot",
      ru: "или прикрепите изображение, скриншот",
    },
    CAPT5: {
      en: "comment, if needed",
      ru: "комментарий при необходимости",
    },
    CAPT6: {
      ru: "Если возникнут вопросы, мы свяжемся по вашему контакту: ",
      en: "If there are any questions, we will contact you via: ",
    },
    CAPT7: {
      en: "Usually we check payments within 1-2 hours. If it's correct, your account balance will be increased by the paid amount. You'll be notified in any case",
      ru: "Обычно мы проверяем переводы в течение 1-2 часов. Если все верно, ваш баланс увеличится на сумму платежа. В любом случае вы будете уведомлены",
    },
  },
  BalanceRecords: {
    TTL1: {
      en: (ispass) =>
        ispass ? "Minutes transactions" : "Balance transactions",
      ru: (ispass) => (ispass ? "История минут" : "История баланса"),
    },
    TTL2: {
      en: `Minutes left: `,
      ru: `Остаток минут: `,
    },
    FLTRBTN: {
      en: (ispass) => (ispass ? "Package " : "Order "),
      ru: (ispass) => (ispass ? "Абонемент " : "Заказ "),
    },
  },
  Cart: {
    PGTTL: {
      en: (prog) => "Book a " + prog + " class",
      ru: (prog) => "Бронировать занятие " + prog,
    },
    PGTTL2: {
      en: (quant) => `Book ${quant} with `,
      ru: (quant) => `Бронировать ${quant} с `,
    },
    BTNTX1: {
      en: `This package is not yours`,
      ru: "Этот абонемент не ваш",
    },
    BTNTX2: {
      en: `Can't join a someone's private class`,
      ru: "Чужое частное занятие недоступно",
    },
    BTNTX3: {
      en: "Sign in to book",
      ru: "Войдите в аккаунт",
    },
    BTNTX4: {
      en: "Charge my balance",
      ru: "Списать с баланса",
    },

    EVTER1: {
      en: (doc) => "The class is " + (doc ? "cancelled" : "already passed"),
      ru: (doc) => "Занятие " + (doc ? "отменено" : "уже прошло"),
    },
    EVTER2: {
      en: (book) =>
        book
          ? "The class was just booked"
          : "The class was just updated. Check the new info",
      ru: (book) =>
        book
          ? "Занятие уже забронировано"
          : "Занятие только что обновилось. Проверьте новые данные",
    },
    EVTER3: {
      en: "Can't join anymore",
      ru: "Слишком поздно",
    },
    EVTER4: {
      en: `The class is already going on. Can't book it`,
      ru: "Занятие уже началось. Слишком поздно для бронирования",
    },
    UNBOOKBL: {
      en: "too late to book",
      ru: "слишком поздно",
    },

    PASER1: {
      en: "No time left in the package",
      ru: "В абонементе не осталось минут",
    },
    PASER2: {
      en: (is1event) =>
        (is1event ? "the class duration" : "total duration of the classes") +
        "is more than the package's left",
      ru: (is1event) =>
        (is1event ? "длительность занятия" : "общая длительность занятий") +
        " больше, чем остаток минут в абонементе",
    },
    PRIVER: {
      en: "isn't your private",
      ru: "не является вашим частным",
    },
    PASER3: {
      en: "starts later than the package ends",
      ru: "начинается позже, чем закончится абонемент",
    },
    PASER4: {
      en: "has more than 1 person",
      ru: "с более чем 1 учеником",
    },
    PASER5: {
      en: (mypass) =>
        mypass
          ? "Can't place the order. Check the reason (red text) and the hint below"
          : `This package is not yours. You can't use it`,
      ru: (mypass) =>
        mypass
          ? "Не удалось оформить заказ. Ознакомьтесь с причиной (красный текст) и подсказкой ниже"
          : "Вы не можете использовать чужой абонемент",
    },

    PASER7: {
      en: (is1) =>
        "Can't book the class" + (is1 ? "" : "es") + " with this package: ",
      ru: (is1) =>
        "Нельзя забронировать заняти" +
        (is1 ? "е" : "я") +
        " с этим абонементом: ",
    },
    PASER8: {
      en: (is1) =>
        is1
          ? "Press the class data to edit it. Or remove it and create a new one"
          : "Remove extra class(es) or press the booking data to edit it",
      ru: (is1) =>
        is1
          ? "Нажмите на данные занятия, чтобы изменить их. Или удалите его и создайте новое"
          : "Удалите лишние занятия или нажмите на данные брони, чтобы изменить их",
    },
    PASBTN1: {
      en: (cantUse) => (cantUse ? "Change the cart" : "Write off the package"),
      ru: (cantUse) => (cantUse ? "Измените корзину" : "Списать с абонемента"),
    },

    PASCNFRM: {
      en: "Do you want to continue anyway?",
      ru: "Хотите продолжить в любом случае?",
    },

    PASLD: {
      en: "loading your package info..",
      ru: "загружаю информацию об абонементе..",
    },
    PASRST1: {
      en: (rest) =>
        `The package will be left with ${rest} minutes after this order. You won't be able to use it for a new booking, since the minimum class duration is 30 minutes.`,
      ru: (rest) =>
        `После этого заказа в абонементе останется ${rest} минут. Вы не сможете использовать его для нового бронирования, так как минимальная продолжительность занятия 30 минут.`,
    },
    PASRST2: {
      en: (is1event) =>
        `We recommend you to increase ${
          is1event ? "the" : "some"
        } class duration by 15 minutes. Press your booking data to edit`,
      ru: (is1event) =>
        `Мы рекомендуем вам увеличить ${
          is1event ? "длительность занятия" : "некоторые занятия"
        } на 15 минут. Нажмите на данные вашей брони, чтобы изменить их`,
    },
    TRL1: {
      en: "To get a free trial class, the first class here should be ",
      ru: "Для получения пробного занятия, первое занятие должно быть ",
    },
    ALRTL: {
      en: "Can't book the class",
      ru: "Нельзя забронировать занятие",
    },
    ALRTX: {
      ru: (num, time) => `Занятие #${num} ${time} нельзя забронировать – `,
      en: (num, time) => `The class #${num} on ${time} can't be booked – `,
    },
    OFR1: {
      en: ".\nEdit, replace or remove one of the crossed classes",
      ru: ".\nИзмените, замените или удалите одно из пересекающихся занятий",
    },
    OFR2: {
      en: ".\nEdit or remove it",
      ru: ".\nИзмените или удалите его",
    },
    OFR3: {
      en: (ischange) =>
        ".\nReplace it with " +
        (ischange ? "the updated one" : "some other one") +
        " or remove it",
      ru: (ischange) =>
        ".\nЗамените его " +
        (ischange ? "обновленным" : "другим") +
        " или удалите",
    },
  },
  Chat: {
    EMPT: {
      en: "Write your first message",
      ru: "Напишите свое первое сообщение",
    },
  },
  CustomEvent: {
    SLTS1: {
      en: "Start times have been updated",
      ru: "Слоты обновлены",
    },
    WEBTTL: {
      en: (name) => "Build a private class with " + name + " – BLOSS.AM",
      ru: (name) => "Частные занятия с " + name + " – BLOSS.AM",
    },
    TTL: {
      en: (name) => (name || "Coach") + "'s private classes",
      ru: (name) => "Частные занятия с " + (name || "тренером"),
    },
    LOAD1: {
      en: (name) => `Getting ${name || "coach"}'s timing...`,
      ru: (name) => `Загружаю расписание ${name || "тренера"}...`,
    },
    EMPT: {
      en: (name) => name + " has no available time slots for now",
      ru: (name) => `У ${name} сейчас нет свободных слотов`,
    },
    PASSEMP: {
      en: "No time left in your package",
      ru: "В вашем абонементе не осталось минут",
    },
    EVT1: {
      en: (active) =>
        (active ? "Your booking" : "This class") + " is already cancelled",
      ru: (active) =>
        (active ? "Ваше бронирование" : "Это занятие") + " уже отменено",
    },
    EVTLD: {
      en: "Getting class info",
      ru: "Загружаю информацию о занятии",
    },
    PASER1: {
      en: "The package was cancelled",
      ru: "Абонемент отменен",
    },
    PASER2: {
      en: (name) => `Your ${name} package is not active anymore`,
      ru: (name) => `Ваш ${name} абонемент больше не активен`,
    },
    TRL1: {
      en: (time) =>
        `To build a free trial class, pick a start earlier than ${time}. Or delete this time from your cart!`,
      ru: (time) =>
        `Для создания пробного занятия выберите время раньше чем ${time}. Или удалите это время из корзины!`,
    },
    TRL2: {
      en: "To build a free trial class, pick the duration 30–45 min and 1 person",
      ru: "Для создания пробного занятия выберите длительность 30–45 мин и 1 ученика",
    },
    RSCLDTX: {
      en: "The class was rescheduled",
      ru: "Занятие перенесено",
    },
  },

  EditProfile: {
    HDRTTL: {
      en: "Edit profile",
      ru: "Редактировать профиль",
    },
    TST1: {
      en: "Too short name",
      ru: "Слишком короткое имя",
    },
    TST2: {
      en: "Please, fill in your contact",
      ru: "Пожалуйста, укажите ваш контакт для связи",
    },
    TST3: {
      en: "Too short contact",
      ru: "Слишком короткий контакт",
    },
    TST4: {
      en: "Too short experience & goals",
      ru: "Слишком короткий опыт и цели",
    },
    TST5: {
      en: "Incorrect photo link",
      ru: "Некорректное фото",
    },
    DELER1: {
      en: "You have a booking ",
      ru: "У вас есть бронь ",
    },
    DELER2: {
      en: `If you want to delete your account, please cancel all your active bookings at first. Open the class, press the icon ··· at the top right corner, then pick an option "Cancel booking"`,
      ru: `Если вы хотите удалить свой аккаунт, сначала отмените все активные брони. Откройте занятие, нажмите на иконку ··· в верхнем правом углу, затем выберите опцию "Отменить бронь"`,
    },
    DELQ: {
      en: "Delete account & data",
      ru: "Удалить аккаунт и данные",
    },
    INP1: {
      en: "Messenger or contact phone",
      ru: "Мессенджер или телефон для связи",
    },
    INP2: {
      en: "Experience & goals",
      ru: "Опыт и цели",
    },
    INP3: {
      en: `f.e. "no experience"`,
      ru: `например "без опыта"`,
    },
    AGECAP: {
      en: "Student's age",
      ru: "Возраст ученика",
    },
    DELBT: {
      en: "Delete account & data",
      ru: "Удалить аккаунт и данные",
    },
    SVIMG: {
      en: "loading image...",
      ru: "загружаю изображение...",
    },
  },

  Event: {
    WEBTTL: {
      en: (name) => (name ? name + " class – BLOSS.AM" : "Class – BLOSS.AM"),
      ru: (name) =>
        name ? "Занятие " + name + " – BLOSS.AM" : "Занятие – BLOSS.AM",
    },
    CNCL1: {
      en: "The class was cancelled",
      ru: "Занятие отменено",
    },
    CNCL2: {
      en: (passed) => (passed ? " is already passed" : " is cancelled"),
      ru: (passed) => (passed ? " уже прошло" : " отменено"),
    },
    CHNG1: {
      en: (book) => "The class was just " + (book ? "booked" : "updated"),
      ru: (book) =>
        "Занятие только что " + (book ? "забронировано" : "обновилось"),
    },
    CNTBK1: {
      en: "Can't book anymore",
      ru: "Слишом поздно",
    },
    CNTBK2: {
      en: `The class is already going on. Can't book it`,
      ru: "Занятие уже началось. Слишком поздно для бронирования",
    },
    WEBADDCLNDR: {
      en: "Add to calendar",
      ru: "Добавить в календарь",
    },
    CNTJIN1: {
      en: `Can't join a someone's private class`,
      ru: "Чужое частное занятие недоступно",
    },
    CNTJIN2: {
      en: `Someone's private class`,
      ru: "Чужое частное занятие",
    },
    СNTJIN3: {
      ru: "Нельзя забронировать чужое частное занятие. Если вы знакомы с его заказчиком, вы можете попросить нас добавить вас, или попросить заказчика изменить занятие на группу",
      en: `You can't book someone's private class. If you know its customer, you can ask us to add you, or ask the customer to change the class to a group one`,
    },
    CNTJIN4: {
      en: (myid) =>
        `My user id is ${myid}, i'd like to join someone else's private class. Class id is `,
      ru: (myid) =>
        `Мой id пользователя ${myid}, я хочу присоединиться к чужому частному занятию. Id занятия – `,
    },
    CRTRMV: {
      en: "Remove from cart",
      ru: "Убрать из корзины",
    },
  },

  Filters: {
    TTL: {
      en: (isprog, isage) => (isprog ? "Programs" : isage ? "Age" : "Level"),
      ru: (isprog, isage) =>
        isprog ? "Программы" : isage ? "Возраст" : "Уровень",
    },
  },

  History: {
    EMPTY: {
      en: (type) =>
        (type.includes("comments")
          ? `All coaches' comments`
          : `Your history of ` + type.toLowerCase()) + " \nwill be here",
      ru: (type) =>
        (type.includes("comments")
          ? "Комментарии тренеров"
          : type === "orders"
          ? "Итория ваших заказов"
          : type === "classes"
          ? "Ваша история занятий"
          : "Ваша история покупок пакетов") + " \nбудет здесь",
    },
  },

  Image: {
    ACER1: {
      en: "No access to files & photos",
      ru: "Нет доступа к файлам и фото",
    },
    SCS: {
      en: "Photo saved to gallery",
      ru: "Фото сохранено в галерее",
    },
    SWP: {
      en: "Swipe down to close",
      ru: "Смахните вниз для закрытия",
    },

    DWNER: {
      en: "Error saving image: ",
      ru: "Ошибка сохранения фото: ",
    },
    BTNTX: {
      en: (loaded) => (loaded ? `✓   Saved` : "Download"),
      ru: (loaded) => (loaded ? `✓   Скачано` : "Скачать"),
    },
  },

  Order: {
    AUTHER: {
      en: "You should be signed in to view orders",
      ru: "Для просмотра заказов войдите в аккаунт",
    },
    UIDER: {
      en: `You can't view other people's orders`,
      ru: "Вы не можете просматривать чужие заказы",
    },
    TTL: {
      en: "Classes order",
      ru: "Заказ занятий",
    },

    CRTDBY: {
      en: (by) => `\nCreated by ${by || "admin"}`,
      ru: (by) => `\nСоздано ${by || "администратором"}`,
    },
  },
  PassOrder: {
    AUTHER: {
      en: "You should be signed in to view orders",
      ru: "Для просмотра заказов войдите в аккаунт",
    },
    UIDER: {
      en: `You can't view other people's orders`,
      ru: "Вы не можете просматривать чужие заказы",
    },
    TTL: {
      en: (paid) => "Package " + (paid ? "purchase" : "order"),
      ru: (paid) => (paid ? "Покупка пакета" : "Заказ пакета"),
    },
  },
  Product: {
    TTL1: {
      en: (name, priv) => name + `'s ${priv ? "1-on-1" : "group"} classes`,
      ru: (name, priv) =>
        (priv ? "Частные" : "Групповые") + " занятия с " + name,
    },
    TTL2: {
      en: (priv) => (priv ? "Build your own class" : "Join a group class"),
      ru: (priv) => (priv ? "Создайте свое занятие" : "Групповые занятия"),
    },
    PRVBTN: {
      en: "Create a class",
      ru: "Создать занятие",
    },
    PRCTERM: {
      en: "if booking 2+ persons",
      ru: "при бронировании 2+ человек",
    },
    GETDIFF: {
      en: " (learn the difference)",
      ru: " (узнать разницу)",
    },
    NOGRP: {
      en: (filter) =>
        filter ? `No groups with this age` : "No groups for now",
      ru: (filter) =>
        filter ? `Нет групп с таким возрастом` : "Групповых занятий пока нет",
    },
  },
  Package: {
    RMVD1: {
      en: "The package was removed",
      ru: "Пакет удален",
    },
    NEWBKBTN: {
      en: "Book a new class",
      ru: "Создать новое занятие",
    },
    AVLBTX: {
      en: "Available for purchase:",
      ru: "Доступно для покупки:",
    },
    PASORDRS: {
      en: (ispass) => (ispass ? "Other" : "All") + " package purchases",
      ru: (ispass) => (ispass ? "Другие" : "Все") + " покупки пакета",
    },
    PAYMTHD: {
      en: (method) => "by " + method,
      ru: (method) => (method == "balance" ? "с баланса" : "картой"),
    },
    RENEWBTN: {
      en: (durtext, price) => "Renew: " + durtext + " for " + price + "$",
      ru: (durtext, price) => "Продлить: " + durtext + " за " + price + "$",
    },
    BUYBTN: {
      en: (active, logged, hadpaid) =>
        !active
          ? "Package isn't available"
          : !logged
          ? "Login to continue"
          : hadpaid
          ? "Renew package"
          : "Buy package",
      ru: (active, logged, hadpaid) =>
        !active
          ? "Пакет недоступен"
          : !logged
          ? "Войдите в аккаунт"
          : hadpaid
          ? "Продлить пакет"
          : "Купить пакет",
    },
    TERMTTL: {
      en: "Period to use:",
      ru: "Срок действия:",
    },

    PASSOPTS: {
      en: (num) =>
        num === 1 ? "Minutes balance" : num == 2 ? "Bookings" : "Purchase info",
      ru: (num) =>
        num === 1
          ? "Баланс минут"
          : num == 2
          ? "Бронирования"
          : "Инфо о покупке",
    },

    TTL: {
      en: (name) => name + " package",
      ru: (name, ispass) => (ispass ? "Абонемент " : "Пакет ") + name,
    },

    PRIVDESC: {
      en: "1-on-1 classes",
      ru: "Частные занятия",
    },
  },

  BalanceRecord: {
    EVTYPE1: {
      en: "Class booking",
      ru: "Бронирование занятия",
    },
    EVTYPE2: {
      en: (isplus, iscancel) =>
        !isplus
          ? "Class re-schedule"
          : "Refund for booking " + (iscancel ? "cancellation" : "re-schedule"),
      ru: (isplus, iscancel) =>
        !isplus
          ? "Перенос занятия"
          : "Возврат за " + (iscancel ? "отмену брони" : "перенос занятия"),
    },
    PASPRCH: {
      en: "Package purchase",
      ru: "Покупка пакета",
    },
    PASEND: {
      en: (dur) => "Package " + (dur ? "finished" : "completed"),
      ru: (dur) => (dur ? "Окончание абонемента" : "Абонемент использован"),
    },
    PASCSTM: {
      en: (isplus) => (isplus ? "Admin deposit" : "Admin write-off"),
      ru: (isplus) =>
        isplus ? "Пополнение администратором" : "Списание администратором",
    },
    BLNCPACK: {
      en: (isplus) => "Package pass " + (isplus ? "refund" : "purchase"),
      ru: (isplus) =>
        isplus ? "Возврат за покупку пакета" : "Покупка абонемента",
    },
    BLNCCSTM: {
      en: (isplus) => (isplus ? "Deposit" : "Write-off"),
      ru: (isplus) => (isplus ? "Пополнение" : "Списание"),
    },
    BLNCORD: {
      en: (isplus) =>
        isplus ? "Refund for order's expired classes" : "Order payment",
      ru: (isplus) =>
        isplus ? "Возврат за незавершенные брони в заказе" : "Оплата заказа",
    },
    RSDLDTM: {
      en: ". New timing: ",
      ru: ". Новое время занятия: ",
    },
    RECWHO: {
      en: (who, desc) => (who && desc ? ` (by ${who})` : "by " + who),
      ru: (who, desc) => (who && desc ? ` (${who})` : "от " + who),
    },
    CNCTN: {
      en: (passID) => (passID ? "Package " : "Order "),
      ru: (passID) => (passID ? "Абонемент " : "Заказ "),
    },
    DEPTTL: {
      en: "Request for deposit",
      ru: "Запрос на пополнение баланса",
    },
    DEPSTS: {
      en: (status, full) => (full ? "Request is " : "") + (status || "sent"),
      ru: (status, full) =>
        (full ? "Запрос " : "") +
        (status === "approved"
          ? "одобрен"
          : status === "denied"
          ? "отклонен"
          : !status || status === "sent"
          ? "отправлен"
          : status),
    },
    DEPSENT: {
      en: "sent, waitinig for response",
      ru: "отправлен, ожидаем ответ",
    },
    DEPTRANS: {
      en: "Transfer via ",
      ru: "Перевод ",
    },
    DEPCNFRM1: {
      en: (img, link) =>
        "Provided confirmation " +
        (img && link ? "image & link" : img ? "image" : "link") +
        ". \n",
      ru: (img, link) =>
        "Прикреплено: подтверждающ" +
        (img && link ? "ие фото и ссылка" : img ? "ее фото" : "ая ссылка") +
        ". \n",
    },
    DEPCNFRM2: {
      en: (img, link) =>
        (img ? ` \nConfirmation image – ${img}` : "") +
        (link ? `\nConfirmation link – ${link}` : ""),
      ru: (img, link) =>
        (img ? ` \nПодтверждающее фото – ${img}` : "") +
        (link ? `\nПодтверждающая ссылка – ${link}` : ""),
    },
    ORDTMTX: {
      en: (ispass) =>
        (ispass ? "The package purchase" : "The") + " order was created on ",
      ru: (ispass) =>
        (ispass ? "Заказ на покупку абонемента" : "Заказ") + " создан ",
    },

    COPYID: {
      en: (eID, orderID, passID) =>
        eID
          ? " \nClass ID is " + eID
          : orderID
          ? " \nOrder ID is " + orderID
          : " \nPackage ID is " + passID,
      ru: (eID, orderID, passID) =>
        eID
          ? " \nID занятия – " + eID
          : orderID
          ? " \nID заказа – " + orderID
          : " \nID абонемента – " + passID,
    },

    ALRTLBL1: {
      en: (ispass) => "Open " + (ispass ? "package purchase" : "order"),
      ru: (ispass) => "Открыть " + (ispass ? "заказ абонемента" : "заказ"),
    },
    ALRTDEPLBL: {
      en: (img) => "Check " + (img ? "image" : "link"),
      ru: (img) => "Открыть " + (img ? "фото" : "ссылку"),
    },
    CNTCTTTL: {
      en: (ispass) => (ispass ? "PACKAGE TRANSACTION" : "BALANCE TRANSACTION"),
      ru: (ispass) =>
        ispass ? "ТРАНЗАКЦИЯ В АБОНЕМЕНТЕ" : "ТРАНЗАКЦИЯ НА БАЛАНСЕ",
    },
    CNTCTTXT: {
      en: (ispass) =>
        "Hello! I have an issue with an operation in my " +
        (ispass ? "package" : "account") +
        " balance history. \nMy id is ",
      ru: (ispass) =>
        "Здравствуйте! У меня вопрос по операции в истории " +
        (ispass ? "минут абонемента" : "моего баланса") +
        ". \nМой id – ",
    },
    CNTCTTXT2: {
      en: `Hello! I have an issue with a deposit operation in my balance history. \nMy id is `,
      ru: `Здравствуйте! У меня вопрос по операции депозита в истории моего баланса. \nМой id – `,
    },
    CNTCTBTN: {
      en: "Copy & contact us",
      ru: "Написать нам",
    },
  },

  BookCard: {
    RTRMND: {
      en: (passed, going) =>
        passed
          ? "Rate the latest class"
          : going
          ? "Ongoing class"
          : "Your nearest class",
      ru: (passed, going) =>
        passed ? "Оцените занятие" : going ? "Идет занятие" : "Ближайшая бронь",
    },
    RTQST: {
      en: "How would you rate the class?",
      ru: "Как вы бы оценили занятие?",
    },
  },

  ChatComps: {
    SENDER: {
      en: (islink) =>
        "Sorry, sending " +
        (islink ? "messenger links" : "phone numbers") +
        " is not allowed",
      ru: (islink) =>
        "К сожалению, отправка " +
        (islink ? "ссылок на мессенджеры" : "телефона") +
        " запрещена",
    },
    CANTTX: {
      en: (active) =>
        active
          ? "To continue the chat, book a class with the coach"
          : `The coach isn't active anymore`,
      ru: (active) =>
        active
          ? "Чтобы возобновить чат, забронируйте занятие с тренером"
          : "Тренер больше не активен",
    },
  },
  CoachBody: {
    PRDTL: {
      en: (priv) => (priv ? "Build your own class" : "Join a group class"),
      ru: (priv) => (priv ? "Создайте свое занятие" : "Групповые занятия"),
    },
    NOSLT1: {
      en: (priv) => "No " + (priv ? "free slots" : "classes"),
      ru: (priv) => "Нет " + (priv ? "свободных слотов" : "занятий"),
    },

    NOSLT2: {
      en: (priv) =>
        priv ? "no available time\nslots for now" : "no classes for now",
      ru: (priv) => (priv ? "свободных слотов\nпока нет" : "пока нет"),
    },
    PCKSTTL: {
      en: "1-on-1 packages",
      ru: "Пакеты занятий 1-на-1",
    },
    BOLDTYPE: {
      en: (privat) => (privat ? "1-on-1" : "groups"),
      ru: (privat) => (privat ? "1-на-1" : "группы"),
    },
  },
  CoachCard: {
    CHTICN: {
      en: (name) => [
        "Can't chat without a recent class",
        `Book a class with ${name} at first`,
      ],
      ru: (name) => [
        "Нельзя начать чат без недавней брони",
        `Сначала забронируйте занятие с ${name}`,
      ],
    },
    APPSPROMO: {
      en: `Chats with coaches (after you book their class)`,
      ru: `Чаты с тренерами (после бронирования их занятия)`,
    },
  },

  CoachHeader: {
    SCRS: {
      en: (qt) => "by " + qt + " scores",
      ru: (qt) => qt + " оценок",
    },
  },

  EventBuilder: {
    TOTALCLS: {
      en: (privat, qt) =>
        privat
          ? qt + ` private class${qt > 1 ? "es" : ""}`
          : qt + ` my group${qt > 1 ? "s" : ""}`,
      ru: (privat, qt) =>
        qt === 1
          ? privat
            ? `1 частное занятие`
            : `1 моя группа`
          : privat
          ? qt + ` частных занятия(й)`
          : qt < 5
          ? qt + ` мои группы`
          : qt + ` моих групп`,
    },
    BTNTX1: {
      en: (multi) => (multi ? "Pick some times" : "Pick a time"),
      ru: (multi) => (multi ? "Выберите несколько времен" : "Выберите время"),
    },
    BTNTX2: {
      en: (picked) => (picked ? "Add to cart" : "Pick a correct time"),
      ru: (picked) =>
        picked ? "Добавить в корзину" : "Выберите корректное время",
    },
    SVER1: {
      en: "Pick a correct start time or shorter duration! Red colored time means that its maximum duration is less than ",
      ru: "Выберите корректное время начала или меньшую длительность! Красное время означает, что максимальная длительность занятия для него меньше, чем ",
    },
    SVER2: {
      en: (time) => [
        "Unavailable start",
        `Start on ${time} isn't available for now. Pick a correct start time or shorter duration`,
      ],
      ru: (time) => [
        "Недоступное время",
        `Время начала ${time} сейчас недоступно. Выберите корректное время начала или меньшую длительность`,
      ],
    },
    SVER3: {
      en: " is crossed by some class already in your cart",
      ru: " пересекается с занятием, которое уже в вашей корзине",
    },
    MAKGRP: {
      en: (only1, pricediff) =>
        `Open ${only1 ? "a" : "every"}` +
        ` class for other clients to join as a group. We'll refund you ${pricediff}$` +
        (only1 ? "" : " per class") +
        ` as a price difference, if someone joins you`,
      ru: (only1, pricediff) =>
        `Открыть ${only1 ? "" : "каждое "}` +
        `занятие как группу, доступную для других клиентов. Мы вернем вам разницу в цене ${pricediff}$` +
        (only1 ? "" : " за каждое занятие") +
        `, если кто-то присоединится к вам`,
    },
  },

  EventCard: {
    TTL1: {
      en: (book) => (book ? "My p" : "P") + "rivate class",
      ru: (book) => (book ? "Мое ч" : "Ч") + "астное занятие",
    },
  },

  EventComps: {
    PAYRSCD1: {
      en: "Reschedule isn't free anymore, since the class starts in less than 8 hours.\n",
      ru: "Перенос занятия больше не бесплатный, так как оно начинается менее чем через 8 часов.\n",
    },
    PAYRSCD2: {
      en: (dur) => `We'll write off ${dur} minutes from your package again`,
      ru: (dur) => `Мы снова списываем ${dur} минут с вашего абонемента`,
    },
    PAYRSCD3: {
      en: (sum) => `You'll need to pay ${sum}$ again`,
      ru: (sum) => `Вам нужно будет снова оплатить ${sum}$`,
    },
    CNCLRF1: {
      en: (dur) => `We'll compensate for ${dur} minutes to your package`,
      ru: (dur) => `Мы вернем ${dur} минут на ваш абонемент`,
    },
    CNCLRF2: {
      en: (sum) => `We'll refund ${sum}$ to your account balance`,
      ru: (sum) => `Мы вернем ${sum}$ на ваш баланс`,
    },
    CNCTTL: {
      en: "Cancel booking",
      ru: "Отменить бронь",
    },
    CNCTTL2: {
      en: (pass) => ` without ${pass ? "compensation" : "refund"}?`,
      ru: (pass) => ` без ${pass ? "компенсации" : "возврата"}?`,
    },
    CNCLNRF1: {
      en: (pass) =>
        (pass ? "No compensation for the package minutes" : "No refund") +
        ", since the class starts in less than 8 hours",
      ru: (pass) =>
        (pass ? "Без компенсации минут абонемента" : "Без возврата средств") +
        ", так как занятие начинается менее чем через 8 часов",
    },
    CNCALRT: {
      en: "Yes, cancel it",
      ru: "Да, отменить",
    },
    ZOOM1: {
      en: (index) => (!index ? "Zoom link" : "Password"),
      ru: (index) => (!index ? "Ссылка" : "Пароль"),
    },
    OPT1: {
      en: "Order info ",
      ru: "Инфо о заказе ",
    },
    OPT2: {
      en: "Package info",
      ru: "Абонемент",
    },
    OPT3: {
      en: "Re-schedule",
      ru: "Перенести",
    },
    BOOKLNK: {
      en: `Booking link – `,
      ru: `Ссылка на бронирование – `,
    },
  },

  OrderCard: {
    TTL1: {
      en: (paidPack) => (paidPack ? "Purchase" : "Order"),
      ru: (paidPack) => (paidPack ? "Покупка" : "Заказ"),
    },
    PASINCLD: {
      en: ", 1-on-1 classes",
      ru: ", частные занятия",
    },
    EVSTTS: {
      en: (cancelled, rescdled) =>
        cancelled ? "cancelled" : rescdled ? "rescheduled" : "expired",
      ru: (cancelled, rescdled) =>
        cancelled ? "отменено" : rescdled ? "перенос" : "просрочено",
    },
    ALRTEXPRD: {
      en: (passed) => [
        "Expired",
        passed
          ? "The class is already passed"
          : "It's too late to book this start time",
      ],
      ru: (passed) => [
        "Слишком поздно",
        passed
          ? "Занятие уже прошло"
          : "Слишком поздно для бронирования этого времени",
      ],
    },
    CSTMADDCART: {
      en: [
        "Change this class?",
        `You can't change a created order. To change this class, add it to the cart again or re-create the whole cart via the "Repeat order" option (down the page). \nYou can change the class from the cart and create a new order then.`,
      ],
      ru: [
        "Изменить занятие?",
        `Вы не можете изменить созданный заказ. Чтобы изменить это занятие, добавьте его в корзину снова или создайте заказ заново через опцию "Повторить заказ" (внизу страницы). \nВы сможете изменить занятие из корзины и заказать его снова.`,
      ],
    },
    GRPADDCART: {
      en: [
        "Change your booking?",
        `You can't change a created order. To change your booking data, add this class to the cart again or re-create the whole cart via the "Repeat order" option (down the page). \nYou can change your booking data from the cart and create a new order then.`,
      ],
      ru: [
        "Изменить данные брони?",
        `Вы не можете изменить созданный заказ. Чтобы изменить данные брони, добавьте это занятие в корзину снова или создайте заказ заново через опцию "Повторить заказ" (внизу страницы). \nВы сможете изменить данные вашей брони из корзины и заказать занятие снова.`,
      ],
    },
    ADDCARTLBL: {
      en: "Add to cart",
      ru: "Добавить в корзину",
    },

    CHGBY: {
      en: (who) => " by " + (who === "client" ? "you" : who),
      ru: (who) => (who === "client" ? " вами" : ` ${who}`),
    },
    PASREFNTX: {
      en: (refund) =>
        refund > 0
          ? `. \n${refund} minutes were compensated back to your package`
          : `. \n${Math.abs(refund)} minutes were debited from your package`,
      ru: (refund) =>
        refund > 0
          ? `. \nМы вернули ${refund} минут на ваш абонемент`
          : `. \nМы списали ${Math.abs(refund)} минут с вашего абонемента`,
    },
    BALREFNTX: {
      en: (refund) =>
        refund > 0
          ? `. \n${refund}$ were refunded to your account balance`
          : `. \n${Math.abs(refund)}$ were debited from your account balance`,
      ru: (refund) =>
        refund > 0
          ? `. \nМы вернули ${refund}$ на ваш баланс`
          : `. \nМы списали ${Math.abs(refund)}$ с вашего баланса`,
    },
    CHGDETTL1: {
      en: (cancelled) =>
        cancelled ? "Cancelled booking" : "Re-scheduled class",
      ru: (cancelled) => (cancelled ? "Отмена брони" : "Перенос занятия"),
    },
    CHGENOREF1: {
      en: (bypass) =>
        (bypass ? ". \nNo minutes compensation" : ". \nNo refund") +
        ", since it was cancelled later than 8 hours before the class start",
      ru: (bypass) =>
        (bypass ? ". \nБез компенсации минут" : ". \nБез возврата средств") +
        ", так как отмена произошла позднее 8 часов до начала занятия",
    },

    RSCLDTM: {
      en: "New timing: ",
      ru: "Новое время занятия: ",
    },
    RSCLDTX1: {
      en: "The class was rescheduled",
      ru: "Занятие перенесено",
    },

    OTHCHGTTL: {
      en: (waspaid) =>
        waspaid ? "The class wasn't booked" : "Can't book the class",
      ru: (waspaid) =>
        waspaid ? "Занятие не забронировано" : "Нельзя забронировать занятие",
    },
    OTHCHGTX1: {
      en: (custom) =>
        custom
          ? "The coach's time slots or prices per hour were changed"
          : "The class was changed or cancelled",
      ru: (custom) =>
        custom
          ? "Изменились свободные слоты тренера или его цена за час"
          : "Занятие изменилось или отменено",
    },
    OTHCHGTX2: {
      en: (waspaid) =>
        waspaid
          ? " before the payment, or the order was paid too late for booking"
          : ", or it's too late to book it",
      ru: (waspaid) =>
        waspaid
          ? " до оплаты, или заказ оплачен слишком поздно для бронирования"
          : ", или было слишком поздно для его бронирования",
    },

    OPNCLS: {
      en: "Open the class",
      ru: "Открыть занятие",
    },
    OPNBLNC: {
      en: (bypass) => (bypass ? "Package balance" : "Balance history"),
      ru: (bypass) => (bypass ? "История минут" : "История баланса"),
    },
  },

  OrderFooter: {
    SKIPREORD1: {
      en: (time) => `Class at ${time} is skipped. It's too late to book it`,
      ru: (time) =>
        `Занятие в ${time} пропущено. Слишком поздно для его бронирования`,
    },

    CARTQNTTX: {
      en: (quant, coach) => quant + " bookable class(es) with " + coach,
      ru: (quant, coach) =>
        quant +
        (quant === 1 ? " актуальное занятие с " : " актуальных занятия(й) с ") +
        coach,
    },
    QNTTX2: {
      en: (quant) => (quant > 1 ? `these ${quant} classes` : "this class"),
      ru: (quant) => (quant > 1 ? `эти ${quant} занятия(й)` : "это занятие"),
    },
    ALRTCART: {
      en: (cartText, quantText) => [
        "Cart isn't empty",
        `You have ${cartText} in your cart. Do you want to add ${quantText} or replace the whole cart?`,
      ],
      ru: (cartText, quantText) => [
        "Корзина не пустая",
        `У вас ${cartText} в корзине. Хотите добавить ${quantText} или заменить всю корзину?`,
      ],
    },
    CNCLTX: {
      en: "The order was cancelled",
      ru: "Заказ отменен",
    },
    ALRTCNCL: {
      en: ["Cancel the order?", "Are you sure you want to cancel the order?"],
      ru: ["Отменить заказ?", "Вы уверены, что хотите отменить заказ?"],
    },
    ALRTCNCLLBL: {
      en: "Yes, cancel",
      ru: "Да, отменить",
    },
    REORDTX: {
      en: "Repeat the order",
      ru: "Повторить заказ",
    },
    CNCLTX2: {
      en: "Cancel the order",
      ru: "Отменить заказ",
    },
    CNCLBY: {
      en: (who) => " by " + (who === "client" ? "you" : who),
      ru: (who) => (who === "client" ? " вами" : ` ${who}`),
    },
    RCPT: {
      en: "Payment receipt",
      ru: "Чек оплаты",
    },
    PASSPAID1: {
      en: (pass) => "Debited from " + pass,
      ru: (pass) => "Списано с " + pass,
    },
    PASSRECS1: {
      en: (qnt) => qnt + " package operation(s)",
      ru: (qnt) => qnt + ` операци${qnt === 1 ? "я" : "и(й)"} с абонементом`,
    },
    CNTBK1: {
      en: "Can't book anymore",
      ru: "Слишом поздно",
    },
    CNTBK2: {
      en: `No classes can be booked anymore:\n`,
      ru: `Занятия больше нельзя забронировать:\n`,
    },

    ALRTCHGDTTL: {
      en: `Can't book `,
      ru: `Нельзя забронировать `,
    },

    ALRTCHGDTXT: {
      en: (qnt, sum) =>
        (qnt > 1 ? qnt + " classes have" : "1 class has") +
        ` been changed or expired. \nWe can skip ` +
        (qnt > 1 ? "them" : "it") +
        ` and pay ${sum}$ for the rest `,
      ru: (qnt, sum) =>
        (qnt > 1 ? "1 занятие изменилось" : qnt + " занятия(й) изменились") +
        ` или крайний срок ${qnt > 1 ? "их" : "его"}` +
        ` бронирования истек. \nМожно пропустить ${qnt > 1 ? "их" : "его"}` +
        ` и оплатить ${sum}$ за оставшиеся `,
    },
    ERRTYPE: {
      en: "pay for the order",
      ru: "оплатить заказ",
    },
    BLNCLACKROW: {
      en: (lackLess5, balance) =>
        lackLess5
          ? `Charge from balance (${balance}$)`
          : "Charge the balance funds",
      ru: (lackLess5, balance) =>
        lackLess5 ? `Списание с баланса (${balance}$)` : "Списание баланса",
    },
    SUMPAY: {
      en: (less5) => (less5 ? "Minimum payment" : "The rest to pay"),
      ru: (less5) => (less5 ? "Минимальный платеж" : "Остаток к оплате"),
    },
    PAYMTHD: {
      en: (payBalance, sum) =>
        (payBalance ? `Pay with balance` : `Pay by card`) + `, ${sum}$`,
      ru: (payBalance, sum) =>
        (payBalance ? `Оплатить с баланса` : `Оплатить картой`) + `, ${sum}$`,
    },
    SCNDPAYBTN: {
      en: "Pay in other ways",
      ru: "Другие способы оплаты",
    },
    BLNCRECSLINK: {
      en: (qnt) => qnt + " balance operation(s)",
      ru: (qnt) => qnt + ` операци${qnt === 1 ? "я" : "и(й)"} с балансом`,
    },
    ALRTCARDERR: {
      en: [
        "Got an error: no link from Stripe",
        `Please, make sure you have an internet connection and try again.\nIf your network is fine and the problem persists, it means we're having a trouble with card payments for now.\n\nTo pay the order, please, pick another method via button "Pay in other ways"`,
      ],
      ru: [
        "Получили ошибку: нет ссылки от Stripe",
        `Пожалуйста, убедитесь, что у вас есть доступ в интернет и попробуйте снова.\nЕсли сеть в порядке и проблема остается, значит у нас проблемы с приемом оплаты картами.\n\nДля оплаты заказа выберите другой метод через кнопку "Другие способы оплаты"`,
      ],
    },

    PAIDBY: {
      en: (method) => "Paid by " + method + " at ",
      ru: (method) =>
        "Оплачено " + (method === "balance" ? "с баланса" : "картой") + " в ",
    },

    ORDTYPE: {
      en: (ispass) => (ispass ? "package" : "order"),
      ru: (ispass) => (ispass ? "абонемент" : "заказ"),
    },

    ALRTBLNCLACK: {
      en: (type, balance, sum) => [
        "Not enough balance",
        `You have ${balance}$, but the ${type} costs ${sum}$`,
      ],
      ru: (type, balance, sum) => [
        "Недостаточный баланс",
        `У вас ${balance}$, а стоимость ${type}а – ${sum}$`,
      ],
    },
    ALRTPAID1: {
      en: (type) => (type == "balance" ? "Paid by " : "Debited from ") + type,
      ru: (type) =>
        type == "balance" ? "Оплачено с баланса" : `Списано с ${type}а`,
    },

    ALRTPAID2: {
      en: (type) =>
        type == "balance"
          ? " were charged from your account balance"
          : " were debited from your " + type,

      ru: (type) =>
        " списаны с вашего " + (type == "balance" ? "баланса" : type + "а "),
    },
    ALRTPAIDLBL1: {
      en: (isPass) => (isPass ? "Minutes balance" : "Balance history"),
      ru: (isPass) => (isPass ? "История минут" : "История баланса"),
    },
    ALRTPAIDLBL2: {
      en: "Open package",
      ru: "Открыть абонемент",
    },
  },

  PassOrderFooter: {
    EXPDTST: {
      en: "The purchase is expired. ",
      ru: "Покупка просрочена. ",
    },
    CNCLSTTS: {
      en: "The purchase was cancelled",
      ru: "Покупка отменена",
    },
    ALRTCNCL: {
      en: [
        "Cancel the purchase?",
        "Are you sure you want to cancel the package purchase?",
      ],
      ru: [
        "Отменить покупку?",
        "Вы уверены, что хотите отменить покупку пакета?",
      ],
    },
    ALRTCNCLLBL: {
      en: "Yes, cancel",
      ru: "Да, отменить",
    },
    CNCLOPTN: {
      en: "Cancel purchase",
      ru: "Отменить покупку",
    },
    CNCLBY: {
      en: (who) => " by " + (who === "client" ? "you" : who),
      ru: (who) => (who === "client" ? " вами" : ` ${who}`),
    },
    BALREFNTX: {
      en: (refund) => `. \n${refund}$ were refunded to your account balance`,
      ru: (refund) => `. \nМы вернули ${refund}$ на ваш баланс`,
    },
    PAIDBYTXT: {
      en: (method) => ". \nThe purchase was paid by " + (method || "card"),
      ru: (method) =>
        ". \nПокупка оплачена " +
        (method == "balance" ? "с баланса" : "картой"),
    },
    CHCKBLNC: {
      en: "Check balance history",
      ru: "История баланса",
    },
    RCPT: {
      en: "Payment receipt",
      ru: "Чек оплаты",
    },

    CNCLDBTN: {
      en: (refund) => `Cancelled` + (refund ? " with refund" : ""),
      ru: (refund) => `Отменено` + (refund ? " с возвратом" : ""),
    },
    BLNCRECSLINK: {
      en: (qnt) => qnt + " balance operation(s)",
      ru: (qnt) => qnt + ` операци${qnt === 1 ? "я" : "и(й)"} с балансом`,
    },

    OPNPACKLBL: {
      en: "Open package",
      ru: "Открыть пакет",
    },
    ALRTERR1TTL: {
      en: "Can't purchase",
      ru: "Нельзя приобрести",
    },
    PASSTRMDESC: {
      en: (term) => ` for ${term} days`,
      ru: (term) => ` на ${term} дней`,
    },

    ERRTYPE: {
      en: "pay for the package purchase",
      ru: "оплатить покупку пакета",
    },
  },

  PackageCard: {
    POSTNAME: {
      en: () => " package",
      ru: (ispass) => (ispass ? " абонемент" : " пакет"),
    },
    WRAPDESC: {
      en: (isorder, term) =>
        (isorder ? "1-on-1 classes. " : "") + term + " days to use",
      ru: (isorder, term) =>
        (isorder ? "Частные занятия. " : "") + `Действует ${term} дней`,
    },
    PACKENDSTTS: {
      en: (passed) => (passed ? "expired " : "till "),
      ru: (passed) => (passed ? "истек " : "до "),
    },
    NRSTDAYS: {
      en: (daysgap) =>
        !daysgap ? "today" : daysgap === 1 ? "tomorrow" : "yesterday",
      ru: (daysgap) =>
        !daysgap ? "сегодня" : daysgap === 1 ? "завтра" : "вчера",
    },
  },

  Rescheduler: {
    TIMER: {
      en: "This time isn't available for now. Pick another one",
      ru: "Это время сейчас недоступно. Выберите другое",
    },
    DURER: {
      en: "This duration isn't available for this start time. Pick a shorter duration or another time",
      ru: "Эта длительность недоступна для этого времени начала. Выберите меньшую длительность или другое время",
    },
    ALRTPAID: {
      en: (ispass, sum) => [
        "No more free re-schedule",
        "The class starts in less than 8 hours. A re-schedule isn't free anymore. Continue " +
          (ispass ? "with package minutes write-off?" : `for ${sum}$?`),
      ],
      ru: (ispass, sum) => [
        "Перенос не бесплатный",
        "Занятие начинается менее чем через 8 часов. Перенос больше не бесплатный. Продолжить " +
          (ispass ? "со списанием минут с абонемента?" : `за ${sum}$?`),
      ],
    },
    EVTER1: {
      en: (e) => "The class is " + (e ? "cancelled" : "already passed"),
      ru: (e) => "Занятие " + (e ? "отменено" : "уже прошло"),
    },
    EVTER2: {
      en: "Your booking is already cancelled",
      ru: "Ваше бронирование уже отменено",
    },
    EVTER3: {
      en: (secndClient) =>
        "Can't re-schedule this class anymore: " +
        (secndClient
          ? "it's already booked by another client"
          : "it's not your private class"),
      ru: (secndClient) =>
        "Нельзя перенести это занятие: " +
        (secndClient
          ? "оно уже забронировано другим клиентом"
          : "это не ваше частное занятие"),
    },
    EVTER4: {
      en: "The class was just updated. Check the new info and try to re-schedule again if needed",
      ru: "Занятие только что обновилось. Просмотрите его и при необходимости попробуйте перенести еще раз",
    },
    EVTER5: {
      en: "Your booking data was just updated. Please, review it and try to re-schedule again",
      ru: "Данные вашей брони только что обновились. Пожалуйста, проверьте и попробуйте перенести еще раз",
    },
    ALRTPASS: {
      en: (dur) => [
        `Re-schedule with package write-off?`,
        `We'll write off ${dur} minutes from your package again, since the class starts in less than 8 hours`,
      ],
      ru: (dur) => [
        `Перенести со списанием минут?`,
        `Мы снова спишем ${dur} минут с вашего абонемента, так как занятие начинается менее чем через 8 часов`,
      ],
    },
    ALRTNEWDUR: {
      en: (paid, newsum) => [
        "Confirm re-booking?",
        `We'll cancel this booking, refund you ${paid}$ to the account balance immediately and create a new order with the re-scheduled class.\nThen you'll have to pay ${newsum}$ for it`,
      ],
      ru: (paid, newsum) => [
        "Подтвердите перенос?",
        `Мы отменим эту бронь, вернем вам ${paid}$ на баланс и создадим новый заказ с перенесенным занятием.\nПосле этого вам нужно будет оплатить ${newsum}$`,
      ],
    },
    ALRTPAID2: {
      en: (sum) => [
        `Re-schedule for ${sum}$?`,
        `We'll cancel this booking and create a new order with the re-scheduled class.\nThen you'll have to pay ${sum}$ for it`,
      ],
      ru: (sum) => [
        `Перенести за ${sum}$?`,
        `Мы отменим эту бронь и создадим новый заказ с перенесенным занятием.\nПосле этого вам нужно будет оплатить ${sum}$`,
      ],
    },
    SUMTXT: {
      en: (sum) => (sum > 0 ? "Need to pay" : "Your refund"),
      ru: (sum) => (sum > 0 ? "Нужно оплатить" : "Возврат"),
    },
    BUTNTXT: {
      en: (noChanges) => (noChanges ? "Close" : `Re-schedule`),
      ru: (noChanges) => (noChanges ? "Закрыть" : `Перенести`),
    },
    DENYPASS: {
      en: (active, durLeft) => [
        active ? "Not enough package minutes" : "Package expired",
        "Can't re-schedule anymore, since the class starts in less than 8 hours and " +
          (active
            ? `you have only ${durLeft} minutes left in your package`
            : "your package is expired") +
          `.\nYou can cancel this class and book a new one`,
      ],
      ru: (active, durLeft) => [
        active ? "Недостаточно минут на абонементе" : "Абонемент истек",
        "Нельзя перенести занятие, так как оно начинается менее чем через 8 часов и " +
          (active
            ? `у вас осталось всего ${durLeft} минут на абонементе`
            : "ваш абонемент истек") +
          `.\nВы можете отменить это занятие и забронировать новое`,
      ],
    },
    DENYTRIAL: {
      en: (time) => [
        "You have a class earlier",
        `You've already booked a paid class on ${time}. This free trial class can't start earlier than a paid one. Pick an earlier time or cancel the paid booking first`,
      ],
      ru: (time) => [
        "У вас есть занятие раньше",
        `Вы уже забронировали платное занятие ${time}. Пробное занятие не может начаться раньше платного. Выберите более раннее время или сначала отмените платную бронь`,
      ],
    },
  },

  TimeComp: {
    DURTX: {
      en: (passed) => `end${passed ? "ed" : "s"} at `,
      ru: (passed) => `законч${passed ? "илось" : "ится"} в `,
    },
  },
  TimesBuilder: {
    UNACTLST1: {
      en: (time) =>
        `Start on ${time} isn't available for now and was removed from the chosen starts`,
      ru: (time) => `Время начала ${time} сейчас недоступно и удалено`,
    },
    NOSLOTS: {
      en: (name) => name + " has no available time slots for now",
      ru: (name) => `У ${name} сейчас нет свободных слотов`,
    },
    PASMAXDUR: {
      en: " is a whole package's remaining duration",
      ru: " – это весть остаток времени на абонементе",
    },
    PICKTL: {
      en: "Pick 1 or more start times:",
      ru: "Выберите 1+ время начала:",
    },
    BLCK1: {
      en: (time) =>
        `This time is blocked, since you've added the class ${time} to your cart. Pick another time or remove the class from the cart`,
      ru: (time) =>
        `Это время заблокировано, так как вы добавили занятие ${time} в корзину. Выберите другое время или удалите занятие из корзины`,
    },
    BLCK2: {
      en: (time) => ["Active booking", `You have a booked class on ${time}`],
      ru: (time) => ["Бронь занятия", `У вас забронировано занятие на ${time}`],
    },
    BLCK3: {
      en: (time) => [
        "Already in cart",
        `The class on ${time} is already in your cart`,
      ],
      ru: (time) => ["Уже в корзине", `Занятие на ${time} уже в вашей корзине`],
    },
    BLCK4: {
      en: (time, dur) =>
        "This time is blocked, since you've picked " +
        `${time} with ${dur} duration. Pick another time or shorter duration`,
      ru: (time, dur) =>
        "Это время заблокировано, так как вы выбрали " +
        `${time} с длительностью ${dur}. Выберите другое время или меньшую длительность`,
    },
    DENYDUR: {
      en: (classTime, dur) =>
        (classTime ? `The picked start ${classTime}` : "This time") +
        ` has maximum available duration ${dur}. Pick an earlier time or shorter duration`,
      ru: (classTime, dur) =>
        (classTime
          ? `Для выбранного времени ${classTime}`
          : "Для этого времени") +
        ` максимальная доступная длительность – ${dur}. Выберите более раннее время или меньшую длительность`,
    },
  },

  Video: {
    TTL: {
      en: "Rhythmic gymnastics & ballet classes with Russian coaches",
      ru: "Занятия художественной гимнастикой и балетом с русскими тренерами",
    },
  },
};
