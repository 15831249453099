import React, { useEffect, useRef, useState } from "react";
import { ScrollView, View } from "react-native";
import { observer } from "mobx-react-lite";
import { onSnapshot } from "firebase/firestore";
import dayjs from "dayjs";
import { useAuth, useClient, useSchool } from "../commons/Stores";
import {
  dbPasses,
  showToast,
  copytext,
  parsePackage,
  isDesktop,
} from "../commons/utils";
import {
  Loader,
  Title2,
  Row,
  MarkerComp,
  Container,
  AbsLoader,
  Toaster,
  IDCaption,
} from "../commons/UI";
import CoachCard from "../comp/CoachCard";
import PackageCard from "../comp/PackageCard";
import OrderFooter from "../comp/PassOrderFooter";
import { translates } from "../translates";

export default observer(({ navigation: nav, route: { params: p } }) => {
  const mount = useRef(true),
    { navigate, goBack, replace } = nav,
    { myid, lang, hhfrmt, setAfterLogin } = useAuth();

  useEffect(() => {
    if (!myid)
      navigate("ProfileStack", { screen: "Login" }),
        showToast(AUTHER[lang]),
        setAfterLogin("PassOrder", p);
  }, []);

  const passID = p.passID || p.id || p.orderID,
    { init } = p,
    {
      passes: { [passID]: o },
      setPass,
    } = useClient(),
    {
      coaches: { [o?.coachID || "00"]: c },
    } = useSchool(),
    { coachID } = o || {},
    parsed = parsePackage(o, myid, lang),
    { paid, pending, expired, cancelled } = parsed,
    [initWait] = useState(init && paid),
    [load, setLoad] = useState(initWait),
    dbref = dbPasses(passID);

  const ondb = (d) => {
    let refuse = () => (goBack(), showToast(UIDER[lang]));
    if (!d.exists()) return init ? null : refuse();
    if (!mount.current) return;
    let doc = d.data();
    if (doc.uid !== myid) return refuse();
    setPass(doc);
  };

  // If the user just ordered and auto-navigated here (params.init), show load && <AbsLoader/> and close it after the payment url opened
  useEffect(() => {
    if (initWait) {
      let openPass = () => (
        setLoad(false), (isDesktop ? replace : navigate)("Package", { passID })
      );
      setTimeout(openPass, 2000);
    }

    const listener = onSnapshot(dbref, ondb);
    return () => ((mount.current = false), listener());
  }, []);

  if (!o) return <Loader big />;

  const title =
    TTL[lang](paid) +
    dayjs(o.created).format(
      (dayjs().diff(o.created, "d") < 8 ? " dddd," : "") +
        " D MMMM YYYY " +
        hhfrmt
    );

  const ListHeaderComponent = (
    <>
      <Row>
        <Title2 style={{ flex: 1 }} selectable>
          {title}
        </Title2>

        {(cancelled || expired || pending) && (
          <MarkerComp
            text={parsed.orderStatusText}
            color={parsed.orderStatusColor}
            style={{ marginLeft: 18 }}
          />
        )}
      </Row>
      <Row>
        <IDCaption onPress={() => copytext(passID)} numberOfLines={1}>
          id {passID}
        </IDCaption>
      </Row>
      <CoachCard small order {...{ coachID }} style={{ marginTop: 4 }} />
    </>
  );

  return (
    <Container>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, padding: 24, paddingBottom: 16 }}
      >
        {ListHeaderComponent}
        <View style={{ flex: 1, minHeight: 40 }} />

        <PackageCard
          big
          order
          p={o}
          ispass={paid && !!o.from}
          coach={c}
          navigate={nav.push}
          {...{ lang, hhfrmt }}
        />

        <View style={{ flex: 1, minHeight: 40 }} />
        <OrderFooter {...{ init, passID, setLoad }} />
      </ScrollView>

      {load && <AbsLoader />}
      {Toaster}
    </Container>
  );
});

let { AUTHER, UIDER, TTL } = translates.PassOrder;
