import React, { useEffect, useState } from "react";
import { StatusBar, View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { useAuth } from "../commons/Stores.js";
import Home from "./Home";
import Coach, { CoachModal } from "./Coach";
import CustomEvent from "./CustomEvent.js";
import Cart from "./Cart";
import Filters from "./Filters";
import Balance from "./Balance";
import AddInfo, { LangPicker } from "./AddInfo";
import Image from "./Image";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
import BalanceRecords, { PassBalance } from "./BalanceRecords";
import Event from "./Event";
import Order from "./Order";
import Login from "./Login";
import CoachGroups, { CoachPrivats } from "./Product.js";
import Package from "./Package.js";
import PassOrder from "./PassOrder";
import History, { Comments, Orders, Passes } from "./History";
import { wwidth, offline } from "../commons/utils.js";
import { ToastView, RowCentered, Text16 } from "../commons/UI.js";
import { translates } from "../translates.js";

export default {
  CoachModal,
  CustomEvent,
  Cart,
  Filters,
  AddInfo,
  LangPicker,
  Image,
  Home,
  Coach,
  Profile,
  Login,
  EditProfile,
  Balance,
  BalanceRecords,
  Event,
  Order,
  Package,
  PassOrder,
  PassBalance,
  CoachGroups,
  CoachPrivats,
  History,
  Orders,
  Comments,
  Passes,
};

let ww = window;

export let NetListener = observer(() => {
  const { lang } = useAuth(),
    [state, set] = useState(offline() ? 0 : 1);

  let onOffline = () => set(0),
    onOnline = () => {
      if (!state) setTimeout(() => ww.location.reload(), 1500);
    };

  useEffect(() => {
    ww.addEventListener("online", onOnline);
    ww.addEventListener("offline", onOffline);

    return () => {
      ww.removeEventListener("online", onOnline);
      ww.removeEventListener("offline", onOffline);
    };
  }, [state]);

  if (state === 1) return null;
  else
    return (
      <>
        <StatusBar
          backgroundColor="rgba(0, 0, 0, 0.75)"
          barStyle="light-content"
        />

        <NetworkWarn>
          <RowCentered style={{ height: 60 }}>
            <Text16
              children={INTRNT[lang]()}
              style={{ flex: 1, color: "white" }}
            />
          </RowCentered>
        </NetworkWarn>
      </>
    );
});

let NetworkWarn = styled(ToastView)`
  border-radius: 0px;
  /* width: ${wwidth}px;
  max-width: ${wwidth}px; */
  padding: 0 16px;
`;

let { INTRNT } = translates.index;
