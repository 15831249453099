import React, { useEffect } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { useNavigation } from "@react-navigation/native";
import { updateDoc, writeBatch } from "firebase/firestore";
import dayjs from "dayjs";
import { useAuth, useClient, useSchool } from "../commons/Stores";
import { paidPassUpdates, prebuyPackageChecks } from "../commons/orderChecks";
import {
  db,
  dbUsers,
  dbPasses,
  getSuprtAppeal,
  openPayURL,
  callAlert,
  vibroToast,
  capitalize,
  durtnText,
  parsePackage,
  openURL,
  offline,
  offlineToast,
  handleError,
} from "../commons/utils";
import {
  BLUE,
  Text14,
  GRAY,
  FooterOption,
  SumRow,
  Button,
  Press,
  BACKBLUE,
} from "../commons/UI";
import {
  PaidButtonComp,
  PayButtonComp,
  lackBalanceAlert,
  sendStripe,
} from "./OrderFooter";
import { translates } from "../translates";

export default observer(({ passID, setLoad, init, ...pr }) => {
  const nav = useNavigation(),
    { navigate, push } = nav,
    {
      myid,
      lang,
      rus,
      hhfrmt,
      balance,
      checkDBUser,
      profile,
      updateFields: updateProfile,
    } = useAuth(),
    {
      passes: { [passID]: o },
      setPass,
    } = useClient(),
    {
      coaches: { [o?.coachID || "00"]: coach },
      setPackage,
      getCoach,
    } = useSchool(),
    {
      price,
      time,
      duration: dur,
      term,
      packID,
      receipt,
      name: title,
    } = o || {},
    parsed = parsePackage(o, myid, lang),
    { name: coachName } = coach || {},
    { paid, canbuy, cancelled, expired } = parsed,
    dbref = dbPasses(passID);

  const updatePass = (obj) => {
    setPass(Object.assign({ id: passID }, obj));
    if (obj.status) console.log("updatePass", obj.status);
    updateDoc(dbref, obj);
  };

  const deactivate = () =>
    updatePass({ active: false, status: "expired", time: Date.now() });

  const handlePrecheck = (res) => {
    if (res.error) return deactivate(), vibroToast(EXPDTST[lang] + res.error);
  };

  useEffect(() => {
    if (canbuy)
      prebuyPackageChecks(o, myid, setPackage, getCoach).then(handlePrecheck);
  }, [canbuy]);

  const cancelOrder = () => {
    if (offline()) return offlineToast();

    let data = {
      active: false,
      status: "cancelled",
      cancelTime: Date.now(),
      cancelType: CNCLSTTS[lang],
      cancelBy: "client",
    };

    let proceed = () => {
      if (offline()) return offlineToast();
      updatePass(data);
    };

    return callAlert(...ALRTCNCL[lang], [
      { label: ALRTCNCLLBL[lang], onClick: proceed },
    ]);
  };

  const Options = (
    <>
      <View style={{ marginTop: 40 }}>
        {canbuy && (
          <FooterOption
            text={CNCLOPTN[lang]}
            onPress={cancelOrder}
            border
            noarrow
          />
        )}
        <FooterOption
          text={CNTSPRT[lang]}
          onPress={() => getSuprtAppeal({ myid, passID }, lang)}
          border
          noarrow
          center={!canbuy}
          color={!canbuy && GRAY}
          style={!canbuy && { paddingTop: 8 }}
        />
      </View>
    </>
  );

  const TotalComp = ({ big }) => (
    <SumRow {...{ big }} name={rus ? "Стоимость" : "Price"} sum={price} />
  );

  if (cancelled) {
    let { cancelType: type, cancelBy, refund } = o,
      paidTime = o.from || (o.method && time),
      balanceRecords = Object.values(profile.balance || {}).filter(
        (r) => r.passId === passID
      ).length;

    let timeFormt = (tm) =>
      (rus ? "" : " on") + dayjs(tm).format(" D MMM " + hhfrmt);

    let cancelTime = timeFormt(o.cancelTime || o.time);

    let cancelText = CNCLSTTS[lang];
    if (cancelBy) cancelText += CNCLBY[lang](cancelBy);
    cancelText += cancelTime;

    if (!cancelBy && type)
      cancelText += (rus ? " \nОписание: " : ". \nDescription: ") + type;
    if (refund) cancelText += BALREFNTX[lang](refund);
    if (paidTime) cancelText += PAIDBYTXT[lang](o.method) + timeFormt(paidTime);

    let openBalance = () => push("BalanceRecords", { passID });

    const onPress = () =>
      callAlert(CNCLSTTS[lang], cancelText, [
        refund && { text: CHCKBLNC[lang], onPress: openBalance },
        receipt && { text: RCPT[lang], onPress: () => openURL(receipt) },
      ]);

    return (
      <>
        <TotalComp big />
        {refund > 0 && (
          <Press {...{ onPress }} style={{ paddingVertical: 4 }}>
            <SumRow
              big
              name={rus ? "Возврат" : "Refund"}
              sum={-refund}
              color={BLUE}
            />
          </Press>
        )}

        <Button
          text={CNCLDBTN[lang](refund) + cancelTime}
          {...{ onPress }}
          color={BLUE}
          style={{ marginTop: 8, backgroundColor: BACKBLUE }}
        />

        {balanceRecords > 0 && (
          <Press onPress={openBalance}>
            <BalanceWarn>{BLNCRECSLINK[lang](balanceRecords)}</BalanceWarn>
          </Press>
        )}
        {Options}
      </>
    );
  }

  if (expired) return Options;

  if (paid)
    return (
      <>
        <TotalComp big />
        <PaidButtonComp {...{ o, passID, push }} />
        {Options}
      </>
    );

  // else is canbuy

  const { payurl } = o,
    canPayBalance = balance >= price,
    hasBalanceLack = balance > 0 && !canPayBalance;

  const runChecks = async () => {
    let checkData = await prebuyPackageChecks(
        o,
        myid,
        setPackage,
        getCoach,
        checkDBUser
      ),
      { error, balance: newBalance } = checkData;

    if (error) {
      deactivate();
      let openPakg = () => push("Package", { packID });

      let alertButtons = ["has changed", " изменил"].some((ch) =>
        error.includes(ch)
      )
        ? [{ text: OPNPACKLBL[lang], onPress: openPakg }]
        : checkData.alertButtons;

      callAlert(ALRTERR1TTL[lang], capitalize(error), alertButtons);
      setLoad();
      return;
    }

    return { change: error, newBalance };
  };

  const payCard = async (renewUrl) => {
    if (payurl && renewUrl !== true) return openPayURL(payurl);
    if (offline()) return offlineToast();
    setLoad(true);

    let checkData = await runChecks();
    if (!checkData) return;

    if (offline()) return offlineToast(), setLoad();

    let { change, newBalance } = checkData;
    if (change) return;

    if (newBalance >= price) return payBalance(checkData, "ignore");

    const cardSum = price - newBalance < 5 ? 5 : price - newBalance;

    let postData = {
      passID,
      isDeposit: newBalance > 0,
      sum: cardSum,
      pack: title,
      coach: coachName,
      dur,
    };

    const { url } = await sendStripe(
      postData,
      profile,
      updateProfile,
      offline,
      lang
    );

    if (!url) return setLoad();
    updatePass({ payurl: url });
    setLoad();
    openPayURL(url);
  };

  const payBalance = async (data, ignoreChecks) => {
    if (offline()) return offlineToast();
    setLoad(true);

    let checkData = ignoreChecks ? data : await runChecks();
    if (!checkData) return;
    if (offline()) return offlineToast(), setLoad();

    let { change, newBalance } = checkData;
    if (change) return;

    if (newBalance < price)
      return (
        setLoad(), lackBalanceAlert({ passID }, newBalance, price, lang, nav)
      );

    let batch = writeBatch(db),
      passUpdates = paidPassUpdates(passID, dur, term),
      time = Date.now();

    batch.update(dbref, passUpdates);

    let balanceRec = {
      time,
      sum: -price,
      passID,
      desc:
        PASSPRCH[lang](title, coachName) +
        durtnText(dur, "f", rus) +
        PASSTRMDESC[lang](term),
    };
    batch.update(dbUsers(myid), { [`balance.${time}`]: balanceRec });

    let error;
    await batch.commit().catch((er) => (error = er));

    setLoad();

    if (error) return handleError(ERRTYPE[lang], error, offline);
    setPass(passUpdates);
  };

  return (
    <>
      <TotalComp big={!hasBalanceLack} />
      <PayButtonComp {...{ o, passID, init, payCard, payBalance }} />
      {Options}
    </>
  );
});

let BalanceWarn = styled(Text14)`
  color: ${BLUE};
  text-align: center;
  padding: 24px 0 8px;
`;

let { CNTSPRT, PASSPRCH } = translates;
let {
  EXPDTST,
  CNCLSTTS,
  ALRTCNCL,
  ALRTCNCLLBL,
  CNCLOPTN,
  CNCLBY,
  BALREFNTX,
  PAIDBYTXT,
  CHCKBLNC,
  RCPT,
  CNCLDBTN,
  BLNCRECSLINK,
  OPNPACKLBL,
  ALRTERR1TTL,
  PASSTRMDESC,
  ERRTYPE,
} = translates.PassOrderFooter;
