import React, { useEffect } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { useNavigation } from "@react-navigation/native";
import { updateDoc, writeBatch } from "firebase/firestore";
import axios from "axios";
import dayjs from "dayjs";
import { useAuth, useCart, useClient, useSchool } from "../commons/Stores";
import { dbbatchOrderEvents, orderChecks } from "../commons/orderChecks";
import {
  db,
  dbOrders,
  dbUsers,
  callAlert,
  parseBalance,
  showToast,
  isbookblEvent,
  openPayURL,
  openURL,
  getSuprtAppeal,
  parseOrder,
  durtnText,
  device,
  offline,
  offlineToast,
  handleError,
  parsePackage,
  resetStackRoute,
  parseChangeText,
} from "../commons/utils";
import {
  Row,
  Text14,
  Button,
  BLUE,
  GREEN,
  BACKGREEN,
  Press,
  RefreshIcon,
  SumRow,
  FooterOption,
  Text18,
  GRAY,
  BACKBLUE,
} from "../commons/UI";
import { PackImage } from "./PackageCard";
import { translates } from "../translates";

export default observer(({ orderID, setLoad, init, ...pr }) => {
  const nav = useNavigation(),
    { navigate, push } = nav,
    {
      myid,
      lang,
      rus,
      hhfrmt,
      balance,
      checkDBUser,
      profile,
      updateFields: updateProfile,
    } = useAuth(),
    {
      orders: { [orderID]: o },
      passes: { [o?.passID || "00"]: pass },
      setOrder,
      getPass,
    } = useClient(),
    {
      coaches: { [o?.coachID || "00"]: coach },
      deleteGroup,
      updateGroup,
      programs,
      getCoach,
    } = useSchool(),
    { clearCart, getCart, add: addCart } = useCart(),
    { events, quant, coachID, passID, time, total } = o || {},
    parsed = parseOrder(o, lang),
    { name: coachName } = coach || {},
    { paid, canbuy, paidByPass, quantText, totalDur, cancelled, expired } =
      parsed,
    dbref = dbOrders(orderID);

  useEffect(() => {
    if (passID && !pass) getPass(passID);
  }, [passID && !pass]);

  const canRepeat =
    !paidByPass &&
    Object.values(events || {}).some((e) =>
      isbookblEvent(e.active ? e : { ...e, active: true })
    );

  const updateOrder = (obj) => {
    setOrder(Object.assign({ id: orderID }, obj));
    updateDoc(dbref, obj);
  };

  let reorder = (merge) => {
    let cartObj = {};

    for (let eid in events) {
      let {
          id,
          client: { quant: cq, sum: cs },
          time,
          cancelTime,
          cancelType,
          cancelBy,
          reschedule,
          refund,
          ...e
        } = events[eid],
        timetx = dayjs(e.from).format(hhfrmt);
      e.active = true;

      if (!isbookblEvent(e)) {
        let warn = SKIPREORD1[lang](timetx);
        showToast(warn, 4000);
        continue;
      }

      e.client = { uid: myid, quant: cq, sum: cs, passID };
      cartObj[eid] = e;
    }

    let arr = Object.values(cartObj);
    if (merge) addCart(arr);
    else clearCart({ passID, coachID }), addCart(arr);

    return navigate("Cart", { coachID, passID });
  };

  const onReorder = () => {
    let cartActives = getCart(passID || coachID).filter(
      (e) => isbookblEvent(e) && !e.error
    );
    if (!cartActives[0]) return reorder();

    let cartQuantText = CARTQNTTX[lang](cartActives.length, coachName),
      quantText2 = QNTTX2[lang](quant);

    callAlert(...ALRTCART[lang](cartQuantText, quantText2), [
      { label: rus ? "Добавить" : "Add", onClick: () => reorder("merge") },
      { label: rus ? "Заменить" : "Replace", onClick: () => reorder() },
    ]);
  };

  const TotalComp = ({ big }) => (
    <SumRow
      {...{ big }}
      isdur={paidByPass}
      name={(rus ? "Итого " : "Total ") + quantText}
      sum={paidByPass ? totalDur : total}
      style={{ marginTop: canbuy || paid ? 28 : 16 }}
    />
  );

  const onlyContactOption = !canRepeat && !canbuy;

  let cancelOrder = () => {
    if (offline()) return offlineToast();
    updateOrder({
      status: "cancelled",
      cancelTime: Date.now(),
      cancelType: CNCLTX[lang],
      cancelBy: "client",
    });
  };

  const onCancel = () => {
    if (offline()) return offlineToast();
    if (!canbuy) return;
    return callAlert(...ALRTCNCL[lang], [
      { label: ALRTCNCLLBL[lang], onClick: cancelOrder },
    ]);
  };

  const Options = (
    <>
      {(expired || paid) && <View style={{ flex: 1 }} />}
      <View style={{ marginTop: 40 }}>
        {canRepeat && (
          <FooterOption
            text={REORDTX[lang]}
            onPress={onReorder}
            border={expired}
          />
        )}
        {canbuy && (
          <FooterOption
            text={CNCLTX2[lang]}
            onPress={onCancel}
            border={canRepeat || expired}
            noarrow
          />
        )}
        <FooterOption
          text={CNTSPRT[lang]}
          onPress={() => getSuprtAppeal({ myid, orderID }, lang)}
          border
          noarrow
          center={onlyContactOption}
          color={onlyContactOption && GRAY}
          style={onlyContactOption && { paddingTop: 8 }}
        />
      </View>
    </>
  );

  if (cancelled) {
    let { cancelType: type, cancelBy } = o;
    let timeText =
      (rus ? "" : " on") +
      dayjs(o.cancelTime || time).format(" D MMM " + hhfrmt);

    let cancelText = CNCLTX[lang];
    if (cancelBy) cancelText += CNCLBY[lang](cancelBy);
    cancelText += timeText;
    if (!cancelBy && type)
      cancelText += (rus ? " \nОписание: " : ". \nDescription: ") + type;

    const onPress = () =>
      callAlert(CNCLTX[lang], cancelText, [
        o.receipt && { text: RCPT[lang], onPress: () => openURL(o.receipt) },
      ]);

    return (
      <>
        <TotalComp big />
        <Button
          text={(rus ? "Отменен" : "Cancelled") + timeText}
          {...{ onPress }}
          color={BLUE}
          style={{ marginTop: 8, backgroundColor: BACKBLUE }}
        />
        {Options}
      </>
    );
  }

  if (expired)
    return (
      <>
        <TotalComp big />
        {Options}
      </>
    );

  let timeText = (rus ? " в " : " at ") + dayjs(time).format(hhfrmt);

  if (paidByPass) {
    const { duration: dur, color } = pass || {},
      passName =
        (o.passName || pass?.name + "") + (rus ? " абонемент" : " package");

    const passRecords = Object.values(pass?.uses || {}).filter(
      (r) => r.orderId === orderID
    ).length;

    const openPassBalance = () => push("PassBalance", { passID, orderID }),
      openPass = () => push("Package", { passID });

    const onPress = () =>
      balanceChargeAlert(
        passName,
        totalDur,
        time,
        hhfrmt,
        lang,
        openPassBalance,
        openPass
      );

    return (
      <>
        <TotalComp big />
        <PaidButton {...{ onPress }}>
          {pass && <PackImage mini {...{ dur, color }} />}
          <Text18 style={{ color: GREEN }} numberOfLines={1}>
            {PASSPAID1[lang](passName) + timeText}
          </Text18>
        </PaidButton>

        {passRecords > 0 && (
          <Press onPress={openPassBalance}>
            <BalanceWarn>{PASSRECS1[lang](passRecords)}</BalanceWarn>
          </Press>
        )}

        {Options}
      </>
    );
  }

  if (paid)
    return (
      <>
        <TotalComp big />
        <PaidButtonComp {...{ o, orderID, push }} />
        {Options}
      </>
    );

  // else is canbuy

  const { payurl } = o,
    canPayBalance = balance >= total,
    hasBalanceLack = balance > 0 && !canPayBalance;

  const runChecks = async () => {
    let newEvents = { ...events }; // if changes check gives updates, update 'newEvents' object directly

    let { changed, refund } = await orderChecks(
      newEvents,
      (eid, slotID) => (newEvents[eid].slotID = slotID), // for privats checks, update slotID if needed
      updateGroup,
      getCoach
    );

    if (changed[0])
      changed.forEach(
        (c) => (
          (newEvents[c.id].active = false), // 'newEvents' then will be sent to update Order in store
          c.change === "cancelled or passed" && deleteGroup(c.id)
        )
      );

    let newTotal = changed[0] ? parseFloat((total - refund).toFixed(2)) : total,
      newQuant = quant - changed.length,
      updates = {
        events: newEvents,
        total: newTotal,
        quant: newQuant,
      };

    if (changed[0]) updateOrder(updates);

    if (newQuant < 1) {
      setLoad();
      callAlert(CNTBK1[lang], CNTBK2[lang] + renderClassChanges(changed, rus));
      return null;
    }

    let user = await checkDBUser(),
      newBalance = parseBalance(user.balance);
    return { changed, updates, newBalance };
  };

  const payCard = async (renewUrl) => {
    if (payurl && renewUrl !== true) return openPayURL(payurl);
    if (offline()) return offlineToast();

    setLoad(true);
    let checkData = await runChecks();
    if (!checkData) return;
    if (offline()) return offlineToast(), setLoad();

    let { changed, updates, newBalance } = checkData,
      { total: newTotal, quant: newQuant } = updates;

    if (newBalance >= newTotal) return payBalance(checkData, "ignore");

    const sum = newTotal - newBalance < 5 ? 5 : newTotal - newBalance,
      progs = parsed.progs.map((p) => programs[p].name);

    let proceed = async () => {
      if (offline()) return offlineToast();

      let postData = {
        orderID,
        isDeposit: newBalance > 0,
        sum,
        quant: changed[0] ? newQuant : quant,
        progs,
      };
      if (coachName) postData.coach = coachName;

      let { url } = await sendStripe(
        postData,
        profile,
        updateProfile,
        offline,
        lang
      );

      if (!url) return setLoad();
      updateOrder({ payurl: url });
      setLoad();
      openPayURL(url);
    };

    if (!changed[0]) return proceed();

    let alertTitle = ALRTCHGDTTL[lang] + CLSQNT[lang](changed.length);
    let alertText =
      ALRTCHGDTXT[lang](changed.length, sum) + CLSQNT[lang](newQuant);

    callAlert(
      alertTitle,
      alertText,
      [
        {
          label: (rus ? "Оплатить" : "Pay") + ` ${sum}$`,
          onClick: proceed,
        },
        { label: rus ? "Отмена" : "Cancel", onClick: () => setLoad() },
      ],
      "strict"
    );
  };

  const payBalance = async (data, ignoreChecks) => {
    if (offline()) return offlineToast();
    setLoad(true);

    let checkData = ignoreChecks ? data : await runChecks();
    if (!checkData) return;
    if (offline()) return offlineToast(), setLoad();

    let { changed, updates, newBalance } = checkData,
      { total: newTotal } = updates;

    if (newBalance < newTotal)
      return (
        setLoad(),
        lackBalanceAlert({ orderID }, newBalance, newTotal, lang, nav)
      );

    let time = Date.now(),
      orderUpdates = Object.assign(
        { id: orderID, status: "paid", method: "balance", device, time },
        changed[0] && updates
      );

    let batch = writeBatch(db);
    batch.update(dbref, orderUpdates);
    batch = dbbatchOrderEvents(updates.events, batch, changed);

    let balanceRec = { time, sum: -newTotal, orderID };
    batch.update(dbUsers(myid), {
      [`balance.${time}`]: balanceRec,
      canTrial: false,
    });

    let error;
    await batch.commit().catch((er) => (error = er));
    setLoad();

    if (error) return handleError(ERRTYPE[lang], error, offline);
    setOrder(orderUpdates);
  };

  return (
    <>
      {hasBalanceLack && <View style={{ flex: 1 }} />}
      <TotalComp big={!hasBalanceLack} />
      <PayButtonComp {...{ o, orderID, init, payCard, payBalance }} />
      {Options}
    </>
  );
});

export const PayButtonComp = observer(({ o, ...pr }) => {
  const { lang, balance } = useAuth(),
    nav = useNavigation(),
    ispass = pr.passID && !pr.orderID,
    idkey = ispass ? "passID" : "orderID",
    id = pr[idkey],
    sum = ispass ? o.price : o.total,
    balanceDiff = parseFloat((sum - balance).toFixed(2)),
    canPayBalance = balance >= sum,
    hasBalanceLack = balance > 0 && !canPayBalance,
    lackLess5 = balanceDiff < 5,
    extraTopay = !canPayBalance && (lackLess5 ? 5 : balanceDiff),
    canUpdateStripe = !canPayBalance && !pr.init && o.payurl;

  return (
    <>
      {hasBalanceLack && (
        <>
          <SumRow
            name={BLNCLACKROW[lang](lackLess5, balance)}
            sum={-(lackLess5 ? balance - (5 - balanceDiff) : balance)}
          />
          <SumRow name={SUMPAY[lang](lackLess5)} sum={extraTopay} />
        </>
      )}

      {!ispass && !hasBalanceLack && <View style={{ flex: 1 }} />}

      <Row style={{ marginTop: hasBalanceLack ? 12 : 16 }}>
        <Button
          big={canPayBalance}
          text={PAYMTHD[lang](canPayBalance, canPayBalance ? sum : extraTopay)}
          onPress={canPayBalance ? pr.payBalance : pr.payCard}
          style={payButnStyle(canUpdateStripe)}
        />
        {canUpdateStripe && <RefreshButton onPress={() => pr.payCard(true)} />}
      </Row>

      {!canPayBalance && (
        <Button
          transp
          text={SCNDPAYBTN[lang]}
          onPress={openDeposit(nav, { [idkey]: id })}
          style={{ marginTop: 16 }}
        />
      )}
    </>
  );
});

export const PaidButtonComp = observer(({ o, ...pr }) => {
  const {
      lang,
      hhfrmt,
      myid,
      profile: { balance },
    } = useAuth(),
    ispass = pr.passID && !pr.orderID,
    idkey = ispass ? "passID" : "orderID",
    id = pr[idkey],
    { time } = o,
    paidSum = ispass ? o.price : o.total,
    { paidByBalance } = ispass ? parsePackage(o, myid, lang) : parseOrder(o),
    balanceRecords = Object.values(balance || {}).filter(
      (r) => r[idkey] === id
    ).length;

  const openBalance = () => pr.push("BalanceRecords", { [idkey]: id });

  const onPress = paidByBalance
    ? () =>
        balanceChargeAlert("balance", paidSum, time, hhfrmt, lang, openBalance)
    : o.receipt
    ? () => openURL(o.receipt)
    : null;

  return (
    <>
      <PaidButton
        text={PAIDBY[lang](o.method) + dayjs(time).format(hhfrmt)}
        {...{ onPress }}
      />
      {balanceRecords > 0 && (
        <Press onPress={openBalance}>
          <BalanceWarn>{BLNCRECSLINK[lang](balanceRecords)}</BalanceWarn>
        </Press>
      )}
    </>
  );
});

export const sendStripe = async (
  { ...data },
  profile,
  updateProfile,
  offline,
  lang
) => {
  let { uid, name, email, stripeID: client } = profile,
    addData = { uid, name, email, client, isweb: true };

  for (let key in addData) {
    if (addData[key]) data[key] = addData[key];
  }

  let res = await axios
    .post(`https://blsm.xyz/pay`, data)
    .catch((er) => console.warn("ERROR axios.post " + er));

  let { url, stripeID } = res?.data || {};
  if (url) {
    if (stripeID !== client) updateProfile({ stripeID });
    return { url, stripeID };
  }

  if (offline()) offlineToast();
  else callAlert(...ALRTCARDERR[lang]);
  return { error: true };
};

export const openDeposit = (nav, params) => {
  const isINProfileStack = nav.getState().routeNames?.[0] === "Profile",
    navigate = isINProfileStack ? nav.navigate : resetStackRoute;
  return () => navigate("Balance", params);
};

export const lackBalanceAlert = (idobj, balance, total, lang, nav) => {
  let rus = lang === "ru",
    ispass = "passID" in idobj,
    type = ORDTYPE[lang](ispass);

  let onClick = openDeposit(nav, idobj);
  return callAlert(...ALRTBLNCLACK[lang](type, balance, total), [
    { label: (rus ? "Добавить" : "Add") + ` ${total - balance}$`, onClick },
  ]);
};

export const balanceChargeAlert = (
  type,
  sum,
  time,
  hhfrmt,
  lang,
  onClick,
  onClick2
) => {
  let rus = lang === "ru",
    isPass = type !== "balance";

  let sumText = isPass ? durtnText(sum, "f", rus) : sum + "$";
  let timeText =
    (rus ? "" : " on") + dayjs(time).format(" D MMMM YYYY, " + hhfrmt);

  return callAlert(
    ALRTPAID1[lang](type),
    sumText + ALRTPAID2[lang](type) + timeText,
    [
      { label: ALRTPAIDLBL1[lang](isPass), onClick },
      onClick2 && isPass && { label: ALRTPAIDLBL2[lang], onClick: onClick2 },
    ]
  );
};

let renderClassChanges = (changes, rus) => {
  let render = (c, i) =>
    `#${i + 1} – ${rus ? parseChangeText(c.change, "ru") : c.change}`;
  return changes.map(render).join(",\n");
};

let PaidButton = (pr) => (
  <Button
    {...pr}
    color={GREEN}
    style={{
      marginTop: 8,
      paddingHorizontal: 12,
      backgroundColor: BACKGREEN,
    }}
  />
);

const RefreshButton = (pr) => (
  <Button
    {...pr}
    style={{
      marginLeft: 1,
      width: 50,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }}
  >
    <RefreshIcon />
  </Button>
);

let payButnStyle = (canUpdate) =>
  canUpdate
    ? { flex: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }
    : { flex: 1 };

let BalanceWarn = styled(Text14)`
  color: ${BLUE};
  text-align: center;
  padding: 24px 0 8px;
`;

let { CNTSPRT, CLSQNT } = translates;
let {
  SKIPREORD1,
  CARTQNTTX,
  QNTTX2,
  ALRTCART,
  CNCLTX,
  ALRTCNCL,
  ALRTCNCLLBL,
  REORDTX,
  CNCLTX2,
  CNCLBY,
  RCPT,
  PASSPAID1,
  PASSRECS1,
  CNTBK1,
  CNTBK2,
  ALRTCHGDTTL,
  ALRTCHGDTXT,
  ERRTYPE,
  BLNCLACKROW,
  SUMPAY,
  PAYMTHD,
  SCNDPAYBTN,
  BLNCRECSLINK,
  ALRTCARDERR,
  PAIDBY,
  ORDTYPE,
  ALRTBLNCLACK,
  ALRTPAID1,
  ALRTPAID2,
  ALRTPAIDLBL1,
  ALRTPAIDLBL2,
} = translates.OrderFooter;
