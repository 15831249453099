import React, { useState, useEffect } from "react";
import {
  View,
  ActivityIndicator,
  Pressable,
  TouchableOpacity as Touchbl,
  ImageBackground,
  StyleSheet,
} from "react-native";
import styled from "styled-components/native";
import rstyled from "styled-components";
import { observer } from "mobx-react-lite";
import Hyperlink from "react-native-hyperlink";
import { RefreshControl } from "react-native-web-refresh-control";
import { useNavigation } from "@react-navigation/native";
import Toast from "react-native-toast-message";
import AntDesign from "@expo/vector-icons/AntDesign";
import Entypo from "@expo/vector-icons/Entypo";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import Ionicons from "@expo/vector-icons/Ionicons";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { useAuth, useClient } from "./Stores";
import {
  wheight,
  wwidth,
  isDesktop,
  copyDeepLink,
  modalWidth,
  openRefundPlcy,
  openURL,
  getIMGRatio,
  offsetName,
  durtnText,
  localRus,
  localLang,
} from "./utils";
import { translates } from "../translates";

let isMobl = true; //window.innerWidth <= 450;

export const GRAY = "#999",
  DGRAY = "#444",
  ACTIVEGRAY = "#777",
  BACKGRAY = "#f4f4f4",
  LINEGRAY = "#bebebe",
  ICONGRAY = "#CDCDCD",
  BORDERGRAY = "#D7D7D7",
  LITEBORDER = "#E9EBF0",
  BLUE = "#253b6e",
  LINEBLUE = "#9BA5BC",
  BACKBLUE = "#F0F1F5", // '#f2f0f8',
  GREEN = "#4E9F4E",
  BACKGREEN = "#EEFDEE",
  ORANGE = "#fac32d",
  RED = "#C72415",
  BACKRED = "#FBEDEC";

//'#ea4335';

export const Image = ImageBackground,
  Press = Pressable,
  Touch = ({ ao, onPress, ...pr }) => (
    <Touchbl
      activeOpacity={onPress ? ao || 0.7 : 1}
      onPress={onPress || null}
      {...pr}
    />
  ),
  Row = styled.View`
    flex-direction: row;
  `,
  RowCentered = styled(Row)`
    align-items: center;
  `,
  RowBetween = styled(RowCentered)`
    justify-content: space-between;
  `,
  Text14 = styled.Text`
    font-family: "CeraPro-Regular";
    color: black;
    font-size: ${isMobl ? 14 : 12}px;
    line-height: ${isMobl ? 18 : 15.5}px;
    flex-shrink: 1;
    text-align-vertical: top;
    /* vertical-align: top; */
    /* include-font-padding: false;  // для разных line-height по разному себя ведет  */
  `,
  Text10 = styled(Text14)`
    font-size: ${isMobl ? 10 : 9}px;
    line-height: ${isMobl ? 13 : 11}px;
  `,
  Text11 = styled(Text10)`
    font-size: ${isMobl ? 11 : 10}px;
  `,
  Text12 = styled(Text14)`
    font-size: ${isMobl ? 12 : 10.5}px;
    line-height: ${isMobl ? 15 : 13}px;
  `,
  Text15 = styled(Text14)`
    font-size: ${isMobl ? 15 : 13}px;
    line-height: ${isMobl ? 20 : 17}px;
  `,
  Text16 = styled(Text14)`
    font-size: ${isMobl ? 16 : 14}px;
    line-height: ${isMobl ? 22 : 19}px;
  `,
  Text18 = styled(Text14)`
    font-size: ${isMobl ? 18 : 15.5}px;
    line-height: ${isMobl ? 23 : 20}px;
  `,
  Text21 = styled(Text14)`
    font-size: ${isMobl ? 21 : 18}px;
    line-height: ${isMobl ? 26 : 22.5}px;
  `,
  Text24 = styled(Text14)`
    font-size: ${isMobl ? 24 : 20.5}px;
    line-height: ${isMobl ? 30 : 26}px;
  `,
  Text28 = styled(Text14)`
    font-size: ${isMobl ? 28 : 24}px;
    line-height: ${isMobl ? 35 : 30}px;
  `,
  Medium10 = styled(Text10)`
    font-family: "CeraPro-Medium";
  `,
  Medium12 = styled(Text12)`
    font-family: "CeraPro-Medium";
  `,
  Medium14 = styled(Text14)`
    font-family: "CeraPro-Medium";
  `,
  Medium15 = styled(Text15)`
    font-family: "CeraPro-Medium";
  `,
  Medium16 = styled(Text16)`
    font-family: "CeraPro-Medium";
  `,
  Medium18 = styled(Text18)`
    font-family: "CeraPro-Medium";
  `,
  Medium19 = styled(Medium18)`
    font-size: ${isMobl ? 19 : 16.5}px;
  `,
  Medium21 = styled(Text21)`
    font-family: "CeraPro-Medium";
  `,
  Medium24 = styled(Text24)`
    font-family: "CeraPro-Medium";
  `,
  Medium28 = styled(Text28)`
    font-family: "CeraPro-Medium";
  `,
  PageTitle = styled(Text28)`
    color: ${DGRAY};
  `,
  Title = styled(Text24)`
    color: ${DGRAY};
  `,
  Title2 = styled(Text21)`
    color: ${DGRAY};
  `;

export let GrayContainer = styled.View`
    flex: 1;
    background: ${BACKGRAY};
  `,
  SafeArea = GrayContainer,
  Container = styled(GrayContainer)`
    background: white;
  `,
  Body = styled.View`
    flex-shrink: 1;
    background: white;
    padding: 26px 24px 40px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  `,
  PageImage = rstyled.div`
    width: ${modalWidth}px;
    height: ${wwidth}px;
    background: ${BACKGRAY};
    margin-bottom: -18px;
    overflow: hidden;
    /* object-fit: cover; */
    background-size: cover;
    background-position: center;
  `,
  CardImage = rstyled.div`
    width: 84px;
    height: 84px;
    background: ${BACKGRAY};
    overflow: hidden;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
  `;

export const BackTouch = ({ color, goBack, ...r }) => {
  return (
    <Press onPress={goBack}>
      <View {...r}>
        <BackIconView style={{ height: 60 }}>
          <BackIcon style={color && { backgroundColor: color }} />
        </BackIconView>
      </View>
    </Press>
  );
};

let BackIconView = styled.View`
  width: 66px;
  justify-content: center;
  align-items: center;
`;

export let BackIcon = styled.View`
  width: 21px;
  height: 1px;
  background: ${GRAY};
  transform: rotate(-45deg);
`;

export const BlankUserPic = styled.View`
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 84px;
    border-radius: 15px;
  `,
  BlankUserChar = styled.Text`
    font-family: "CeraPro-Medium";
    font-size: ${isMobl ? 32 : 28}px;
    color: white;
    text-transform: capitalize;
  `;

export let TextDot = (pr) => (
  <Medium14 style={{ lineHeight: 14, color: pr.color || BLUE }}>·</Medium14>
);

export const ShowMore = ({ style, ...r }) => {
  let lang = r.lang ?? localLang(),
    Wrap = r.onPress ? Touch : View;
  return (
    <Wrap {...r} style={{ paddingTop: 8 }}>
      <RowCentered>
        <TextDot />
        <Text14 style={{ flex: 1, color: BLUE }}>{SHOWMORE[lang]}</Text14>
      </RowCentered>
    </Wrap>
  );
};

export const Button = ({ text, ...r }) => (
  <ButtonComp {...r} activeOpacity={r.ao || (r.onPress ? 0.7 : 1)}>
    {r.children || (
      <ButtonText
        style={[
          r.big && { fontSize: 21 },
          { color: r.color || (r.inactive ? GRAY : r.transp ? BLUE : "white") },
        ]}
        numberOfLines={1}
      >
        {text}
      </ButtonText>
    )}
  </ButtonComp>
);

const ButtonComp = ({ big, transp, ...r }) => {
  let Comp = transp ? ButtonTouchTransp : ButtonTouch;
  let style = [
    { height: big ? 65 : 54 },
    r.inactive && !transp && { backgroundColor: BACKGRAY },
    r.style,
  ];
  return <Comp {...r} {...{ style }} />;
};

let ButtonTouch = styled(Touchbl)`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 54px;
    background: black;
    border-radius: 10px;
  `,
  ButtonTouchTransp = styled(ButtonTouch)`
    background: rgba(0, 0, 0, 0);
    border: 1px solid ${BORDERGRAY};
  `,
  ButtonText = styled(Text18)`
    text-align: center;
    flex-shrink: 0;
    padding: 8px 16px;
  `;

export const SocButtons = (r) => {
  let iconstyle = { marginRight: 12, marginTop: -2 };
  return (
    <View style={{ width: wwidth - 24 * 2, ...r.style }}>
      <Button
        big
        onPress={r.appleLogin}
        style={{ backgroundColor: "white", ...shadow16 }}
      >
        <SocIcon provider="apple" size={16} style={iconstyle} />
        <GoogleText>{r.rus ? "Войти с " : "Sign in with "}Apple</GoogleText>
      </Button>

      <Button
        big
        onPress={r.googleLogin}
        style={{ backgroundColor: "white", marginTop: 24, ...shadow16 }}
      >
        <GoogleIcon size={16} style={iconstyle} />
        <GoogleText>{r.rus ? "Войти с " : "Sign in with "}Google</GoogleText>
      </Button>
    </View>
  );
};

let GoogleText = styled(Medium21)``;

export const DropDown = ({ text, hasIcon, style, ...r }) => (
  <Button
    transp
    style={{ paddingRight: 14, paddingLeft: 14 + (hasIcon ? 8 : 0), ...style }}
    {...r}
  >
    <DropText numberOfLines={1}>{text}</DropText>
    {hasIcon && <IconDown />}
  </Button>
);

let DropText = styled(Text16)`
  text-align: center;
  flex: 1;
`;

export const FilterButton = ({ active, transp, ...r }) => {
  let style = [
    { backgroundColor: active ? BLUE : transp ? undefined : "white" },
    transp && { borderWidth: 1 },
  ];
  return (
    <Touch {...r}>
      <FilterView {...{ style }}>
        <FilterText
          style={{ color: active ? "white" : transp ? "black" : DGRAY }}
          numberOfLines={1}
        >
          {r.text}
        </FilterText>
        {r.hasIcon && (
          <IconDown
            size={14}
            color={active ? "white" : DGRAY}
            style={{ marginRight: -4, marginLeft: 8 }}
          />
        )}
        {r.children}
      </FilterView>
    </Touch>
  );
};

export let FilterView = styled(RowCentered)`
    height: 36px;
    justify-content: center;
    border: 0 solid ${BORDERGRAY};
    border-radius: 20px;
    padding: 0 16px;
  `,
  FilterText = styled(Text14)`
    text-align: center;
    color: ${DGRAY};
    min-width: 40px;
    max-width: 120px;
  `;

export const EventIcon = (pr) => (
  <AntDesign name="calendar" color={"#BDBDBD"} size={16} {...pr} />
);

export const AbsLoader = ({ style, ...r }) => (
  <Loader
    big
    style={{
      ...StyleSheet.absoluteFill,
      position: "absolute",
      height: wheight,
      zindex: 11,
      backgroundColor: "rgba(255, 255, 255, 0.75)",
      ...style,
    }}
    {...r}
  >
    {r.text && <BlankText>{r.text}</BlankText>}
  </Loader>
);

export const Loader = ({ style, color = DGRAY, small, big, left, children }) =>
  big ? (
    <LoadView {...{ style }}>
      <ActivityIndicator
        {...{ color }}
        size="large"
        style={children && { marginTop: -32, marginBottom: 16 }}
      />
      {children}
    </LoadView>
  ) : (
    <ActivityIndicator
      {...{ color }}
      size={small ? "small" : "large"}
      style={[
        !small && { paddingVertical: 12 },
        left && { alignSelf: "flex-start" },
        style,
      ]}
    />
  );

let LoadView = styled.View`
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 32px;
`;

export const CopyIcon = (pr) => (
  <Ionicons
    name="copy-outline"
    size={16}
    color={LINEGRAY}
    style={{ marginLeft: 8, marginTop: 2 }}
    {...pr}
  />
);

export const CommentIcon = ({ style, ...pr }) => (
  <Ionicons
    name="chatbubble-sharp"
    size={20}
    color={BLUE}
    {...pr}
    style={{ transform: [{ scaleX: -1 }], ...style }}
  />
);

export const SocIcon = ({ provider: name, hasback, size = 11, ...r }) => (
  <View
    style={
      hasback && {
        width: 18,
        height: 18,
        borderRadius: 9,
        backgroundColor: BACKBLUE,
        justifyContent: "center",
        alignItems: "center",
        marginRight: 6,
        ...r.style,
      }
    }
  >
    <FontAwesome
      {...{ name, size }}
      color="black"
      style={!hasback && r.style}
    />
  </View>
);

export const IconDown = ({ size = 16, color = DGRAY, style }) => (
  <Entypo
    name="chevron-thin-down"
    {...{ size, color }}
    style={{ width: size + 1, marginLeft: 8, marginBottom: -1, ...style }}
  />
);

export const SettingsIcon = (pr) => (
  <Entypo name="dots-three-horizontal" size={22} color={DGRAY} {...pr} />
);

export const RemoveIcon = ({ onPress, style, height, color = LINEGRAY }) => (
  <Press {...{ onPress, style }}>
    <View style={{ height, paddingHorizontal: 16, justifyContent: "center" }}>
      <AntDesign name="closecircle" size={22} {...{ color }} />
    </View>
  </Press>
);

export const CheckboxIcon = ({ active, ...r }) =>
  active ? <CheckIcon {...r} /> : <CheckboxView />;

let CheckboxView = styled.View`
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid ${BORDERGRAY};
  `,
  CheckIcon = ({ size = 26, color = "white", style }) => (
    <MaterialIcons
      name="check"
      {...{ size, color }}
      style={{ marginLeft: -2, ...style }}
    />
  );

export const QuantButton = ({ text, color, minus, plus, ...pr }) => (
  <Button transp ao={1} {...pr}>
    <Press style={{ padding: (60 - 17) / 2 }} onPress={minus}>
      <MinusIcon {...{ color }} active={minus} />
    </Press>
    <RowCentered style={{ flex: 1, justifyContent: "center" }}>
      <ButtonText style={{ fontSize: 16 }} numberOfLines={1}>
        {text}
      </ButtonText>
      {pr.children}
    </RowCentered>
    <Press onPress={plus} style={{ padding: (60 - 17) / 2 }}>
      <PlusIcon size={15} {...{ color }} active={plus} />
    </Press>
  </Button>
);

export const MinusIcon = ({ size = 17, ...r }) => (
  <View
    style={{ justifyContent: "center", height: size, width: size, ...r.style }}
  >
    <View
      style={{
        height: r.height || 1,
        backgroundColor: r?.color || (r?.active ? DGRAY : BORDERGRAY),
      }}
    />
    {r.children}
  </View>
);

export const PlusIcon = (pr) => (
  <MinusIcon {...pr}>
    <View
      style={{
        position: "absolute",
        alignSelf: "center",
        width: pr.width || 1,
        height: pr.size || 17,
        backgroundColor: pr.color || (pr.active ? DGRAY : BORDERGRAY),
      }}
    />
  </MinusIcon>
);

// ШАБЛОНЫ
// box-shadow: x-offset y-offset blur-radius spread-radius color | inset
export const shadow2 = {
    // shadowOffset: { height: 2 },
    // shadowOpacity: 0.1,
    // shadowRadius: 2,
    boxShadow: "0 2px 2px rgba(0,0,0,0.1)",
  },
  shadow4 = {
    // shadowOffset: { height: 4 },
    // shadowOpacity: 0.12,
    // shadowRadius: 8,
    boxShadow: "0 4px 8px rgba(0,0,0,0.12)",
  },
  shadow16 = {
    // shadowOpacity: 0.16,
    // shadowOffset: { height: 8 },
    // shadowRadius: 16,
    boxShadow: "0 8px 16px rgba(0,0,0,0.16)",
  };

export const BlankView = styled.View`
    flex: 1;
    /* flex-grow: 1; */
    justify-content: center;
    align-items: center;
    padding: 24px 0;
    /* background: red; */
  `,
  BlankText = styled(Text16)`
    text-align: center;
    color: ${GRAY};
  `,
  EmptyText = styled(BlankText)`
    width: ${wwidth - 24 * 2}px;
    padding: 2px 0;
  `;

export const RemoveCircle = ({ onPress, style }) => (
  <Press
    style={{
      backgroundColor: "white",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 2,
      height: 44,
      width: 44,
      borderRadius: 25,
      ...style,
    }}
    {...{ onPress }}
  >
    <MinusIcon color={RED} height={2} />
  </Press>
);

export const VideoIcon = (pr) => (
  <Entypo name="youtube" size={24} color={LINEGRAY} {...pr} />
);

export const ZoomView = styled.View`
  background: ${BACKBLUE};
  flex-shrink: 1;
  border-radius: 18px;
  padding: 16px;
  margin-top: 4px;
`;

export let toastConfig = {
  basic: (pr) => (
    <Press onPress={pr.onPress}>
      <ToastView>
        <Text16 style={{ color: "white" }}>{pr.text1}</Text16>
      </ToastView>
    </Press>
  ),
};

export let ToastView = styled.View({
  minHeight: 54,
  maxWidth: wwidth - 20 * 2,
  justifyContent: "center",
  backgroundColor: "rgba(0,0,0,0.75)",
  borderRadius: 8,
  paddingVertical: 12,
  paddingHorizontal: 16,
  zIndex: 1000,
});

export const Toaster = <Toast config={toastConfig} />;

export const UserPic = ({
  photo: uri,
  big,
  small,
  mini,
  style: style0,
  ...r
}) => {
  let size = r.size || (mini ? 20 : small ? 50 : big ? 110 : 84),
    style = Object.assign(
      {
        backgroundImage: `url(${uri})`,
        backgroundColor: r.color || BLUE,
        width: size,
        height: size,
        borderRadius: size > 80 ? 15 : size / 5,
      },
      r.right ? { marginLeft: 18 } : { marginRight: mini ? 8 : 18 },
      style0
    );
  if (uri) return <CardImage {...{ style }} />;
  else {
    let fontSize = mini ? 14 : small ? 24 : big ? 44 : size / 2;
    return (
      <BlankUserPic {...{ style }}>
        <BlankUserChar style={{ fontSize }}>
          {r.name?.[0]?.toUpperCase() || "A"}
        </BlankUserChar>
      </BlankUserPic>
    );
  }
};

export const StatsComp = ({
  colored,
  desc1,
  desc2,
  cap1,
  cap2,
  row,
  style,
  num1,
  num2,
  isprice,
}) => {
  let StatsWrap = row ? Row : View;
  return (
    <StatsWrap style={[row && { height: 50 }, style]}>
      <StatCircle
        num={Math.round(num1)}
        cap={cap1}
        {...{ isprice }}
        color={colored && GREEN}
        backColor={colored && BACKGREEN}
        desc={desc1}
        style={desc1 && { flex: 1 }}
      />
      <StatCircle
        num={Math.round(num2)}
        cap={cap2}
        desc={desc2}
        {...{ isprice }}
        backColor={colored && BACKBLUE}
        style={[desc1 && { flex: 1 }, !row && { marginTop: 20 }]}
      />
    </StatsWrap>
  );
};

export const StatCircle = ({
  color = DGRAY,
  backColor: BC,
  big,
  cap,
  desc,
  num,
  isprice,
  style,
}) => {
  let txstyle = color && { style: { color } },
    islongPrice =
      isprice && String(num).length > (String(num).includes(".") ? 4 : 3),
    NumComp = big ? Medium21 : islongPrice ? Medium16 : Medium19;

  return (
    <StatRow style={[style, big && { height: 70 }]}>
      <StatCircleView
        style={[
          BC && { backgroundColor: BC, borderColor: BC },
          big && { width: 70, height: 70 },
          { marginRight: desc && cap ? 16 : desc ? 12 : cap ? 32 : 0 },
        ]}
      >
        <Row>
          {isprice && <Dollar {...txstyle}>$</Dollar>}
          <NumComp {...txstyle}>{num}</NumComp>
        </Row>
        {cap && <StatCap {...txstyle}>{cap}</StatCap>}
      </StatCircleView>
      {desc && <StatDesc {...txstyle}>{desc}</StatDesc>}
    </StatRow>
  );
};

let StatRow = styled(RowCentered)`
    height: 50px;
  `,
  StatCircleView = styled.View`
    justify-content: center;
    align-items: center;
    /* background: white */
    width: 50px;
    height: 50px;
    border-radius: 40px;
    border: 1px solid ${BORDERGRAY};
  `;

export let StatCap = styled(Text10)`
    font-size: 9px;
    width: 44px;
    color: ${DGRAY};
    position: absolute;
    top: 2px;
    left: 33px;
    z-index: 1;
  `,
  StatDesc = styled(Text14)`
    line-height: 16px;
    color: ${DGRAY};
  `,
  Dollar = styled(Medium12)`
    height: 15px;
    color: ${DGRAY};
    margin: 2px 0 0 -2px;
  `;

export const ShareIcon = ({ path, style }) => (
  <Touch
    style={{ padding: 16, ...style }}
    onPress={async () => await copyDeepLink(path)}
  >
    <Entypo name="share" size={22} color={DGRAY} />
  </Touch>
);

export const CloseIcon = ({ onPress, size = 32, color = LINEGRAY, ...r }) => (
  <Press {...{ onPress }}>
    <View
      style={{ justifyContent: "center", alignItems: "center", ...r.style }}
    >
      <AntDesign name="close" {...{ size, color }} />
    </View>
  </Press>
);

export const TitleNCloseRow = ({ title, ...r }) => {
  if (isDesktop && !title) return null;

  const { goBack } = useNavigation(),
    txtstyle = {
      style: {
        marginTop: isDesktop ? 24 : 12,
        marginBottom: isDesktop ? 12 : 0,
      },
    };

  return (
    <RowBetween style={{ paddingHorizontal: 24, ...r.style }}>
      <Title2 {...txtstyle} numberOfLines={1}>
        {title || ""}
      </Title2>

      {!isDesktop && (
        <CloseIcon
          onPress={goBack}
          style={{
            padding: 24,
            paddingBottom: 12,
            marginTop: -4,
            marginRight: -24,
          }}
        />
      )}
    </RowBetween>
  );
};

export const MarkerComp = ({ color = RED, small, style, ...pr }) => {
  let TextComp = small ? Text14 : Medium16,
    MarkViewComp = small ? MiniMarkView : MarkView;

  return (
    <View {...{ style }}>
      <Row>
        <MarkViewComp {...pr} style={{ backgroundColor: color }}>
          <TextComp style={{ color: "white" }} numberOfLines={1}>
            {pr.text}
          </TextComp>
        </MarkViewComp>
      </Row>
    </View>
  );
};

export const MarkView = styled(Press)`
    background: ${RED};
    flex-shrink: 0;
    border-radius: 6px;
    padding: 2px 8px;
  `,
  MiniMarkView = styled(MarkView)`
    border-radius: 4px;
    padding: 2px 6px;
  `;

export const Input = styled.TextInput`
    font-family: "CeraPro-Regular";
    font-size: 16px;
    line-height: 20px;
    text-align-vertical: top;
    /* vertical-align: top; */
    color: black;
    padding: 12px 0;
    border-bottom-width: 1px;
    border-color: ${LITEBORDER};
  `,
  Caption = styled(Text12)`
    line-height: 12px;
    color: ${GRAY};
    text-transform: uppercase;
    letter-spacing: 0.3px;
  `;

export const InputComp = ({ style, caption: cap, ...r }) => {
  let marginBottom = r.multiline && cap && 6;
  return (
    <View {...{ style }}>
      {cap && <Caption style={{ marginBottom: 2 }}>{cap}</Caption>}
      <Input
        ref={r.reff}
        placeholderTextColor={GRAY}
        {...r}
        style={r.inputStyle}
      />
    </View>
  );
};

export let AttachIcon = (pr) => (
  <Entypo name="attachment" size={18} color={LINEGRAY} {...pr} />
);

export let ApplyIcon = ({ status, ...pr }) =>
  status ? (
    <Ionicons name="document-text-outline" size={19} color={GRAY} {...pr} />
  ) : (
    <FontAwesome name="hourglass-o" size={17} color={GRAY} {...pr} />
  );

export const RefundsText = styled(Text14)`
  color: ${GRAY};
  line-height: 20px;
`;

export const RefundsComp = observer(({ cart, style }) => {
  let { lang, rus } = useAuth();
  return (
    <Touch onPress={openRefundPlcy} style={{ marginTop: 24, style }}>
      <RefundsText selectable>
        {REFND[lang](cart)}
        <RefundsText style={{ color: BLUE }}>
          {rus ? " Открыть правила" : " Read the policy"}
        </RefundsText>
      </RefundsText>
    </Touch>
  );
});

export const RefreshIcon = (pr) => (
  <Ionicons name="refresh-outline" size={22} color={"white"} {...pr} />
);

export const InfoIcon = () => (
  <Entypo
    name="info-with-circle"
    color="#d3d3d3"
    size={20}
    style={{ paddingRight: 18, paddingTop: 2 }}
  />
);

export const QuestMark = ({ style, ...r }) => (
  <Press
    style={{
      width: 32,
      height: 32,
      borderRadius: 16,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: LITEBORDER,
      ...style,
    }}
    {...r}
  >
    <Medium14 style={{ color: DGRAY }}>?</Medium14>
  </Press>
);

export const AppleIcon = (pr) => (
  <svg
    width="83"
    height="100"
    viewBox="0 0 83 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...pr}
  >
    <path
      d="M60.4769 9.45834C59.5181 12.4011 57.9666 15.1285 56.0967 17.397V17.4011C54.21 19.6825 51.6898 21.6997 48.8862 23.0371C46.313 24.2645 43.4924 24.9255 40.6856 24.7067L39.8266 24.6397L39.7135 23.7844C39.3489 21.0261 39.77 18.2337 40.6568 15.6497C41.6888 12.6426 43.3605 9.89887 45.1543 7.8198L45.154 7.81949C47.0102 5.64506 49.4995 3.75317 52.1654 2.38239C54.8403 1.0069 57.7131 0.146802 60.3278 0.0402744L61.3162 0L61.4246 0.987434C61.7412 3.87532 61.3561 6.76002 60.4769 9.45834Z"
      fill="white"
    />
    <path
      d="M78.6497 35.4013C77.7022 35.9876 68.5437 41.6546 68.6572 53.0775C68.777 66.8484 80.4535 71.6221 81.098 71.8855H81.1022L81.13 71.8971L82.0462 72.2764L81.7324 73.2139C81.7138 73.2695 81.7349 73.2104 81.7077 73.2976C81.4098 74.2521 79.3901 80.7225 74.8276 87.3896C72.7573 90.4138 70.648 93.4334 68.1575 95.7626C65.5788 98.1742 62.6339 99.8021 58.9958 99.8697C55.5668 99.9341 53.3219 98.9627 50.9882 97.953C48.763 96.9901 46.4488 95.9887 42.8274 95.9887C39.0232 95.9887 36.5914 97.0247 34.2491 98.0226C32.0442 98.9619 29.9118 99.8704 26.8397 99.9929H26.8355C23.2882 100.125 20.2121 98.3954 17.4784 95.8342C14.8629 93.3839 12.5714 90.1752 10.4833 87.1549C5.76959 80.3487 1.6702 70.4455 0.403665 60.3757C-0.638093 52.0928 0.232683 43.6787 4.23722 36.7297C6.47273 32.8417 9.59715 29.6687 13.2501 27.4546C16.8864 25.2508 21.0467 23.9966 25.3771 23.9316V23.9316C29.1685 23.8628 32.7433 25.2815 35.8713 26.5228C38.0851 27.4012 40.0574 28.184 41.5361 28.184C42.8424 28.184 44.768 27.4248 47.0126 26.5398C50.8037 25.0452 55.4417 23.2168 60.346 23.7044C62.4055 23.794 66.6954 24.2873 71.0805 26.6638C73.874 28.1778 76.7075 30.4508 79.0367 33.8597L79.6509 34.7586L78.7335 35.351C78.6844 35.3828 78.7321 35.3504 78.6497 35.4013Z"
      fill="white"
    />
  </svg>
);

export const ChatIcon = (pr) => (
  <svg
    width={29}
    height={26}
    viewBox="0 0 29 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...pr}
  >
    <ellipse cx={17.5} cy={9} rx={11.5} ry={9} fill={BACKBLUE} />
    <path
      d="M24 15.444c-.003 2.504-1.199 4.905-3.324 6.675-2.124 1.771-5.005 2.767-8.01 2.77-1.76.01-3.498-.333-5.066-1L0 26l2.533-6.333c-.795-1.309-1.206-2.756-1.2-4.223.004-2.504 1.199-4.904 3.324-6.675C6.78 7 9.662 6.003 12.667 6h.666c2.778.13 5.402 1.108 7.37 2.748 1.967 1.64 3.14 3.826 3.297 6.14v.556z"
      fill={ICONGRAY}
    />
    <path stroke="#fff" d="M15 17.5L7 17.5" />
    <path stroke="#fff" d="M19 14.5L7 14.5" />
  </svg>
);

const GoogleIcon = (props) => (
  <svg
    width={13}
    height={13}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.861 6.646a7.53 7.53 0 00-.103-1.23h-6.12V7.86h3.504a3.018 3.018 0 01-1.3 1.94v1.625h2.091c1.224-1.133 1.928-2.8 1.928-4.778z"
      fill="#4285F4"
    />
    <path
      d="M6.638 13c1.755 0 3.223-.585 4.296-1.576L8.843 9.799c-.585.39-1.327.628-2.205.628-1.695 0-3.13-1.143-3.645-2.687H.837v1.674A6.49 6.49 0 006.638 13z"
      fill="#34A853"
    />
    <path
      d="M2.992 7.74a3.772 3.772 0 01-.206-1.24c0-.433.076-.85.206-1.24V3.586H.836a6.425 6.425 0 000 5.828l2.156-1.673z"
      fill="#FBBC05"
    />
    <path
      d="M6.638 2.573c.959 0 1.815.33 2.492.975l1.852-1.853C9.862.645 8.393 0 6.638 0A6.49 6.49 0 00.837 3.586L2.993 5.26c.514-1.544 1.95-2.687 3.645-2.687z"
      fill="#EA4335"
    />
  </svg>
);

export const GroupsIcon = ({ foc, ...props }) => {
  let fill = foc ? BLUE : ICONGRAY;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      fill="none"
      {...props}
    >
      <rect width={30} height={24.444} fill="#F4F4F4" rx={3} />
      <circle cx={7.778} cy={10} r={5.556} {...{ fill }} />
      <circle cx={22.222} cy={10} r={5.556} {...{ fill }} />
      <circle cx={7.778} cy={24.445} r={5.556} {...{ fill }} />
      <circle cx={22.222} cy={24.445} r={5.556} {...{ fill }} />
    </svg>
  );
};

export const UserIcon = ({ foc, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={30}
    fill="none"
    {...props}
  >
    <path
      d="M0 30v-5.497a10.923 10.923 0 0 1 3.24-7.75 11.095 11.095 0 0 1 7.811-3.209c2.93 0 5.74 1.155 7.812 3.21a10.923 10.923 0 0 1 3.239 7.749V30H0Z"
      fill={foc ? BLUE : ICONGRAY}
    />
    <path
      fill="#EFEFEF"
      d="M18.948 7.901a7.901 7.901 0 1 1-15.802 0 7.901 7.901 0 0 1 15.802 0Z"
    />
  </svg>
);

export const LOGO = () => (
  <Press onPress={() => openURL("https://bloss.am/")}>
    <svg xmlns="http://www.w3.org/2000/svg" width={33} height={32} fill="none">
      <path
        fill="url(#a)"
        fillRule="evenodd"
        d="M21.664 30.503C30 26.823 34.583 17.367 31.547 9.245 28.512 1.122 19.161-2.184 10.824 1.496 2.487 5.176-2.095 14.633.94 22.756c3.036 8.122 12.386 11.428 20.724 7.747Zm8.49-20.643c2.698 7.222-1.341 15.825-9.023 19.216-7.683 3.392-16.098.286-18.797-6.936-2.699-7.222 1.341-15.825 9.023-19.217 7.683-3.39 16.098-.285 18.797 6.937ZM17.743 7.205c-5.09.01-9.632 3.963-10.18 8.85.002.018-.016.125-.02.148-.097.471-.346.66-.773.66-.427 0-.774-.15-.774-.566 0-.088.021-.258.045-.44.739-5.665 6.051-10.188 11.947-10.153a.784.784 0 0 1 .761.781c0 .417-.346.72-.773.72H17.743Z"
        clipRule="evenodd"
      />
      <path
        fill="#253B6E"
        fillRule="evenodd"
        d="M21.664 30.503C30 26.823 34.583 17.367 31.547 9.245 28.512 1.122 19.161-2.184 10.824 1.496 2.487 5.176-2.095 14.633.94 22.756c3.036 8.122 12.386 11.428 20.724 7.747Zm8.49-20.643c2.698 7.222-1.341 15.825-9.023 19.216-7.683 3.392-16.098.286-18.797-6.936-2.699-7.222 1.341-15.825 9.023-19.217 7.683-3.39 16.098-.285 18.797 6.937ZM17.743 7.205c-5.09.01-9.632 3.963-10.18 8.85.002.018-.016.125-.02.148-.097.471-.346.66-.773.66-.427 0-.774-.15-.774-.566 0-.088.021-.258.045-.44.739-5.665 6.051-10.188 11.947-10.153a.784.784 0 0 1 .761.781c0 .417-.346.72-.773.72H17.743Z"
        clipRule="evenodd"
      />
    </svg>
  </Press>
);

export const GooglePlayIcon = (pr) => (
  <svg
    width="81"
    height="85"
    viewBox="0 0 81 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    // scale={0.5}
    {...pr}
  >
    <path
      d="M58.7926 25.5572L13.0358 1.06037C10.658 -0.212561 8.07972 -0.277615 5.84814 0.535989L44.8309 39.5188L58.7926 25.5572Z"
      fill="white"
    />
    <path
      d="M75.8563 34.6926L62.6761 27.6364L47.8124 42.4999L62.6761 57.3636L75.8561 50.3072C82.091 46.9694 82.091 38.0304 75.8563 34.6926Z"
      fill="white"
    />
    <path
      d="M2.27929 2.92944C0.881232 4.46602 0 6.51737 0 8.86772V76.1322C0 78.4825 0.881232 80.534 2.27912 82.0706L41.8498 42.4999L2.27929 2.92944Z"
      fill="white"
    />
    <path
      d="M5.84814 84.464C8.07955 85.2774 10.658 85.2127 13.0358 83.9396L58.7926 59.4427L44.8311 45.4812L5.84814 84.464Z"
      fill="white"
    />
  </svg>
);

export const CartButton = ({ coachID, ...pr }) => (
  <Touch onPress={() => pr.navigate("Cart", { coachID })} ao={0.8}>
    <CartView style={shadow4}>
      <Text21 style={{ color: "white" }}>{pr.quant}</Text21>
      <EventIcon style={{ marginLeft: 4, marginRight: -2, marginTop: -1 }} />
    </CartView>
  </Touch>
);

let CartView = styled(RowCentered)`
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 11;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: black;
`;

export const SumRow = ({ big, black, discont, sum, ...r }) => {
  let rus = localRus(),
    sumText = r.isdur
      ? durtnText(sum, sum <= 60 || !(sum % 60), rus)
      : sum + "$",
    NameComp = big ? BigPriceName : PriceName, // : GrayPriceName,
    SumComp = big ? BigPrice : Price, // : GrayPrice;
    txtStyle = r.color && { style: { color: r.color } };

  return (
    <PriceRow {...r}>
      <NameComp {...txtStyle}>{r.name}</NameComp>
      {!discont && <SumComp {...txtStyle}>{sumText}</SumComp>}

      {discont > 0 && (
        <BigPrice {...txtStyle}>
          <CrossedPrice>{sum + discont}$</CrossedPrice>
          {" " + sum}$
        </BigPrice>
      )}
    </PriceRow>
  );
};

export let PriceRow = styled(RowBetween)`
    align-items: flex-start;
    flex-shrink: 1;
    padding: 4px 0;
  `,
  BigPriceName = styled(Title2)`
    flex: 1;
  `,
  PriceName = styled(Text18)`
    color: ${DGRAY};
    flex: 1;
  `,
  BigPrice = styled(Title2)`
    flex-shrink: 0;
    text-align: right;
    min-width: 80px;
    max-width: ${wwidth / 2 - 8}px;
    margin-left: 8px;
  `,
  CrossedPrice = styled(Title2)`
    flex-shrink: 0;
    text-align: right;
    color: ${GRAY};
    text-decoration-line: line-through;
    text-decoration-style: solid;
  `,
  Price = styled(Text18)`
    color: ${DGRAY};
    width: 82px;
    flex-shrink: 0;
    text-align: right;
    margin-left: 8px;
  `;

export const LogOut = observer((r) => {
  const { rus } = useAuth(),
    { handleLogout } = useClient() || {};

  let onPress = async () => (
    r.setLoad && r.setLoad(true), await handleLogout()
  );
  return (
    <Touch
      {...{ onPress }}
      style={{ padding: 16, justifyContent: "center", ...r.style }}
    >
      <Medium16
        style={[r.white && { color: "white" }, { flexShrink: 0 }]}
        numberOfLines={1}
      >
        {rus ? "Выйти" : "Log out"}
      </Medium16>
    </Touch>
  );
});

let comIMGWidth =
  (window.innerWidth > 450 ? 450 : window.innerWidth || 390) - 40 * 2;

export const CommentImage = ({ uri, ...r }) => {
  const { height: hg0, width: wd0, ...style } = r.style,
    width = wd0 || comIMGWidth,
    [ratio, setRatio] = useState(hg0 ? hg0 / width : 1),
    height = ratio * width;

  useEffect(() => {
    if (r.full) getIMGRatio(uri, setRatio);
  }, []);

  return (
    <Press onPress={() => r.navigate("Image", { uri })} {...{ style }}>
      {height > 0 && (
        <ComImage style={{ width, height, backgroundImage: `url(${uri})` }} />
      )}
    </Press>
  );
};

let ComImage = rstyled(CardImage)`
    width: ${comIMGWidth}px;
    height: ${comIMGWidth}px;
    border-radius: 6px;
    margin-top: 2px;
  `;

export const TrialComp = observer(({ style, ...r }) => {
  const { lang, myid } = useAuth(),
    { canTrial } = useClient();
  if (myid && !canTrial) return null;

  let Wrap = r.onPress ? Press : View;
  return (
    <Wrap {...r}>
      <TrialView {...{ style }}>
        <Trial>{r.text || TRL[lang](myid)}</Trial>
        {r.hide && (
          <CloseIcon
            size={24}
            onPress={r.hide}
            color="white"
            style={{
              margin: -16,
              marginTop: -18,
              padding: 16,
              paddingLeft: 24,
            }}
          />
        )}
      </TrialView>
    </Wrap>
  );
});

let TrialView = styled(Row)`
    background: ${GREEN};
    padding: 12px 16px;
    min-height: 56px;
    border-radius: 10px;
  `,
  Trial = styled(Text15)`
    flex: 1;
    color: white;
  `;

export const DownloadIcon = ({ onPress, ...pr }) => (
  <Press {...{ onPress }}>
    <MaterialIcons name="file-download" size={24} color={"white"} {...pr} />
  </Press>
);

export const ReplaceIcon = (pr) => (
  <Entypo name="cycle" size={20} color={"white"} {...pr} />
);

export const TmznComp = observer(({ br, ...r }) => {
  let { lang } = useAuth();
  return (
    <TmznText style={{ height: br ? 30 : 15, ...r.style }}>
      {TMZTX[lang]}
      {(br ? "\nGMT" : ", GMT") + offsetName}
    </TmznText>
  );
});

let TmznText = styled(Caption)`
  line-height: 15px;
  height: 15px;
`;

export let Divider = styled.View`
    background: ${LITEBORDER};
    height: 1px;
  `,
  Divider16 = styled(Divider)`
    margin: 16px 0;
  `,
  Divider20 = styled(Divider)`
    margin: 20px 0;
  `,
  Divider40 = styled(Divider)`
    margin: 40px 0 32px;
  `;

export let IDCaption = styled(Text14)`
  color: ${GRAY};
  line-height: 24px;
  flex: 1;
  flex-shrink: 0;
  padding: 4px 0;
`;

export let PercentIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="#fff"
      fillOpacity={0.2}
      d="M55.495 24.035A3.41 3.41 0 0 0 50.82 25.3L31.185 59.29a3.411 3.411 0 0 0 1.265 4.675 3.025 3.025 0 0 0 1.705.44 3.465 3.465 0 0 0 2.97-1.705L56.76 28.71a3.354 3.354 0 0 0-1.265-4.675Zm-15.07 11.33a11.88 11.88 0 0 0-11.33-12.32 11.935 11.935 0 0 0-11.385 12.32A11.935 11.935 0 0 0 29.095 47.74a11.88 11.88 0 0 0 11.33-12.375Zm-11.33 5.5a5.115 5.115 0 0 1-4.51-5.5 5.06 5.06 0 0 1 4.51-5.5 5.005 5.005 0 0 1 4.455 5.5 5.06 5.06 0 0 1-4.455 5.5Zm29.81-.605a11.88 11.88 0 0 0-11.33 12.32 11.88 11.88 0 0 0 11.33 12.375A11.935 11.935 0 0 0 70.29 52.58a11.935 11.935 0 0 0-11.385-12.32Zm0 17.82a5.06 5.06 0 0 1-4.455-5.5 5.06 5.06 0 0 1 4.455-5.5 5.06 5.06 0 0 1 4.51 5.5 5.115 5.115 0 0 1-4.51 5.5Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.2}
      d="M44 2.75A42.68 42.68 0 0 0 0 44a42.68 42.68 0 0 0 44 41.25A42.68 42.68 0 0 0 88 44 42.68 42.68 0 0 0 44 2.75Zm0 75.625A35.86 35.86 0 0 1 6.875 44 35.86 35.86 0 0 1 44 9.625 35.86 35.86 0 0 1 81.125 44 35.86 35.86 0 0 1 44 78.375Z"
    />
  </svg>
);

export const VerticalDots = (pr) => (
  <Entypo
    name="dots-three-vertical"
    size={16}
    color={"black"}
    style={{ backgroundColor: BACKGRAY, padding: 6, borderRadius: 20 }}
  />
);

export let ArrowRight = (pr) => (
  <AntDesign name="arrowright" size={20} color={DGRAY} {...pr} />
);

export const FooterOption = ({ text, noarrow, color, center, ...r }) => (
  <Touch
    {...r}
    style={[
      r.border && { borderTopWidth: 1, borderTopColor: LITEBORDER },
      r.style,
    ]}
  >
    <RowBetween>
      <FooterOptionText
        style={[color && { color }, center && { textAlign: "center" }]}
        numberOfLines={1}
      >
        {text}
      </FooterOptionText>
      {!noarrow && !center && <Arrow />}
    </RowBetween>
  </Touch>
);

export const FooterOptionText = styled(Text16)`
    color: ${DGRAY};
    flex: 1;
    padding: 16px 0;
  `,
  Arrow = styled(BackIcon)`
    width: 18px;
    transform: rotate(45deg);
    background: ${GRAY};
  `;

export const Refresher = ({ update, ...pr }) => {
  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = async () => (
    setRefreshing(true), update(), setTimeout(() => setRefreshing(false), 1500)
  );
  return <RefreshControl {...{ refreshing, onRefresh }} {...pr} />;
};

export const HyperlinkWrap = ({ style, ...pr }) => (
  <Hyperlink
    onPress={openURL}
    linkStyle={{ color: BLUE, textDecorationLine: "underline" }}
    {...{ style }}
  >
    {pr.children || (
      <Text16 style={{ color: DGRAY }} selectable>
        {pr.tx || pr.text}
      </Text16>
    )}
  </Hyperlink>
);

let { REFND, TRL, TMZTX, SHOWMORE } = translates.UI;
