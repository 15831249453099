import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, View, StatusBar } from "react-native";
import styled from "styled-components/native";
import "react-confirm-alert/src/react-confirm-alert.css";
import { observer } from "mobx-react-lite";
import {
  NavigationContainer,
  useNavigation,
  DefaultTheme,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { onAuthStateChanged } from "firebase/auth";
import { useFonts } from "expo-font";
import dayjs from "dayjs";
require("dayjs/locale/en");
require("dayjs/locale/ru");
let localizedFormat = require("dayjs/plugin/localizedFormat");
import { auth } from "./config/index.js";
import screens, { NetListener } from "./src/screens";
import { StoresProvider, useAuth, useClient } from "./src/commons/Stores.js";
import EffectsProvider from "./src/commons/EffectsProvider.js";
import { handleGoogleAuthUser } from "./src/commons/AuthStore.js";
import { rootNavg } from "./src/commons/RootNavigation";
import {
  isDesktop,
  tabbarHeight,
  modalWidth,
  deskPadding,
  wwidth,
  isMobl,
  localLang,
} from "./src/commons/utils.js";
import {
  BackTouch,
  BACKGRAY,
  BLUE,
  Row,
  UserIcon,
  Press,
  CloseIcon,
  GroupsIcon,
  BackIcon,
  Toaster,
  shadow4,
  ICONGRAY,
  Text18,
  DGRAY,
} from "./src/commons/UI";
import "./App.css";
import { translates } from "./src/translates.js";

dayjs.locale(localLang());
dayjs.extend(localizedFormat);

export default () => {
  return (
    <View style={{ flex: 1, flexDirection: "column" }}>
      <StatusBar barStyle="dark-content" backgroundColor="#fff" />
      <StoresProvider>
        <NetListener appmount={Date.now()} />
        <EffectsProvider>
          <Routes />
        </EffectsProvider>
      </StoresProvider>
      {Toaster}
    </View>
  );
};

let Stack = createStackNavigator(),
  Tab = createBottomTabNavigator(),
  theme = {
    ...DefaultTheme,
    colors: { ...DefaultTheme.colors, background: BACKGRAY },
  };

let modalWrap = (name) => {
  let Screen = screens[name];
  if (isMobl) return Screen;

  let width = ["AddInfo", "CoachModal"].includes(name) ? modalWidth : wwidth;

  return (pr) => {
    let { goBack: onPress } = pr.navigation;
    return (
      <View style={{ flex: 1, alignItems: "flex-end" }}>
        <Press
          {...{ onPress }}
          style={{
            ...StyleSheet.absoluteFill,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        >
          <CloseIcon
            {...{ onPress }}
            color="white"
            size={40}
            style={{
              alignSelf: "flex-end",
              margin: 24,
              marginRight: width + 24,
            }}
          />
        </Press>
        <View style={{ flex: 1, width, backgroundColor: "white" }}>
          <Screen {...pr} />
        </View>
      </View>
    );
  };
};

let renderModals = (name, lang) => (
  <Stack.Screen
    {...{ name }}
    component={name == "Image" ? screens[name] : modalWrap(name)}
    options={Object.assign(
      getTitle(name, lang),
      ["AddInfo", "LangPicker", "Image", "Filters"].includes(name)
        ? transpModal
        : modal
    )}
    key={name}
  />
);

let renderCards = (nm, lang) => {
  let isbasic = ["Home", "Coach", "Profile", "Login"].includes(nm),
    ismodal = isDesktop && !isbasic;
  if (ismodal) return renderModals(nm, lang);
  return (
    <Stack.Screen
      name={nm}
      key={nm}
      component={ismodal ? modalWrap(nm) : screens[nm]}
      options={({ route: { params: p } }) => {
        let title = HEADTTL[lang](nm);
        if (nm === "Orders" && p?.passID) title = PASORDRS[lang];
        if (nm === "Passes" && p?.packID) title = PCKPASESS[lang];

        let data = getTitle(nm, lang);
        if (ismodal) data.presentation = "transparentModal";
        data.animationEnabled =
          p?.noanim || (isbasic && nm != "Coach") ? false : true;

        data.headerTitle = title[0]
          ? () => (
              <Text18 style={{ color: DGRAY }} numberOfLines={1}>
                {title}
              </Text18>
            )
          : "";

        return data;
      }}
    />
  );
};

const Routes = observer(() => {
  useFonts({
    "CeraPro-Medium": require("./assets/fonts/CeraPro-Medium.ttf"),
    "CeraPro-Regular": require("./assets/fonts/CeraPro-Regular.ttf"),
    "CeraPro-Bold": require("./assets/fonts/CeraPro-Bold.ttf"),
    "CeraPro-Black": require("./assets/fonts/CeraPro-Black.ttf"),
  });

  const mount = useRef(true),
    { myid, age, lang, getDBUser, addDBUser, setLoad } = useAuth();

  const renderModals2 = (name) => renderModals(name, lang);

  useEffect(() => {
    // Linking.getInitialURL().then((d) => { let link = d.slice(d.indexOf("/", 10) + 1);  console.log("LINKING ====", link);  // setParams({ link });});
    var signed = false;
    let handleAuth = (user) => {
      if (!user) return signed ? (signed = false) : 0;

      let created = dayjs(user.metadata.creationTime).valueOf(),
        isnew = created > Date.now() - 60000;
      if (isnew) return console.log("onAuthStateChanged NEW USER");

      if (signed || !mount.current) return;
      setLoad(true);
      signed = true;
      let proceed = isnew ? addDBUser : getDBUser;
      proceed(handleGoogleAuthUser(user));
    };

    const listener = onAuthStateChanged(auth, handleAuth);
    return () => ((mount.current = false), listener());
  }, []);

  return (
    <NavigationContainer ref={rootNavg} linking={linking(myid)} {...{ theme }}>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {myid && !age ? (
          <Stack.Screen
            name="EditProfile"
            component={screens.EditProfile}
            options={{
              animationEnabled: false,
              ...getTitle("EditProfile", lang),
            }}
          />
        ) : (
          <>
            <Stack.Screen
              name="TabStack"
              component={TabNavigator}
              options={{ animationEnabled: false }}
            />
            {[
              "CustomEvent",
              "Cart",
              "AddInfo",
              "LangPicker",
              "Filters",
              "Image",
            ].map(renderModals2)}
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
});

const TabNavigator = () => {
  let renderTabStack = (name, i) => (
    <Tab.Screen
      {...{ name }}
      component={tabStacks[name]}
      options={{ tabBarIcon: !i ? HomeTab : UserTab }}
      key={name}
    />
  );

  return (
    <Tab.Navigator screenOptions={tabOptions} initialRouteName={"HomeStack"}>
      {["HomeStack", "ProfileStack"].map(renderTabStack)}
    </Tab.Navigator>
  );
};

const HomeStack = observer(() => {
  const { myid, lang } = useAuth(),
    renderScreen = (s) => renderCards(s, lang);

  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={({ route: { name: rn } }) => ({
        ...stackHeadOptions,
        headerShown: isDesktop ? rn == "Coach" : rn != "Home",
        headerLeft: () => <BackComp />,
      })}
    >
      {renderScreen("Home")}
      {myid && authedScreens.map(renderScreen)}
      {commonScreens.map(renderScreen)}
    </Stack.Navigator>
  );
});

const ProfileStack = observer(() => {
  const { myid, lang } = useAuth(),
    renderScreen = (s) => renderCards(s, lang);

  return (
    <Stack.Navigator
      initialRouteName={myid ? "Profile" : "Login"}
      screenOptions={({ route: { name: rn } }) => {
        let isEditPrf = rn === "EditProfile";
        return {
          ...stackHeadOptions,
          headerShown: isDesktop
            ? rn == "Coach"
            : !["Profile", "Login"].includes(rn),
          headerLeft: () => <BackComp white={isEditPrf} />,
        };
      }}
    >
      {!myid && renderScreen("Login")}
      {myid && (
        <>
          {renderScreen("Profile")}
          {["EditProfile", "Balance"].concat(authedScreens).map(renderScreen)}
        </>
      )}
      {commonScreens.map(renderScreen)}
    </Stack.Navigator>
  );
});

let tabStacks = { HomeStack, ProfileStack };

let tabOptions = {
  headerShown: false,
  tabBarShowLabel: false,
  tabBarStyle: { height: tabbarHeight, paddingHorizontal: deskPadding },
  tabBarItemStyle: {
    flex: 1,
    height: tabbarHeight,
    justifyContent: "center",
    alignItems: "center",
    marginTop: -2,
  },
  tabBarActiveTintColor: BLUE,
  tabBarInactiveTintColor: ICONGRAY,
};

let stackHeadOptions = {
  headerTitle: "",
  headerMode: "screen",
  headerBackVisible: false,
  headerBackTitleVisible: false,
  headerTitleAlign: "center",
  headerStyle: { backgroundColor: "white", height: isDesktop ? 1 : undefined },
};

let transpModal = {
    presentation: "transparentModal",
    animationEnabled: false,
  },
  modal = isDesktop
    ? transpModal
    : { presentation: "modal", animationEnabled: true };

let commonScreens = [
    "Coach",
    "CoachPrivats",
    "CoachGroups",
    "Event",
    "Package",
    "Order",
    "PassOrder",
  ],
  authedScreens = [
    "BalanceRecords",
    "PassBalance",
    "History",
    "Orders",
    "Passes",
    "Comments",
  ];

const BackComp = ({ white }) => {
  let { goBack } = useNavigation();

  if (isDesktop)
    return (
      <Press onPress={goBack} style={closeButtonStyle}>
        <BackIcon />
      </Press>
    );

  if (white) return <BackTouch color="white" {...{ goBack }} />;

  return (
    <Press
      onPress={goBack}
      style={{ width: wwidth / 2, marginRight: -wwidth / 2 + 66 }}
    >
      <BackTouch {...{ goBack }} />
    </Press>
  );
};

let HomeTab = ({ focused: foc }) => <GroupsIcon {...{ foc }} />;

let UserTab = ({ focused: foc }) => (
  <Row>
    <UserIcon {...{ foc }} />
    {!foc && <OrderMark />}
  </Row>
);

let OrderMark = observer(() => {
  const { hasUnpaidOrder } = useClient();
  if (!hasUnpaidOrder) return null;
  return <Mark />;
});

let Mark = styled.View`
  position: absolute;
  top: -2px;
  right: -14px;
  width: 14px;
  height: 14px;
  border-radius: 10px;
  background: ${BLUE};
`;

let closeButtonStyle = {
  position: "absolute",
  top: 24,
  left: 24,
  backgroundColor: "white",
  width: 52,
  height: 52,
  borderRadius: 26,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  zIndex: 1,
  ...shadow4,
};

let linking = (myid) => ({
  // getPathFromState: (state, config) => {
  //   let { name } = getRoute() || {};
  //   if (  ![ "Profile", "Home", "Login", "CoachModal", "Coach", "Event", "Order", "CustomEvent", ].includes(name))  return "";
  //   // if (['Event','Order'].includes(name)) return getPathFromState(state, config);
  //   let defPath = getPathFromState(state, config);
  //   return defPath.includes("classes/") ? defPath.replace("classes/", "class/") : defPath.includes("order/") ? defPath.replace("order/", "orders/") : defPath.includes("coaches/") ? defPath.replace("coaches/", "coach/") : defPath;},
  config: {
    initialRouteName: "TabStack",
    screens: {
      NotFound: "*",
      // Coach: {
      //   path: "coaches/:coachID",
      //   stringify: { from: () => `` },
      // },
      // Event: "class/:id",
      // Order: "order/:orderID",
      TabStack: {
        initialRouteName: "HomeStack",
        screens: {
          HomeStack: {
            initialRouteName: "Home",
            screens: {
              Home: "",
              Coach: {
                path: "coaches/:coachID",
                stringify: { from: () => `` },
              },
              CoachGroups: "coach-groups/:coachID",
              CoachPrivats: "coach-1on1/:coachID",
              Event: "classes/:id",
              Order: "order/:orderID",
              Package: {
                path: "package/:packID",
                // stringify: { fromCoachScreen: () => `` },
              },
              PassOrder: "package-order/:passID",
              // PassBalance:  "package-minutes/:passID",
            },
          },
          ProfileStack: {
            initialRouteName: myid ? "Profile" : "Login",
            screens: {
              Profile: "profile",
              Login: "login",
              BalanceRecords: "balance",
              Coach: {
                path: "coach/:coachID",
                stringify: { from: () => `` },
              },
              CoachGroups: "coach-group-classes/:coachID",
              CoachPrivats: "coach-1on1-classes/:coachID",
              Event: "class/:id",
              Order: "orders/:orderID",
              Package: {
                path: "packages/:packID",
                // stringify: { fromCoachScreen: () => `` },
              },
              PassOrder: "package-orders/:passID",
              // PassBalance: "package-balance/:passID",
              History: "passed-classes",
              Orders: "classes-orders",
              Passes: "packages-orders",
              Comments: "comments",
            },
          },
        },
      },
      CustomEvent: {
        path: "custom-class/:coachID",
        // stringify: { id: () => `` },
      },
      Promo: "promo/:id",
    },
  },
});

let getTitle = (screen = "Home", lang) => {
  let title = HEADTTL[lang](screen) || WEBTTL[lang](screen);
  if (screen == "Home") title = "BLOSS.AM – " + title;
  else title += " – BLOSS.AM";

  return { title };
};

let { PASORDRS, PCKPASESS, HEADTTL, WEBTTL } = translates.App;
