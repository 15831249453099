import React, { useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { LinearGradient } from "expo-linear-gradient";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
dayjs.extend(calendar);
import { useAuth, useClient, useSchool } from "../commons/Stores";
import {
  durtnText,
  wwidth,
  getDay,
  localStor,
  parsePackage,
  horizCardWidth,
  localLang,
} from "../commons/utils";
import {
  DGRAY,
  GRAY,
  Medium18,
  Touch,
  RowBetween,
  Text14,
  CloseIcon,
  LITEBORDER,
  Row,
  MarkerComp,
  ACTIVEGRAY,
  PercentIcon,
} from "../commons/UI";
import CoachCard, { SmallCardWrap } from "./CoachCard";
import { translates } from "../translates";

const Package = observer(({ p, hhfrmt, home, order, ispass, style, ...r }) => {
  const lang = r.lang ?? localLang(),
    rus = lang === "ru",
    { price, duration: dur, color } = p || {},
    data = parsePackage(p, r.myid, lang),
    { passID, packID } = data,
    postname = POSTNAME[lang](passID),
    [hidden, setHide] = useState(false);

  if (!p) {
    console.log("missed package", !p);
    return null;
  }

  const big = home || r.big,
    black = home || color === "black",
    TitleComp = black ? WhiteTitle : Title,
    DescComp = black ? WhiteDesc : Desc,
    CaptionComp = black ? WhiteCaption : Caption;

  const onPress = () => {
    if (r.onPress) return r.onPress();
    let params = ispass ? { passID } : { packID };
    params.fromCoachScreen = r.coachScreen;
    return r.navigate("Package", params);
  };

  if (r.small) {
    return (
      <SmallCardWrap
        Image={<PackImage small {...{ dur, color }} />}
        {...{ onPress, style }}
      >
        <Desc style={{ marginTop: 1 }} numberOfLines={1}>
          {(p?.name || passID) + postname}
        </Desc>
        {p && <GrayDesc>{getPeriodText(p.from, p.to)}</GrayDesc>}
      </SmallCardWrap>
    );
  }

  const Wrapper = (pr) => (
    <Touch {...{ onPress, style }}>
      <PackImage dur={!home && dur} {...{ big, color, home }}>
        {pr.OfferLimit || pr.Coach}
        <View style={{ flex: 1 }} />
        <TitleComp>{p.name + (big || order ? postname : "")}</TitleComp>

        {pr.desc && <DescComp>{pr.desc}</DescComp>}
        {pr.children}
      </PackImage>
      {pr.Marker}
    </Touch>
  );

  if (ispass) {
    let endStatus = data.endTimeStatus.replace(" on", "").toLowerCase();
    return (
      <Wrapper
        desc={endStatus + " " + getTimeText(p.to, hhfrmt, lang, "1")}
        Coach={
          r.showCoach && (
            <CoachCard mini {...r.coach} style={{ marginTop: 2 }} />
          )
        }
        Marker={
          <MarkerComp
            small={!big}
            text={data.passStatusText}
            color={data.passStatusColor}
            style={markerStyle(big)}
          />
        }
      >
        <PriceRow>
          <TitleComp numberOfLines={1}>
            {(p.durLeft ? durtnText(p.durLeft, big, rus) : 0) +
              (rus ? " остаток" : " left")}
          </TitleComp>
          <CaptionComp numberOfLines={1}>
            {(rus ? "из " : "of ") + durtnText(dur, big, rus)}
          </CaptionComp>
        </PriceRow>
      </Wrapper>
    );
  }

  const { offerLimit: limit } = p,
    fullPrice = ((r.coachRate || r.coach?.price) * dur) / 60;

  const OfferLimit = limit && (
    <DescComp style={{ marginTop: big && !home ? 4 : 0 }}>
      {(rus ? "Актуально " : "Offer ") + getTimeText(limit, hhfrmt, lang)}
    </DescComp>
  );

  const DurNPrice = (
    <RowBetween>
      <PriceRow>
        <TitleComp numberOfLines={1}>
          {durtnText(dur, "f", rus) + (home ? (rus ? "" : ", 1-on-1") : "")}
        </TitleComp>
      </PriceRow>
      <PriceRow style={{ flexShrink: 0 }}>
        <CaptionComp numberOfLines={1}>
          {(rus ? "экономия " : "save ") + (fullPrice - price) + "$"}
        </CaptionComp>
        <TitleComp numberOfLines={1}>{price + "$"}</TitleComp>
      </PriceRow>
    </RowBetween>
  );

  if (home) {
    const hide = () => {
      setHide(true);
      localStor.setItem("mainHiddenPack-" + packID, "true");
      setTimeout(() => r.setPackage({ id: packID, mainHidden: true }), 1500);
    };

    return (
      <Wrapper {...{ OfferLimit }}>
        <CoachCard
          mini
          {...r.coach}
          color={"white"}
          style={{ marginTop: 8, marginBottom: 2 }}
        />
        {DurNPrice}

        <CloseIcon
          size={28}
          onPress={hidden ? null : hide}
          color={LITEBORDER}
          style={{ position: "absolute", right: 0, padding: 16 }}
        />
        {hidden && <Overlay />}

        {PERCENT}
      </Wrapper>
    );
  }

  return (
    <Wrapper desc={WRAPDESC[lang](order, p.term)} {...{ OfferLimit }}>
      {DurNPrice}
    </Wrapper>
  );
});

export default Package;

export const PackImage = observer(({ huge, color = "silver", ...pr }) => {
  const dur = pr.dur || pr.duration,
    {
      packagesColors: { black: blackColors, [color]: colors },
    } = useSchool(),
    ViewComp = pr.mini ? MiniPackView : pr.small ? SmallPackView : PackView,
    big = pr.big || huge;

  return (
    <ViewComp
      colors={pr.home ? blackColors : colors}
      start={{ x: 0.1, y: big ? 1.3 : 1.1 }}
      end={{ x: 0.9, y: big ? -0.3 : -0.1 }}
      style={[
        big && {
          width: huge ? wwidth : bigWidth,
          height: huge ? hugeHeight : pr.home ? homeHeight : packHeight(true),
        },
        huge && { borderRadius: 0, marginBottom: -18 },
        (pr.mini || pr.small) && { marginRight: 12 },
        pr.style,
      ]}
    >
      {pr.children}
      {dur > 0 && (
        <BackNumber {...pr} black={color == "black"} {...{ dur, huge }} />
      )}
    </ViewComp>
  );
});

export const HomeBanner = observer(({ navigate }) => {
  const { lang } = useAuth(),
    { firstPackageOffer: offer } = useClient(),
    {
      coaches: { [offer?.coachID || "00"]: coach },
      setPackage,
    } = useSchool();

  if (!offer) return null;

  return (
    <Package
      home
      {...{ coach, lang, navigate, setPackage }}
      p={offer}
      style={{ marginBottom: 32 }}
    />
  );
});

export let getPeriodText = (from, to) =>
  dayjs(from).format("D MMM – ") + dayjs(to).format("D MMM");

export let getTimeText = (time, hhfrmt, lang, ispass, full) => {
  let rus = lang === "ru",
    timeFromNow = (time - Date.now()) / (60 * 60000),
    hhmm = dayjs(time).format(" " + hhfrmt + " "),
    day = getDay(time),
    passed = time < Date.now(),
    AT = rus ? " в " : " at ",
    ON = rus ? " " : " on ";

  let [today, tmrw, ystd] = [0, 1, -1].map((diff) => {
    let checkName = NRSTDAYS[lang](diff),
      checkDay = getDay(diff ? dayjs().add(diff, "d") : null);
    return day === checkDay ? checkName : false;
  });

  let timeText;
  let trim = (tx) => tx.trim().replace(/  /g, " ");

  if (ispass) {
    timeText = ystd
      ? ystd + (full ? AT + hhmm : "")
      : timeFromNow < -24
      ? (full ? "" : ON) +
        dayjs(time).format(full ? "D MMM YYYY," : "D MMM") +
        hhmm
      : today
      ? today + AT + hhmm
      : tmrw
      ? tmrw + hhmm
      : (full ? "" : ON) +
        dayjs(time).format(full ? "dddd D MMM" : "D MMM") +
        hhmm;

    return trim(timeText);
  }

  if (!ispass) {
    timeText = ystd
      ? ystd + (full ? AT : "") + hhmm
      : timeFromNow < -24
      ? ON + dayjs(time).format("D MMM") + hhmm
      : today && passed
      ? (full ? today : "") + AT + hhmm
      : today
      ? hhmm + today
      : tmrw
      ? hhmm + tmrw
      : dayjs(time).format("D MMM") + hhmm;

    return PACKENDSTTS[lang](passed) + trim(timeText);
  }
};

export let width = horizCardWidth,
  bigWidth = wwidth - 48,
  hugeHeight = wwidth / 1.6,
  homeHeight = 164,
  packHeight = (big) => (big ? bigWidth : width) / 1.6,
  packFontSize = (big) => packHeight(big) * 0.6;

export let BackNumber = ({ dur, big, huge, mini, small, black }) => {
  let fontSize = mini
    ? 22
    : small
    ? 28
    : packFontSize(big || huge) * (huge ? 1.3 : 1);
  return (
    <BackNumberText
      style={[
        black && { color: "rgba(0,0,0,0.25)" },
        {
          fontSize,
          lineHeight: fontSize,
          top: fontSize / (big || mini || small ? 4 : 5),
          right: mini ? 6 : small ? 7 : huge ? 24 : 20,
          letterSpacing: mini ? -1 : small ? -1.2 : -5,
        },
      ]}
    >
      {Math.round(dur / 60)}
    </BackNumberText>
  );
};

export let BackNumberText = styled.Text`
  font-family: "CeraPro-Black";
  font-size: ${packFontSize()}px;
  line-height: ${packFontSize()}px;
  letter-spacing: -5px;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
  top: ${packFontSize() / 5}px;
  right: 20px;
`;

let markerStyle = (big) => ({
  position: "absolute",
  zIndex: 11,
  right: big ? -8 : -6,
  bottom: 20,
});

let PERCENT = (
  <PercentIcon
    style={{
      position: "absolute",
      top: 24,
      left: wwidth / 2 - 8,
      width: 88,
      height: 88,
    }}
  />
);

let PackView = styled(LinearGradient)`
    width: ${width}px;
    height: ${packHeight()}px;
    border-radius: 18px;
    padding: 20px 18px;
  `,
  MiniPackView = styled(LinearGradient)`
    width: 48px;
    height: 34px;
    border-radius: 6px;
  `,
  SmallPackView = styled(LinearGradient)`
    width: 60px;
    height: 42px;
    border-radius: 8px;
  `,
  Title = styled(Medium18)`
    flex-shrink: 0;
    color: ${DGRAY};
  `,
  WhiteTitle = styled(Title)`
    color: white;
  `,
  Desc = styled(Text14)`
    color: ${DGRAY};
    margin-top: 4px;
  `,
  WhiteDesc = styled(Desc)`
    color: rgba(255, 255, 255, 0.8);
  `,
  GrayDesc = styled(Text14)`
    color: ${GRAY};
  `,
  Caption = styled(Text14)`
    flex-shrink: 2;
    color: ${ACTIVEGRAY};
    line-height: 23px;
    margin: 0 6px -1px 6px;
  `,
  WhiteCaption = styled(Caption)`
    color: rgba(255, 255, 255, 0.8);
  `,
  PriceRow = styled(Row)`
    flex-shrink: 1;
    align-items: flex-end;
    margin-top: 12px;
  `,
  Overlay = styled.View`
    position: absolute;
    top: 0;
    left: 0;
    width: ${wwidth - 48}px;
    height: ${wwidth - 48}px;
    background: rgba(255, 255, 255, 0.6);
  `;

let { POSTNAME, WRAPDESC, PACKENDSTTS, NRSTDAYS } = translates.PackageCard;
