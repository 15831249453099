import React from "react";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import { ages, openURL, horizCardWidth, localLang } from "../commons/utils";
import {
  Press,
  RowCentered,
  Medium14,
  GREEN,
  GRAY,
  Touch,
  DGRAY,
  Text14,
  BACKGREEN,
  Text16,
  CloseIcon,
  VideoIcon,
  CommentIcon,
  ZoomView,
  Row,
  CommentImage,
  LINEGRAY,
} from "../commons/UI";
import { DurComp, TimeComp } from "./TimeComp";
import { translates } from "../translates";

export default observer(({ event: e, passed, navigate, ...r }) => {
  const lang = r.lang || localLang(),
    rus = lang === "ru",
    { id, active, from, to, commentImage: uri, coachComment: comment } = e, // || r.events[r.id],
    isnow = Date.now(),
    going = active && from < isnow && to > isnow,
    cancel = !active,
    name =
      r.coaches[e.coachID]?.name || (rus ? "Тренер " : "Coach ") + e.coachID,
    type = CLSTYPE[lang](e.privat, e.clientCreated == r.myid, e.isTrial),
    hasComment = passed && active && comment,
    video = passed && active && e.video,
    color = going ? GREEN : active ? DGRAY : GRAY,
    txstyle = { style: { color } },
    wide = r.wide || passed,
    CancelStatus = r.profile ? Status : Text;

  const onPress = () => navigate("Event", { id, passed });

  return (
    <Touch {...{ onPress }} {...r}>
      <BookCardWrap
        style={[
          going && { backgroundColor: BACKGREEN },
          { width: wide ? undefined : bookWidth },
          // !hasComment && { height: bookHeight },
        ]}
      >
        <BookCardRow>
          <TimeRow>
            <TimeComp {...{ from, going, passed, cancel }} />
            {active && !going && <DurComp {...{ from, to, passed, cancel }} />}
          </TimeRow>

          {going && <Status>{rus ? "идет" : "ongoing"}</Status>}
          {cancel && (
            <CancelStatus>{rus ? "отменено" : "cancelled"}</CancelStatus>
          )}
        </BookCardRow>

        <BookCardRow style={{ marginTop: 8 }}>
          <Text {...txstyle} numberOfLines={1}>
            {r.program?.name || e.progID}
            {active && (
              <Age style={going && { color, opacity: 0.7 }}>
                {ages[lang].find((a) => a.id >= e.age).name}
              </Age>
            )}
          </Text>
        </BookCardRow>

        <BookCardRow style={{ marginTop: 8 }}>
          <Text {...txstyle} numberOfLines={1}>
            {name}, {type}
          </Text>
        </BookCardRow>

        {/* {hasComment && (
          <ComView> <RowCentered>   <CommentIcon style={{ marginTop: -2, marginRight: 8 }} />   <Text14 style={{ flex: 1 }} numberOfLines={1}>     {rus ? "Комментарий " + name : name + "'s comment"}:   </Text14> </RowCentered> <Row style={{ marginTop: 8 }}>   {uri && (     <CommentImage       {...{ uri, navigate }}       style={{ width: 50, height: 50, marginRight: 12 }}     />   )}   <Text numberOfLines={3}>{comment}</Text> </Row>
          </ComView>
        )} */}

        {cancel && r.profile && r.markViewed && (
          <RemoveButton onPress={() => r.markViewed(id)}>
            <CloseIcon
              onPress={() => r.markViewed(id)}
              size={20}
              color="white"
            />
            <OKText style={{ color: "white", marginLeft: 8 }}>OK</OKText>
          </RemoveButton>
        )}

        {/* {video && (
          <VideoIconView onPress={() => openURL(video)}>
            <VideoIcon />
          </VideoIconView>
        )} */}
        {(video || hasComment) && (
          <IconsRow>
            {hasComment && (
              <VideoIconView>
                <CommentIcon color={LINEGRAY} />
              </VideoIconView>
            )}
            {video && (
              <Press onPress={() => openURL(video)}>
                <VideoIconView style={hasComment && { marginLeft: -16 }}>
                  <VideoIcon />
                </VideoIconView>
              </Press>
            )}
          </IconsRow>
        )}
      </BookCardWrap>
    </Touch>
  );
});

export const CommentCard = observer(
  ({ event: e, program: prg, navigate, ...r }) => {
    const lang = r.lang || localLang(),
      rus = lang === "ru",
      { id, from, commentImage: uri, coachID } = e,
      name = r.coach?.name || (rus ? "Тренер " : "Coach ") + coachID;
    return (
      <Touch onPress={() => navigate("Event", { id, passed: true })}>
        <CommentView>
          <CommentRow1>
            <CommentCaption numberOfLines={1}>
              {name + ",  " + dayjs(from).format("D MMM")}
            </CommentCaption>
            <Gray14>{prg?.short || prg?.name || e.progID}</Gray14>
          </CommentRow1>

          <Row style={{ marginTop: 12 }}>
            {uri && (
              <CommentImage
                {...{ uri, navigate }}
                style={{ width: 56, height: 56, marginRight: 12 }}
              />
            )}
            <Comment numberOfLines={3}>{e.coachComment}</Comment>
          </Row>
        </CommentView>
      </Touch>
    );
  }
);

export const bookWidth = horizCardWidth, // (wwidth - 24 - 16) * 0.85,
  bookHeight = 112,
  commentHeight = 128,
  BookCardWrap = styled.View`
    height: ${bookHeight}px;
    background: white;
    border-radius: 18px;
    padding: 18px 16px;
  `,
  BookCardRow = styled(RowCentered)`
    height: 20px;
  `,
  Comment = styled(Text14)`
    color: ${DGRAY};
    flex: 1;
    line-height: 20px;
  `;

let CommentView = styled(BookCardWrap)`
    height: ${commentHeight}px;
  `,
  TimeRow = styled(RowCentered)`
    flex: 1;
    margin-right: 16px;
  `,
  Text = styled(Text14)`
    color: ${DGRAY};
    flex-shrink: 0;
  `,
  Age = styled(Text)`
    color: ${GRAY};
    margin-left: 8px;
  `,
  Status = styled(Medium14)`
    color: ${DGRAY};
    flex-shrink: 0;
  `,
  OKText = styled(Text16)`
    color: white;
    margin-left: 8px;
  `,
  CommentView2 = styled(ZoomView)`
    padding: 16px 12px;
    margin-top: 14px;
  `,
  CommentRow1 = styled(RowCentered)`
    flex-shrink: 1;
    margin-right: 6px;
  `,
  RemoveButton = styled(Press)`
    position: absolute;
    right: 10px;
    bottom: 12px;
    flex-direction: row;
    align-items: center;
    background: ${DGRAY};
    padding: 8px 12px;
    border-radius: 14px;
  `,
  IconsRow = styled(RowCentered)`
    position: absolute;
    right: 0;
    top: 0;
  `,
  VideoIconView = styled.View`
    padding: 14px 16px 8px;
  `,
  Gray14 = styled(Text14)`
    color: ${GRAY};
  `,
  CommentCaption = styled(Gray14)`
    flex: 1;
    margin-right: 16px;
  `;

let { CLSTYPE } = translates;
