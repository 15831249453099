import React, { useState, useEffect, useCallback, useRef } from "react";
import { FlatList, ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { onSnapshot } from "firebase/firestore";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import { useAuth, useCart, useClient, useSchool } from "../commons/Stores";
import { orderChecks } from "../commons/orderChecks";
import { findCartCross } from "../commons/CartStore";
import {
  dbEvents,
  dbPasses,
  vibroToast,
  callAlert,
  resetStackRoute,
  isbookblEvent,
  capitalize,
  genrtItemLayout,
  parseChangeText,
  durtnText,
  showToast,
  filterPassEvents,
  offline,
  offlineToast,
  wwidth,
  rootNavEvent,
  rootNavCoach,
} from "../commons/utils";
import {
  Button,
  Container,
  AbsLoader,
  BLUE,
  TitleNCloseRow,
  Text16,
  TrialComp,
  Loader,
  RowCentered,
  Press,
  RefundsComp,
  SumRow,
  BlankText,
  RED,
  TmznComp,
  GRAY,
} from "../commons/UI";
import { CartCard, cartHeight } from "../comp/EventCard";
import CoachCard from "../comp/CoachCard";
import PackageCard from "../comp/PackageCard";
import { Legals } from "./Login";
import { translates } from "../translates";

let toast = (tx) => vibroToast(tx, 3000);

export default observer(
  ({
    navigation: nav,
    route: {
      params: { coachID, eventID, quant, passID },
    },
  }) => {
    const mount = useRef(true),
      { goBack, navigate, replace } = nav,
      { myid, rus, lang, hhfrmt, setAfterLogin } = useAuth(),
      {
        programs,
        groups: { [eventID || "00"]: evt },
        updateGroup,
        deleteGroup,
      } = useSchool(),
      {
        balance,
        createOrder,
        canTrial: clientCanTrial,
        load: clientLoad,
        ordersLoad,
        handlePassListener,
        passes: { [passID || "00"]: pass },
      } = useClient(),
      ismypass = pass?.uid === myid,
      cantBuy = (evt?.privat && evt.clientsIds[0]) || (passID && !ismypass),
      [load, setLoad] = useState();

    useEffect(() => {
      return () => (mount.current = false);
    }, []);

    const gotoLogin = () => (
      navigate("ProfileStack", { screen: "Login" }),
      setAfterLogin("Cart", coachID ? { coachID } : { eventID, quant })
    );

    const OrderButton = ({ total, ...r }) => {
      let onPress = cantBuy ? null : !myid ? gotoLogin : r.onOrder;

      let text;
      if (cantBuy && passID) text = BTNTX1[lang];
      else if (cantBuy && !passID) text = BTNTX2[lang];
      else if (!myid) text = BTNTX3[lang];
      else if (r.text) text = r.text;
      else if (!total) text = rus ? "Забронировать" : "Book now";
      else if (balance < total) text = rus ? "К оплате" : "Checkout";
      else text = BTNTX4[lang] + (coachID ? "" : `, ${total}$`);

      return (
        <Button
          big
          {...{ text, onPress }}
          inactive={cantBuy || r.inactive}
          style={{ marginTop: eventID ? 24 : 12, ...r.style }}
        />
      );
    };

    //////////////////////////
    // if oordering one class from its page screen

    if (eventID) {
      const total = evt?.price * quant,
        program = programs[evt.progID],
        progName = program?.name,
        title = PGTTL[lang](progName);

      const ondb = (dc) => {
        if (!mount.current) return;
        let d = dc.exists() && dc.data();
        if (!d?.active) {
          goBack();
          deleteGroup(eventID);
          return toast(EVTER1[lang](d));
        }

        let wasEdited = d.edited !== evt.edited,
          wasBooked = !load && d.clientsIds?.includes(myid);
        if (wasEdited || wasBooked) {
          navigate("Event", { id: eventID });
          updateGroup(d);
          return toast(EVTER2[lang](wasBooked));
        }
      };

      useEffect(() => {
        const listener = onSnapshot(dbEvents(eventID), ondb);
        return () => listener();
      }, [!load]);

      const onOrder = async () => {
        if (evt.from < Date.now() - 10 * 60000)
          return callAlert(...ALRTEVT[lang]);

        if (offline()) return offlineToast();
        setLoad(true);
        let cart = [{ ...evt, client: { quant, sum: total, uid: myid } }];
        createOrder({ total, cart }, balance >= total);
      };

      return (
        <Container>
          <TitleNCloseRow {...{ title }} />
          <ScrollView
            {...{ contentContainerStyle }}
            showsVerticalScrollIndicator={false}
          >
            {TMZN}
            <CartCard
              full
              event={evt}
              {...{ lang, quant, program, navigate }}
            />
            <OrderButton {...{ onOrder, total }} />
            {LEGALSREFUNDS}
          </ScrollView>
          {load && <AbsLoader />}
        </Container>
      );
    }

    ////////////////////////////////////////
    // else are if cart of package (passID) or coach (coachID)
    ////////////////////////////////////////

    const cartKey = passID || coachID,
      {
        coaches: { [coachID]: coach },
        getCoach,
      } = useSchool(),
      {
        commonCart: { [cartKey]: cartObj },
        remove: cartRemove,
        update: updateCart,
        clearCart,
        markError,
      } = useCart(),
      cart = orderBy(Object.values(cartObj || {}), "from"),
      cartQuant = cart.length,
      cartOnly1 = cartQuant === 1,
      [first] = cart || [];

    let quantText = CLSQNT[lang](cartQuant),
      title = PGTTL2[lang](quantText) + coach?.name,
      totalRowText = (rus ? "Итого " : "Total ") + quantText;

    useEffect(() => {
      let unactual = cart.filter((e) => !e.error && !isbookblEvent(e));
      unactual.forEach(({ id }) => markError(cartKey, id, UNBOOKBL[lang]));
    }, [cart?.length]);

    const addClass = useCallback(
      () =>
        passID
          ? replace("CustomEvent", { coachID, passID })
          : rootNavCoach({ coachID, from: "Cart" }),
      []
    );

    const classErrorAlert = ({ id, ...ev }, change) => {
      ev.prog = programs[ev.progID].name;
      if (change) ev.error = change;
      if (!cartOnly1) ev.num = cart.findIndex((c) => c.id === id) + 1;

      let remove = () => (
        cartOnly1 && goBack(), cartRemove({ coachID, passID, id })
      );

      let pickChange = () =>
        ev.custom
          ? replace("CustomEvent", { coachID, id, passID })
          : ev.error === "changed"
          ? (rootNavEvent({ id, from: "Coach" }), remove())
          : (addClass(), remove());

      let pickRemove = () => (cartOnly1 && goBack(), remove());
      return callErrorAlert(ev, lang, hhfrmt, pickChange, pickRemove);
    };

    const onEventPress = (e) =>
      e.error
        ? classErrorAlert(e)
        : e.custom
        ? replace("CustomEvent", { id: e.id, coachID, passID })
        : rootNavEvent({ id: e.id, from: "Cart" });

    const onOrder = async ({ ...data }, bybalance) => {
      if (cart.some((e) => e.error))
        return classErrorAlert(cart.find((e) => e.error));

      // extra check for cross timing classes
      let iscross;
      if (cartQuant > 1)
        cart.reduce((prev, e, i) => {
          if (iscross) return prev;
          iscross = i > 0 && findCartCross(e, prev) ? e : null;
          if (iscross) markError(cartKey, e.id, "crossing");
          else prev.push(e);
          return prev;
        }, []);

      if (iscross) return classErrorAlert(iscross, "crossing");

      if (cart.some((e) => !e.client.uid)) {
        let needAddUid = cart.filter((e) => !e.client.uid);
        needAddUid.forEach((e) =>
          updateCart(cartKey, { id: e.id, client: { ...e.client, uid: myid } })
        );
      }

      if (offline()) return offlineToast();
      setLoad(true);

      let { changed } = await orderChecks(
        cartObj,
        (id, slotID) => updateCart(cartKey, { id, slotID }),
        updateGroup,
        getCoach
      );

      if (offline()) return offlineToast();

      if (changed[0]) {
        changed.forEach(
          ({ id, change }) => (
            classErrorAlert(cartObj[id], change),
            markError(cartKey, id, change),
            change == "cancelled or passed" && deleteGroup(id)
          )
        );
        return setLoad();
      }

      data.cart = cart;
      return createOrder(
        data,
        bybalance,
        () => clearCart({ passID, coachID }),
        () => setLoad()
      );
    };

    const TitleComp = <TitleNCloseRow {...{ title }} />;

    ////////////////////////////////////////
    // if ordering via a package

    if (passID) {
      const ondbPass = (doc) => {
        if (!mount.current) return;
        let d = handlePassListener(doc);
        if (d?.active && d.durLeft < 30) vibroToast(PASER1[lang]);
      };

      useEffect(() => {
        let passListener = onSnapshot(dbPasses(passID), ondbPass);
        return () => passListener();
      }, []);

      if (!first) return <Loader big />;

      if (!pass)
        return (
          <Loader big>
            <BlankText>{PASLD[lang]}</BlankText>
          </Loader>
        );

      const totalDur = cart.reduce((pr, e) => pr + (e.to - e.from), 0) / 60000,
        passDur = pass.durLeft ?? pass.duration,
        passWillRest = passDur - totalDur,
        passDurText = durtnText(passDur, "f", rus),
        canAddClass = ismypass && passDur >= totalDur + 30,
        cantUsePass =
          !ismypass ||
          passDur < totalDur ||
          cart.some((e) => !filterPassEvents(e, pass));

      const leav15min = !cantUsePass && passWillRest < 30 && passWillRest >= 15;

      let leav15Text = PASRST1[lang](passWillRest),
        leav15tip = PASRST2[lang](cartOnly1);

      let classText2 = rus ? "занятие " : "the class ";
      if (!cartOnly1)
        classText2 = rus
          ? "какое-то " + classText2
          : classText2.replace("the", "some");

      let passErrorText = !cantUsePass
        ? null
        : !ismypass
        ? BTNTX1[lang]
        : totalDur > passDur
        ? PASER2[lang] + ` (${passDurText})`
        : cart.some((e) => !e.price)
        ? classText2 + (rus ? "бесплатно" : "is for free")
        : cart.some((e) => !e.custom)
        ? classText2 + PRIVER[lang]
        : cart.some((e) => e.from > pass.to)
        ? classText2 + PASER3[lang]
        : classText2 + PASER4[lang];

      let butnText = PASBTN1[lang](cantUsePass);

      const renderItem = ({ item: e }) => {
        let { id } = e,
          onPress = () => onEventPress(e),
          remove = () => (cartOnly1 && goBack(), cartRemove({ passID, id }));
        return (
          <CartCard
            pass
            event={e}
            program={programs[e.progID]}
            errorAlert={classErrorAlert}
            {...{ lang, navigate, onPress, remove }}
          />
        );
      };

      const preorder = async () => {
        if (cantUsePass) return showToast(PASER5[lang](ismypass));
        if (offline()) return offlineToast();

        let proceed = async () => {
          if (offline()) return offlineToast();
          await onOrder({ passID });
        };

        if (leav15min) {
          let opt1 = {
            label: rus ? "Да, продолжить" : "Yes, confirm",
            onClick: proceed,
          };

          return callAlert(
            rus ? "Потеря 15 минут" : "Loosing 15 minutes",
            leav15Text + ` \n${leav15tip}. \n` + PASCNFRM[lang],
            [opt1]
          );
        }

        proceed();
      };

      const ListFooterComponent = (
        <>
          <RowCentered style={{ marginTop: 20 }}>
            <PackageCard
              small
              {...{ navigate, lang, hhfrmt }}
              p={pass}
              style={{ maxWidth: miniCardWidth }}
            />
            {canAddClass && (
              <AddPress onPress={addClass}>
                <AddText>{rus ? "+ занятие" : "+ add a class"}</AddText>
              </AddPress>
            )}
          </RowCentered>

          {cantUsePass && (
            <>
              <WarnText>{PASER7[lang](cartOnly1) + passErrorText}.</WarnText>
              {ismypass && <Caption>{PASER8[lang](cartOnly1)}</Caption>}
            </>
          )}
          {leav15min && (
            <>
              <WarnText>{leav15Text}</WarnText>
              <Caption>{leav15tip}</Caption>
            </>
          )}

          {FooterDivider}
          <SumRow big isdur name={totalRowText} sum={totalDur} />
          <OrderButton
            onOrder={preorder}
            inactive={cantUsePass}
            text={butnText}
          />
          {LEGALSREFUNDS}
        </>
      );

      return (
        <Container>
          {TitleComp}
          <FlatList
            data={cart}
            {...listProps}
            ListHeaderComponent={TMZN}
            {...{ renderItem, ListFooterComponent }}
          />
          {load && <AbsLoader />}
        </Container>
      );
    }

    ////////////////////////////////////////
    // if ordering via coach products (1on1 / Groups)

    if (coachID) {
      const { hasTrial } = coach || {},
        dur1 = (first?.to - first?.from) / 60000,
        canTrial = clientCanTrial && hasTrial,
        applyTrial = canTrial && first?.custom && dur1 <= 45;

      const [showTrial, setShowTrial] = useState(
        (canTrial && !applyTrial) || (hasTrial && !myid)
      );

      if (!first) return <Loader big />;

      const canAddClass = !applyTrial;

      const discont = applyTrial ? first.client.sum : 0,
        initSum = cart.reduce((pr, e) => pr + e.client.sum, 0),
        total = initSum - discont;

      const preorder = async () => {
        if (offline()) return offlineToast();
        let data = { total: initSum, applyTrial };
        return await onOrder(data, balance >= total);
      };

      const renderItem = ({ item: e, index: i }) => {
        let { id } = e,
          onPress = () => onEventPress(e),
          remove = () => (cartOnly1 && goBack(), cartRemove({ coachID, id }));
        return (
          <CartCard
            event={e}
            program={programs[e.progID]}
            isTrial={!i && applyTrial}
            discont={!i && discont}
            errorAlert={classErrorAlert}
            {...{ lang, remove, onPress }}
          />
        );
      };

      let trialText = "";
      if (showTrial && canTrial && !applyTrial) trialText = TRL1[lang];
      if (first.custom) trialText += rus ? "30-45 минут" : "30-45 min. long";
      else
        trialText +=
          (rus ? "частным" : "a private one") +
          (dur1 <= 45 ? "" : rus ? " и 30-45 минут" : " and 30-45 min. long");

      const ListHeaderComponent = (
        <>
          {showTrial && (
            <TrialComp
              text={trialText}
              hide={() => setShowTrial(false)}
              onPress={!myid && gotoLogin}
              style={{ marginBottom: 32 }}
            />
          )}
          {TMZN}
        </>
      );

      const ListFooterComponent = (
        <>
          <RowCentered style={{ marginTop: 20 }}>
            <CoachCard
              small
              {...{ coachID }}
              style={{ maxWidth: miniCardWidth }}
            />
            {canAddClass && (
              <AddPress onPress={addClass}>
                <AddText>{rus ? "+ занятие" : "+ add a class"}</AddText>
              </AddPress>
            )}
          </RowCentered>

          {FooterDivider}
          {!discont && <SumRow big name={totalRowText} sum={total} />}

          {discont > 0 && (
            <>
              <SumRow name={totalRowText} sum={initSum} />
              <SumRow name={rus ? "Скидка" : "Discount"} sum={-discont} />
              <SumRow
                black
                name={rus ? "Итоговая сумма" : "Final amount"}
                sum={total}
              />
            </>
          )}

          {/* if need trial, need to wait client back process loaded to check trials availability*/}
          {applyTrial && (clientLoad || ordersLoad) ? (
            <Loader style={{ marginTop: 16 }} />
          ) : (
            <OrderButton onOrder={preorder} {...{ total }} />
          )}
          {LEGALSREFUNDS}
        </>
      );

      return (
        <Container>
          {TitleComp}
          <FlatList
            data={cart}
            {...listProps}
            {...{ renderItem, ListHeaderComponent, ListFooterComponent }}
          />
          {load && <AbsLoader />}
        </Container>
      );
    }
  }
);

let TMZN = <TmznComp style={{ marginTop: -4, marginBottom: 16 }} />,
  FooterDivider = <View style={{ flex: 1, minHeight: 36 }} />;

let LEGALSREFUNDS = (
  <>
    <RefundsComp cart />
    <Legals cart />
  </>
);

let callErrorAlert = (
  { num, from, custom, error },
  lang,
  hhfrmt,
  onPress,
  onPress2
) => {
  let rus = lang === "ru",
    title = ALRTL[lang] + (num ? ` #${num}` : ""),
    timetx = dayjs(from).format("D MMM, dddd " + hhfrmt);

  let beginText = num ? ALRTX[lang](num, timetx) : "";

  let changeText = parseChangeText(error, lang);

  let offerText = error.includes("cross")
    ? OFR1[lang]
    : custom
    ? OFR2[lang]
    : OFR3[lang](error === "changed");

  let text =
    beginText + (num ? changeText : capitalize(changeText)) + offerText;

  let opt1text;
  if (custom) opt1text = rus ? "Изменить" : "Edit it";
  else opt1text = rus ? "Заменить" : "Replace";

  return callAlert(title, text, [
    { label: opt1text, onClick: onPress },
    { label: rus ? "Удалить" : "Remove it", onClick: onPress2 },
  ]);
};

let miniCardWidth = wwidth - 48 - 112 - 24;

let keyExtractor = (it) => it.id,
  cartLayout = (_, i) => genrtItemLayout(cartHeight + 20, i),
  ItemSeparatorComponent = styled.View`
    height: 20px;
  `,
  contentContainerStyle = {
    flexGrow: 1,
    padding: 24,
    paddingTop: 12,
    paddingBottom: 32,
  };

let listProps = {
  keyExtractor,
  getItemLayout: cartLayout,
  ItemSeparatorComponent,
  contentContainerStyle,
  ListFooterComponentStyle: { flexGrow: 1 },
};

let AddPress = styled(Press)`
    justify-content: center;
    height: 56px;
    padding-left: 16px;
  `,
  AddText = styled(Text16)`
    color: ${BLUE};
  `,
  WarnText = styled(Text16)`
    color: ${RED};
    margin-top: 16px;
  `,
  Caption = styled(Text16)`
    color: ${GRAY};
    margin-top: 8px;
  `;

let { CLSQNT } = translates;

let {
  BTNTX1,
  BTNTX2,
  BTNTX3,
  BTNTX4,
  PGTTL,
  PGTTL2,
  EVTER1,
  EVTER2,
  ALRTEVT,
  PASER1,
  PASER2,
  PASER3,
  PRIVER,
  PASER4,
  PASER5,
  PASER7,
  PASER8,
  PASCNFRM,
  PASLD,
  PASRST1,
  PASRST2,
  TRL1,
  ALRTL,
  ALRTX,
  OFR1,
  OFR2,
  OFR3,
  UNBOOKBL,
  PASBTN1,
} = translates.Cart;
