import { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import {
  onSnapshot,
  where,
  query,
  orderBy,
  limit,
  updateDoc,
} from "firebase/firestore";
import {
  ref as rdbref,
  query as rdbquery,
  orderByChild,
  equalTo,
  onValue,
  off as rdboff,
} from "firebase/database";
import { useAuth, useCart, useClient, useSchool } from "./Stores";
import { navigate } from "./RootNavigation";
import {
  dbCoaches,
  dbBooks,
  dbPassed,
  dbOrders,
  dbUsers,
  dbPasses,
  rdbProgs,
  rdbLevels,
  rdbPackages,
  dbQueryToObj,
  tmzn,
  timezoneName as tmzName,
  checkCantrial,
  parseBalance,
  vibroToast,
  dayAgo,
  device,
  getLocalUid,
  rdb,
  localLang,
  rootNavBalance,
} from "./utils";
import { translates } from "../translates";

export default observer((pr) => {
  const mount = useRef(true),
    { latestCoachCart } = useCart(),
    { getAllGroups, load: initLoad, setState } = useSchool(),
    {
      myid,
      load: authLoad,
      isactive,
      profile,
      updateFields,
      localUpdateFields,
      balance,
      afterLoginRoute,
      setAfterLogin,
      lang,
    } = useAuth(),
    {
      load: clientLoad,
      booksQuant,
      handleBooksListener,
      canTrial,
      lastOrder,
      ordersLoad,
      setOrdersLoad,
      setOrder,
      setPassed,
      setPasses,
      setPass,
    } = useClient(),
    { added: lastCartChange } = latestCoachCart || {};

  useEffect(() => {
    let coachesListener = onSnapshot(
      query(dbCoaches(), where("status", "==", "approved")),
      (q) => q.size && setState("initCoaches", dbQueryToObj(q))
    );

    let types = [
        "initPrograms",
        "initLevels",
        "initPackages",
        "packagesColors",
      ],
      dbrefs = types.map((tp, i) =>
        !i
          ? rdbProgs
          : i === 1
          ? rdbLevels
          : i === 2
          ? rdbquery(rdbPackages(), orderByChild("active"), equalTo(true))
          : rdbref(rdb, tp)
      );

    types.forEach((k, i) =>
      onValue(dbrefs[i], (v) => v.exists() && setState(k, v.val()))
    );

    return () => (
      (mount.current = false),
      coachesListener(),
      dbrefs.forEach((r) => rdboff(r))
    );
  }, []);

  useEffect(() => {
    if (!initLoad) getAllGroups();
  }, [!initLoad]);

  useEffect(() => {
    if (isactive) {
      const booksListen = onSnapshot(dbBooks(myid), handleBooksListener);
      return () => booksListen();
    }
  }, [isactive]);

  useEffect(() => {
    if (myid && afterLoginRoute)
      navigate(afterLoginRoute.screen, afterLoginRoute.params),
        setAfterLogin(null);
  }, [!myid]);

  useEffect(() => {
    if (myid && !profile.lang) updateFields({ lang: lang || "en" });
  }, [myid && !profile.lang]);

  // open cart after login, if cart was changed less than 3 mins ago
  useEffect(() => {
    if (!afterLoginRoute && myid && lastCartChange > Date.now() - 3 * 60000) {
      const wasLogged = getLocalUid();
      if (!wasLogged) navigate("Cart", latestCoachCart);
    }
  }, [lastCartChange && !afterLoginRoute && myid]);

  //  check Timezone and device, get last order
  useEffect(() => {
    if (isactive && !clientLoad) {
      // active packages passes listener
      let passesListener = onSnapshot(
        query(
          dbPasses(),
          where("uid", "==", myid),
          where("active", "==", true),
          where("to", ">", Date.now())
        ),
        (q) => {
          if (!q.empty && mount.current) setPasses(dbQueryToObj(q));
        }
      );

      const orderListener = onSnapshot(
        query(
          dbOrders(),
          where("client", "==", myid),
          orderBy("created", "desc"),
          limit(1)
        ),
        (q) => {
          if (!mount.current) return;
          if (q.empty) setOrdersLoad(false);
          else setOrder(q.docs[0].data());
        }
      );

      // last package order listener to check if need payment
      const packOrderListener = onSnapshot(
        query(
          dbPasses(),
          where("uid", "==", myid),
          orderBy("created", "desc"),
          limit(1)
        ),
        (q) => {
          if (!q.empty && mount.current) setPass(q.docs[0].data());
        }
      );

      //  last passed event listener to check if need rate or has a coach comment
      const listenPassed = onSnapshot(
        query(
          dbPassed(),
          where("clientsIds", "array-contains", myid),
          where("active", "==", true),
          orderBy("to", "desc"),
          limit(1)
        ),
        (q) => {
          if (!q.empty && mount.current) setPassed(q.docs[0].data());
        }
      );

      return () => (
        passesListener(), orderListener(), packOrderListener(), listenPassed()
      );
    }
  }, [isactive && !clientLoad]);

  useEffect(() => {
    if (isactive && !authLoad && !clientLoad) {
      const checkProflData = () => {
        if (!mount.current) return;
        let upd = {};
        if (device !== profile.device) upd.device = device;
        if (tmzn !== profile.timezone) upd.timezone = tmzn;
        if (tmzName !== profile.timezoneName) upd.timezoneName = tmzName;
        if (Object.keys(upd)[0]) updateFields(upd);
      };

      setTimeout(() => checkProflData(), 3000);
    }
  }, [isactive && !authLoad && !clientLoad]);

  // user balance listener,
  useEffect(() => {
    const ondb = (dc) => {
      if (!dc.exists() || !mount.current) return;
      let d = dc.data(),
        diff = parseBalance(d.balance) - balance;
      localUpdateFields(d);

      if (diff) {
        let text = BALTX[localLang()](diff);
        vibroToast(text, 0, 0, rootNavBalance);
      }

      // check if lastorder has stripe url, means it was for an unactual balance, remove it
      if (diff && lastOrder) {
        let o = lastOrder;
        if (o.status == "pending" && o.created > dayAgo() && o.payurl)
          updateDoc(dbOrders(o.id), { payurl: null });
      }
    };

    if (isactive && !clientLoad) {
      const listener = onSnapshot(dbUsers(myid), ondb);
      return () => listener();
    }
  }, [isactive && !clientLoad, balance]);

  // check if can have a trial. !ordersLoad goes after !clientLoad, so bookings are already loaded
  useEffect(() => {
    if (isactive && !ordersLoad && canTrial !== false) {
      let updateTrial = (b) => updateFields({ canTrial: b });
      let checkTrial = async () => {
        if (!lastOrder && !booksQuant) return canTrial ? 0 : updateTrial(true);
        let dbcheck = await checkCantrial(lastOrder, myid, setOrder);
        if (dbcheck !== canTrial) updateTrial(dbcheck);
      };
      checkTrial();
    }
  }, [isactive && !ordersLoad && canTrial !== false, lastOrder]);

  return pr.children;
});

let { BALTX } = translates.EffectsProvider;
